import { WrField } from './CommonComponentWrappers';
import { ChangeEventHandler, useContext, useState } from 'react';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import {
  CoverSheetMode, coverSheetModeOptions,
  CustomFieldConfiguration, CustomFieldType,
  FormCode,
  FormCodeUnion,
  FormInstance,
  FormSigningState, MaterialisedPropertyData,
  TransactionMetaData
} from '@property-folders/contract';
import { FormUtil } from '@property-folders/common/util/form';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { v4 } from 'uuid';
import { useStore } from 'react-redux';
import { YjsDocContext } from '../../context/YjsDocContext';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { ensureFormStatesInstances } from '@property-folders/common/yjs-schema/property/form';
import { ensureCoverSheetCustomisation, getCustomFieldsFilterWhenCoveringFor } from '@property-folders/common/yjs-schema/property/cover-sheet-customisation';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useNavigate } from 'react-router-dom';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { YValue } from './YValue';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { getPageInfo, mapFontName, PdfInformationExtractor, SupportedFontFamily } from '@property-folders/common/util/pdf';
import { clearTaggedFields } from '@property-folders/common/util/pdf/clear-tagged-fields';
import { ExtractedField } from '@property-folders/common/util/pdf/types';
import { defaultFontSize, defaultLineHeight } from '@property-folders/contract/property/meta';
import clsJn from '@property-folders/common/util/classNameJoin';
import { CoordinateMath, HtmlPdfReflection, PageDims } from '@property-folders/common/util/coords';
import { ColourUtil } from '@property-folders/common/util/colour';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { useQpdfWorker } from '../../hooks/useQpdfWorker';

export function OutputFileCustomisationSection({ formCode, formId, breadcrumbs }: { formCode: FormCodeUnion, formId: string, breadcrumbs: CrumbDefn[] }) {
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '', ydocForceKey: PropertyRootKey.Data });
  const navigate = useNavigate();
  const { docName: propertyId, ydoc } = useContext(YjsDocContext);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const store = useStore();
  const { value: formInstance, fullPath: parentPath, transactionMetaRootKey, transactionRootKey } = useLightweightTransaction<FormInstance>({
    myPath: FormUtil.getFormPath(formCode, formId),
    bindToMetaKey: true
  });
  const { updateDraft: updateForm } = useYdocBinder<FormInstance>({ path: parentPath, bindToMetaKey: true });
  // the referenced uploaded file should be in the root,
  // but because we could be editing a contract in a sublineage,
  // the root key is explicitly specified
  const { updateDraft: updateMeta } = useYdocBinder<TransactionMetaData>({ path: '', bindToMetaKey: true, ydocForceKey: PropertyRootKey.Meta });
  const [error, setError] = useState('');
  const qpdfWorker = useQpdfWorker();

  const handleDrop = async (files: File[]) => {
    if (!updateMeta) return;
    if (!updateForm) return;
    if (!propertyId) return;
    setError('');
    const file = files.shift();
    if (!file) {
      setError('Invalid file');
      return;
    }
    const name = file.name;
    const type = file.type;
    let customFields: CustomFieldConfiguration[] = [];

    const decryptedPdf = await qpdfWorker.decrypt({ pdf: await file.arrayBuffer() });

    // future: extract existing form fields, identify things starting with @greatforms. (? or maybe we can leverage existing field extraction stuff)
    const { isEncrypted, pdf } = (await processPdf(
      decryptedPdf,
      async doc => {
        if (doc.getPageCount() !== 1) {
          setError('Invalid page count');
          throw new Error('Invalid page count');
        }
        // extract form fields
        const extract = new PdfInformationExtractor(doc);
        const fields = (await extract.getFieldPositions(true)).filter(field => field.id.startsWith('@@greatforms-'));
        const pageDims = doc.getPages().map(p => getPageInfo(doc, p).dims);
        const typeFilter = getCustomFieldsFilterWhenCoveringFor([formCode]);
        customFields = fields.flatMap(f => mapExtractedFieldToCustomFields(f, pageDims))
          .filter(f => typeFilter(f.type));
        clearTaggedFields(doc);
      }
    )) || {};
    if (isEncrypted) {
      setError('PDF is Encrypted');
      return;
    }
    if (!pdf) {
      return;
    }
    // todo: restrict to first page
    const flat = new Blob([pdf], { type });

    const newFormId = v4();
    const newFormCode = FormCode.UploadedDocument;
    // future: after upload v2 ships, generate a separate id.
    const newFileId = v4();

    updateMeta(draft => {
      const instances = ensureFormStatesInstances(draft, newFormCode);
      const now = Date.now();
      instances.push({
        id: newFormId,
        formCode: newFormCode,
        created: now,
        modified: now,
        dataModified: now,
        annexures: [],
        upload: {
          v: 2,
          files: [{
            id: newFileId,
            contentType: type,
            name,
            created: now,
            size: flat.size,
            uploader: sessionInfo?.agentUuid ? {
              id: sessionInfo.agentUuid,
              linkedSalespersonId: sessionInfo.agentId,
              name: sessionInfo?.name,
              email: sessionInfo?.email
            } : undefined
          }],
          name,
          editableAsCoverFor: [formCode]
        },
        signing: {
          state: FormSigningState.None,
          customFields
        }
      });
    });

    updateForm(draft => {
      const cover = ensureCoverSheetCustomisation(draft);
      cover.custom = {
        file: {
          id: newFormId,
          code: newFormCode
        }
      };
    });

    await FileStorage.write(
      newFileId,
      FileType.PropertyFile,
      type,
      flat,
      StorageItemSyncStatus.PendingUpload,
      {
        propertyFile: {
          formCode: newFormCode,
          formId: newFormId,
          propertyId: propertyId || ''
        }
      },
      {
        store,
        ydoc
      }
    );

    const headline = generateHeadlineFromMaterialisedData(transRoot);
    const propertySlug = LinkBuilder.seoFriendlySlug(propertyId, headline);
    const documentSlug = LinkBuilder.seoFriendlySlug(newFormId);
    const path = `/properties/${propertySlug}/document/${documentSlug}/cover-config`;
    navigate(path, { state: { breadcrumbs } });
  };

  const handleUpload: ChangeEventHandler<HTMLInputElement> = e => {
    e.stopPropagation();
    e.preventDefault();
    e.target.files && handleDrop([...e.target.files]);
  };

  const { getRootProps, getInputProps, isDragAccept, inputRef } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    accept: { 'application/pdf': [] },
    multiple: false
  });

  return <div className='w-100'>
    <div className='w-100'>
      <WrField.CheckRadio
        radioType='radio'
        label={'What type of cover sheet?'}
        options={coverSheetModeOptions}
        valueType='int'
        parentPath={parentPath}
        myPath={'cover.mode'}
        name={'cover.mode'}
        bindToMetaKey={true}
      />
    </div>
    {formInstance?.cover?.mode === CoverSheetMode.Custom && <div {...getRootProps({
      className: clsJn('mt-2 ms-2 me-2', isDragAccept ? 'drop-target drop-accept' : '')
    })}>
      <input {...getInputProps({
        className: 'd-none',
        accept: '.pdf',
        onChange: handleUpload
      })} />
      <div className='d-flex flex-row justify-content-between'>
        {formInstance?.cover?.custom?.file ? <YValue<FormInstance>
          parentPath={FormUtil.getFileFormPath(formInstance.cover.custom.file)}
          ydocForceKey={PropertyRootKey.Meta}
          bindToMetaKey={true}
        >{value =>
            <div className='d-flex flex-column'>
              <span>{value?.upload?.name}</span>
              {!!value?.created && <small className='text-muted'>Uploaded on: {formatTimestamp(value?.created)}</small>}
            </div>
          }</YValue> : <div className='d-flex flex-column'>
          <span>No file selected</span>
          <small className='text-muted'>Click Upload or drag in a file</small>
        </div>}
        <div className='d-flex flex-row gap-2'>
          <Button variant='outline-secondary' onClick={() => inputRef?.current?.click()}>Upload</Button>
          {formInstance?.cover?.custom?.file && <Button
            variant='outline-secondary'
            onClick={() => {
              if (!propertyId) return;
              if (!formInstance.cover?.custom?.file?.id) return;
              const headline = generateHeadlineFromMaterialisedData(transRoot);
              const propertySlug = LinkBuilder.seoFriendlySlug(propertyId, headline);
              const documentSlug = LinkBuilder.seoFriendlySlug(formInstance.cover.custom.file.id);
              const path = `/properties/${propertySlug}/document/${documentSlug}/cover-config`;
              navigate(path, { state: { breadcrumbs } });
            }}>Configure</Button>}
          {formInstance?.cover?.custom?.file && <Button variant='outline-secondary' onClick={() => {
            updateForm?.(draft => {
              const cover = ensureCoverSheetCustomisation(draft);
              if (cover?.custom?.file) {
                delete cover.custom.file;
              }
            });
          }}>Remove</Button>}
        </div>
      </div>
      {error && <span className='text-danger'>{error}</span>}
    </div>}
  </div>;
}

function mapExtractedFieldToCustomFields(field: ExtractedField, pageDims: PageDims[]): CustomFieldConfiguration[] {
  const id = field.id.toLowerCase();
  if (!id.startsWith('@@greatforms-')) return [];
  const type = getCustomFieldType(field.id.replace('@@greatforms-', ''));
  if (!type) return [];
  return field.positions.map<CustomFieldConfiguration>(position => {
    const page = pageDims[position.page];
    const pt = CoordinateMath.orientToPage(position, page, HtmlPdfReflection);
    const rect = CoordinateMath.absRect({
      x: pt.x,
      y: pt.y,
      width: position.width,
      height: -position.height
    }, page.degrees);

    return {
      type,
      id: v4(),
      position: {
        ...rect,
        page: position.page
      },
      fontSize: field.appearance?.fontSize || defaultFontSize,
      lineHeight: field.appearance?.fontSize || defaultLineHeight,
      fontFamily: getFontFamilyOption(field.appearance?.fontName),
      fontColour: scaleAndMapColour(field.appearance?.colour) ?? '#000000',
      bg: Boolean(field.appearance?.backgroundColour),
      bgColour: scaleAndMapColour(field.appearance?.backgroundColour),
      border: Boolean(field.appearance?.borderWidth && field.appearance?.borderColour),
      borderColour: scaleAndMapColour(field.appearance?.borderColour),
      borderWidth: field.appearance?.borderWidth,
      borderStyle: field.appearance?.borderStyle
    };
  });
}

type ValidCustomFieldTypes =
  | CustomFieldType.settlement
  | CustomFieldType.salesperson
  | CustomFieldType.purchasePrice
  | CustomFieldType.primaryPurchaser
  | CustomFieldType.primaryVendor
  | CustomFieldType.proposedAllotments
  | CustomFieldType.propertySummary
  | CustomFieldType.saleAddress
  | CustomFieldType.saleTitle;

function getCustomFieldType(id: string): undefined | ValidCustomFieldTypes {
  switch (id) {
    case 'proposed-allotments':
      return CustomFieldType.proposedAllotments;
    case 'property-summary':
      return CustomFieldType.propertySummary;
    case 'sale-address':
      return CustomFieldType.saleAddress;
    case 'sale-title':
      return CustomFieldType.saleTitle;
    case 'primary-vendor':
      return CustomFieldType.primaryVendor;
    case 'primary-purchaser':
      return CustomFieldType.primaryPurchaser;
    case 'purchase-price':
      return CustomFieldType.purchasePrice;
    case 'settlement':
      return CustomFieldType.settlement;
    case 'salesperson':
      return CustomFieldType.salesperson;
    default:
      return undefined;
  }
}

// the options are ['Roboto', 'Open Sans', 'Courier Prime', 'Playfair Display']
function getFontFamilyOption(value?: string): string {
  switch (mapFontName(value)) {
    case SupportedFontFamily.CourierPrimeRegular:
      return 'Courier Prime';
    case SupportedFontFamily.OpenSansRegular:
      return 'Open Sans';
    case SupportedFontFamily.PlayfairDisplayRegular:
      return  'Playfair Display';
    case SupportedFontFamily.RobotoRegular:
    default:
      return 'Roboto';
  }
}

function scaleAndMapColour(colour?: { r: number, g: number, b: number }): string | undefined {
  if (!colour) return undefined;
  const { r, g, b } = colour;
  return ColourUtil.toHex({
    r: 255 * r,
    g: 255 * g,
    b: 255 * b
  });
}
