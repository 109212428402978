import { Button, Form, Modal } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { useNavigate } from 'react-router-dom';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { seoFriendlySlug } from '@property-folders/common/util/url';
import { UploadDocumentUtil, UploadFileInfo } from '@property-folders/components/hooks/useUploadedDocumentUpload';
import * as Y from 'yjs';

export function WithinPropertyUploadFilesModal({
  onClose,
  fileInfos,
  fallbackDoc,
  fallbackPropertyId
}: {
  onClose: () => void,
  fileInfos: UploadFileInfo[],
  fallbackPropertyId?: string,
  fallbackDoc?: Y.Doc
}) {
  const navigate = useNavigate();
  const { ydoc: contextDoc, docName: contextPropertyId } = useContext(YjsDocContext);
  const { instance: yManager } = useContext(YManagerContext);
  const { instance: fileSync } = useContext(FileSyncContext);
  const [processing, setProcessing] = useState(false);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const [target, setTarget] = useState<'separate' | 'envelope'>('separate');
  const ready = Boolean(yManager);

  const doc = contextDoc || fallbackDoc;
  const propertyId = contextPropertyId || fallbackPropertyId;

  if (!(doc && propertyId)) {
    return <></>;
  }

  return <Modal show backdrop='static'>
    <Modal.Header>
      <Modal.Title>Upload documents</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p className='fs-5'>How should these documents be organised?</p>
      <Form.Check
        type='radio'
        id='files-organise-envelope'
        name='files-organise'
        value='envelope'
        label='Combine all documents in a single envelope. They will be signed all at once, by the same parties.'
        checked={target === 'envelope'}
        onChange={() => setTarget('envelope')}
      />
      <Form.Check
        type='radio'
        id='files-organise-separate'
        name='files-organise'
        value='separate'
        label='Keep the documents separate.'
        checked={target === 'separate'}
        onChange={() => setTarget('separate')}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' disabled={processing} onClick={onClose}>Cancel</Button>
      <AsyncButton
        disabled={!ready}
        onClick={async () => {
          if (!fileSync) return;
          if (!yManager) return;
          if (!sessionInfo) return;
          const entity = sessionInfo.entities.at(0);
          if (!entity) return;
          setProcessing(true);
          try {
            const forms = await UploadDocumentUtil.addToPropertyFolder({
              propertyId,
              fileInfos,
              fileSync,
              sessionInfo,
              doc,
              singleEnvelope: target === 'envelope'
            });
            navigate(`/properties/${seoFriendlySlug(propertyId)}/documents/`, { state: { highlight: forms.map(f => f.formId) } });
          } finally {
            setProcessing(false);
            onClose();
            // maybe navigate away too
          }
        }}
      >
        Upload
      </AsyncButton>
    </Modal.Footer>
  </Modal>;
}
