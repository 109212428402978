import { LinkBuilder } from '../util/LinkBuilder';
import { WrappedFetch } from '../client-api/wrappedFetch';
import { FormItem } from '@property-folders/contract/rest/forms';
import { FormSigningState, formSigningStateToString } from '@property-folders/contract';
import { BaseAjaxResponse } from '../client-api/legacyApi';
import { CardDetails } from '@property-folders/components/dragged-components/payment/CardDetails';
import { CardType } from '@property-folders/components/dragged-components/CardLogo';

export interface ListSubscriptionFormsResponse {
  results: FormItem[]
}

export type LogoType = 'Logo' | 'PurchaserRegistrationImage';

export class AjaxPhp {
  static async login(email: string, password: string) {
    const fdata = new FormData();
    fdata.append('email', email.toLowerCase());
    fdata.append('password', password);
    try {
      const response = await fetch(`${LinkBuilder.ajax}?action=login`, {
        method: 'post',
        body: fdata
      });
      const body = await response.json();

      if (!body?.success) {
        console.log('login failure', body);
        return false;
      }

      return true;
    } catch (err: unknown) {
      console.error('login failure', err);
      return false;
    }
  }

  static async loginAs(userId: number) {
    const response = await fetch(`${LinkBuilder.ajax}?action=loginas&UserID=${userId}`, {
      method: 'post'
    });

    return response.status === 200;
  }

  static async extendSession() {
    try {
      const response = await fetch(`${LinkBuilder.ajax}?action=extendsession`, {
        method: 'post'
      });
      const body = await response.json();

      if (!body?.success) {
        console.log('session extension failure', body);
        return false;
      }
      return true;
    } catch (err: unknown) {
      console.error('session extension failure', err);
      return false;
    }
  }

  static listSubscriptionForms(entityId: string | number, withClauses = false) {
    entityId = String(entityId);

    return WrappedFetch.json<ListSubscriptionFormsResponse>(`${LinkBuilder.legacyApi(`/form/entity/${entityId}?withClauses=${withClauses}`)}`);
  }

  static async loginWithCode(code: string) {
    return WrappedFetch.json<{ success: boolean }>(`${LinkBuilder.ajax}?action=login&code=${code}`);
  }

  static async archiveDocument(id: number | string) {
    return WrappedFetch.json<{ success: boolean }>(`${LinkBuilder.ajax}?action=archivedocument&DocumentID=${id}`);
  }

  static async unarchiveDocument(id: number | string) {
    return WrappedFetch.json<{ success: boolean }>(`${LinkBuilder.ajax}?action=unarchivedocument&DocumentID=${id}`);
  }

  static async generatePdf(id: number | string, download?: boolean) {
    return WrappedFetch.json<{ success: boolean; link: string; name: string }>(
      LinkBuilder.ajaxRequest({
        action: 'generatepdf',
        DocumentID: id,
        Download: !!download
      })
    );
  }

  static generatePdfUrl(id: number | string) {
    return LinkBuilder.reaformsFromRoot(`/tools/GeneratePDF.php?DocID=${id}`, true);
  }

  static generateAuditPdfUrl(id: number | string) {
    return LinkBuilder.ajaxRequest({ action: 'generateauditpdf', DocumentID: id });
  }

  static async renameDocument({ id, newName }: { id: number | string, newName: string }) {
    return WrappedFetch.json<{ success: boolean; }>(LinkBuilder.ajaxRequest({
      action: 'renamedocument',
      DocumentID: id,
      renamename: newName
    }));
  }

  static async renameFolder({ folderId, newName }: { folderId: number; newName: string; }) {
    return WrappedFetch.json<{ success: boolean; }>(LinkBuilder.ajaxRequest({
      action: 'renamefolder',
      FolderID: folderId,
      RenameFolderName: newName
    }));
  }

  static async createFolder({ entityId, folderName }: { entityId: number; folderName: string; }) {
    return WrappedFetch.json<{ success: boolean; }>(LinkBuilder.ajaxRequest({
      action: 'createfolder',
      EntityID: entityId,
      NewFolderName: folderName
    }));
  }

  static async deleteFolder({ folderId }: { folderId: number; }) {
    return WrappedFetch.json<{ success: boolean; }>(LinkBuilder.ajaxRequest({
      action: 'deletefolder',
      FolderID: folderId
    }));
  }

  static async moveFolder({ documentId, folderId }: { documentId: number; folderId: number; }) {
    return WrappedFetch.json<{ success: boolean; }>(LinkBuilder.ajaxRequest({
      action: 'movedocument',
      DocumentID: documentId,
      FolderID: folderId
    }));
  }

  static async assignAgentToDocument({ documentId, agentId }: {
    documentId: number | string,
    agentId: number | string
  }) {
    return WrappedFetch.json<{ success: boolean }>(LinkBuilder.ajaxRequest({
      action: 'assignagent',
      DocumentID: documentId,
      assign_available: agentId
    }));
  }

  static async uploadLogo({
    entityUuid,
    type,
    data
  }: {
    entityUuid: string,
    type: LogoType,
    data: Blob
  }) {
    const fdata = new FormData();
    fdata.append('LogoUpload', data);
    return WrappedFetch.json<{ success: boolean, url: string, urlLegacy: string }>(LinkBuilder.ajaxRequest({
      action: 'uploadlogo',
      EntityUUID: entityUuid,
      Type: type
    }), {
      method: 'post',
      body: fdata
    });
  }

  static async removeLogo({
    entityUuid,
    type
  }: {
    entityUuid: string,
    type: LogoType
  }) {
    return WrappedFetch.json<{ success: boolean }>(LinkBuilder.ajaxRequest({
      action: 'removelogo',
      EntityUUID: entityUuid,
      Type: type
    }), {
      method: 'post'
    });
  }

  static async getLogoUrl({
    entityUuid,
    type
  }: {
    entityUuid: string,
    type: LogoType
  }) {
    return WrappedFetch.json<{ success: boolean, url: string }>(LinkBuilder.ajaxRequest({
      action: 'getlogourl',
      EntityUUID: entityUuid,
      Type: type
    }), {
      method: 'post'
    });
  }

  static async getEntitySettingsInfo({
    entityUuid,
    signal
  }: {
    entityUuid: string,
    signal: AbortSignal
  }) {
    return WrappedFetch.json<GetEntitySettingsInfoResponse>(LinkBuilder.ajaxRequest({
      action: 'getentitysettingsinfo',
      EntityUUID: entityUuid
    }), {
      method: 'post',
      signal
    });
  }

  static async saveTeam(props: {
    teamId: number,
    formData: FormData
  }) {
    return WrappedFetch.json<BaseAjaxResponse & { message?: string }>(LinkBuilder.ajaxRequest({
      action: 'team_save',
      TeamID: props.teamId
    }), {
      method: 'POST',
      body: props.formData
    });
  }

  static async adminSaveAgent(props: {
    agentId: number,
    formData: FormData
  }) {
    return WrappedFetch.json<BaseAjaxResponse & { message?: string }>(LinkBuilder.ajaxRequest({
      action: 'admin_saveagent',
      AgentID: props.agentId
    }), {
      method: 'POST',
      body: props.formData
    });
  }

  static async getTeamAddableMembers(props: {
    teamId: number,
    entityId: number,
    teamType?: number
  }) {
    return WrappedFetch.json<BaseAjaxResponse & { items: { value: string, label: string, auto?: boolean  }[] }>(LinkBuilder.ajaxRequest({
      action: 'team_get_addable',
      TeamID: props.teamId,
      EntityID: props.entityId,
      TeamType: props.teamType
    }), { method: 'POST' });
  }

  static teamCheckName(props: {
    teamId: number,
    entityId: number,
    name: string,
    signal: AbortSignal
  }) {
    return WrappedFetch.json<BaseAjaxResponse & { message?: string }>(LinkBuilder.ajaxRequest({
      action: 'team_check_name',
      TeamID: props.teamId,
      EntityID: props.entityId,
      Name: props.name
    }), { method: 'POST', signal: props.signal });
  }

  static async saveAgent(props: {
    agentId: number,
    formData: FormData
  }) {
    return WrappedFetch.json<BaseAjaxResponse & { message?: string }>(LinkBuilder.ajaxRequest({
      action: 'saveagent',
      AgentID: props.agentId
    }), {
      method: 'POST',
      body: props.formData
    });
  }

  static async getEntitiesUserTeams(props: {
    entityIds: number[],
    agentId: number
  }) {
    const fd = new FormData();
    for (const id of props.entityIds) {
      fd.append('EntityIDs[]', id.toString());
    }
    fd.append('AgentID', props.agentId.toString());
    return WrappedFetch.json<BaseAjaxResponse & {
      items: { value: string, label: string, subtitle?: string }[]
    }>(LinkBuilder.ajaxRequest({ action: 'getentitiesuserteams' }), {
      method: 'POST',
      body: fd
    });
  }

  static async getDocumentMeta({
    documentId,
    signal
  }: {
    documentId: string | number,
    signal?: AbortSignal
  }) {
    return WrappedFetch.json<GetDocumentMetaResponse>(LinkBuilder.legacyApi(`/document/${documentId}`), {
      method: 'get',
      signal
    }).then(r => ({
      ...r,
      signatures: r?.signatures.map(s => s.trim()) ?? []
    }) as GetDocumentMetaResponse);
  }

  static async setDocumentPropertyFolder({
    documentId,
    signal,
    propertyFolderId,
    propertyFormId
  }: {
    documentId: string | number,
    propertyFolderId: string;
    propertyFormId: string;
    signal: AbortSignal
  }) {
    return WrappedFetch.bare(LinkBuilder.legacyApi(`/document/${documentId}/property-folder`), {
      method: 'put',
      signal,
      body: JSON.stringify({
        propertyFolderId,
        propertyFormId
      })
    });
  }

  static async updateSubscriptionFolderStatus({
    documentId,
    signal,
    status
  }: {
    documentId: string | number,
    status: FormSigningState,
    signal?: AbortSignal
  }) {
    return WrappedFetch.bare(LinkBuilder.legacyApi(`/document/${documentId}/property-folder/status`), {
      method: 'put',
      signal,
      body: JSON.stringify({
        status: formSigningStateToString(status)
      })
    });
  }

  static async cloneSubsciptionDocument({
    documentId,
    signal,
    name
  }: {
    documentId: string | number,
    signal?: AbortSignal
    name: string;
  }) {
    const body = new FormData();
    body.set('newname', name);
    body.set('DocumentID', String(documentId));

    return WrappedFetch.json<{ documentid: string }>(LinkBuilder.ajaxRequest({ action: 'clonedocument' }), {
      method: 'post',
      signal,
      body
    });
  }

  static async gaGetFeatureFlags({ entityId, signal }: { entityId: number, signal?: AbortSignal }) {
    return WrappedFetch.json<GaGetFeatureFlagsResponse>(LinkBuilder.ajaxRequest({
      action: 'ga_get_entity_feature_flags',
      EntityID: entityId.toString()
    }), { method: 'POST', signal });
  }

  static async gaSetFeatureFlag({
    entityId,
    name,
    value,
    signal
  }: {
    entityId: number,
    name: string,
    value: boolean,
    signal?: AbortSignal
  }) {
    return WrappedFetch.json<BaseAjaxResponse & { value: boolean }>(LinkBuilder.ajaxRequest({
      action: 'ga_set_entity_feature_flag',
      EntityID: entityId.toString(),
      Name: name,
      Value: value
    }), { method: 'POST', signal });
  }

  static async listTitlesForAgent(opts: { signal?: AbortSignal } = {}) {
    const { signal } = opts;

    const res = await WrappedFetch.json<{ results: ListTitlesForAgentResponse[] }>(LinkBuilder.legacyApi('/title'), {
      method: 'get',
      signal
    });

    return res?.results ?? [];
  }

  static async loadEntity(opts: { entityId: number; signal?: AbortSignal }) {
    const { signal, entityId } = opts;
    return await WrappedFetch.json<{
      id: string;
      companyName: string;
      tradingName: string;
      business: string;
      profileName: string;
      address1: string;
      addressDisplay: string;
      suburb: string;
      postCode: string;
      state: string;
      country: string;
      gnafId?: string;
      abn: string;
      rla: string;
      phone: string;
      fax: string;
      email: string;
      billingEmail: string;
      timeZone: string;
      memberOf: string;
    }>(LinkBuilder.legacyApi(`/entity/${entityId}`), {
      method: 'get',
      signal
    });
  }

  static async updateEntity(entityId: number, data: UpdateEntityRequestBody, signal?: AbortSignal) {
    return await WrappedFetch.json(LinkBuilder.legacyApi(`/entity/${entityId}`), {
      method: 'put',
      signal,
      body: JSON.stringify(data)
    });
  }

  static async getEntityBilling({ entityId, signal }: { entityId: number, signal?: AbortSignal }) {
    return await WrappedFetch.json<GetEntityBillingResponse>(LinkBuilder.legacyApi(`/entity/${entityId}/billing`), {
      method: 'get',
      signal
    });
  }

  static async updateTitlePurchaseBilling({ entityId, signal, card }: {
    entityId: number,
    card: CardDetails,
    signal?: AbortSignal
  }) {
    const eCryptKey = import.meta.env.VITE_EWAY_ENCRYPT_KEY;
    const lastFour = card.number.substring(card.number.length - 4);
    card.number = (window as any).eCrypt.encryptValue(card.number, eCryptKey);
    card.cvn = (window as any).eCrypt.encryptValue(card.cvn, eCryptKey);

    const body = new FormData();
    body.set('EntityID', String(entityId));
    body.set('card_name', card.name);
    body.set('card_number', card.number);
    body.set('card_month', card.expiryMonth);
    body.set('card_year', card.expiryYear);
    body.set('card_cvn', card.cvn);
    body.set('LastFour', lastFour);

    return await WrappedFetch.json<any>(LinkBuilder.ajaxRequest({ action: 'titles_savepayment' }), {
      method: 'post',
      signal,
      body
    });
  }

  static async getEntitySetting({ entityId, setting, signal }: {
    entityId: number,
    setting: UpdateableEntitySetting,
    signal?: AbortSignal
  }) {
    return await WrappedFetch.json<{ value: string }>(LinkBuilder.legacyApi(`/entity/${entityId}/setting/${setting}`), {
      method: 'get',
      signal
    });
  }

  static async updateEntitySetting({ entityId, setting, value, signal }: {
    entityId: number,
    setting: UpdateableEntitySetting,
    value: string,
    signal?: AbortSignal
  }) {
    return await WrappedFetch.json(LinkBuilder.legacyApi(`/entity/${entityId}/setting/${setting}`), {
      method: 'put',
      signal,
      body: JSON.stringify({ value })
    });
  }

  static async updateSubscriptionPaymentDetails({
    signal,
    productId,
    entityId,
    card
  }: {
    signal?: AbortSignal;
    productId: number;
    entityId: number;
    card: CardDetails
  }) {
    const eCryptKey = import.meta.env.VITE_EWAY_ENCRYPT_KEY;
    const lastFour = card.number.substring(card.number.length - 4);
    card.number = (window as any).eCrypt.encryptValue(card.number, eCryptKey);
    card.cvn = (window as any).eCrypt.encryptValue(card.cvn, eCryptKey);

    const body = new FormData();
    body.set('EntityID', String(entityId));
    body.set('ProductID', String(productId));
    body.set('card_name', card.name);
    body.set('card_number', card.number);
    body.set('card_month', card.expiryMonth);
    body.set('card_year', card.expiryYear);
    body.set('card_cvn', card.cvn);
    body.set('card_type', card.cardType);
    body.set('LastFour', lastFour);

    return await WrappedFetch.json<any>(LinkBuilder.ajaxRequest({ action: 'monthly_savepayment' }), {
      method: 'post',
      signal,
      body
    });
  }

  static async removeTitleCreditCard({ signal, entityId }: { signal?: AbortSignal, entityId: number }) {
    return WrappedFetch.json(
      LinkBuilder.ajaxRequest({ action: 'titles_removecc', EntityID: entityId }),
      { signal }
    );
  }

  static async removeSubscriptionCreditCard({ signal, entityId, productId }: {
    signal?: AbortSignal,
    entityId: number,
    productId: number
  }) {
    return WrappedFetch.json(
      LinkBuilder.ajaxRequest({
        action: 'monthly_cancelpayment',
        EntityID: entityId,
        ProductID: productId
      }),
      { signal }
    );
  }

  static async checkFormLock({ documentId, signal }: { documentId: string | number, signal?: AbortSignal }) {
    return await WrappedFetch.json<CheckFormLockResponse>(
      LinkBuilder.ajaxRequest({ action: 'checkdocumentlocked', DocumentID: String(documentId) }),
      { signal }
    ).then(r => ({
      isLocked: r?.islocked,
      message: r?.message,
      migrate: r?.migrate,
      reason: r?.reason,
      changes: r?.changes.map(c => ({ description: c.Description, version: c.Version }))
    }));
  }

  static async migrateFormToLatestVersion({ documentId, signal }: {
    documentId: string | number,
    signal?: AbortSignal
  }) {
    return await WrappedFetch.json<CheckFormLockResponse>(
      LinkBuilder.ajaxRequest({ action: 'migrate', DocumentID: String(documentId) }),
      { signal }
    );
  }
}

export type CheckFormLockResponse = {
  success: true;
  islocked: boolean;
  message: string;
  reason: string;
  migrate: boolean;
  changes: { Description: string; Version: number; }[];
};

export type ListTitlesForAgentResponse = {
  titlePurchaseId: number;
  displayName: string;
  invoiceDate: string;
  entityId: number;
  total: string;
  gst: string;
  items: string[];
  downloadUrl: string;
  invoiceUrl: string;
};

export interface GetEntitySettingsInfoResponse extends BaseAjaxResponse {
  urlLogo: string,
  urlPurchaserHeader: string,
  entityId: number,
  entities: {
    entityId: number,
    displayName: string,
    profileName?: string,
    tradingName?: string,
    companyName?: string,
    rla?: string,
    abnAcn?: string,
    managementId: number,
    useContractors: boolean,
    state?: string,
    useNewPropertyTransactions: boolean,
    epfAgencyId?: number,
    country?: string
  }[]
}

interface GetDocumentMetaResponse {
  name: string;
  documentId: number;
  entityId: number;
  formId: number;
  formFileName: string;
  formName: string;
  signatures: string[];
  signatureMetadata: { name?: string; email?: string; mobile?: string; position: string }[];
  signingPortal: {
    enabled: boolean;
    propertyId?: string;
    propertyFormId?: string;
    settings?: [];
  }
}

export interface GaGetFeatureFlagsResponseData {
  UseNewPropertyTransactions: boolean;
  SigningPortalEnabled: boolean;
  UseContractors: boolean;
  UseMyFiles: boolean;
  IntegrationEnabled: boolean;
  TeamManagementVisible: boolean;
  EveryonePropertyAccess: boolean;
  UseCustomNotifications: boolean;
}

export interface GaGetFeatureFlagsResponse extends BaseAjaxResponse {
  data: GaGetFeatureFlagsResponseData;
}

export interface UpdateEntityRequestBody {
  companyName: string;
  tradingName: string;
  business: string;
  profileName: string;
  address1: string;
  addressDisplay: string;
  suburb: string;
  postCode: string;
  state: string;
  country: string;
  abn: string;
  rla: string;
  phone: string;
  fax: string;
  email: string;
  billingEmail: string;
  gnafId: string;
  timeZone: string;
  memberOf: string;
}

export type UpdateableEntitySetting = 'billing-email';

export type GetEntityBillingResponse = {
  billingEmail: string;
  subscriptions: {
    subscriptionId: number;
    productId: number;
    product: string;
    entityId: number;
    period: number;
    tier: number;
    tierName: string;
    monthly: boolean;
    fee: string;
    trial: boolean;
    subAdded: string;
    subModified: string;
    paymentLastChecked: string;
    expiry: string;
    logo: string;
    stateName: string;
    invoiceHistory: {
      activityId: number;
      invoiceId: number;
      dateStamp: string;
      description: string;
      success: boolean;
      action: number;
      productId: number;
      product: string;
    }[];
    payment: {
      paymentId: number;
      lastFour: string;
      dateAdded: string;
      productId: number;
      product: string;
      cardHolder: string;
      ccMonth: string;
      ccYear: string;
      cardVerified: boolean;
      cardType: CardType;
      permanentFail: boolean;
    };
  }[],
  titlePayment: {
    cardName: string;
    lastFour: string;
    dateAdded: string;
    ccMonth: string;
    ccYear: string;
    cardType: CardType;
    permanentFail: boolean;
  }
};
