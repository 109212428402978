import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { useMemo } from 'react';

export function useBreadcrumbAppendedUpdate(
  breadcrumbs: CrumbDefn[],
  label: string,
  path: string
) {
  const result = useMemo<CrumbDefn[]>(() => {
    const base = breadcrumbs.at(-1)?.href ?? '';
    const suffix = base.endsWith('/')
      ? path.startsWith('/')
        ? path.substring(1)
        : path
      : path.startsWith('/')
        ? path
        : `/${path}`;
    const href = `${base}${suffix}`;
    return [
      ...breadcrumbs,
      { label, href }
    ];
  }, [breadcrumbs, label, path]);

  return result;
}
