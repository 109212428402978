import './UploadedDocumentsSelector.scss';
import { Icon } from './Icon';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { useLightweightTransaction } from '../hooks/useTransactionField';
import { ContentType, FileFormRef, FormCodeUnion, FormInstance, UploadType } from '@property-folders/contract';
import Select from 'react-select';
import { useUploadedDocumentUpload } from '../hooks/useUploadedDocumentUpload';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { SpinnerButton } from './AsyncButton';
import { getUploadAsV2 } from '@property-folders/common/yjs-schema/property';

interface UploadedDocumentOption {
  id: string;
  code: FormCodeUnion;
  contentType: string;
  name: string;
  archived?: boolean;
}

export function UploadedDocumentsSelector(props: {
  selectedFileRefs?: FileFormRef[],
  onSelect: (files: FileFormRef[]) => void,
  // assume we'll only allow selection of pdf files
  uploadTypeFilter?: UploadType,
  label?: string,
  overlayLabel?: string,
  overlayClassName?: string,
  containerClassName?: string,
  multi?: boolean,
  error?: string,
  inputClassName?: string,
  noTriggerSync?: boolean,
  disabled?: boolean
}) {
  const selectedIds = new Set((props.selectedFileRefs || []).map(x => x.id));
  const { value: uploadedDocs } = useLightweightTransaction<FormInstance[]>({
    parentPath: 'formStates.UPLOADED.instances',
    bindToMetaKey: true,
    // for now, only the property root key can contain uploaded documents.
    // this component can be used from a sublineage, so we need to override the search key
    ydocForceKey: PropertyRootKey.Meta
  });
  const options = useMemo<UploadedDocumentOption[]>(() => {
    if (!uploadedDocs) return [];
    const result: UploadedDocumentOption[] = [];
    for (const instance of uploadedDocs) {
      const upload = getUploadAsV2(instance.upload, instance.created);
      if (!upload) continue;

      if (selectedIds.has(instance.id)) {
        result.push({
          id: instance.id,
          code: instance.formCode,
          contentType: ContentType.Pdf,
          name: upload.name,
          archived: instance.archived
        });
        continue;
      }

      if (!upload.files?.length) continue;
      result.push({
        id: instance.id,
        code: instance.formCode,
        contentType: ContentType.Pdf,
        name: upload.name,
        archived: instance.archived
      });
    }
    result.sort((a, b) => a.name.localeCompare(b.name));
    return result;
  }, [uploadedDocs, JSON.stringify([...selectedIds.values()])]);
  const selected = options.filter(o => selectedIds.has(o.id));
  const { uploadFiles, uploadProcessing, uploadErrors, handleDrop } = useUploadedDocumentUpload(uploadForms => {
    props.onSelect(uploadForms.map(f => ({ id: f.id, code: f.code })));
  }, props.uploadTypeFilter, props.noTriggerSync);
  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.target.files && handleDrop([...event.target.files]);
  };
  const { getRootProps, getInputProps, inputRef, isDragAccept, isDragActive } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    accept: { 'application/pdf': [] },
    multiple: !!props.multi
  });

  useEffect(() => {
    if (!uploadFiles?.length) return;
    props.onSelect(uploadFiles);
  }, [JSON.stringify((uploadFiles || []).map(x => x.id).join(','))]);

  return <>
    <div
      {...getRootProps({
        className: clsJn(
          'uploaded-documents-selector d-flex flex-row',
          isDragAccept && 'drop-target drop-accept',
          props.containerClassName != null ? props.containerClassName : 'position-relative'
        ),
        style: {
          height: 'var(--constant-form-input-height)'
        }
      })}
    >
      <input
        {...getInputProps({
          className: 'd-none',
          accept: '.pdf',
          onChange: handleUpload,
          disabled: props.disabled
        })}
      />
      <SpinnerButton
        variant='outline-secondary'
        title='upload'
        style={{
          borderColor: 'var(--clr-input-border)'
        }}
        onClick={() => inputRef?.current?.click()}
        disabled={props.disabled}
        processing={uploadProcessing}
      ><Icon name='upload'/></SpinnerButton>
      <Select<UploadedDocumentOption>
        placeholder={props.label || 'Select a file'}
        options={options}
        value={selected}
        isDisabled={props.disabled || uploadProcessing}
        isMulti={!!props.multi}
        isClearable={true}
        getOptionValue={x => x.id}
        onChange={(newValue, meta) => {
          if (!newValue) return props.onSelect([]);
          if (Array.isArray(newValue)) {
            props.onSelect((newValue as UploadedDocumentOption[]).map(x => ({ id: x.id, code: x.code })));
          } else {
            const { id, code } = newValue as UploadedDocumentOption;
            props.onSelect([{ id, code }]);
          }
        }}
        formatOptionLabel={data=> {
          return data.name;
        }}
        classNames={{
          control: base => {
            return props.inputClassName || '';
          }
        }}
        styles={{
          container: base => ({
            ...base,
            flexGrow: 1
          }),
          control: base => ({
            ...base,
            height: '100%',
            background: props.disabled
              ? '#e9ecef'
              : 'var(--bs-body-bg)',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: props.error?.length
              ? 'var(--bs-danger)'
              : 'var(--clr-input-border)',
            borderRadius: 0
          }),
          menu: base => ({
            ...base,
            zIndex: 10
          }),
          option: (base, item) => ({
            ...base,
            color: !item.isSelected && item.data.archived
              ? 'var(--clr-input-border)'
              : base.color
          }),
          singleValue: (base, item) => ({
            ...base,
            color: item.data.archived
              ? 'var(--clr-input-border)'
              : base.color
          }),
          multiValue: (base, item) => ({
            ...base,
            color: item.data.archived
              ? 'var(--clr-input-border)'
              : base.color
          })
        }}
      />
      {isDragActive && isDragAccept && <div className={props.overlayClassName || 'overlay-default'}>
        <span>{props.overlayLabel || 'Drop file here'}</span>
      </div>}
    </div>
    {props.error?.length
      ? <div className='d-block invalid-feedback'>{props.error}</div>
      : <></>}
    {uploadErrors.length
      ? <div className='d-block invalid-feedback'>{uploadErrors[0]}</div>
      : <></>}
  </>;
}
