import { updateImmerPath, useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import {
  AgencySalesperson,
  EmailEvent,
  FolderType,
  FormCode,
  FormInstanceSigning,
  FormSigningState,
  ManifestType,
  MaterialisedPropertyData,
  OwningEntity,
  RemoteSigningTypes,
  SignerProxyType,
  SigningInitiator,
  SigningParty,
  SigningPartySourceType,
  SigningPartyType,
  SigningPartyVerificationType,
  SigningSessionField,
  SigningSessionFieldType,
  SigningSessionOrderItem,
  SigningSessionOrderParty,
  sourceTypeGroup,
  TransactionMetaData
} from '@property-folders/contract';
import {
  replaceSignInPersonSalespersonToken,
  SignerProxyAuthorityOptions,
  SigningPartyTypeOptions
} from '@property-folders/components/dragged-components/signing/Common';
import { Button, ButtonGroup, Dropdown, Offcanvas, Spinner } from 'react-bootstrap';
import { LinkBuilder, SeoFriendlySlugOptions } from '@property-folders/common/util/LinkBuilder';
import { cloneElement, ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { SigningApi } from '@property-folders/common/client-api/signing';
import { useInterval } from 'react-use';
import { canonicalisers, getTimeString, rawTextToHtmlParagraphs } from '@property-folders/common/util/formatting';
import './SigningProcess.scss';
import { Icon } from '../Icon';
import {
  fieldIsSigned,
  partyCategoryToLabelStrings,
  PropertyFormYjsDal
} from '@property-folders/common/yjs-schema/property/form';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import {
  EditSigningSessionPartyModal
} from '@property-folders/components/dragged-components/signing/editSigningSessionParty/EditSigningSessionPartyModal';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { FormUtil } from '@property-folders/common/util/form';
import { LineageContext } from '@property-folders/components/hooks/useVariation';
import { WetSigningModal } from '@property-folders/components/dragged-components/signing/WetSigningModal';
import { useSelector, useStore } from 'react-redux';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { missingWetPdfWarning } from '../../display/properties/MissingWetPdfWarning';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import { unblockSigningParty } from '@property-folders/common/yjs-schema/property';
import { Predicate } from '@property-folders/common/predicate';
import { normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { BelongingEntityMeta, EntitySettingsExtendedType } from '@property-folders/common/redux-reducers/entityMeta';

function splitButtonFactory(props: {primary: boolean, inList: boolean, disabled?: boolean, disabledMsg?: string, variant?: string,onClick: ()=>void, btnText: any, children: JSX.Element | JSX.Element[]}) {
  const standButton = !(props.inList && !props.primary) && <Button variant={props.variant ?? 'outline-secondary'} disabled={props.disabled} onClick={props.onClick}>
    {props.btnText}
  </Button>;
  return !props.inList
    ? <div className='tooltipped' title={props.disabled && props.disabledMsg ? props.disabledMsg : undefined }>{standButton}</div>
    : props.primary
      ? <div className='tooltipped-split' title={props.disabled && props.disabledMsg ? props.disabledMsg : undefined }>
        <Dropdown as={ButtonGroup}>
          {standButton}
          <Dropdown.Toggle split variant={props.variant ?? 'outline-secondary'} title='' />

          <Dropdown.Menu>
            {Array.isArray(props.children) ? props.children.map((el, key) => cloneElement(el, { key })) : cloneElement(props.children, { key: 1 })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      : <div className='tooltipped' title={props.disabled && props.disabledMsg ? props.disabledMsg : undefined } ><Dropdown.Item key={1} disabled={props.disabled} onClick={props.onClick} title={props.disabledMsg}>{props.btnText}</Dropdown.Item></div>;
}

function ResendLinkButton({
  signingSessionId,
  partyId,
  linkLastSent,
  inList = false,
  primary = true,
  locked,
  children,
  ...restProps
}: {
  signingSessionId?: string,
  partyId?: string,
  declined?: boolean,
  linkLastSent: number
  inList: boolean,
  primary: boolean,
  locked?: boolean
  children: JSX.Element | JSX.Element[]
}) {
  const [sending, setSending] = useState(false);
  const [time, setTime] = useState(Date.now());
  useInterval(() => {
    setTime(Date.now());
  }, 5000);
  const resendLink = useCallback(() => {
    if (!(signingSessionId && partyId)) {
      return;
    }

    setSending(true);
    SigningApi
      .resendSigningLink(signingSessionId, partyId)
      .finally(() => setSending(false));
  }, [signingSessionId, partyId]);

  // in the last 5 or so minutes
  const backoffMs = 300000;
  const sentRecently = (time - linkLastSent) < backoffMs;
  const disabled = (sending || sentRecently || !(signingSessionId && partyId));

  const buttonInner = [
    sending && <Spinner as={'span'} className={'mr-3'} size={'sm'} animation={'border'} role={'status'}/>,
    sending
      ? ' Sending'
      : locked
        ? 'Unlock'
        : 'Resend'
  ];

  return splitButtonFactory({ ...restProps, primary, inList, btnText: buttonInner, disabled, disabledMsg: `Disabled until ${getTimeString(linkLastSent+backoffMs, undefined, undefined, true)}`, onClick: resendLink, children });
}

function getPartyState(
  signingParty: SigningParty | undefined,
  signingOrder: SigningSessionOrderItem | undefined,
  partyOrder: SigningSessionOrderParty | undefined
): { partyState?: 'active' | 'inactive' | 'ready', isOrderedParty?: boolean } {
  if (!signingParty) return {};

  if (partyOrder) {
    return { partyState: partyOrder.state, isOrderedParty: true };
  }

  if (signingOrder) {
    const match = signingOrder.parties?.find(p => p.partyId === signingParty.id);
    return {
      partyState: match?.state || signingOrder.state,
      isOrderedParty: !!match
    };
  }

  return { partyState: 'active', isOrderedParty: false };
}

export function PartySessionCard({ paths, property, document, signingSessionId, timeString, timestamp, formCode, formId, initiator, sessionComplete, salespersons, partyId, isSubmitted, signingOrder, hasAuthRep, colour, firstPartyInputRequiredPartyId }: {
  paths: { party: string, fields: string, source: string, sourceEmail: string, sourcePhone: string },
  property?: SeoFriendlySlugOptions,
  document?: SeoFriendlySlugOptions,
  signingSessionId?: string,
  timeString?: string,
  timestamp: number,
  formCode: string,
  formId: string,
  initiator: SigningInitiator,
  sessionComplete: boolean,
  salespersons?: AgencySalesperson[],
  partyId: string
  isSubmitted?: boolean,
  signingOrder?: SigningSessionOrderItem,
  partyOrder?: SigningSessionOrderParty,
  hasAuthRep: boolean,
  colour?: string,
  firstPartyInputRequiredPartyId?: string
}) {
  const online = useOnline();
  const { ydoc, transactionMetaRootKey, transactionRootKey } = useContext(YjsDocContext);
  const { snapshotHistory } = useContext(LineageContext);
  const navigate = useNavigate();
  const { value: signingParty } = useLightweightTransaction<SigningParty>({
    parentPath: paths.party,
    myPath: '',
    bindToMetaKey: true
  });
  const {
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const {
    binder: dataBinder
  } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const { updateDraft: updateSigningParty } = useYdocBinder<SigningParty>({ path: paths.party, bindToMetaKey: true });
  const emailPath = `${paths.source}.${paths.sourceEmail}`;
  const phonePath = `${paths.source}.${paths.sourcePhone}`;
  const isCurrentSalesperson = AuthApi.useIsCurrentSessionSalesperson(signingParty?.snapshot?.linkedSalespersonId);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { value: fields } = useLightweightTransaction<SigningSessionField[]>({
    parentPath: paths.fields,
    myPath: '',
    bindToMetaKey: true
  });
  const [showAdvice, setShowAdvice] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showWetSigningModal, setShowWetSigningModal] = useState(false);
  const signatures = (fields || []).filter(f => (f.type === SigningSessionFieldType.Signature || f.type === SigningSessionFieldType.Initials) && f.partyId === signingParty?.id);
  const allSigned = signatures.length && signatures.every(signature => fieldIsSigned(signature));
  const declined = !!signingParty?.declineReason;
  const locked = !!signingParty?.locked;
  const acceptPending = !!signingParty?.serverAcceptPending;
  const notUploadedWarning = signingParty?.type === SigningPartyType.SignWet && !signingParty?.signedPdf ? <><Icon name='warning' title={missingWetPdfWarning} icoClass='ms-2 me-1 warning-text'/>Paper copy not uploaded</> : '';
  const store = useStore();
  const { value: folderType } = useLightweightTransaction<FolderType>({ myPath: 'folderType', bindToMetaKey: true });
  const { value: owningEntity } = useLightweightTransaction<OwningEntity>({ myPath: 'entity', bindToMetaKey: true });

  const { value: instanceSigning } = useLightweightTransaction<FormInstanceSigning>({ parentPath: normalisePathToStr(FormUtil.generateFormInstancePath(formCode, formId)), myPath: 'signing', bindToMetaKey: true });
  const shouldConsiderMissingEmailForForm1 = sourceTypeGroup.get(signingParty?.source.type) === SigningPartySourceType.Purchaser && formCode === FormCode.RSC_ContractOfSale && instanceSigning?.instanceAutoForm1;
  // const relevantEntityId =
  const entityKey = (owningEntity?.id ?? -1).toString();
  const entityInfo = useSelector<{ entityMeta: BelongingEntityMeta }, EntitySettingsExtendedType | undefined>(state=> state?.entityMeta?.[entityKey]);
  const signingOptions = entityInfo?.signingOptions;
  const sp = entityInfo?.salespeople;
  const partyOrder = instanceSigning?.session?.partyOrder?.find(o => o.partyId === signingParty?.id);
  const { partyState, isOrderedParty } = getPartyState(signingParty, signingOrder, partyOrder);
  const partySigningOnline = signingParty?.type === SigningPartyType.SignOnline || signingParty?.type === SigningPartyType.SignOnlineSms;

  const status = useMemo(() => {
    if (locked)
      return <div className='d-flex align-items-center'><Icon name='lock' icoClass='me-1' style={{ color: 'red' }} />SMS verification failed</div>;
    if (signingParty?.declineType)
      return <div className='d-flex align-items-center'><Icon name='do_not_disturb_on' icoClass='me-1' style={{ color: 'red' }} />Declined</div>;
    if (allSigned && acceptPending)
      return <div className='d-flex align-items-center'><Icon name='pending' icoClass='me-1' />{signingParty?.type !== SigningPartyType.SignWet ? 'Signature' : 'PDF'} upload pending</div>;
    if (allSigned)
      return <div className='d-flex align-items-center'><Icon name='check_circle' icoClass='me-1' style={{ color: 'green' }}/>Signed{notUploadedWarning}</div>;
    if (signingParty?.lastEmailEvent === EmailEvent.bounce)
      return <div className='d-flex align-items-center'><Icon name='cancel' icoClass='me-1' style={{ color: 'red' }} />Message bounced</div>;

    if (partyState === 'ready' && partySigningOnline)
      return <div className='d-flex align-items-center'><Icon name='pause_circle_outline' icoClass='me-1' />Ready to start</div>;

    if (partyOrder && partyState === 'inactive' && partySigningOnline) {
      const prev = instanceSigning?.parties?.find(p => p.id === partyOrder.dependsOn)?.snapshot?.name || 'previous party';
      return <div className='d-flex align-items-center'><Icon name='pause_circle_outline' icoClass='me-1'/>Waiting for {prev} to sign</div>;
    }

    if (signingOrder && partyState === 'inactive' && partySigningOnline) {
      // todo: can depend on signing order info, or it can depend on earlier party within signing order
      if (signingOrder.state === 'inactive' && signingOrder.dependsOn) {
        const plural = signingOrder.dependsOnCount !== 1;
        const labels = partyCategoryToLabelStrings(signingOrder.dependsOn, hasAuthRep);
        const labelText = plural ? labels.plural : labels.singular;
        return <div className='d-flex align-items-center'><Icon name='pause_circle_outline' icoClass='me-1'/>Waiting for {labelText.toLowerCase()}</div>;
      }

      return <div className='d-flex align-items-center'><Icon name='pause_circle_outline' icoClass='me-1'/>Waiting for previous party</div>;
    }

    return <div className='d-flex align-items-center'><Icon name='schedule' icoClass='me-1' />Needs to sign</div>;
  }, [locked, signingParty?.declineType, allSigned, signingParty?.lastEmailEvent === EmailEvent.bounce, acceptPending, signingOrder?.state, partySigningOnline, partyState, partyOrder?.state]);

  const statusDetail = useMemo(() => {
    if (allSigned && signingParty?.signedTimestamp)
      return `Signed ${getTimeString(signingParty?.signedTimestamp, sessionInfo?.timeZone, 'at', false, signingParty.type === SigningPartyType.SignWet)}`;
    if (signingParty?.declineType)
      return `Declined ${getTimeString(signingParty?.declineTimestamp, sessionInfo?.timeZone, 'at')}`;
    if (signingParty?.lastAccessedTimestamp)
      return `Last viewed ${getTimeString(signingParty?.lastAccessedTimestamp, sessionInfo?.timeZone, 'at')}`;

    if (partyOrder &&
      partyOrder.state !== 'active' &&
      partySigningOnline &&
      !signingParty?.lastEmailEvent
    ) {
      if (partyOrder.auto) {
        const prev = instanceSigning?.parties?.find(p => p.id === partyOrder.dependsOn)?.snapshot?.name;
        return prev
          ? `Starts when ${prev} completes`
          : 'Starts when previous party completes';
      }
      return partyOrder.readyNotifiedAt
        ? `Notified ${initiator.name} this is ready to start`
        : `Notify ${initiator.name} when ready to start`;
    }

    if (signingOrder &&
      signingOrder.state !== 'active' &&
      partySigningOnline &&
      !signingParty.lastEmailEvent
    ) {
      if (signingOrder.auto) {
        const plural = signingOrder.dependsOnCount !== 1;
        const labels = partyCategoryToLabelStrings(signingOrder.dependsOn, hasAuthRep);
        const labelText = plural ? labels.plural : labels.singular;
        const completeText = plural ? 'complete' : 'completes';
        return `Starts when ${labelText} ${completeText}`;
      }
      return signingOrder.readyNotifiedAt
        ? `Notified ${initiator.name} this is ready to start`
        : `Notify ${initiator.name} when ready to start`;
    }

    return `Pending ${timeString}`;
  }, [allSigned, signingParty?.signedTimestamp, signingParty?.declineType, signingParty?.declineTimestamp, signingParty?.lastAccessedTimestamp, sessionInfo?.timeZone, timestamp, signingOrder?.state, partySigningOnline, !signingParty?.lastEmailEvent, signingOrder?.readyNotifiedAt, partyOrder?.state, partyOrder?.readyNotifiedAt]);

  const proxyMode = Predicate.proxyNotSelf(signingParty?.proxyAuthority);
  const signerName = proxyMode ? signingParty.proxyName : signingParty?.snapshot?.name;
  const onBehalfText = proxyMode ? `On behalf of ${signingParty?.snapshot?.name}` : '';
  const signerEmail = proxyMode ? signingParty.proxyEmail : signingParty?.snapshot?.email;
  const flagMissingForm1Email = shouldConsiderMissingEmailForForm1 && !signerEmail;
  const signerPhone = proxyMode ? signingParty.proxyPhone : signingParty?.snapshot?.phone;

  const emailDetail = allSigned && signingParty?.signedTimestamp
    // once it's signed, email events are a distraction
    ? ''
    : getEmailEventText(signingParty?.lastEmailEvent, getTimeString(signingParty?.lastEmailEventTimestamp, sessionInfo?.timeZone, 'at'));
  let adviceTitle: string | null = null;
  let adviceText: ReactElement | null = null;
  const spamGuidance = <>Finally, if you think that the recipient's mail provider or spam settings should now allow the message to be received, contact <a href={'mailto:support@reaforms.com.au?subject=' + encodeURIComponent(`Suppression List: Remove ${signerEmail}`) + '&body=' + encodeURIComponent(`Hi there,\n\nplease remove ${signerEmail} from the email suppression list.\n\nThanks,\n${sessionInfo?.name}`)}>support@reaforms.com.au</a>, and we can remove this address from our suppression list.</>;
  if (signingParty?.lastEmailEventTimestamp && signingParty.lastEmailEvent) {
    switch (signingParty.lastEmailEvent) {
      case EmailEvent.bounce:
        adviceTitle = 'Message bounced';
        adviceText = <>
          <p> The recipients mail provider has rejected the message because the email address does not exist.</p>
          <p>When reaforms is notified of a bouncing email address, our mail provider will automatically suppress future messages to that address in order to protect our sending reputation for all users.</p>
          <p>It is likely that future messages sent to this address will be blocked by our mail provider, or by the recipients mail provider.</p>
          <h5>Steps to resolve</h5>
          <ol>
            <li>Check that the email address is correct. Some mail providers reject messages if the case of the email address is incorrect. So a message to UserName@domain.com might succeed, but a message to username@domain.com might bounce.</li>
            <li>If possible, try sending to an alternate email address.</li>
            <li>{spamGuidance}</li>
          </ol>
        </>;
        break;
      case EmailEvent.dropped:
        adviceTitle = 'Message dropped';
        adviceText = <>
          <p>The address has been placed on an email suppression list by our mail provider. This could either be because previous messages to this address have bounced, or the recipient has previously marked reaforms email as spam.</p>
          <p>It is likely that future messages sent to this address will bounce, or be blocked as spam.</p>
          <h5>Steps to resolve</h5>
          <ol>
            <li>Check that the email address is correct. Some mail providers reject messages if the case of the email address is incorrect. So a message to UserName@domain.com might succeed, but a message to username@domain.com might bounce.</li>
            <li>If possible, try sending to an alternate email address.</li>
            <li>{spamGuidance}</li>
          </ol>
        </>;
        break;
      case EmailEvent.spamreport:
        adviceTitle = 'Message marked as spam by recipient';
        adviceText = <>
          <p>The recipient received the message, and instructed their mail provider to automatically block or quarantine future messages from reaforms.</p>
          <p>This may indicate that the mail address is incorrect, and that the mail was sent to somebody that was not expecting it.</p>
          <p>When reaforms is notified that a recipient regards our email as spam, our mail provider will automatically suppress future messages to that address in order to protect our sending reputation for all users.</p>
          <p>It is likely that future messages sent to this address will be blocked by our mail provider, or by the recipients mail provider.</p>
          <h5>Steps to resolve</h5>
          <ol>
            <li>Check that the email address is correct. The most common cause of a spam response is that the email address was entered incorrectly. It is uncommon, but some mail providers reject messages if the case of the email address is incorrect. So a message to UserName@domain.com might succeed, but a message to username@domain.com might bounce.</li>
            <li>If possible, try sending to an alternate email address.</li>
            <li>{spamGuidance}</li>
          </ol>
        </>;
        break;
      default:
        break;
    }
  }

  let method = '';
  switch (signingParty?.type) {
    case SigningPartyType.SignInPerson: {
      if (signingParty?.typeHostParty) {
        if (signingParty.typeHostParty === sessionInfo?.agentId) {
          method = replaceSignInPersonSalespersonToken(`Method: ${SigningPartyTypeOptions[signingParty.type]}`, '', sessionInfo?.agentId, signingParty?.typeHostParty);
          break;

        }
        const name = sp?.find(x => x.id === signingParty.typeHostParty)?.name;
        if (name) {
          method = replaceSignInPersonSalespersonToken(`Method: ${SigningPartyTypeOptions[signingParty.type]}`, name, sessionInfo?.agentId, signingParty?.typeHostParty);
          break;
        }
      }
      if (folderType === FolderType.MyFile) {
        method = replaceSignInPersonSalespersonToken(`Method: ${SigningPartyTypeOptions[signingParty.type]}`, 'user', sessionInfo?.agentId, -1);
        break;
      }
      method = `Method: ${SigningPartyTypeOptions[signingParty.type]}`;
      break;
    }
    default:
      if (signingParty?.type) {
        method = `Method: ${SigningPartyTypeOptions[signingParty.type]}`;
      }
      break;
  }

  const proxyAuthorityVal = signingParty?.proxyAuthority;
  const proxyAuthority = Predicate.proxyNotSelf(proxyAuthorityVal)
    ? `To be signed by: ${SignerProxyAuthorityOptions[proxyAuthorityVal]}`
    : '';

  if (signingParty?.type === SigningPartyType.SignInPerson && isCurrentSalesperson) {
    method = method.replace(/salesperson's/i, 'my');
  } else if (signingParty?.type === SigningPartyType.SignInPerson && signingParty.source.type === SigningPartySourceType.Salesperson) {
    let agentName = signingParty.snapshot?.name || 'Agent';
    agentName = (agentName).endsWith('s')
      ? `${agentName}'`
      : `${agentName}'s`;
    method = method.replace(/salesperson's/i, agentName);
  }
  const proxySigningReaformsUsers = [SignerProxyType.Auctioneer, SignerProxyType.Salesperson].includes(signingParty?.proxyAuthority);
  const currentHost = signingParty?.typeHostParty;
  const isHosterCurrentSalesperson = AuthApi.useIsCurrentSessionSalesperson(currentHost);
  const isProxySalespersonSigningSelf = proxySigningReaformsUsers && isHosterCurrentSalesperson && currentHost === signingParty?.proxyLinkedId;

  const isHosted = proxySigningReaformsUsers
    ? (signingParty?.type === SigningPartyType.SignInPerson && isHosterCurrentSalesperson && !isProxySalespersonSigningSelf)
    : (signingParty?.source?.type !== SigningPartySourceType.Salesperson && signingParty?.type === SigningPartyType.SignInPerson && isHosterCurrentSalesperson);
  const isProxySelfSigning = signingParty?.type === SigningPartyType.SignInPerson && isProxySalespersonSigningSelf;
  const isWet = signingParty?.type === SigningPartyType.SignWet;
  const hostedBlockedByOffline = isHosted && signingParty?.verification?.type === SigningPartyVerificationType.Sms && !online;
  const goSign = useCallback(() => {
    if (!(signingSessionId && signingParty?.id && property && document)) {
      return;
    }
    if (hostedBlockedByOffline) return;

    if (signingParty.declineType && signingParty.declineReason) {
      if (!ydoc) {
        console.warn('No ydoc, no decline clear, no hosting');
        return;
      }
      const formDal = new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey);
      formDal.setPartyDeclined(
        formCode,
        formId,
        signingSessionId,
        signingParty.id,
        {
          type: signingParty.declineType,
          reason: signingParty.declineReason
        }
      );
    }

    if (signingParty.source.type === SigningPartySourceType.Salesperson || isHosted || isProxySelfSigning) {
      navigate(LinkBuilder.signingPath(
        property,
        document,
        {
          id: signingParty.id,
          nicetext: signingParty.snapshot?.name
        },
        folderType
      ));
      return;
    }
  }, [signingSessionId, signingParty?.id, !!property, hostedBlockedByOffline, isHosted]);

  const openEditPartyDialog = useCallback(() => setShowEdit(true), [signingSessionId, signingParty?.id, !!paths]);

  // Running Go sign now clears declines, In particular we want the host button to remain
  const mustWaitForFirstParty = firstPartyInputRequiredPartyId
    ? firstPartyInputRequiredPartyId !== partyId
    : false;
  const showSigningButton = !allSigned && !mustWaitForFirstParty && (isHosted || isProxySelfSigning || (isCurrentSalesperson && !declined) || isWet);
  const partyIsCurrentUser = Boolean((signingParty?.source?.agencySalesPersonId && signingParty.source.agencySalesPersonId === sessionInfo?.agentId) ||
    signingParty?.snapshot?.linkedSalespersonId && signingParty.snapshot.linkedSalespersonId === sessionInfo?.agentId);

  const buttonHierarchy = sessionComplete ? [] : [
    showSigningButton && {
      btnText: isHosted
        ? partyIsCurrentUser
          ? 'Sign'
          : 'Host'
        : 'Sign',
      onClick: () => isWet ? setShowWetSigningModal(true) : goSign(),
      variant: 'primary',
      disabled: hostedBlockedByOffline,
      disabledMsg: 'Internet access is required for SMS verification'
    },
    !allSigned && ydoc && signingSessionId && isOrderedParty && signingParty?.type && RemoteSigningTypes.includes(signingParty.type) && partyState === 'ready' && signingOrder?.state !== 'ready' && {
      btnText: 'Start',
      onClick: () => unblockSigningParty(ydoc, {
        formId,
        formCode,
        signingSessionId,
        partyId,
        metaRootKey: transactionMetaRootKey
      }),
      variant: 'primary'
    },
    !allSigned && signingParty?.type === SigningPartyType.SignOnline && partyState === 'active' &&
    {
      producer: ResendLinkButton,
      producerProps: {
        signingSessionId: signingSessionId,
        partyId: signingParty.id,
        linkLastSent: signingParty.linkLastSent || timestamp,
        variant: declined ? 'primary' : undefined,
        locked
      }
    },
    !allSigned && {
      btnText: 'Edit',
      onClick: openEditPartyDialog
    }
  ].filter(a=>a);

  let btnComponent;
  if (buttonHierarchy.length > 1) {
    const subsequent = buttonHierarchy.slice(1);
    const children = subsequent.map((e, i)=>{
      if (typeof e === 'boolean') {
        return;
      }

      if (e?.producer) {
        const Producer = e.producer;
        return <Producer key={i} inList={true} primary={false} {...e.producerProps} />;
      }

      return cloneElement(splitButtonFactory({ ...e, primary: false, inList: true }), { key: i });
    });
    const e = buttonHierarchy[0];
    if (e.producer) {
      const Producer = e.producer;
      btnComponent = <Producer inList={true} primary={true} {...e.producerProps}>{children}</Producer>;
    } else {
      btnComponent = splitButtonFactory({ ...e, primary: true, children, inList: true });
    }
  } else if (buttonHierarchy.length === 1) {
    const e = buttonHierarchy[0];
    if (e.producer) {
      const Producer = e.producer;
      btnComponent = <Producer inList={false} primary={false} {...e.producerProps}></Producer>;
    } else {
      btnComponent = splitButtonFactory({ ...e, primary: true, inList: false });
    }
  }

  return <>
    <div className='d-flex flex-wrap party-session-card' style={colour ? { borderLeft: `4px solid ${colour}`, paddingLeft: '0.5em' } : undefined}>
      <div className='flex-shrink-1 flex-grow-1'>
        <div className='d-flex flex-wrap' >
          <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
            <div className='fw-bold'>{signerName}</div>
            {onBehalfText && <div className=''>{onBehalfText}</div>}
            <div className='text-truncate'>{signerEmail}</div>
            <div>{canonicalisers.phone(signerPhone||'').display}</div>
          </div>
          <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
            <div className='fw-bold' title={partyState}>{status}</div>
            <div>{!isSubmitted && method}</div>
            <div>{!isSubmitted && proxyAuthority}</div>
            <div>{!isSubmitted && statusDetail}</div>
            {emailDetail ? (adviceText ? <a className='link-with-underline' onClick={() => { setShowAdvice(true); }}>{emailDetail}</a> : <div>{emailDetail}</div>) : null}

          </div>
        </div>
        {declined && <div>
          <div className='fw-bold'>Reason for declining</div>
          <div>{rawTextToHtmlParagraphs(signingParty.declineReason||'')}</div>
        </div>}
      </div>

      <div className='flex-shrink-0 flex-grow-0 mb-2 signing-party-button-stack' style={{ width: '110px' }}>
        {!isSubmitted && btnComponent}
      </div>
      {flagMissingForm1Email && <div className='mt-3 w-100 d-flex justify-content-start'>This party is missing an email address, so the Form 1 cannot be automatically served.</div>}
    </div>
    {showWetSigningModal && <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} onClose={()=>setShowWetSigningModal(false)} />}>
      <WetSigningModal onClose={()=>setShowWetSigningModal(false)} formCode={formCode} formId={formId} signingSessionId={signingSessionId} signingParty={signingParty} />
    </ErrorBoundary>}
    <Offcanvas show={showAdvice} onHide={()=>setShowAdvice(false)} placement='end'>
      <Offcanvas.Header closeButton>
        {adviceTitle && <Offcanvas.Title>{adviceTitle}</Offcanvas.Title>}
      </Offcanvas.Header>
      {adviceText && <Offcanvas.Body className='lead guidance-spacing'>
        {adviceText}
      </Offcanvas.Body>}
    </Offcanvas>
    {showEdit && !!signingSessionId && !!signingParty?.id &&
      <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} onClose={() => setShowEdit(false)} />}>
        <EditSigningSessionPartyModal
          signingOptions={signingOptions}
          disallowPaper={folderType ===  FolderType.MyFile || Boolean(firstPartyInputRequiredPartyId && firstPartyInputRequiredPartyId === partyId)}
          formCode={formCode}
          formId={formId}
          signingSessionId={signingSessionId}
          partyId={signingParty?.id}
          paths={paths}
          party={signingParty}
          initiator={initiator}
          salespersons={salespersons}
          forceShowEmailField={shouldConsiderMissingEmailForForm1}
          onCancel={() => setShowEdit(false)}
          onSave={({ state: newState, emailChanged, phoneChanged, voidSigning }) => {
            setShowEdit(false);
            if (!signingParty) return;
            if (!updateSigningParty) return;
            const formDal = ydoc ? new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey) : undefined;
            const instance = formDal && formId && formCode && signingSessionId
              ? formDal.getFormSigningSession(formCode, formId)
              : undefined;
            const fileId = instance?.id === signingSessionId
              ? instance.file.id
              : undefined;
            const { party: newParty } = newState;
            // Do not update data model with info from proxy
            if (!Predicate.proxyNotSelf(newParty.proxyAuthority) && dataBinder?.update && (emailChanged || phoneChanged)) {
              // draft here is the main data model, not the signing session party
              dataBinder.update(draft => {
                if (emailChanged) {
                  updateImmerPath(draft, newParty.snapshot.email, emailPath);
                }
                if (phoneChanged) {
                  updateImmerPath(draft, newParty.snapshot.phone, phonePath);
                }
              });
            }

            if (voidSigning) {
            // note: we still want to update the parties.
            // transitioning to no signing state just clears the signing session, not any existing parties.
            // but, we want to do this state transition *first* to avoid triggering signing party change handling server-side
              FormUtil.transitionSigningState({ store, formCode, formId, metaBinder, dataBinder, sessionInfo, history: snapshotHistory }, { to: FormSigningState.None });
            }

            const now = Date.now();
            const updatePartyDraft = (draft?: SigningParty) => {
              if (!draft) return;

              draft.lastEditedTimestamp = now;
              draft.type = newParty.type;
              draft.typeHostParty = newParty.typeHostParty;
              draft.proxyAuthority = newParty.proxyAuthority;
              draft.proxyEmail = newParty.proxyEmail;
              draft.proxyPhone = newParty.proxyPhone;
              draft.proxyName = newParty.proxyName;
              draft.typeHostComposite = newParty.typeHostComposite;
              draft.message = newParty.message;
              draft.verification = newParty.verification;
              if (newParty.verificationDefaultCleared != null) draft.verificationDefaultCleared = newParty.verificationDefaultCleared;

              if (!draft.snapshot) return;
              draft.snapshot.phone = newParty.snapshot.phone;
              draft.snapshot.email = newParty.snapshot.email;
            };

            FileStorage.alterManifestData(
              fileId,
              manifest => {
                switch (manifest.manifestType) {
                  case ManifestType.FormInstance:
                    updatePartyDraft((manifest.data.signing?.parties || []).find(p => p.id === partyId));
                    break;
                  case ManifestType.FormInstancePlusMarketingAndSnapshot:
                  case ManifestType.FormInstancePlusMarketingData:
                    updatePartyDraft((manifest.data.formInstance.signing?.parties || []).find(p => p.id === partyId));
                    break;
                  default:
                    break;
                }
              })
              .catch(console.error);

            updateSigningParty(updatePartyDraft);
          }}
        />
      </ErrorBoundary>}
  </>;
}

export function getEmailEventText(eventType: EmailEvent | undefined, timestring: string | undefined, linkType?: 'signing' | 'form1' | 'distrib'): string {
  if (!eventType) return '';
  timestring = timestring || '';
  switch (eventType) {
    case EmailEvent.open:
      return `Message opened ${timestring}`;
    case EmailEvent.bounce:
      return 'Message was not delivered due to bounce';
    case EmailEvent.click:
      switch (linkType) {
        case 'form1':
          return `Form 1 link clicked ${timestring}`;
        case 'distrib':
          return 'Document link clicked';
        case 'signing':
        default:
          return `Signing link clicked ${timestring}`;
      }
    case EmailEvent.delivered:
      return `Message delivered ${timestring}`;
    case EmailEvent.dropped:
      return 'Message dropped';
    case EmailEvent.spamreport:
      return 'Message was marked as spam by recipient';
    case EmailEvent.deferred:
    case EmailEvent.processed:
      return 'Message is currently pending delivery';
  }
}
