import React, { useEffect, useReducer, useState } from 'react';
import { v4 } from 'uuid';
import { ObservableV2 } from 'lib0/observable';

export interface Task<TResult> { fn: () => Promise<TResult>, name: string }
interface TaskInternal<TResult> extends Task<TResult> { id: string }
export type TaskQueueResult<TResult> =
  | { id: string, name: string, result: TResult }
  | { id: string, name: string, err: any };
interface TaskQueueState<TResult> {
  tasks: TaskInternal<TResult>[],
  results: TaskQueueResult<TResult>[];
  completed: boolean;
}

function getEmptyTaskQueueState<TResult>(): TaskQueueState<TResult> {
  return {
    completed: false,
    tasks: [],
    results: []
  };
}

type TaskAction<TResult> =
  | { type: 'add', task: TaskInternal<TResult> }
  | { type: 'finish', id: string, result: TResult }
  | { type: 'error', id: string, err: any }
  | { type: 'clear' };

function reducerFn<TResult>(state: TaskQueueState<TResult>, action: TaskAction<TResult>): TaskQueueState<TResult> {
  switch (action.type) {
    case 'clear':
      return {
        tasks: [],
        results: [],
        completed: false
      };
    case 'add':
      return {
        tasks: [...state.tasks, action.task],
        results: [...state.results],
        completed: false
      };
    case 'finish': {
      const match = state.tasks.find(t => t.id === action.id);
      if (!match) {
        return state;
      }
      return {
        tasks: [...state.tasks.filter(t => t.id !== action.id)],
        results: [...state.results, { id: action.id, name: match.name, result: action.result }],
        completed: state.tasks.length === 1
      };
    }

    case 'error': {
      const match = state.tasks.find(t => t.id === action.id);
      if (!match) {
        return state;
      }
      return {
        tasks: [...state.tasks.filter(t => t.id !== action.id)],
        results: [...state.results, { id: action.id, name: match.name, err: action.err }],
        completed: state.tasks.length === 1
      };
    }
  }
}

export function useTaskQueue<TResult>() {
  const [state, dispatch] = useReducer<React.Reducer<TaskQueueState<TResult>, TaskAction<TResult>>>(reducerFn, getEmptyTaskQueueState());
  const [processing, setProcessing] = useState(false);
  const [eventTarget] = useState(new TaskQueueEventEmitter<TResult>());

  useEffect(() => {
    if (state.tasks.length === 0 || processing) return;
    setProcessing(true);
    const task = state.tasks[0];
    (async () => {
      try {
        const result = await task.fn();
        dispatch({ type: 'finish', id: task.id, result });
      } catch (err: any) {
        dispatch({ type: 'error', id: task.id, err });
      } finally {
        setProcessing(false);
      }
    })();
  }, [state, processing]);

  useEffect(() => {
    if (!state.completed) return;
    eventTarget.emit('completed', [state.results]);
  }, [state.completed]);

  return {
    results: state.results,
    remaining: state.tasks.map(t => t.name),
    enqueue: (tasks: Task<TResult>[]) => {
      for (const task of tasks) {
        dispatch({ type: 'add', task: { id: v4(), ...task } });
      }
    },
    clear: () => {
      dispatch({ type: 'clear' });
    },
    eventTarget
  };
}

export class TaskQueueEventEmitter<TResult> extends ObservableV2<{
  completed: (results: TaskQueueResult<TResult>[]) => void
}> {
  public constructor() { super(); }
}
