import { FormInstance, FormInstanceUploadFileItem, UploadPdfAction } from '@property-folders/contract';
import { getUploadAsV2 } from '../yjs-schema/property';
import { Predicate } from '../predicate';
import { applyPdfChanges, ChangeFn, makeUploadPdfChangeFns } from './pdf';
import { stitch } from './pdf/pdf-stitch';

export interface LoadedFormUploadFile {
  data: Blob,
  name: string,
  id: string,
  actions?: UploadPdfAction[],
  cover?: boolean
}

export interface MergeProcessFileMeta {
  sourceId: string;
  isCover: boolean;
  pages: number;
  start: number;
}

export type OnMergeProcessFileMetaFn = (meta: MergeProcessFileMeta, actions: UploadPdfAction[]) => void;

/**
 * Get all the uploaded files in a form, sorted.
 */
export function getFormUploadRefs(form: FormInstance | undefined): FormInstanceUploadFileItem[] {
  const upload = getUploadAsV2(form?.upload, form?.created);
  if (!upload?.files?.length) return [];

  return [...upload.files];
}

export async function loadFormUploadContents({
  form,
  fnGetFile
}: {
  form: FormInstance | undefined,
  fnGetFile: (id: string) => Promise<Blob | undefined>
}): Promise<LoadedFormUploadFile[]> {
  const refs = getFormUploadRefs(form);

  return (await Promise.all(refs.map<Promise<LoadedFormUploadFile | undefined>>(async (ref, order) => {
    try {
      const data = await fnGetFile(ref.id);
      if (!data) return undefined;

      return {
        data,
        order,
        id: ref.id,
        actions: ref.actions,
        cover: ref.cover,
        name: ref.name
      };
    } catch (err: unknown) {
      return undefined;
    }
  }))).filter(Predicate.isNotNull);
}

export async function makeMergedFormUploadPdfData({
  files,
  destructive,
  onFileMeta,
  fns
}: {
  files: LoadedFormUploadFile[],
  destructive?: boolean,
  onFileMeta?: OnMergeProcessFileMetaFn,
  fns: { makeCover: (title: string) => Promise<ArrayBuffer> }
}) {
  let start = 0;
  const stitchItems: (Uint8Array | ArrayBuffer)[] = [];
  // this needs to be sequential because of the metadata callbacks
  for (const file of files) {
    stitchItems.push(
      await mutateDocument({
        pdf: file.data,
        name: file.name,
        actions: makeUploadPdfChangeFns(file.actions || [], destructive),
        cover: file.cover,
        fns,
        onDoc: pageCount => {
          onFileMeta?.({
            sourceId: file.id,
            pages: pageCount,
            isCover: false,
            start
          }, file.actions || []);
          start += pageCount;
        },
        onCover: pageCount => {
          onFileMeta?.({
            sourceId: file.id,
            pages: pageCount,
            isCover: true,
            start
          }, []);
          start += pageCount;
        }
      })
    );
  }
  return await stitch(stitchItems);
}

export async function mutateDocument({
  pdf,
  name,
  actions,
  cover,
  onDoc,
  onCover,
  fns
}: {
  pdf: Blob,
  name: string,
  actions?: ChangeFn[],
  cover?: boolean,
  onDoc?: (pageCount: number) => void,
  onCover?: (pageCount: number) => void,
  fns: { makeCover: (title: string) => Promise<ArrayBuffer> }
}): Promise<ArrayBuffer | Uint8Array> {
  let data = await pdf.arrayBuffer();

  const coverData = cover
    ? await fns.makeCover(name)
    : undefined;
  if (coverData) {
    onCover?.(1);
  }

  if (actions?.length || onDoc) {
    data = await applyPdfChanges(data, actions || [], doc => onDoc?.(doc.getPageCount()));
  }

  if (coverData) {
    data = await stitch([coverData, data]);
  }

  return data;
}
