import { formatTimestamp } from '@property-folders/common/util/formatting';
import { formatBytes } from '@property-folders/common/react/uncompiled-lib/react-dropzone-uploader/utils';
import React from 'react';

export function FormatUploaderInfo({ name, size, timestamp, timeZone }: { name?: string, size?: number, timestamp?: number, timeZone?: string  }) {
  return <div className='d-flex flex-column align-items-end'>
    {name && <div className='text-end' style={{ textWrap: 'balance' }}>Uploaded by {name}{timestamp ? ` at ${formatTimestamp(timestamp, timeZone, true)}` : ''}</div>}
    {size && <div>{formatBytes(size)}</div>}
  </div>;
}
