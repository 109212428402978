import { Allotment, LayoutPriority } from 'allotment';
import { SigningSession } from '@property-folders/components/dragged-components/signing/SigningSession';
import {
  FormCode,
  FormInstance,
  MaterialisedPropertyData,
  PropertyRootKey,
  SignedStates,
  TransactionMetaData
} from '@property-folders/contract/yjs-schema/property';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { Button, Spinner } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import React from 'react';
import { Y } from '@syncedstore/core';
import { usePdfPreviewUrl } from '@property-folders/components/dragged-components/subscriptionForms/hooks';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { useBrandConfig } from '@property-folders/components/hooks/useEntity';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { applyPreviewWatermark } from '@property-folders/common/subscription-forms/apply-preview-watermark';
import { highlightTemplateForm1Fill } from '@property-folders/common/subscription-forms/highlight-template-form1-fill';

enum AllotmentPaneMode {
  Both = 0,
  Content = 1,
  Preview = 2
}

interface Props {
  yDoc: Y.Doc,
  formInstance: FormInstance;
  onCancel: () => void;
  allotmentPaneMode: AllotmentPaneMode,
  setAllotmentPaneMode: React.Dispatch<React.SetStateAction<AllotmentPaneMode>>
  onVisibleChange: (changedIndex: number, changedNowVisible: boolean) => void
}

export function SubscriptionFormOutForSigning({ yDoc, formInstance, onCancel, allotmentPaneMode, setAllotmentPaneMode, onVisibleChange }: Props) {
  const formCode = formInstance.formCode;
  const formId = formInstance.id;

  const { bindState: dataBindState } = useImmerYjs<MaterialisedPropertyData>(yDoc, PropertyRootKey.Data);
  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(yDoc, PropertyRootKey.Meta);
  const { data } = dataBindState<MaterialisedPropertyData>(m => m);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const renderTextLayer = useBreakpointValue({ base: false, sm: true }, true);
  const {
    signingSessionWizardPropsForSidebar,
    partyGroups,
    serveToPurchaserProps,
    signingMainProps
  } = useSigningNavProps({
    signing: formInstance.signing,
    formCode: formInstance.formCode
  });
  const headline = generateHeadlineFromMaterialisedData(data);

  const showContentPane = allotmentPaneMode === AllotmentPaneMode.Both || allotmentPaneMode === AllotmentPaneMode.Content;
  const showPreviewPane = allotmentPaneMode === AllotmentPaneMode.Both || allotmentPaneMode === AllotmentPaneMode.Preview;
  const formConfigs = useBrandConfig();
  const brand = formConfigs.getFormConfig(meta?.entity?.id);
  const { pdfPreviewUrl, pdfLoadErrorMessage, regenerateForDownloadPrintUsage } = usePdfPreviewUrl({
    form: formInstance,
    yDoc,
    formCode,
    formId,
    brand,
    previewPdfTransforms: FormTypes[formCode]?.formFamily === FormCode.Form1 && SignedStates.has(formInstance.signing.state) ? [applyPreviewWatermark, highlightTemplateForm1Fill ]: []
  });

  const formName = formInstance.upload?.name ?? formInstance.subscription?.fileName ?? formInstance.formCode;
  const fileName = formName ? `${formName} - ${headline}.pdf` : `${headline}.pdf`;

  return <Allotment snap onVisibleChange={onVisibleChange}>
    <Allotment.Pane minSize={300} preferredSize={760} priority={LayoutPriority.High}
      className={'overflow-auto'}
      visible={showContentPane}>
      <SigningSession
        formCode={formCode}
        formId={formId}
        ydoc={yDoc}
        onVoid={onCancel}
        wizardSectionProps={signingSessionWizardPropsForSidebar}
        dataRootKey={PropertyRootKey.Data}
        metaRootKey={PropertyRootKey.Meta}
        partyGroups={partyGroups}
        serveToPurchaserProps={serveToPurchaserProps}
        signingMainProps={signingMainProps}
      />
    </Allotment.Pane>
    <Allotment.Pane minSize={300} visible={showPreviewPane}>
      <SetupPdfLoadStateContext>
        {pdfPreviewUrl?.length
          ? <PDFViewer
            filename={fileName}
            pdfUrl={pdfPreviewUrl}
            bookmark={''}
            allowPrint={true}
            contextDependentLoadingMessage={pdfLoadErrorMessage}
            toolbarRight={(
              <Button variant="secondary" onClick={() => setAllotmentPaneMode(AllotmentPaneMode.Content)}>
                <Icon name="close" />
              </Button>
            )}
            offsetRightByDragBar={allotmentPaneMode === AllotmentPaneMode.Both}
            renderTextLayer={renderTextLayer}
            standalonePreview={true}
            onDocumentRegenerate={regenerateForDownloadPrintUsage}
          />
          : <div className={'h-100 d-flex flex-row align-items-center justify-content-center'}
            style={{ backgroundColor: '#2a2a2e' }}>
            <Spinner animation={'border'} style={{ width: '2.5rem', height: '2.5rem', color: '#CCC' }} />
          </div>}
      </SetupPdfLoadStateContext>
    </Allotment.Pane>
  </Allotment>;
}
