import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from './CommonComponentWrappers';
import '../Form.scss';
import { CollectionEditor } from './CollectionEditor';
import { NarrowSalesAgentInput } from './NarrowSalesAgentInput';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { useSelector } from 'react-redux';
import {
  BelongingEntityMeta,
  EntitySettingsExtendedType,
  REDUCER_NAME as entityMetaKey
} from '@property-folders/common/redux-reducers/entityMeta';

import { canonicalisers } from '@property-folders/common/util/formatting';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { v4 } from 'uuid';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { bindSetOrUnsetIfUndefined } from '@property-folders/common/util/immerTools';
import { companyTradingAs } from '@property-folders/common/util/formatting';
import { SetHeaderActionsFn } from '../Wizard/WizardStepPage';
import { Predicate } from '@property-folders/common/predicate';
import { Agent, TransactionMetaData } from '@property-folders/contract';
import { YValue } from './YValue';
import React, { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ModalDepthContext } from '../../context/WithinModal';
import Select from 'react-select';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';

type SalesRepInputProps = TransactionConsumerProps & {
  removable?: boolean;
  hideDelete?: boolean;
  noPersonAuto?: boolean;
  thisLevel?: number;
  primaryAgentMode?: boolean;
  autoFocus?: boolean;
  noSuggest?: boolean;
  setHeaderActions?: SetHeaderActionsFn;
  manageSalesRepresentatives?: boolean;
  restrictAgentChange?: boolean;
  creating?: boolean;
};

export function generateDetailRow (entity: {rla?: number|string, abn?: number|string}) {
  return <div className='d-flex flex-grow-1 justify-content-between combo-field-subtext'>
    <div>Agent No: {entity?.rla || '(none)'}</div>
    <div>ABN: {canonicalisers.abnacn(entity?.abn).display || '(none)'}</div>
  </div>;
}

export const NarrowAgentInput = ({
  noSuggest = true,
  primaryAgentMode = false,
  thisLevel = 1,
  removable = true,
  hideDelete = false,
  noPersonAuto = false,
  manageSalesRepresentatives = true,
  autoFocus,
  restrictAgentChange = false,
  creating = false,
  ...restProps
}: SalesRepInputProps): JSX.Element => {
  if (primaryAgentMode) {
    noSuggest = false;
  }
  const { value: entity, fullPath, handleRemove } = useTransactionField<Agent>(restProps);
  const { valid: rlaValid, errorKeys: rlaError, mergedRules: rlaRules } = useTransactionField({ parentPath: fullPath, myPath: 'rla' });
  const { updateDraft } = useYdocBinder<Agent>({ path: fullPath });

  const { value: linkedEntity, handleRemove: removeLinkedEntity } = useTransactionField({ parentPath: fullPath, myPath: 'linkedEntityId' });
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const agentNameKey = primaryAgentMode ? 'profileName' : 'company';
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { value: meta } = useLightweightTransaction<TransactionMetaData>({ myPath: '', bindToMetaKey: true });
  const [showConfirm, setShowConfirm] = useState<undefined | { suggestion: SelectEntityOption, salespNotInSuggestion: string[] }>(undefined);

  const options = Object.values(memberEntities||{})
    .map<undefined | SelectEntityOption>(e => {
      if (!e) return undefined;
      if (e.archived === true || e.useNewPropertyTransactions === false) return undefined;

      const compositeName = companyTradingAs(e.name, e.tradeName);
      return {
        label: e.profileName || compositeName,
        compositeName,
        ...e
      };
    }).filter(Predicate.isNotNull);
  const selected = options.find(option => option?.entityId && option?.entityId === entity?.linkedEntityId);

  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  function suggestionSelected(selection: SelectEntityOption[], force?: boolean) {
    setShowConfirm(undefined);
    const suggestion = selection?.[0];
    if (!suggestion) {
      return;
    }
    const currentPerson = suggestion.salespeople?.find(person => person.id === sessionInfo?.agentId);
    // checking for a name filters out blank placeholder salespeople
    const salespNotInSuggestion = entity?.salesp
      ?.filter(sp => sp.name && !suggestion.salespeople?.find(sug => sp.linkedSalespersonId === sug.id))
      ?.map(sp => sp.name) || [];
    if (salespNotInSuggestion.length && force !== true) {
      setShowConfirm({ suggestion, salespNotInSuggestion });
      return;
    }
    updateDraft?.(draft => {
      const setEntityField = bindSetOrUnsetIfUndefined(draft);
      draft.company = suggestion.compositeName;
      draft.profileName = suggestion.label;
      if (primaryAgentMode && !creating) {
        draft.sharingChangesPending = true;
      }
      setEntityField('abn', suggestion.abn ? canonicalisers.abnacn(suggestion.abn).canonical : undefined);
      setEntityField('rla', suggestion.rla);
      setEntityField('linkedEntityId', suggestion.entityId || undefined);

      if (!Array.isArray(draft.salesp)) {
        draft.salesp = [];
      }
      for (let salespIdx = draft.salesp.length - 1; salespIdx >= 0; salespIdx--) {
        const salesp = draft.salesp[salespIdx];
        const spSource = suggestion.salespeople?.find(esp => esp.id === salesp.linkedSalespersonId);
        if (!spSource) {
          (draft.salesp as any[]).splice(salespIdx, 1);
          continue;
        }
        if (!spSource?.id) console.warn('Loop set salesperson has lost id');
        const setSalespField = bindSetOrUnsetIfUndefined(salesp);
        setSalespField('name', spSource?.name);
        setSalespField('email', canonicalisers.email(spSource?.email).canonical);
        setSalespField('phone', canonicalisers.phone(spSource?.phone).canonical);
        setSalespField('linkedSalespersonId', spSource?.id);
      }

      if (draft.salesp.length === 0 && currentPerson?.isSalesperson) {
        if (!currentPerson?.id) console.warn('Default person set has no id');
        draft.salesp.push({
          id: v4(),
          name: currentPerson?.name || '',
          phone: currentPerson?.phone ? canonicalisers.phone(currentPerson?.phone || '').canonical.toString() : '',
          email: currentPerson?.email ? canonicalisers.email(currentPerson?.email || '').canonical.toString() : '',
          linkedSalespersonId: currentPerson?.id
        });
      }
    });
  }

  function onUnbouncedFieldChange() {
    removeLinkedEntity();
  }

  function linkedIcon(text: string) {
    return linkedEntity
      ? <>{text} <i className='bi bi-link-45deg' /></>
      : text;
  }
  const extraRowData = primaryAgentMode
    ? generateDetailRow(entity)
    : undefined;

  const userIsOwner = Boolean(sessionInfo?.agentId && meta?.creator?.id && sessionInfo.agentId === meta.creator.id);
  const userCanManage = sessionInfo?.entities?.filter(x => meta?.entity?.id && x.entityId === meta.entity.id && x.roles.find(r => r === 'Manager')) || [];
  const userCanManageEntity = Boolean(userCanManage.length);
  const userCanChangeEntity = !restrictAgentChange || userIsOwner || userCanManageEntity;

  const { modalClassName, backdropClassName } = useContext(ModalDepthContext);

  return (
    <div className='w-100'>
      {!!showConfirm && <Modal show={true} backdrop='static' onHide={() => setShowConfirm(undefined)} className={modalClassName} backdropClassName={backdropClassName}>
        <Modal.Header>
          <Modal.Title>Change Agency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The assigned salesperson(s) {showConfirm.salespNotInSuggestion.join(', ')} do not have access to {showConfirm.suggestion.name}.</p>
          <p>If you continue to change the Agency, they will be removed from this Property Folder.</p>
          <p>Permissions for Teams and Users not part of {showConfirm.suggestion.name} will be removed.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-secondary' onClick={() => setShowConfirm(undefined)}>Cancel</Button>
          <Button onClick={() => suggestionSelected([showConfirm.suggestion], true )}>Continue</Button>
        </Modal.Footer>
      </Modal>}
      <div className="d-flex w-100 gapped-row">
        <div className='w-100'>
          <div className="d-flex w-100 flex-wrap gapped-row">
            <div className='flex-grow-1' style={{ minWidth: '300px' }}>
              {userCanChangeEntity
                ? noSuggest
                  ? <WrField.Control
                    onChange={onUnbouncedFieldChange}
                    name={agentNameKey}
                    label={primaryAgentMode ? undefined : linkedIcon('Agency name')}
                    autoFocus={autoFocus}
                    parentPath={fullPath}
                    myPath={agentNameKey}
                    placeholder='Agent company name'
                    className={primaryAgentMode ? 'fw-bold' : undefined}
                    extraErrors={!rlaValid && rlaError.length > 0 && rlaError[0] === 'stillInvalidAfterProcessing' ? [rlaRules._validationRequirementFailedMessage] : undefined}
                  />
                  : primaryAgentMode
                    ? <SelectPrimaryAgent
                      onSuggestionSelect={newValue => {
                        if (!newValue) return;
                        suggestionSelected([newValue], Boolean(creating));
                      }}
                      options={options}
                      value={selected}
                    />
                    : <WrField.AutoComplete
                      onChange={onUnbouncedFieldChange}
                      options={options}
                      optionRender={(option, idx) => {
                        const entity = option as EntitySettingsEntity;
                        return <div className='d-flex flex-column'>
                          <div className='fw-bold'>{entity.label}</div>
                          {entity.profileName && <div>{entity.compositeName}</div>}
                          {generateDetailRow(entity)}
                        </div>;
                      }}
                      onSuggestSelect={items => suggestionSelected(items as SelectEntityOption[], true)}
                      name={agentNameKey}
                      label={linkedIcon('Agency name')}
                      autoFocus={autoFocus}
                      parentPath={fullPath}
                      myPath={agentNameKey}
                      placeholder='Agent company name'
                      extraRowData={extraRowData}
                      selectOnly={false}
                      extraErrors={!rlaValid && rlaError.length > 0 && rlaError[0] === 'stillInvalidAfterProcessing' ? [rlaRules._validationRequirementFailedMessage] : undefined}
                    />
                : <YValue
                  parentPath={fullPath}
                  myPath={agentNameKey}
                >{value => <div className='d-flex flex-column'>
                    <div className='fw-bold'>{value}</div>
                    {entity.profileName && <div>{entity.compositeName}</div>}
                    {generateDetailRow(entity)}
                  </div>}</YValue>
              }
            </div>
          </div>
          {!primaryAgentMode && <div className='d-flex w-100 flex-wrap gapped-row'>
            <div className='flex-grow-1' style={{ minWidth: '300px' }}>
              <WrField.Control onChange={onUnbouncedFieldChange} name='rla' label={linkedIcon('Agent RLA')} parentPath={fullPath} myPath='rla' placeholder='######'/>
            </div>
            <div className='flex-grow-0' style={{ minWidth: '200px' }}>
              <WrField.Control onChange={onUnbouncedFieldChange} name='abn' label={linkedIcon('Agent ABN')} parentPath={fullPath} myPath='abn' placeholder='## ### ### ###'/>
            </div>
          </div>}
        </div>
        {(!hideDelete) && <div className='d-flex align-items-center delete-div ms-1'>
          {closeButton}
        </div>}
      </div>
      {manageSalesRepresentatives && <div className='w-100 mt-3'>
        <CollectionEditor
          level={thisLevel}
          title={entity?.salesp?.length > 0 ? (entity?.salesp?.length > 1 ? 'Sales Representatives' : 'Sales Representative') : ''}
          parentPath={fullPath}
          myPath='salesp'
          setHeaderActions={restProps.setHeaderActions}
          autoAddFirst={!noPersonAuto}
          childItemRenderer={NarrowSalesAgentInput}
          itemNoun='Sales Representative'
          restorationFieldDisplay='name'
          indentLevel={hideDelete ? 0 : 1}
          addTooltip='Add an additional Sales Representative to this agency'
          childProps={{ linkedEntityId: entity?.linkedEntityId, primaryAgentMode, creating }}
        />
      </div>}
    </div>

  );
};

export type SelectEntityOption = EntitySettingsExtendedType & {
  label: string,
  compositeName: string
};

export function SelectPrimaryAgent({
  options,
  value,
  onSuggestionSelect
}: {
  options: SelectEntityOption[],
  value?: SelectEntityOption,
  onSuggestionSelect: (selected?: SelectEntityOption) => void,
}) {
  return <Select
    options={options}
    value={value}
    placeholder={''}
    getOptionValue={x => x.entityId.toString()}
    onChange={x => {
      onSuggestionSelect(x || undefined);
    }}
    formatOptionLabel={(entity, meta) => {
      return <div className='d-flex flex-column'>
        <div className='fw-bold'>{entity.label}</div>
        {entity.profileName && <div>{entity.compositeName}</div>}
        {generateDetailRow(entity)}
      </div>;
    }}
    styles={{
      control: (base, state) => ({
        ...base,
        ...state.isFocused ? {
          borderColor: '#8db7fb',
          outline: '0',
          boxShadow: '0 0 0 0.25rem rgba(26, 110, 246, 0.25)',
          ':hover': {
            borderColor: '#8db7fb'
          }
        } : undefined,
        background: 'var(--bs-body-bg)',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'var(--clr-input-border)',
        borderRadius: 0,
        fontSize: '14px',
        height: state.getValue()?.at(0)?.profileName
          ? 'calc(2px + 5rem)'
          : 'calc(2px + 3.5rem)',
        lineHeight: '1.25'
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: '#343a40',
        cursor: 'pointer',
        ':hover': {
          color: '#343a40'
        }
      }),
      indicatorSeparator: () => ({
        display: 'none'
      }),
      input: base => ({
        ...base,
        marginBottom: '1rem'
      }),
      menu: base => ({ ...base, borderRadius: '0', zIndex: 11 }),
      option: (base) => ({
        ...base,
        backgroundColor: 'white',
        color: 'black',
        ':hover': { backgroundColor: '#e9ecef', color: 'black' }
      }),
      singleValue: (base, item) => ({
        ...base,
        color: item.data.archived
          ? 'var(--clr-input-border)'
          : base.color
      })
    }}
  />;
}
