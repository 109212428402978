import { useInfiniteQuery } from '@tanstack/react-query';
import { cloneSimple } from '../util/cloneSimple';
import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';
import {
  GetDocumentSigningDetailsItem,
  ListDocumentQuery,
  ListDocumentResponse,
  PropertyList
} from '@property-folders/contract/rest/document';
import * as Y from 'yjs';
import { generateHeadlineFromMaterialisedData, getDocumentName, materialiseProperty } from '../yjs-schema/property';
import { map } from 'lodash';
import {
  FormCode,
  FormCodeUnion,
  FormInstanceSigning,
  FormOrderState,
  SigningPartyType
} from '@property-folders/contract';
import { compareFormInstances } from '../util/compareFormInstances';
import { getInvolvedSigningParties } from '../yjs-schema/property/form';
import {
  generateDocumentSummary,
  generateFormInstanceSigningStatus
} from '../util/form';
import { byMapperFn } from '../util/sortComparison';
import { Predicate } from '../predicate';

export class DocumentListApi {
  static async getDocumentList(params: ListDocumentQuery): Promise<Partial<ListDocumentResponse>> {
    return {
      ...await WrappedFetch.jsonWithDate<ListDocumentResponse>(LinkBuilder.restApi(`/documents/?${LinkBuilder.buildQueryString(params)}`))
    };
  }

  static useInfiniteDocumentList(defaultParams: ListDocumentQuery & { fromNewPropertyFolder: boolean, propertyYdoc?: Y.Doc, isOnline?: boolean }) {
    return !defaultParams.fromNewPropertyFolder ? useInfiniteQuery(
      [
        'document',
        'list',
        defaultParams.limit ?? 100,
        defaultParams.entityUuid ?? 'all',
        defaultParams.searchTerm ?? '',
        defaultParams.propertyId ?? '',
        defaultParams.productId ?? -1,
        defaultParams.moduleId ?? -1,
        defaultParams.formId ?? -1,
        defaultParams.folderId ?? -1,
        defaultParams.formCode ?? '',
        defaultParams.showArchived ?? false,
        defaultParams.documentType
      ],
      async context => {
        return await DocumentListApi.getDocumentList(context.pageParam ?? defaultParams);
      },
      {
        getNextPageParam: (lastPage, _) => {
          if (!lastPage?.results || lastPage.results.length === 0) {
            return undefined;
          }

          const p = cloneSimple(defaultParams);
          p.pageNumber = (lastPage.pageNumber ?? 1) + 1;
          return p;
        }
      }
    ) : {
      data: {
        pages: [{
          pageNumber: 1,
          results: defaultParams.propertyYdoc
            ? generateDocumentsListFromYdoc(
              defaultParams.propertyYdoc,
              !!defaultParams.isOnline,
              !!defaultParams.showArchived,
              defaultParams.searchTerm)
            : []
        }]
      },
      hasNextPage: false,
      isFetching: false,
      isFetchingNextPage: false,
      fetchNextPage: undefined,
      refetch: () => {}
    };
  }
}

export function generateDocumentsListFromYdoc(yDoc: Y.Doc, isOnline: boolean, showArchived?: boolean, filter?: string): PropertyList[] {
  const { meta, data, alternativeRoots } = materialiseProperty(yDoc) || {};
  if (!meta || !data) return [];

  const allRoots = [{ meta, data }, ...map(alternativeRoots, r => r)];
  let id = 0;

  // return details about the latest instance in each family, unless it's the uploaded documents family,
  // in which case return them all.
  // also exclude archived forms if showArchived is false.
  return allRoots
    .flatMap((r, idx) => Object
      .entries(r.meta.formStates || {})
      .flatMap(([formCode, family]) => {
        if (!idx && formCode === FormCode.RSC_ContractOfSale) return [];
        if (!showArchived && family.archived) return [];

        const instances = formCode === FormCode.UploadedDocument || formCode === FormCode.Form1
          ? [...family?.instances || []]
          : [family?.instances?.sort?.(compareFormInstances)?.at(0)].filter(Predicate.isNotNull);

        // `folderId` does not exist on `subscription`.
        return instances.map<Omit<PropertyList, 'subscriptionFolderId'> | undefined>(instance => {
          if (!showArchived && instance.archived) return undefined;
          if (instance.order && instance.order.state !== FormOrderState.ReturnedToClient) return undefined;
          const formName = getDocumentName(formCode as FormCodeUnion, instance);
          if (!formName) return undefined;
          const signingParties = getInvolvedSigningParties(instance.signing);

          return {
            id: id++,
            subscriptionDocumentId: instance.subscription?.documentId,
            subscriptionFormId: instance.subscription?.formId,
            propertyId: data.id,
            propertyFormId: instance.id,
            name: generateHeadlineFromMaterialisedData(data),
            status: generateFormInstanceSigningStatus(family, instance),
            statusDetail: {
              Total: signingParties.length,
              Signed: signingParties.filter(p => p.signedTimestamp).length
            },
            signingDetail: getSigningDetail(instance.signing),
            ownerId: meta.creator?.id || -1,
            ownerName: meta.creator?.name || '',
            entityId: meta.entity?.id || -1,
            createdStamp: new Date(instance.created || 0),
            updateStamp: new Date(isOnline ? (instance.dataModified || instance.modified || 0) : instance.modified || 0),
            formName,
            isArchived: !!(family.archived || instance.archived),
            formCode: formCode,
            summary: generateDocumentSummary(family, r.data || data)
          };
        }).filter(Predicate.isNotNull);
      })
    )
    .filter(Predicate.isNotNull)
    .filter(d => d && (!filter || `${d.formName} ${d.summary}`.toUpperCase().indexOf(filter.toUpperCase()) >= 0))
    .sort(byMapperFn(x => x?.updateStamp.getTime() || 0, 'desc'));
}

const getSigningDetail = (signing: FormInstanceSigning | undefined) : GetDocumentSigningDetailsItem[] | undefined => {
  if (!signing) return undefined;
  const parties = getInvolvedSigningParties(signing);
  if (!parties.length) return undefined;
  return parties.map(p => {
    const proxyMode = Predicate.proxyNotSelf(p.proxyAuthority);
    const email = proxyMode ? p.proxyEmail : p.snapshot?.email;
    const phone = proxyMode ? p.proxyPhone : p.snapshot?.phone;
    return {
      type: 'sign',
      status: p.signedTimestamp ? 'done' : p.declineTimestamp ? 'declined' : 'pending',
      method: getMethod(p.type),
      timestamp: p.signedTimestamp || signing.session?.timestamp,
      name: p.snapshot?.name || '',
      recipient: p.type === SigningPartyType.SignOnline
        ? email
        : p.type === SigningPartyType.SignOnlineSms
          ? phone
          : undefined
    };
  });
};

function getMethod(type: SigningPartyType): GetDocumentSigningDetailsItem['method'] {
  switch (type) {
    case SigningPartyType.SignOnline:
      return 'email';
    case SigningPartyType.SignInPerson:
      return 'hosted';
    case SigningPartyType.SignWet:
      return 'wet';
    case SigningPartyType.SignOnlineSms:
      return 'sms';
  }
}
