import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { boolYesNoOpts } from '@property-folders/common/data-and-text/constants';
import { Predicate } from '@property-folders/common/predicate';
import { AddressSelector } from '../AddressSelector';
import { WrField } from '../CommonComponentWrappers';
import './ContractSpecialConditions.scss';
import { usePropertyGnafLocation } from '../../../hooks/usePropertyGnafLocation';
import type { ContractSpecialType } from '@property-folders/contract';
import { useContext } from 'react';
import { LineageContext } from '../../../hooks/useVariation';

export const ContractSpecialConditionsPurchaserSale = ({ purchaserMode, radioEnable, letterOfOffer }: {purchaserMode?: boolean; radioEnable?: boolean, letterOfOffer?: boolean}): JSX.Element => {
  const { value } = useLightweightTransaction<ContractSpecialType>({ myPath: 'contractSpecial' });
  const sectionApplicable = value?.purchaserSaleRequired;
  const settlementRequired = value?.saleSettleAlsoRequired;
  const minimumPriceAllowed = Predicate.boolFalse(value?.saleSettleAlsoRequired);
  const gnafId = usePropertyGnafLocation();

  const { variationsMode, snapshotData } = useContext(LineageContext);
  const doNotRenderHide = purchaserMode || ( variationsMode && !snapshotData?.contractSpecial?.hideSaleRequired);

  return <div className="w-100">
    <div className="subsection">
      {!doNotRenderHide  && <div className="w-100 mt-2">
        <WrField.BoolCheck name='hideFinanceSC' label={'Do not print this Special Condition'} parentPath={'contractSpecial'} myPath='hideSaleRequired' />
      </div>}
      {!value?.hideSaleRequired && <>
        <div className="w-100 mt-2">
          <WrField.CheckRadio
            label={!purchaserMode ? 'Is this Contract subject to the sale of the Purchaser’s property?' : 'Is this offer subject to the sale of your property?'}
            radioType={radioEnable ? 'radio' : 'checkbox'}
            options={boolYesNoOpts}
            valueType="boolean"
            inline={true}
            name="purchaserSaleRequired"
            myPath="contractSpecial.purchaserSaleRequired"
          />
        </div>
        {sectionApplicable && <>
          <div className="w-100 mt-2">
            <WrField.CheckRadio
              label='Has a contract been entered into for the sale of the Purchaser’s property?'
              radioType={radioEnable ? 'radio' : 'checkbox'}
              options={boolYesNoOpts}
              valueType="boolean"
              inline={true}
              name="saleSettleAlsoRequired"
              myPath="contractSpecial.saleSettleAlsoRequired"
            />
          </div>
          {
            letterOfOffer && value?.saleSettleAlsoRequired && <div className="w-100 mt-2">
              <WrField.CheckRadio
                label="Is the contract for the sale of the Purchaser’s property unconditional?"
                radioType={radioEnable ? 'radio' : 'checkbox'}
                options={boolYesNoOpts}
                valueType="boolean"
                inline={true}
                name="saleSettleUnconditional"
                myPath="contractSpecial.saleSettleUnconditional"
              />
            </div>
          }
          {
            letterOfOffer && Predicate.boolFalse(value?.saleSettleAlsoRequired) && <div className="w-100 mt-2">
              <WrField.CheckRadio
                label="Is the Purchaser’s property listed for sale?"
                radioType={radioEnable ? 'radio' : 'checkbox'}
                options={boolYesNoOpts}
                valueType="boolean"
                inline={true}
                name="saleSettleUnconditional"
                myPath="contractSpecial.salePropertyListedForSale"
              />
            </div>
          }
          <div className="w-100 mt-2">
            <AddressSelector
              label="Address of property to be sold"
              myPath="contractSpecial.salePropertySingleLine"
              gnafCentre={gnafId}
              international={purchaserMode} // Purchaser portal doesn't have the authorisation context to access our regular lookup
            />
          </div>
          <div className="w-100 d-flex flex-wrap">
            <div className="flex-grow-1 mt-2" style={{ minWidth: '150px' }}>
              <WrField.Control
                type="date"
                dateFromToday={settlementRequired ? false : true}
                dateUntilToday={settlementRequired ? true : false}
                label={settlementRequired ? 'Date of contract entered into' : 'Deadline for entering contract'}
                name="purchaserSaleContractDeadline"
                myPath="contractSpecial.purchaserSaleContractDeadline"
                useCanonical={true}
              />
            </div>
            <div className="flex-grow-1 mt-2" style={{ minWidth: '150px' }}>
              <WrField.Control
                type="date"
                dateFromToday={true}
                label={settlementRequired ? 'Deadline for settlement' : 'Deadline for settling on contract'}
                name="purchaserSaleSettlementDeadline"
                myPath="contractSpecial.purchaserSaleSettlementDeadline"
                useCanonical={true}
              />
            </div>
            {minimumPriceAllowed && <div className="flex-grow-1 mt-2" style={{ minWidth: '150px' }}>
              <WrField.Control
                label="Minimum price of contracted sale"
                name="purchaserSaleMinimumPrice"
                myPath="contractSpecial.purchaserSaleMinimumPrice"
              />
            </div>}
          </div>
        </>
        }
      </>}
    </div>
  </div>;
};
