import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { Button, Form, Modal } from 'react-bootstrap';
import { SelectPropertyFolder } from '~/components/SelectPropertyFolder';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';

import { seoFriendlySlug } from '@property-folders/common/util/url';
import { UploadDocumentUtil, UploadFileInfo } from '@property-folders/components/hooks/useUploadedDocumentUpload';
import { AllDocumentStatus, DocumentType, PropertyList } from '@property-folders/contract/rest/document';
import { FormCode, LookupCanShareResultItem } from '@property-folders/contract';
import { AddSharingRecipient, MixedSharingItem, SharingRecipientRow } from '@property-folders/components/dragged-components/Wizard/SharingWizardStepPage';
import { v4 } from 'uuid';
import { useFeatureFlags } from '@property-folders/components/hooks/useFeatureFlags';
import { SelectEntityOption, SelectPrimaryAgent } from '@property-folders/components/dragged-components/form/NarrowAgentInput';
import { companyTradingAs } from '@property-folders/common/util/formatting/functions/companyTradingAs';
import { useAppSelector } from '~/redux/hooks';
import { Predicate } from '@property-folders/common/predicate';

type OrganiseType = 'separate' | 'envelope';
type DestinationType = 'property' | 'my-files';

export function FormsListUploadFilesModal({
  onClose,
  fileInfos
}: {
  onClose: () => void,
  fileInfos: UploadFileInfo[]
}) {
  const navigate = useNavigate();
  const { instance: yManager } = useContext(YManagerContext);
  const { instance: fileSync } = useContext(FileSyncContext);
  const [organise, setOrganise] = useState<OrganiseType>('separate');
  const [destination, setDestination] = useState<DestinationType>('my-files');
  const [processing, setProcessing] = useState(false);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const [selectedPropertyId, setSelectedPropertyId] = useState<string>('');
  const [sharingItems, setSharingItems] = useState<LookupCanShareResultItem[]>([]);
  const { existingSids, displayItems } = useMemo(() => {
    return {
      existingSids: new Set<number>(sharingItems.map(si => si.sid)),
      displayItems: sharingItems.map<MixedSharingItem>(x => ({
        accessId: x.sid * -1,
        sid: x.sid,
        role: x.type === 'entity'
          ? 'Agency'
          : x.type === 'team'
            ? 'Team'
            : 'User',
        entityId: x.type === 'entity' ? x.id : undefined,
        teamId: x.type === 'team' ? x.id : undefined,
        agentId: x.type === 'agent' ? x.id : undefined,
        headline: x.name,
        subtitle: x.subtitle || '',
        canShare: false,
        editable: true,
        deletable: true
      }))
    };
  }, [sharingItems]);

  const single = fileInfos.length < 2;
  const ready = Boolean(yManager && (destination !== 'property' || selectedPropertyId));
  const theseDocuments = single ? 'this document' : 'these documents';

  useEffect(() => {
    if (single) {
      setOrganise('separate');
    }
  }, [single]);

  const ff = useFeatureFlags();

  const memberEntities = useAppSelector(state => state.entityMeta);
  const entityOptions = useMemo(() => Object.values(memberEntities||{})
    .map<undefined | SelectEntityOption>(e => {
      if (!e) return undefined;
      if (e.archived === true || !e.useNewPropertyTransactions || !e.useMyFiles) return undefined;

      const compositeName = companyTradingAs(e.name, e.tradeName);
      return {
        label: e.profileName || compositeName,
        compositeName,
        ...e
      };
    }).filter(Predicate.isNotNull), [memberEntities]);
  const [entityId, setEntityId] = useState(entityOptions.at(0)?.entityId);
  useEffect(() => {
    if (entityOptions.length === 1) {
      setEntityId(entityOptions[0].entityId);
    }
  }, [entityOptions]);
  const selectedEntity = entityOptions.find(option => option?.entityId && option?.entityId === entityId);

  return <Modal show backdrop='static' size='lg'>
    <Modal.Header>
      <Modal.Title>Upload {single ? 'document' : 'documents'}</Modal.Title>
    </Modal.Header>
    <Modal.Body className='d-flex flex-column gap-3'>
      {!single && <div>
        <p className='fs-5'>How should {theseDocuments} be organised?</p>
        <Form.Check
          type='radio'
          id='files-organise-envelope'
          name='files-organise'
          value='envelope'
          label='Combine all documents in a single envelope. They will be signed all at once, by the same parties.'
          checked={organise === 'envelope'}
          onChange={() => setOrganise('envelope')}
        />
        <Form.Check
          type='radio'
          id='files-organise-separate'
          name='files-organise'
          value='separate'
          label='Keep the documents separate.'
          checked={organise === 'separate'}
          onChange={() => setOrganise('separate')}
        />
      </div>}
      <div>
        <p className='fs-5'>Where should {theseDocuments} be placed?</p>
        <Form.Check
          type='radio'
          id='files-destination-my-files'
          name='files-destination'
          value='my-files'
          label='Add to SignAnything.'
          checked={destination === 'my-files'}
          onChange={() => setDestination('my-files')}
        />
        <Form.Check
          type='radio'
          id='files-destination-property'
          name='files-destination'
          value='property'
          label='Add to an existing Property Folder.'
          checked={destination === 'property'}
          onChange={() => setDestination('property')}
        />
      </div>
      <div>
        {(() => {
          switch (destination) {
            case 'my-files':
              return <>
                <p><em>SignAnything</em> is your personal document store.</p>
                {ff?.teamManagementVisible && <>
                  {entityOptions.length > 1 && <div className={'mb-3'}>
                    <span>Agency:</span>
                    <SelectPrimaryAgent
                      onSuggestionSelect={newValue => {
                        if (!newValue) return;
                        if (newValue.entityId !== entityId) {
                          setEntityId(newValue.entityId);
                          setSharingItems([]);
                        }
                      }}
                      options={entityOptions}
                      value={selectedEntity}
                    />
                  </div>}
                  {selectedEntity?.teamManagementVisible && <>
                    <p>By default these documents are only editable by you, but you can share with other users or teams in {selectedEntity?.label || 'the agency'}.</p>
                    {displayItems.map(si => <SharingRecipientRow
                      key={si.sid}
                      item={si}
                      canEdit={false}
                      canRemove={true}
                      onChangeCanShare={canShare => {
                        console.log('change canShare', canShare, si, sharingItems);
                      }}
                      onRemove={() => {
                        setSharingItems(cur => {
                          return cur.filter(x => x.sid !== si.sid);
                        });
                      }}
                    />)}
                    {entityId && <AddSharingRecipient
                      entityId={entityId}
                      existingSids={existingSids}
                      onAdd={item => {
                        setSharingItems(cur => {
                          return [...cur, item];
                        });
                      }}
                    />}
                  </>}
                </>}
              </>;
            case 'property':
              return <>
                <SelectPropertyFolder
                  placeholder={'Select a property folder'}
                  label={'Which property folder?'}
                  defaultSearch={''}
                  onSelect={items => setSelectedPropertyId(items.at(0)?.propertyId || '')}
                />
              </>;
          }
        })()}
      </div>
    </Modal.Body>
    <Modal.Footer className='d-flex justify-content-between'>
      <div className='d-flex flex-row gap-2'>
        <Button variant='outline-secondary' disabled={processing} onClick={onClose}>Cancel</Button>
        <AsyncButton
          disabled={!ready}
          onClick={async () => {

            if (!fileSync) return;
            if (!yManager) return;
            if (!sessionInfo) return;
            const entity = sessionInfo.entities.find(e => e.entityId === entityId);
            if (!entity) return;
            setProcessing(true);
            try {
              switch (destination) {
                case 'my-files': {
                  const created = await UploadDocumentUtil.createMyFilesEnvelopes({
                    fileInfos,
                    yManager,
                    entity,
                    sessionInfo,
                    fileSync,
                    singleEnvelope: organise === 'envelope',
                    sharing: sharingItems.map(si => ({
                      id: v4(),
                      sid: si.sid,
                      canShare: false,
                      headline: si.name,
                      subtitle: si.subtitle || '',
                      entityId: si.type === 'entity' ? si.id : undefined,
                      teamId: si.type === 'team' ? si.id : undefined,
                      agentId: si.type === 'agent' ? si.id : undefined
                    }))
                  });
                  if (created.length === 1) {
                    const [{ propertyId, formId }] = created;
                    navigate(`/signanything/${seoFriendlySlug(propertyId)}/envelope/${seoFriendlySlug(formId)}`);
                  } else {
                    navigate('/forms', {
                      state: {
                        fakeRows: created.map<PropertyList>((c, idx) => ({
                          id: -idx,
                          propertyId: c.propertyId,
                          propertyFormId: c.formId,
                          name: c.name,
                          status: AllDocumentStatus.Draft,
                          statusDetail: {},
                          ownerId: sessionInfo?.agentId || 0,
                          ownerName: sessionInfo?.name || '',
                          entityId: entity.entityId,
                          createdStamp: new Date(),
                          updateStamp: new Date(),
                          formName: c.name,
                          formCode: FormCode.UploadedDocument,
                          isArchived: false,
                          signingPortalEnabled: false,
                          type: DocumentType.MyFile,
                          envelopeCount: c.count
                        }))
                      },
                      replace: true
                    });
                  }
                  break;
                }
                case 'property': {
                  if (!selectedPropertyId) return;
                  const added = await UploadDocumentUtil.addToPropertyFolderById({
                    yManager,
                    selectedPropertyId,
                    fileInfos,
                    fileSync,
                    sessionInfo,
                    singleEnvelope: organise === 'envelope'
                  });
                  if (added.length === 1) {
                    const [{ formId, name }] = added;
                    navigate(`/properties/${seoFriendlySlug(selectedPropertyId)}/document/${seoFriendlySlug(formId, name)}`);
                  } else {
                    navigate(`/properties/${seoFriendlySlug(selectedPropertyId)}/documents`);
                  }
                  break;
                }
              }
            } finally {
              setProcessing(false);
              onClose();
            }
          }}
        >
        Upload
        </AsyncButton>
      </div>
    </Modal.Footer>
  </Modal>;
}
