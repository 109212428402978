import * as Y from 'yjs';
import { Button, Form, InputGroup } from 'react-bootstrap';
import React, { ReactElement, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Allotment, LayoutPriority } from 'allotment';
import { useMediaQuery } from 'react-responsive';
import 'allotment/dist/style.css';
import './Wizard.scss';
import { PDFViewer } from '../PDFViewer/PDFViewer';
import { useTimeout } from '../../hooks/useTimeout';
import { BreadCrumbs } from '../BreadCrumbs';
import { clearFormMode, NavigationFormState, setActiveForm, setActiveFormSection, unlockFormSectionClick } from '@property-folders/common/redux-reducers/navigation';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { PresentUsersList } from '../presence/PresentUsersList';
import { useImmerYjs } from '../../hooks/useImmerYjs';
import { Annexure, ContentType, FormCode, FormInstance, FormSigningState, InstanceHistory, MaterialisedPropertyData, SigningPartyType, TransactionMetaData } from '@property-folders/contract';
import { FormUtil, ITransitionSigningStateOpts } from '@property-folders/common/util/form';
import { Maybe } from '@property-folders/common/types/Utility';
import { StandaloneWizardStepPageProps, WizardSidebarSubsetProps, WizardStepPageProps } from './WizardStepPage';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { Predicate } from '@property-folders/common/predicate';
import { FileStorage, FileType, StorageItemFileStatus } from '@property-folders/common/offline/fileStorage';
import { SigningSession } from '@property-folders/components/dragged-components/signing/SigningSession';
import { sortBy } from 'lodash';
import { FileSync } from '@property-folders/common/offline/fileSync';
import clsJn from '@property-folders/common/util/classNameJoin';
import { useFileTrack, useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useLiveQuery } from 'dexie-react-hooks';
import { determineIfFormPassesConditions } from '@property-folders/common/util/stagedDataTransformations';
import { footerCompanyName } from '@property-folders/common/util/formatting';
import { NoSigningDialog } from './NoSigningDialog';
import { determinePreviewFiles, FormTypes, generateDocumentDropdownInfo, PreviewType, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { AppFileProvider } from '@property-folders/components/dragged-components/signing/SigningProcess';
import { fillPdf } from '@property-folders/common/signing/fill-pdf';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { useBrandConfig, useEntities } from '@property-folders/components/hooks/useEntity';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { WarnBeforeUpdateWithContext } from './WarnBeforeUpdateWithContext';
import { LineageContext } from '@property-folders/components/hooks/useVariation';
import { PdfFormFiller } from '@property-folders/common/signing/pdf-form-filler';
import { ShowNetStateIndicators } from '@property-folders/components/dragged-components/NetStateContext';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import { PDFLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { blobTob64, buildSigningTimelines } from '@property-folders/common/util/dataExtract';
import { FileRef, FileTrackState, FormStates, PropertyRootKey, StillUploadingStates } from '@property-folders/contract/yjs-schema/property';
import { isScrolling } from '@property-folders/common/util/html-element';
import { PdfWorker, usePdfWorker } from '@property-folders/components/hooks/usePdfWorker';
import { SingleArrayBufferFileProvider } from '@property-folders/common/util/SingleBlobFileProvider';
import { DiffCollection } from '@property-folders/common/util/form/DiffCollection';
import { isPathInAnyHierachy } from '@property-folders/common/util/variations/isPathInAnyHierachy';
import { useGesture } from '@use-gesture/react';
import { FormUserInteractionContextType } from '@property-folders/common/types/FormUserInteractionContextType';
import { FormUserInteractionContext } from '@property-folders/components/context/FormUserInteractionContext';
import { WizardDisplayContext, WizardDisplayContextType, WizardFieldFocusStateContext } from '@property-folders/components/context/WizardContexts';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { DefinitionMode, IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { useNoopUndefined } from '../../hooks/useNoop';
import { useBreakpointValue } from '../../hooks/useBreakpointValue';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import { useFileRef } from '../../hooks/useFileRef';
import { FileSyncContext } from '../../context/fileSyncContext';
import { BelongingEntityMeta } from '@property-folders/common/redux-reducers/entityMeta';
import { archiveToggleMigrationFactory } from '../OfferContractCard';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { useFormFileRef } from '../../hooks/useFormFileRef';

export * from '@property-folders/common/util/SingleBlobFileProvider';

type WizardProps = TransactionFormProps & {
    title: string,
    subTitle: string,
    afterTitle?: string | JSX.Element;
    printHeadline?: string
    pdfDefinition: IPdfDefinitionProvider,
    formName: string,
    transactionRootKey?: string | undefined,
    transactionMetaRootKey?: string | undefined
    docName: string
    children: ReactElement<WizardStepPageProps> | (ReactElement<WizardStepPageProps>|null)[],
    ydoc?: Y.Doc,
    signing?: boolean;
    entityLogoLoadedUri?: string,
    onFormSigningClicked?: () => void,
    signingSessionOtherButton?: JSX.Element
    signingSessionTitleOverride?: string | JSX.Element
    onVoidSigning?: () => void,
    showAnnexuresByDefault?: boolean
};

const PREVIEW_PSEUDO_TARGET = '#preview';

const generatePdf = async (
  provider: IPdfDefinitionProvider,
  metaData: TransactionMetaData | undefined,
  brand: EntityBrandFormConfig,
  agencyName: string,
  agentName: string | undefined,
  headline: string | undefined,
  documentLabel: string | undefined,
  cb: (url: string) => void,
  pdfWorker: PdfWorker,
  images: {
    agencyLogoImage?: string,
    marketingHeaderImage?: string
  } | undefined,
  annexures: Annexure[] | undefined,
  timeZone: string | undefined,
  getChangeSet: ()=>{changes: DiffCollection|null, original?: MaterialisedPropertyData, history?: InstanceHistory|null} | undefined,
  noBoldContentMode: boolean | undefined,
  memberEntities: BelongingEntityMeta,
  customCoverSheet: Blob | undefined
) => {
  try {
    const { changes, original, history } = getChangeSet?.() ?? {};
    const def = await provider.getDefinitionForPdfWorker(
      DefinitionMode.Preview,
      brand,
      agencyName,
      images,
      changes ?? undefined,
      original,
      history,
      noBoldContentMode,
      memberEntities
    );

    const formBlob = await pdfWorker.generatePdf({
      ...def,
      brand,
      meta: {
        headline,
        agentName,
        documentLabel
      }
    }, 'preview');
    const filler = new PdfFormFiller('preview', new SingleArrayBufferFileProvider(formBlob), timeZone, true, undefined, undefined, undefined);
    await filler.fill([]);
    const filled = await filler.getBytes();

    const coverSheets = customCoverSheet
      ? [await customCoverSheet.arrayBuffer()]
      : [];
    const decoratedAnnexures = annexures && annexures.length > 0
      ? await Promise.all(annexures.map(a => pdfWorker.generateAnnexure({
        annexure: a,
        brand,
        coversheet: {
          headline,
          agentName,
          documentLabel
        },
        meta: metaData
      })))
      : [];

    const merged = coverSheets.length > 0 || decoratedAnnexures.length > 0
      ? await pdfWorker.stitchPdf({ pdfs: [...coverSheets, filled, ...decoratedAnnexures], idxBase: coverSheets.length })
      : filled;

    cb(URL.createObjectURL(new Blob([merged], { type: ContentType.Pdf })));
  } catch (e: unknown) {
    if (e && typeof e === 'object' && 'message' in e && e.message !== 'Aborted') {
      console.warn(e);
    }
  }
};

function getChildrenAsArray<P>(children: ReactElement<P> | (ReactElement<P>|null)[]): ReactElement<P>[] {
  return (Array.isArray(children)
    ? children
    : [children])
    // sometimes there are `undefined` children, and that breaks things later on.
    .filter(Predicate.isTruthy);
}

function getChildrenList<P>(
  children: ReactElement<P> | (ReactElement<P>|null)[],
  signing: boolean,
  signingState: Maybe<FormSigningState>,
  cancelSigningSession: () => void | undefined,
  formCode: string,
  formId: string,
  ydoc: Maybe<Y.Doc>,
  getFormInstance: () => Maybe<FormInstance>,
  dataRootKey: string,
  metaRootKey: string,
  signingSessionOtherButton?: JSX.Element,
  signingSessionTitleOverride?: JSX.Element | string
): ReactElement<P>[] {
  const instance = getFormInstance();
  const {
    signingSessionWizardPropsForSidebar,
    showSigningSession,
    partyGroups,
    serveToPurchaserProps,
    signingMainProps
  } = useSigningNavProps({ signing: instance?.signing, formCode, titleOverride: signingSessionTitleOverride });
  if (!signing || signingState === FormSigningState.None) {
    return getChildrenAsArray(children);
  }

  if (showSigningSession) {
    return [<SigningSession
      key='session-pseudo-collection'
      name='session-pseudo-collection'
      wizardSectionProps={signingSessionWizardPropsForSidebar}
      formCode={formCode}
      formId={formId}
      ydoc={ydoc}
      onVoid={cancelSigningSession}
      dataRootKey={dataRootKey}
      metaRootKey={metaRootKey}
      otherButton={signingSessionOtherButton}
      partyGroups={partyGroups}
      serveToPurchaserProps={serveToPurchaserProps}
      signingMainProps={signingMainProps}
    />];
  }

  return [];
}

enum singleModes {
  both = 0,
  wiz = 1,
  preview = 2
}

function getRelativeOffsetFromWizardScroll (target: HTMLElement) {
  let effectiveOffsetParent = target.offsetParent;
  let offsetSoFar = target?.offsetTop;

  while (effectiveOffsetParent?.id !== 'form-scroll-content' && effectiveOffsetParent?.id !== 'root') {
    offsetSoFar = effectiveOffsetParent?.offsetTop + offsetSoFar;
    effectiveOffsetParent = effectiveOffsetParent?.offsetParent;
  }
  return offsetSoFar;
}

const documentPreviewRegenerateStates = new Set<FormSigningState>([FormSigningState.None, FormSigningState.Configuring]);

export const Wizard = ({
  title,
  subTitle,
  afterTitle,
  children,
  pdfDefinition,
  printHeadline,
  formName,
  docName,
  transactionRootKey = PropertyRootKey.Data,
  transactionMetaRootKey = PropertyRootKey.Meta,
  formId,
  breadcrumbs,
  ydoc,
  signing,
  entityLogoLoadedUri,
  onFormSigningClicked,
  signingSessionOtherButton,
  signingSessionTitleOverride,
  onVoidSigning,
  showAnnexuresByDefault
}: WizardProps): JSX.Element => {
  const { variationsMode, changeSet, snapshotData, snapshotHistory, expandAll } = useContext(LineageContext);
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(expandAll?.value || false);
    if (!expandAll) return;

    const handler = (value: boolean) => {
      setExpanded(value);
    };

    expandAll.on('changed', handler);
    return () => {
      expandAll.off('changed', handler);
    };
  }, [expandAll]);
  const getChangeSet = () => ({
    changes: changeSet,
    original: snapshotData,
    history: snapshotHistory
  });
  const navigate = useNavigate();
  const { hash: locationHash, pathname, search } = useLocation();
  const navType = useNavigationType();
  const formConfigs = useBrandConfig();
  const splitEnabled = useMediaQuery({ minWidth: BP_MINIMA.xl });
  const store = useStore();
  const focusErrList = useSelector((state: any) => state?.validation?.focusErrList?.[docName ?? '']?.[transactionRootKey ?? '']?.[formName]);
  const allowSigning = useSelector((state: any) => {
    const errorPathTree = state?.validation?.errorPaths?.[docName ?? '']?.[transactionRootKey ?? '']?.[formName];
    return !!errorPathTree && !Object.keys(errorPathTree).length;
  });

  const parentMetaBinder = transactionMetaRootKey !== PropertyRootKey.Meta // Intentional use of meta constant
    ? useImmerYjs<TransactionMetaData>(ydoc, PropertyRootKey.Meta)?.bindState
    : useNoopUndefined()();
  const { data: parentFormStates } = parentMetaBinder ? parentMetaBinder<FormStates>(m=>m.formStates) : (useNoopUndefined()()??{});
  const { bindState: metaBindState, binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const propertyData = useLightweightTransaction<MaterialisedPropertyData>({ parentPath: '', myPath: '' });
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const annexures = useMemo(()=>FormUtil.getAnnexures(formName, formId, meta),[formName, formId, meta]);
  const formState = FormUtil.getFormFamilyState(formName, meta);
  const signingState = FormUtil.getSigningState(formName, formId, meta);
  const formInstance = FormUtil.getFormState(formName, formId, meta);
  const pdfOnly = FormTypes[formName].wizardOpts?.pdfOnly;
  const signingSession = formInstance?.signing;
  const {
    files: previewFileList,
    fill: buildWithSignatures,
    buster: buildWithSignaturesBuster,
    buildNew: shouldGeneratePdf,
    buildCoverPage: shouldGenerateCoverPage,
    customCover
  } = determinePreviewFiles(formInstance);
  const coverSheetRef = formInstance?.signing?.session?.associatedFiles?.coverSheetPdfRef;
  const coverSheetRefFileId = coverSheetRef?.id;
  const fileTrack = useFileTrack()??{};

  const coverSheetBlob = useFileRef(coverSheetRefFileId, FileType.PropertyFile, coverSheetRef?.contentType as ContentType, {
    propertyFile: {
      propertyId: propertyData?.value?.id,
      formId,
      formCode: formInstance?.formCode
    }
  });
  const { file: customCoverBlob, status } = useFormFileRef(
    customCover?.id,
    customCover?.code,
    Boolean(customCover));

  const previewFiles = useLiveQuery(async () => {
    return previewFileList ? Promise.all(previewFileList?.map(pf => FileStorage.read(pf?.id))) : undefined;
  }, [previewFileList?.map(p => p.id)?.join('')]);

  const signatureFileList = formInstance?.signing?.session?.fields?.map(field => field.file?.id)?.filter(Predicate.isTruthy)??[];
  const signatureFilesMeta = useLiveQuery(async () => {
    return signatureFileList ? Promise.all(signatureFileList?.map(fid => FileStorage.readMeta(fid))) : undefined;
  }, [signatureFileList?.join('')]);
  const filesReady = (previewFiles?.length??0) === 0 || previewFiles?.every(p=>!p || p.fileStatus===StorageItemFileStatus.Available);
  const sigsReady = signatureFilesMeta?.every(p=>p?.fileStatus===StorageItemFileStatus.Available);
  const pdfLoadErrorMessage = previewFileList.some(f=>f.id && StillUploadingStates.has(fileTrack[f.id]?.state))
    ? 'File is still being uploaded by other user'
    : previewFileList.some(f=>f.id && fileTrack[f.id]?.state === FileTrackState.ServerProcessing)
      ? 'Server is processing the file'
      : !filesReady
        ? 'Getting file'
        : !sigsReady
          ? 'Getting signatures'
          : '';

  const dispatch = useDispatch();
  const [pdfPreviewUrl, setPdfPreviewUrls] = useState<{ id: string, name: string, url: string }[] | string>();
  const [pdfPreviewShowAnnexures, setPdfPreviewShowAnnexures] = useState(showAnnexuresByDefault??false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const { awaitingLoadCompletion } = useContext(PDFLoadStateContext);
  const setCurrentTab = (a: string)=>dispatch(setActiveFormSection(a));
  const [currentPdfScrollFocus, setCurrentPdfScrollFocus] = useState<string>('');
  const [selectorLineClientPos, setSelectorLineClientPos] = useState(0);
  const [selectorLineBounds, setSelectorLineBounds] = useState([0,0]);
  const [lastFieldPosition, setLastFieldPosition] = useState<[number, number]|null>(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [observer, setObserver] = useState<IntersectionObserver|null>(null);
  const [scrollWait, setScrollWait] = useState<number>(0);
  const { currentSelection, sidebarSelected } = useSelector(state=>((state as any)?.navigation?.sidebar?.form as NavigationFormState|undefined) || {} as Record<any,undefined> );

  // As long as preview is not the first page opened, this will do for setting the hash code.
  // the defaults here show that it is either split or wizard by default
  const [singlePaneMode, internalSetSinglePaneMode] = useState(splitEnabled ? singleModes.both : singleModes.wiz);
  const setSinglePaneMode = (newMode: singleModes) =>{
    if (newMode === singleModes.preview) {
      navigate(pathname+search+PREVIEW_PSEUDO_TARGET, { preventScrollReset: true });
    }
    internalSetSinglePaneMode(newMode);
  };
  const [promptVisible, setPromptVisible] = useState(false);
  const [showCannotSign, setShowCannotSign] = useState(false);
  const [userHasClickedSign, setUserHasClickedSign] = useState(false);
  const displayFocusErrors = userHasClickedSign;
  const wizardDisplayContextState = useMemo<WizardDisplayContextType>(()=>({
    showFocusErrors: displayFocusErrors || true, // So this is always true? Hmm ok
    focusErrList: focusErrList || []
  }), [displayFocusErrors, focusErrList]);

  const scrollRepositionTimer = useRef<NodeJS.Timeout>(); // We aren't targeting Node, but that's what it wants???
  const memberEntities = useEntities();
  const localEntity = memberEntities && meta?.entity?.id ? memberEntities[meta.entity.id] : null;
  const entityOfficialName = footerCompanyName(localEntity?.name, localEntity?.tradeName);
  const isEditable = useMemo(() => signingState === FormSigningState.None, [signingState]);

  const addFieldFocus = useCallback((fieldId: string, evt: React.FocusEvent<HTMLElement, Element>) => {
    setCurrentPdfScrollFocus(`field_focus_${fieldId}`);
    if (!evt.nativeEvent.target) {
      return;
    }
    const fromScroll = getRelativeOffsetFromWizardScroll(evt.nativeEvent.target);
    const viewport = dataPagesViewport.current;
    const visibleOffset = fromScroll - (viewport?.scrollTop||0);
    setLastFieldPosition([visibleOffset, fromScroll]);
  }, [setCurrentPdfScrollFocus, setLastFieldPosition]);

  // This way, fields can be overwritten, but not reset by other fields defocussing
  const remFieldFocus = useCallback((fieldId: string) => {
    setCurrentPdfScrollFocus(ff=>ff===`field_focus_${fieldId}`?'':ff);
    // It isn't an amazing solution, but we don't have any information about what
    // caused a scroll. So basically we just tell the system, don't refocus scroll just
    // because we tabbed away from a field
    setScrollWait(sw=>sw+1);
  }, [setCurrentPdfScrollFocus]);

  const blockingNonFieldFocus = currentPdfScrollFocus.startsWith('field_focus_');

  const previewerTarget = useRef<HTMLDivElement|null>(null);

  // Be aware, this may have odd pointer event capture behaviour. Witnessed when it prevented
  // browser implemented date pickers popping up in chrome family browsers. This is why it now only
  // has a target when in preview only mode. Even though the type says target can't be null, it
  // seems to be happy enough to enable and disable based on this.
  useGesture({
    onDrag: ({ swipe: swipeXY }) => {
      const swipeX = swipeXY[0];
      if (singlePaneMode === singleModes.preview && swipeX > 0) {
        navigate(-1);
      }
    }
  },
  {
    target: singlePaneMode === singleModes.preview ? window : null,
    drag: { swipe: { velocity: 0.25, duration: 500 } }
  });

  const fieldFocusContextSetState = useMemo(()=>{
    return {
      addFieldFocus,
      remFieldFocus
    };
  }, [addFieldFocus, remFieldFocus]);

  //debugging code for highlighting currently focussed element for pdf scroll
  useLayoutEffect(()=>{
    if (!window.fnDev.focusDebuggerEnabled) {
      return;
    }
    if (!currentPdfScrollFocus) {
      return;
    }
    let highlightedElements: Element[] | undefined;
    if (currentPdfScrollFocus.startsWith('bookmark')) {
      highlightedElements = [...document.querySelectorAll(`#wiz-spy-${currentSelection}`)];
    } else if (blockingNonFieldFocus) {
      // Haven't actually got something to highlight here as there's no class or data on these
      // don't think we really need it because we should know what is being focussed on
    } else {
      highlightedElements = [...document.querySelectorAll('.scrollspy-target')].filter(elem=>elem.dataset.focusPath===currentPdfScrollFocus);
    }
    highlightedElements?.forEach(elem=>elem.classList.add('scrollspy-focus'));
    return ()=>highlightedElements?.forEach(elem=>elem.classList.remove('scrollspy-focus'));

  }, [currentPdfScrollFocus]);

  const showPreview = singlePaneMode===singleModes.both || singlePaneMode === singleModes.preview;
  const showWiz = singlePaneMode===singleModes.both || singlePaneMode === singleModes.wiz;

  const dataPagesViewport = useRef<HTMLDivElement>(null);

  // This should never trigger rerenders when updated, so I'm making it as a static store. This is
  // because the event list from the threshold callback only lists changed elements, but I need
  // the event callback to also make assessments based on all elements. So the callback is
  // responsible for triggering the side effects, not the contents of this dict
  const lastIntersectionMeasurement = useRef<Record<string, IntersectionObserverEntry>>({});
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const setSigningState = (value: ITransitionSigningStateOpts) => {
    FormUtil.transitionSigningState({ store, formCode: formName, formId, metaBinder, dataBinder, sessionInfo, history: snapshotHistory, entitySigningOpts: localEntity?.signingOptions }, value);
    // This sucks, but apparently there are circumstances were it won't scroll, so adding a delay before render should
    // make it reasonably likely to work properly... even if it is a little jumpy
    setTimeout(()=>dataPagesViewport.current?.scrollTo({ top: 0, behavior: 'auto' }),25);
  };
  const getFormInstance = () => {
    return FormUtil.getFormState(formName, formId, metaBinder?.get());
  };

  // todo: possibly lift this up to parent or something
  const childrenList = getChildrenList<WizardStepPageProps>(
    children,
    signing || false,
    signingState,
    onVoidSigning??(() => setSigningState({ to: FormSigningState.None })),
    formName,
    formId,
    ydoc,
    getFormInstance,
    transactionRootKey,
    transactionMetaRootKey,
    signingSessionOtherButton,
    signingSessionTitleOverride
  );
  const sidebarChildren = childrenList.filter(Predicate.isNotNull).map(elem=>{
    // This is neccessary because when we have a list of React Elements, the contents of them
    // is not yet resolved. As a result we only know what props have been passed in, not what
    // is produced. As such, in order to still be able to extract meaningful heading information
    // from props, we need to pass said information in, not get it after evaluation. As such
    // the element will have to take these headings explictly and use them.
    if (!Array.isArray(elem.props.wizardSectionProps)) {
      return [elem.props] as WizardSidebarSubsetProps[];
    }
    return elem.props.wizardSectionProps as WizardSidebarSubsetProps[];
  }).flat();
  const sidebarItems = useMemo(() => sidebarChildren.map(childProps =>{
    const { name: id, label, icon, iconPack } = childProps;
    return {
      id, label, dispatchById: true, icon, iconPack
    };
  }), [sidebarChildren.map(c=>c.name+c.label+c.icon).join('')]);

  const flattenedWizardSections = childrenList
    .map(ch=>(ch?.props?.wizardSectionProps ? ch?.props?.wizardSectionProps : [ch]) as React.ReactElement<StandaloneWizardStepPageProps[]>)
    .flat();

  const watchPaths = flattenedWizardSections
    .filter(ch=>ch?.props?.variationWatchPaths?.length)
    .map(ch=>ch?.props?.variationWatchPaths??[]).flat() as string[][];

  const variationCallbacks = flattenedWizardSections
    .filter(ch=>ch?.props?.variationDeterminationCallback?.length)
    .map(ch=>ch?.props?.variationDeterminationCallback??[]).flat() as (()=>boolean)[];

  let anyMarkedVariation = false;
  for (const path of watchPaths) {
    anyMarkedVariation = isPathInAnyHierachy(path, changeSet);
    if (anyMarkedVariation) {
      break;
    }
  }

  if (!anyMarkedVariation) {
    for (const callback of variationCallbacks) {
      anyMarkedVariation = callback();
      if (anyMarkedVariation) {
        break;
      }
    }
  }

  useEffect(()=>{
    dispatch(setActiveForm({
      formName: formName,
      sidebarItems
    }));
    return ()=>{dispatch(clearFormMode());};
  }, [sidebarItems]);

  useTimeout(()=>{setScrollWait(0);dispatch(unlockFormSectionClick());}, 300, !scrollWait ? null : scrollWait);

  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry=>{
      // IDs seem to be empty strings when not set, so need to use || not ??
      lastIntersectionMeasurement.current[entry.target.id||entry.target.dataset.focusPath] = entry;
    });
  };

  useEffect(()=>{
    const dc = dataPagesViewport.current;
    // Just defining a step list that the Intersection Observer wants
    // [0,0.125,0.25...1]
    const thresholdList = (new Array(9)).fill(1).map((_,i)=>(i)/8);
    if (dc && !scrollWait) {
      const lObserver = new IntersectionObserver(intersectionCallback, {
        root: dc,
        threshold: thresholdList
      });
      // It would be more react-y to use a useRef, however, our sections are dynamic, and if we
      // add new hooks, thus changing the hook list, react will complain.
      const obsElements = dc?.querySelectorAll('.scrollspy-target');
      obsElements.forEach(element => {
        lObserver.observe(element);
      });
      setObserver(lObserver);
      return ()=>observer?.disconnect();
    } else if (scrollWait) {
      const ecb = ()=>setScrollWait(sw=>sw+1);
      dc?.addEventListener('scroll', ecb);
      return ()=>dc?.removeEventListener('scroll', ecb);
    }
    // We need to create a new observer every time the current section changes, otherwise the
    // callback gets an old closure state
  },[
    Boolean(dataPagesViewport.current),
    Boolean(scrollWait),
    childrenList.map(child=>child?.props?.name).join('|'),
    propertyData?.value?.marketingTemplate?.id //needed so bookmarks work after enabling/disabling the marketing template - maybe find a more generic way
  ]);

  const rerequestRef = useRef<string[]>([]);
  const { instance: fileSync } = useContext(FileSyncContext);

  // note: would be good to marry this functionality up with the usePdfPreviewUrl hook.
  // that hook currently doesn't do any pdf generation functionality, however.
  // its initial focus has been signing process previews.
  const regeneratePreview = async(
    pdfWorker: PdfWorker,
    forceShouldGenerate?: boolean | undefined,
    forceAnnexures?: boolean | undefined,
    generateResultCallback?: (urlData: string)=>void,
    noBoldContentMode?: boolean,
    abort?: AbortSignal
  ) => {
    if (!ydoc) {
      return;
    }
    const timeZone = signingSession?.session?.initiator.timeZone || 'Australia/Adelaide';

    if (!(forceShouldGenerate??shouldGeneratePdf)) {
      if (!previewFiles?.every(p => p?.id)) return;

      const sortedPdfs = sortBy(previewFiles, [
        pf => signingSession?.parties?.filter(p => p.signedPdf?.id === pf?.id)?.[0]?.type === SigningPartyType.SignWet,
        pf => signingSession?.parties?.filter(p => p.signedPdf?.id === pf?.id)?.[0]?.signedTimestamp
      ]);
      const previewPdfs = sortedPdfs?.map((pf, i) => {
        const { text, signingPartyType } = generateDocumentDropdownInfo(
          i + 1,
          previewFiles?.length || 0,
          pf?.id,
          signingSession?.parties || []);
        const preview = previewFileList?.find(p => p?.id === pf?.id);
        // This is basically a clone, no existing references should exist on this object after this
        return {
          id: pf?.id || '',
          name: text,
          url: pf?.data ? URL.createObjectURL(pf.data) : '',
          type: signingPartyType,
          previewType: preview?.type
        };
      });

      //update the signatures on the intermediate file if requested
      if (buildWithSignatures) {
        const bytes = await fillPdf(
          new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey),
          new AppFileProvider(),
          formId,
          formName,
          signingSession?.session?.id || '',
          timeZone,
          true,
          false,
          { requestFileFillNotFound: async (file: FileRef) => {
            if (rerequestRef.current.includes(file.id)) return;
            rerequestRef.current.push(file.id);
            const readMeta = await FileStorage.readMeta(file.id);
            if (readMeta?.fileStatus !== StorageItemFileStatus.Failed) {
              const foundIndex = rerequestRef.current.findIndex(a => a === file.id);
              if (foundIndex === -1) return;
              rerequestRef.current.splice(foundIndex, 1);
              return;
            }
            await FileStorage.requeueIndividualDownload(file.id);
            FileSync.triggerSync(fileSync);
            setTimeout(()=>{
              const foundIndex = rerequestRef.current.findIndex(a => a === file.id);
              if (foundIndex === -1) return;
              rerequestRef.current.splice(foundIndex, 1);
            }, 5000);
          } }
        );
        if (bytes) {
          const intermediate = previewPdfs.find(p => p.previewType === PreviewType.intermediate);
          intermediate && (intermediate.url = URL.createObjectURL(new Blob([bytes], { type: ContentType.Pdf })));
        }
      }
      if (coverSheetBlob && shouldGenerateCoverPage) {
        for (const previewPdf of previewPdfs) {
          // stitch it in and replace the object url.
          const previewData = await (await (await fetch(previewPdf.url)).blob()).arrayBuffer();
          const coverPageData = await coverSheetBlob.arrayBuffer();
          // if we've only got one pdf, then we can pass the object directly for a slight performance gain
          // otherwise we'll need to copy it, or we get into all sorts of ownership wars (detached Array Buffer)
          // tbh I don't fully understand this, but it works.
          const coverPage = previewPdfs.length > 1 ? coverPageData.slice(0) : coverPageData;

          const stitched = await pdfWorker.stitchPdf({ pdfs: [coverPage, previewData] });
          previewPdf.url = URL.createObjectURL(new Blob([stitched], { type: ContentType.Pdf }));
        }
      }
      if (customCoverBlob) {
        for (const previewPdf of previewPdfs) {
          const previewData = await (await (await fetch(previewPdf.url)).blob()).arrayBuffer();
          const coverPageData = await customCoverBlob.arrayBuffer();
          const stitched = await pdfWorker.stitchPdf({ pdfs: [coverPageData, previewData], idxBase: 1 });
          previewPdf.url = URL.createObjectURL(new Blob([stitched], { type: ContentType.Pdf }));
        }
      }

      if (!abort?.aborted) {
        setPdfPreviewUrls(previewPdfs);
      }
      return;
    }

    if (!generateResultCallback) setIsGeneratingPdf(true);

    const meta = metaBinder?.get();
    const entityId = meta?.entity?.id;
    const formConfig = formConfigs.getFormConfig(entityId);
    const formFamily = FormTypes[formName]?.formFamily;

    const extraImages: Record<string, string> = {};
    const marketingTemplate = propertyData?.value?.marketingTemplate;
    if (formFamily === FormCode.RSAA_SalesAgencyAgreement && marketingTemplate?.headerImage?.id) {
      const headerImageFile = await FileStorage.read(marketingTemplate.headerImage?.id);
      extraImages.marketingHeaderImage = await blobTob64(headerImageFile?.data);
    }

    generatePdf(
      pdfDefinition,
      meta,
      formConfig,
      entityOfficialName,
      sessionInfo?.name,
      printHeadline,
      FormTypes[formName].label,
      generateResultCallback??((url:string) => {
        if (!abort?.aborted) {
          setPdfPreviewUrls(url);
          setIsGeneratingPdf(false);
        }
      }),
      pdfWorker,
      {
        agencyLogoImage: entityLogoLoadedUri,
        ...extraImages
      },
      (forceAnnexures??pdfPreviewShowAnnexures) ? annexures : undefined,
      timeZone,
      getChangeSet,
      noBoldContentMode,
      memberEntities,
      customCoverBlob
    );
  };

  const pdfWorker = usePdfWorker();

  useEffect(() => {
    if (singlePaneMode === singleModes.wiz && !pdfOnly) {
      return;
    }

    const ac = new AbortController();
    regeneratePreview(pdfWorker, undefined, undefined, undefined, undefined, ac.signal)
      .then(() => { /**/ })
      .catch(console.error);

    return () => {
      ac.abort();
    };
  }, [
    previewFiles,
    !!coverSheetBlob,
    customCoverBlob,
    !!ydoc,
    buildWithSignatures,
    buildWithSignaturesBuster,
    signingSession?.state,
    signingSession?.session?.id || '',
    signingSession?.session?.initiator.timeZone || 'Australia/Adelaide',
    JSON.stringify(propertyData),
    formConfigs.repaintId,
    pdfPreviewShowAnnexures,
    entityLogoLoadedUri,
    changeSet,
    singlePaneMode,
    annexures // Need to show the annexure label in the main form.
  ]);

  useEffect(()=>{
    if (!sidebarSelected) {
      return;
    }
    observer?.disconnect();
    setObserver(null);
    setScrollWait(sw=>sw+1);

    const scrollTarget = dataPagesViewport.current?.querySelector(`#wiz-spy-${sidebarSelected}`);
    if (scrollTarget instanceof HTMLElement) {
      // All this is a workaround of a chromium bug that scrollIntoView doesn't work with something
      // else doing scrolling at the same time.
      // See https://stackoverflow.com/questions/49318497/google-chrome-simultaneously-smooth-scrollintoview-with-more-elements-doesn/63563437#63563437
      const offset = Math.max(scrollTarget.offsetTop-10, 0);

      let scrollParent = scrollTarget.parentNode;

      while (scrollParent instanceof HTMLElement) {
        if (isScrolling(scrollParent)) {
          break;
        }

        const localParent = scrollParent?.parentNode;
        if (!(localParent instanceof HTMLElement)) {
          break;
        }
        scrollParent = localParent;
      }
      if (scrollParent instanceof HTMLElement) {
        scrollParent.scrollTo({ behavior: 'smooth', top: offset });
      }
    }

    if (!blockingNonFieldFocus) {
      setCurrentPdfScrollFocus(`bookmark_${sidebarSelected}`);
    }
  }, [sidebarSelected]);

  useEffect(()=>{
    if (splitEnabled) {
      setSinglePaneMode(singleModes.both);
    } else {
      setSinglePaneMode(singleModes.wiz);
    }
  }, [splitEnabled]);

  useTimeout(()=>{
    setPromptVisible(false);
  }, 1500, promptVisible);

  useEffect(()=>{
    singlePaneMode !== singleModes.both && setPromptVisible(true);
  }, [singlePaneMode]);

  const { dependencyPassed: formPriorFulfilled } = determineIfFormPassesConditions(
    formName,
    FormTypes[formName]?.signingRequirements || {},
    meta?.formStates,
    parentFormStates
  );

  const fullyExecuted = signingState === FormSigningState.Signed;
  const renderTextLayer = useBreakpointValue({ base: false, sm: true }, true) && fullyExecuted;

  const downloadFileTitle = [FormTypes[formName].label, printHeadline].filter(Predicate.isTruthy).join(' - ') || 'document';
  const fname = fullyExecuted
    ? `Fully executed ${downloadFileTitle}.pdf`
    : `${downloadFileTitle}.pdf`;

  const auditFormIdPath = useMemo(() => {
    if (!propertyData.value?.id) return undefined;

    return LinkBuilder.auditPath(
      {
        id: propertyData.value?.id || '',
        nicetext: title
      },
      formId);
  }, [!!propertyData.value?.id, title, formId]);

  const timelines = useMemo(()=>buildSigningTimelines(snapshotHistory), [snapshotHistory]);
  const currentAgreementExpired = false;// timelines.latestExpiry && timelines.latestExpiry < new Date(); // We've had an agency get caught out by this expiry. We need a new method to allow this to be overridden without just allowing it outright. Not for now though

  const userInteraction = useMemo<FormUserInteractionContextType>(()=>({ userShouldSeeAllValidation: userHasClickedSign }), [userHasClickedSign]);

  useEffect(()=>{
    if (singlePaneMode === singleModes.preview && locationHash !== PREVIEW_PSEUDO_TARGET && navType === 'POP') {
      splitEnabled
        ? setSinglePaneMode(singleModes.both)
        : setSinglePaneMode(singleModes.wiz);
      return;
    }
    if (locationHash === PREVIEW_PSEUDO_TARGET && singlePaneMode !== singleModes.preview) {

      navigate(pathname+search, { replace: true, preventScrollReset: true }); // This will retrigger this effect, but having removed the hashes, nothing should happen
    }
  }, [locationHash]);

  const handleUnarchive = () => {
    const formFam = FormTypes[formName].formFamily;
    if (!ydoc) return;
    if (!formFam) return;
    if (!meta?.formStates?.[formFam]?.archived) return;

    applyMigrationsV2_1<TransactionMetaData>({
      typeName: 'Property',
      doc: ydoc,
      docKey: transactionMetaRootKey,
      migrations: [{
        name: 'Unarchive current form family',
        fn: archiveToggleMigrationFactory(formName)
      }]
    });

  };

  const wizardPanel =
  <FormUserInteractionContext.Provider value={userInteraction}>
    <div id="WizardPanel" className='WizardPanel'>
      <div className="wizard-container container-fluid h-100 g-1 p-0">
        <div className='w-100 shadow bg-white' style={{ borderBottom: '1px #d2d2d2 solid' }}>

          <div className={'d-flex flex-row align-items-center justify-content-between px-2 px-md-3 '}>
            <div className="d-flex flex-grow-2 align-items-baseline">
              <h1>{title}</h1>
              <h5 className={'text-muted small ms-2'}>{subTitle}</h5>
            </div>
            {afterTitle}
            {formState?.archived
              ? <span><Button variant='outline-secondary'
                key='unarchive'
                className='mt-1'
                onClick={handleUnarchive}
              >Unarchive</Button></span>
              : FormTypes[formName].wizardOpts?.noSigning ? null : currentAgreementExpired
                ? <span>Agreement Expired</span>
                : variationsMode && !anyMarkedVariation && isEditable
                  ? <span>No variation to sign</span>
                  : signing && isEditable &&
                <Button
                  key='startSigning'
                  className='mt-1'
                  variant={'primary'}
                  disabled={isGeneratingPdf}
                  onClick={() => {
                    setUserHasClickedSign(true);
                    onFormSigningClicked?.();
                    if (allowSigning && !isGeneratingPdf) {
                      if (!formPriorFulfilled) {
                        setShowCannotSign(true);
                        return;
                      }
                      setSigningState({
                        to: FormSigningState.Configuring,
                        configuringData: {
                          entitySigningOpts: localEntity?.signingOptions
                        }
                      });
                      return;
                    }

                    let scrolled = false;
                    for (const classTarget of focusErrList) {
                      const focusTargetElement = document.querySelector(`.${classTarget}`);
                      if (focusTargetElement) {
                        focusTargetElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
                        scrolled = true;
                        break;
                      }
                    }

                    if (!scrolled) {
                      console.error('signing not permitted but could not find element to scroll into view! relevant error paths:', focusErrList);
                    }
                  }}
                >
                  Signing
                </Button>
            }
            { signing && signingState === FormSigningState.Configuring &&
                <Button key='cancelConfigure' className='mt-1' variant={'outline-secondary'} onClick={() => setSigningState({ to: FormSigningState.None })}>
                  Cancel Signing
                </Button>
            }
          </div>

          <div className='d-flex flex-row align-items-center w-100 mb-1 mb-md-2 px-2 px-md-3'>
            <div><BreadCrumbs segments={breadcrumbs}/></div>
            <div className='ms-auto'>
              <PresentUsersList pathPrefix='' />
            </div>
            <div className='ms-2 me-2'>
              <ShowNetStateIndicators />
            </div>
            <div>
              <InputGroup size='sm' className='gap-2'>
                {isEditable && expandAll && !expanded && <Button
                  variant='link'
                  title='Expand all'
                  size='sm'
                  className='px-0 py-0 border-0 bg-transparent link-secondary'
                  onClick={() => { if (!expandAll) return; expandAll.value = true; }}
                >
                  <Icon name='unfold_more'/>
                </Button>}
                {isEditable && expandAll && expanded && <Button
                  variant='link'
                  title='Collapse all'
                  size='sm'
                  className='px-0 py-0 border-0 bg-transparent link-secondary'
                  onClick={() => { if (!expandAll) return; expandAll.value = false; }}
                >
                  <Icon name='unfold_less'/>
                </Button>}
                {auditFormIdPath &&
                  <Button
                    variant='link'
                    title='View history'
                    size='sm'
                    className='px-0 py-0 border-0 bg-transparent link-secondary'
                    onClick={() => navigate(auditFormIdPath)}
                  >
                    <Icon name='history'/>
                  </Button>}
                {signingState !== FormSigningState.Configuring && singlePaneMode !== singleModes.both &&
                  <Button
                    variant='link'
                    title='Preview'
                    size='sm'
                    className='px-0 py-0 border-0 bg-transparent link-secondary'
                    onClick={()=> splitEnabled
                      ? setSinglePaneMode(singleModes.both)
                      : setSinglePaneMode(singleModes.preview)}
                  >
                    Preview
                  </Button>}
              </InputGroup>
            </div>
          </div>

        </div>

        <div className='overflow-hidden position-relative' id='form-scroll-content'>
          {/* containingElement will need to be changed below if this div is moved into another containing element */}
          <div
            ref={dataPagesViewport}
            className="overflow-auto main-content h-100"
            onScroll={() => {

              const viewport = dataPagesViewport.current;
              if (!viewport) {
                return;
              }
              // Not really a lot of reason to do this react-like
              if (!scrollWait && (!blockingNonFieldFocus || Math.abs(viewport?.scrollTop||0 - lastScrollTop) > 100)) {
                clearTimeout(scrollRepositionTimer.current);
                scrollRepositionTimer.current = setTimeout(()=>{
                // Clear field focus if we've scrolled more than 100 pixels away. Because the
                // conditional is above, all we need to check is that it was a field focus
                  if (currentPdfScrollFocus.startsWith('field_focus_')) setCurrentPdfScrollFocus('');

                  if (viewport?.scrollTop === lastScrollTop) return;
                  if (viewport?.scrollTop != null) setLastScrollTop(viewport?.scrollTop);
                  const scrollDown = (viewport?.scrollTop??0) > lastScrollTop;

                  const intersectionCandidates = Object.values(lastIntersectionMeasurement.current);

                  const matchingOnscreen = intersectionCandidates.filter(entry=>entry.isIntersecting);

                  const scrollRatio = viewport?.scrollTop/viewport?.scrollTopMax;
                  const nearEndRatioRange = Math.min(1, viewport?.clientHeight*2.5 / viewport?.scrollHeight);
                  const nearEndLimit = 1 - nearEndRatioRange;

                  // This creates a virtual line which acts as the selector. As we get close to the end
                  // `viewport?.clientHeight*#.#` the line will begin to move towards the bottom of
                  // the input page, and until then, remains near the top. This is to avoid the issue of
                  // not being able to focus on the elements near the bottom of the input form, albeit
                  // making it more likely to skip over sections as you get near the bottom. It's an
                  // imperfect solution which should/will be supplemented by a higher priority focus
                  // on a field when a field is selected.
                  const clientHeight = viewport?.clientHeight;
                  const startOffsetRatio = 0.07;
                  const endOffsetRatio = 0.15;
                  let determineHighlightLine = Math.round((
                    scrollRatio < nearEndLimit
                      ? clientHeight * startOffsetRatio
                      : (scrollRatio - nearEndLimit)/nearEndRatioRange * (clientHeight*(1-(startOffsetRatio + endOffsetRatio))) + clientHeight*startOffsetRatio
                  )||0);

                  let matchingInline: IntersectionObserverEntry[] = [];
                  const retryLimit = 8;
                  let currentTry = 0;
                  const searchStep = 20;
                  // We're searching for sections, but we still want tabs in the list for later
                  while (matchingInline.filter(observed => observed.target.dataset.focusPath).length === 0 && currentTry < retryLimit) {
                    determineHighlightLine = determineHighlightLine > viewport?.clientHeight*0.5
                      ? determineHighlightLine - searchStep
                      : determineHighlightLine + searchStep;
                    const scrollLinePos = determineHighlightLine + viewport?.scrollTop;
                    matchingInline = matchingOnscreen.filter(sect => {
                      const target = sect.target as HTMLElement;
                      let effectiveOffsetParent = target.offsetParent;

                      let lowerBound = target?.offsetTop;
                      let upperBound = target?.offsetTop+target?.clientHeight;

                      while (effectiveOffsetParent?.id !== 'form-scroll-content' && effectiveOffsetParent?.id !== 'root') {
                        const newOffset = effectiveOffsetParent?.offsetTop + lowerBound;
                        lowerBound = newOffset;
                        upperBound = newOffset +target?.clientHeight;
                        effectiveOffsetParent = effectiveOffsetParent?.offsetParent;
                      }
                      const computedStyle = getComputedStyle(target);

                      lowerBound = lowerBound - parseInt(computedStyle.marginTop) - currentTry * searchStep;
                      upperBound = upperBound + parseInt(computedStyle.marginBottom) + currentTry * searchStep;
                      const inRange = (
                        scrollLinePos >= lowerBound
                      && scrollLinePos <= upperBound
                      && (lastFieldPosition == null ||
                        (scrollDown && lowerBound > lastFieldPosition[1])
                        ||(!scrollDown && upperBound < lastFieldPosition[1])
                      )
                      );
                      return inRange;
                    });
                    currentTry++;
                  }
                  currentTry--;
                  window.fnDev.focusDebuggerEnabled && setSelectorLineClientPos(determineHighlightLine);
                  window.fnDev.focusDebuggerEnabled && setSelectorLineBounds([
                    (determineHighlightLine) - currentTry * searchStep,
                    (determineHighlightLine) + currentTry * searchStep
                  ]);

                  if (matchingInline.length > 0) {
                    setLastFieldPosition(null);
                    const mainTabs = matchingInline.map(observed => {
                      const matches = /^wiz-spy-(.+)$/.exec(observed.target.id);
                      const nid = matches && matches[matches?.length-1];
                      return nid;
                    }).filter(a=>a);
                    if (mainTabs.length > 1) {
                    // We'll take the last, which should be the lower most section
                      mainTabs.splice(0, mainTabs.length-1);
                    }
                    // We no longer focus on tabs, doing so means scrolling up will jump around, as we
                    // go to the top of a section, and then to the bottom subsection.
                    if (mainTabs.length === 1) {
                      const nid = mainTabs[0];
                      if (
                        (!sidebarSelected || (sidebarSelected && !matchingInline.map(e=>e.target.id).includes(`wiz-spy-${sidebarSelected}`)))
                      && nid
                      && nid !== currentSelection
                      ) {
                        setCurrentTab(nid);
                      }
                    }
                    const nonTabs = matchingInline.filter(observed => observed.target.dataset.focusPath).map(obs=>obs.target).filter((target,_i,otherMatches) => {
                    // If this is a parent of any other matching node, then we yield to the child
                      for (const other of otherMatches) {
                        if (other === target) continue;
                        if (target.contains(other)) return false;
                      }
                      return true;
                    });
                    if (nonTabs.length === 0) {
                      return;
                    }
                    // If we happen to cross two highlight areas that are found, but are not parents of one or the
                    // other, we just pick the last
                    const newHighlightSection = nonTabs[nonTabs.length-1];
                    setCurrentPdfScrollFocus(newHighlightSection.dataset.focusPath);
                  }

                }, 100);

              }
            }}>

            <Form className='wizard-form' autoComplete="off" noValidate>
              {childrenList.map(child => {
                return <div key={child?.props?.name} id={`wiz-spy-${child?.props?.name}`} className='scrollspy-target target-section'>
                  {child}
                </div>;
              })}
            </Form>
          </div>
          {window.fnDev.focusDebuggerEnabled && <div style={{ position: 'absolute', top: `${selectorLineBounds[0]}px`, height: '1px', width: '100px', backgroundColor: 'black', right: 0 }} />}
          {window.fnDev.focusDebuggerEnabled && <div style={{ position: 'absolute', top: `${selectorLineClientPos}px`, height: '1px', width: '150px', backgroundColor: 'green', right: 0 }} />}
          {window.fnDev.focusDebuggerEnabled && <div style={{ position: 'absolute', top: `${selectorLineBounds[1]}px`, height: '1px', width: '100px', backgroundColor: 'black', right: 0 }} />}
          {window.fnDev.focusDebuggerEnabled && lastFieldPosition && <div style={{ position: 'absolute', top: `${lastFieldPosition[0]}px`, height: '1px', width: '80px', backgroundColor: 'blue', right: 0 }} />}
        </div>
      </div>
      <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={showCannotSign} onClose={()=>setShowCannotSign(false)} />}>
        <NoSigningDialog
          ydoc={ydoc}
          formStates={meta?.formStates}
          onHide={()=>setShowCannotSign(false)}
          show={showCannotSign}
          thisFormCode={formName}
          ignoreAction={()=>setSigningState({
            to: FormSigningState.Configuring,
            configuringData: { entitySigningOpts: localEntity?.signingOptions }
          })}
        />
      </ErrorBoundary>
    </div>
  </FormUserInteractionContext.Provider>;

  const viewer = <PDFViewer
    useAdvancedMode={showWiz}
    filename={fname}
    pdfUrl={pdfPreviewUrl}
    contextDependentLoadingMessage={pdfLoadErrorMessage}
    bookmark={currentPdfScrollFocus}
    renderTextLayer={renderTextLayer}
    standalonePreview={false}
    allowPrint={true}
    // if it's signed/executed, then the annexures will already be in the document, so should not force regeneration.
    // we want the fully signed pdf to be what is printed in this case
    onDocumentRegenerate={documentPreviewRegenerateStates.has(signingState) ? () => new Promise((resolve)=>{
      regeneratePreview(pdfWorker, true, true, urlData=>{
        resolve(urlData);
      }, true);
    }) : undefined}
    toolbarRight={<>
      {(annexures?.length??0) > 0 && !previewFiles?.length &&
      <Form.Check
        id={'show-annexures'}
        type="switch"
        label="Annexures"
        className={'me-3'}
        style={{ color: '#FFF' }}
        checked={pdfPreviewShowAnnexures}
        onChange={(e) => setPdfPreviewShowAnnexures(e.target.checked)}
      />}
      {
        // i.e. when the preview is visible
        singlePaneMode !== singleModes.wiz
          ? <Button
            variant="secondary"
            onClick={()=>{
              if (pdfOnly) {
                // Breadcrumbs last element should be the current page, so we want the one before it
                navigate(breadcrumbs[breadcrumbs.length-2].href);
              }
              singlePaneMode === singleModes.preview ? navigate(-1) : setSinglePaneMode(singleModes.wiz);
            }}
          >
            <Icon name='close'/>
          </Button>
          : undefined}
    </>
    }
    offsetRightByDragBar={singlePaneMode === singleModes.both}
    toolbarBottom={!awaitingLoadCompletion && !pdfPreviewShowAnnexures && (annexures?.length??0) > 0 && !previewFiles?.length
      ? <div className={'d-flex w-100 align-items-center justify-content-center mt-4 mb-2'}>
        <Button variant="secondary" onClick={()=> {
          setIsGeneratingPdf(true);
          setPdfPreviewShowAnnexures(true);
        }}>
          <span className={'fs-4'}>{`Show ${annexures?.length} annexure${(annexures?.length??0)> 1 ? 's' : ''}`}</span>
        </Button>
      </div>
      : isGeneratingPdf || awaitingLoadCompletion ? <div style={{ height: '100px' }}></div> : undefined}
  />;

  const onVisibleChange = useCallback((changedIndex: number, changedNowVisible: boolean) =>{
    let newMode;
    if (!changedNowVisible) {
      newMode = changedIndex === 0 ? singleModes.preview : singleModes.wiz;
    } else if (splitEnabled) {
      newMode = singleModes.both;
    } else {
      newMode = changedIndex === 0 ? singleModes.wiz : singleModes.preview;
    }
    setSinglePaneMode(newMode);
  }, [splitEnabled]);

  return <WizardFieldFocusStateContext.Provider value={fieldFocusContextSetState}>
    <WizardDisplayContext.Provider value={wizardDisplayContextState}>
      <WarnBeforeUpdateWithContext>
        <div className={clsJn('alot-container position-relative h-100 w-100 d-flex', singlePaneMode !== singleModes.both && 'separator-zero')}>
          <Allotment
            snap
            onVisibleChange={onVisibleChange}
          >
            {!pdfOnly && <Allotment.Pane minSize={300} preferredSize={760} priority={LayoutPriority.High} visible={showWiz}>
              {wizardPanel}
            </Allotment.Pane>}
            {signingState !== FormSigningState.Configuring && <Allotment.Pane minSize={300} visible={Boolean(showPreview||pdfOnly)}>
              <div ref={previewerTarget} className='previewPanel'>{viewer}</div>
            </Allotment.Pane>}
          </Allotment>
        </div>
      </WarnBeforeUpdateWithContext>
    </WizardDisplayContext.Provider>
  </WizardFieldFocusStateContext.Provider>;

};
