import {
  AgentSessionInfoResult,
  ContentType,
  FileFormRef, FolderType,
  FormCode,
  ManifestData,
  ManifestType, MaterialisedPropertyData,
  Maybe,
  TransactionMetaData,
  UploadType, YDocContentType
} from '@property-folders/contract';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { v4 } from 'uuid';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { applyMigrationsV2, applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { PropertyRootKey, TransactionSharingQueueItem } from '@property-folders/contract/yjs-schema/property';
import { FileSyncContext } from '../context/fileSyncContext';
import { useReactRouterData } from './useReactRouterHooks';
import { RouterData } from '@property-folders/web/src/App';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { YjsDocContext } from '../context/YjsDocContext';
import { ensureFormStatesInstances } from '@property-folders/common/yjs-schema/property/form';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { TaskQueueResult, useTaskQueue } from './useTaskQueue';
import { Predicate } from '@property-folders/common/predicate';
import * as Y from 'yjs';
import { YManager } from '@property-folders/common/offline/yManager';
import { stripExtension } from '@property-folders/common/yjs-schema/property';
import { useQpdfWorker } from './useQpdfWorker';

interface UploadFile {
  filename: string;
  id: string;
  contentType: string;
}

interface UploadFileWithData extends UploadFile {
  data: ArrayBuffer | Uint8Array;
}

type UploadFormRef = FileFormRef & {
  filename: string;
};

export function useUploadedDocumentUpload(
  afterUpload?: (files: UploadFormRef[]) => void,
  type?: UploadType,
  noTriggerSync?: boolean,
  process = true,
  singleEnvelope = true
) {
  const qpdfWorker = useQpdfWorker();
  const { ydoc: ydoc1, docName: docName1 } = useContext(YjsDocContext);
  const { ydoc: ydoc2, transId: docName2 } = useReactRouterData<RouterData>();
  const { instance: fileSync } = useContext(FileSyncContext);
  const [uploadProcessing, setUploadProcessing] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<Maybe<UploadFile[]>>(undefined);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const store = useStore();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const propertyId = docName1 || docName2;
  const ydoc = ydoc1 || ydoc2;

  const handleDrop = async (acceptedFiles: File[]) => {
    if (!propertyId) return;
    setUploadProcessing(true);

    try {

      const { fulfilled: files, rejected: errors } = await transformUploadedFiles(
        acceptedFiles,
        process,
        async (file: File) => {
          return new File(
            [await qpdfWorker.decrypt({ pdf: await file.arrayBuffer() })],
            file.name,
            { type: file.type, lastModified: file.lastModified }
          );
        }
      );

      setUploadFiles(files.map<UploadFile>(f => ({ id: f.id, contentType: f.contentType, filename: f.filename })));
      setUploadErrors(errors);

      if (!files.length) return;

      const manifestData: ManifestData = {
        manifestType: ManifestType.None
      };

      await Promise.all(files?.map(async file => {
        return FileStorage.write(
          file.id,
          FileType.PropertyFile,
          ContentType.Pdf,
          new Blob([file.data], { type: ContentType.Pdf }),
          StorageItemSyncStatus.PendingUpload,
          {
            propertyFile: {
              propertyId,
              formCode: FormCode.UploadedDocument,
              formId: file.id
            }
          },
          { store, ydoc },
          manifestData,
          undefined,
        );
      }));

      if (!noTriggerSync) {
        FileSync.triggerSync(fileSync);
      }

      const uploadedForms: UploadFormRef[] = [];
      applyMigrationsV2<TransactionMetaData>({
        doc: ydoc,
        docKey: PropertyRootKey.Meta.toString(),
        typeName: 'Property',
        migrations: [{
          name: 'add uploaded documents',
          fn: state => {
            const instances = ensureFormStatesInstances(state, FormCode.UploadedDocument);
            if (singleEnvelope && files.length) {
              const formId = v4();
              const formCode = FormCode.UploadedDocument;
              const formName = files[0].filename;
              instances.push({
                id: formId,
                formCode,
                created: new Date().getTime(),
                modified: new Date().getTime(),
                upload: {
                  v: 2,
                  name: formName,
                  files: files.map(file => ({
                    id: file.id,
                    contentType: ContentType.Pdf,
                    name: file.filename,
                    size: file.data.byteLength,
                    uploader: sessionInfo?.agentUuid ? {
                      id: sessionInfo.agentUuid,
                      linkedSalespersonId: sessionInfo.agentId,
                      name: sessionInfo?.name,
                      email: sessionInfo?.email
                    } : undefined,
                    created: Date.now()
                  }))

                }
              });
              uploadedForms.push({
                id: formId,
                code: formCode,
                filename: formName
              });
            } else {
              for (const file of files) {
                const formId = v4();
                const formCode = FormCode.UploadedDocument;
                const formName = file.filename;
                instances.push({
                  id: formId,
                  formCode,
                  created: new Date().getTime(),
                  modified: new Date().getTime(),
                  upload: {
                    v: 2,
                    name: file.filename,
                    files: [{
                      id: file.id,
                      contentType: ContentType.Pdf,
                      name: file.filename,
                      size: file.data.byteLength,
                      uploader: sessionInfo?.agentUuid ? {
                        id: sessionInfo.agentUuid,
                        linkedSalespersonId: sessionInfo.agentId,
                        name: sessionInfo?.name,
                        email: sessionInfo?.email
                      } : undefined,
                      created: Date.now()
                    }]
                  }
                });
                uploadedForms.push({
                  id: formId,
                  code: formCode,
                  filename: formName
                });
              }
            }
          }
        }]
      });

      afterUpload?.(uploadedForms);
    } finally {
      setUploadProcessing(false);
    }
  };

  return { uploadFiles, uploadErrors, uploadProcessing, handleDrop };
}

async function transformUploadedFiles(files: File[], process: boolean, decryptFunction: (file: File) => Promise<File>) {
  const decryptedFiles = await Promise.all(files.map(file => decryptFunction(file)));

  const results = await Promise.allSettled(
    decryptedFiles.map(async file => transformDecryptedUploadedFile(file, process)));

  const fulfilled = new Array<UploadFileWithData>();
  const rejected = new Array<string>();
  for (const result of results) {
    switch (result.status) {
      case 'fulfilled':
        fulfilled.push(result.value);
        break;
      case 'rejected':
        rejected.push(getErrorMessage(result.reason));
        break;
    }
  }

  return { fulfilled, rejected };
}

function getErrorMessage(error: any): string {
  if (typeof error === 'string') {
    return error;
  }

  if (typeof error === 'object') {
    if ('message' in error && typeof error.message === 'string') {
      return error.message;
    }
    if ('toString' in error && typeof error.toString === 'function') {
      return error.toString();
    }
  }

  return 'Unknown error';
}

async function transformDecryptedUploadedFile(file: File, process: boolean): Promise<UploadFileWithData> {
  const rawData = await file.arrayBuffer();
  const id = v4();
  const filename = file.name;
  const contentType = ContentType.Pdf;
  if (!process) {
    return { id, filename, data: rawData, contentType };
  }

  // pdf already decrypted at this point
  const result = await processPdf(rawData);
  if (result?.isEncrypted) throw new Error('PDF is encrypted');
  if (!result?.pdf) throw new Error('File could not be processed as PDF');

  return { id, filename, data: result.pdf, contentType };
}

export interface UploadFileInfo {
  name: string,
  file: File
}

export function useUploadedDocumentUploadV2({
  onFilesPreProcessed
}: {
  onFilesPreProcessed?: (files: File[]) => void,
  propertyContext?: {
    propertyId: string,
    ydoc: Y.Doc,
    headline: string
  }
}) {
  const qpdfWorker = useQpdfWorker();
  const queue = useTaskQueue<File>();

  useEffect(() => {
    if (!onFilesPreProcessed) return;

    const handler = (results: TaskQueueResult<File>[]) => {
      const files = results
        .map(r => {
          if ('err' in r) return undefined;
          return r.result;
        })
        .filter(Predicate.isNotNull);
      onFilesPreProcessed(files);
    };

    queue.eventTarget.on('completed', handler);

    return () => {
      queue.eventTarget.off('completed', handler);
    };
  }, [onFilesPreProcessed]);

  const handleDrop = useCallback((files: File[]) => {
    queue.clear();
    queue.enqueue(files.map(f => {
      return {
        name: f.name,
        fn: async () => {
          const { fulfilled: files, rejected: errors } = await transformUploadedFiles(
            [f],
            true,
            async (file: File) => {
              return new File(
                [await qpdfWorker.decrypt({ pdf: await file.arrayBuffer() })],
                file.name,
                { type: file.type, lastModified: file.lastModified }
              );
            }
          );
          const file = files.at(0);
          if (file) {
            return new File([file.data], f.name, { type: f.type });
          } else {
            throw errors.at(0) || new Error('Unable to process pdf');
          }
        }
      };
    }));
  }, [queue]);

  const clear = useCallback(() => {
    queue.clear();
  }, [queue]);

  return {
    handleDrop,
    clear,
    hasFiles: Boolean(queue.results.some(r => 'result' in r))
  };
}

export class UploadDocumentUtil {
  public static async createMyFilesEnvelopes({
    fileInfos,
    yManager,
    entity,
    sessionInfo,
    fileSync,
    singleEnvelope,
    sharing
  }: {
    fileInfos: UploadFileInfo[],
    yManager: YManager,
    entity: AgentSessionInfoResult['entities'][0],
    sessionInfo: AgentSessionInfoResult,
    fileSync: FileSync,
    singleEnvelope?: boolean,
    sharing?: TransactionSharingQueueItem[]
  }): Promise<{ propertyId: string, formId: string, name: string, count: number }[]> {
    console.log('createMyFilesEnvelopes', sharing);
    const fileGroups: ({id: string} & UploadFileInfo)[][] = singleEnvelope
      ? [fileInfos.map(fi => ({ id: v4(), ...fi }))]
      : fileInfos.map(fi => [{ id: v4(), ...fi }]);
    const createdItems: { propertyId: string, formId: string, name: string, count: number }[] = [];
    for (const fileGroup of fileGroups) {
      const propertyId = v4();
      const formId = v4();
      const { doc, localProvider } = yManager.get(propertyId, YDocContentType.Property, { preferLocal: true });
      await localProvider.whenSynced;
      await yManager.waitForSync(doc);
      const now = new Date();
      const groupName = fileGroup[0].name;
      const formName = stripExtension(groupName);
      applyMigrationsV2_1<TransactionMetaData>({
        doc,
        docKey: PropertyRootKey.Meta.toString(),
        typeName: 'Property',
        migrations: [{
          name: 'Initialise new SignAnything doc',
          fn: state => {
            state.folderType = FolderType.MyFile;
            state.createdUtc = now.toISOString();
            state.entity = {
              id: entity.entityId,
              name: entity.name
            };
            state.creator = {
              id: sessionInfo.agentId,
              name: sessionInfo.name,
              timestamp: now.getTime()
            };
            state.sharing = {
              createQueue: sharing,
              creating: true
            };
            const instances = ensureFormStatesInstances(state, FormCode.UploadedDocument);
            instances.push({
              id: formId,
              upload: {
                v: 2,
                name: formName,
                files: fileGroup.map(fi => ({
                  id: fi.id,
                  contentType: ContentType.Pdf,
                  uploader: sessionInfo?.agentUuid ? {
                    id: sessionInfo.agentUuid,
                    linkedSalespersonId: sessionInfo.agentId,
                    name: sessionInfo?.name,
                    email: sessionInfo?.email
                  } : undefined,
                  name: stripExtension(fi.file.name),
                  size: fi.file.size,
                  created: Date.now()
                }))
              },
              formCode: FormCode.UploadedDocument,
              created: now.getTime(),
              modified: now.getTime(),
              annexures: []
            });
          }
        }, {
          name: 'set property id',
          docKey: PropertyRootKey.Data.toString(),
          fn: (state: any) => {
            (state as MaterialisedPropertyData).id = propertyId;
            (state as MaterialisedPropertyData).headline = groupName;
          }
        }]
      });
      for (const file of fileGroup) {
        await FileStorage.write(
          file.id,
          FileType.PropertyFile,
          ContentType.Pdf,
          file.file,
          StorageItemSyncStatus.PendingUpload,
          {
            propertyId,
            propertyFile: {
              propertyId,
              formId,
              formCode: FormCode.UploadedDocument
            }
          },
          { store: fileSync.store, ydoc: doc }
        );
      }
      createdItems.push({ propertyId, formId, name: formName, count: fileGroup.length });
    }

    for (const { propertyId } of createdItems) {
      yManager.get(propertyId, YDocContentType.Property, { preferLocal: false });
    }

    FileSync.triggerSync(fileSync);
    return createdItems;
  }

  public static async addToPropertyFolderById({
    yManager,
    selectedPropertyId,
    fileInfos,
    fileSync,
    sessionInfo,
    singleEnvelope
  }: {
    yManager: YManager,
    selectedPropertyId: string,
    fileInfos: UploadFileInfo[],
    fileSync: FileSync,
    sessionInfo: AgentSessionInfoResult,
    singleEnvelope?: boolean
  }) {
    const { doc, localProvider } = yManager.get(selectedPropertyId, YDocContentType.Property, { preferLocal: true });
    await localProvider.whenSynced;
    await yManager.waitForSync(doc);

    return await this.addToPropertyFolder({
      doc,
      fileInfos,
      fileSync,
      sessionInfo,
      singleEnvelope,
      propertyId: selectedPropertyId
    });
  }

  public static async addToPropertyFolder({
    doc,
    fileInfos,
    fileSync,
    propertyId,
    sessionInfo,
    singleEnvelope
  }: {
    doc: Y.Doc,
    fileInfos: UploadFileInfo[],
    fileSync: FileSync,
    propertyId: string,
    sessionInfo: AgentSessionInfoResult,
    singleEnvelope?: boolean,
  }) {
    // form id, file id, name
    const formFileQueue: { formId: string, files: { fileId: string, name: string, size: number }[] }[] = [];
    const now = new Date();
    if (singleEnvelope) {
      const formId = v4();
      const files: { fileId: string, name: string, size: number }[] = [];
      for (const { file } of fileInfos) {
        if (!file) continue;
        const formId = v4();
        const fileId = v4();
        await FileStorage.write(
          fileId,
          FileType.PropertyFile,
          ContentType.Pdf,
          file,
          StorageItemSyncStatus.PendingUpload,
          {
            propertyId,
            propertyFile: {
              propertyId,
              formId,
              formCode: FormCode.UploadedDocument
            }
          },
          { store: fileSync.store, ydoc: doc }
        );
        files.push({ fileId, name: file.name, size: file.size });
      }
      formFileQueue.push({ formId, files });
    } else {
      for (const { file } of fileInfos) {
        if (!file) continue;
        const formId = v4();
        const fileId = v4();
        await FileStorage.write(
          fileId,
          FileType.PropertyFile,
          ContentType.Pdf,
          file,
          StorageItemSyncStatus.PendingUpload,
          {
            propertyId,
            propertyFile: {
              propertyId,
              formId,
              formCode: FormCode.UploadedDocument
            }
          },
          { store: fileSync.store, ydoc: doc }
        );
        formFileQueue.push({ formId, files: [{ fileId, name: file.name, size: file.size }] });
      }
    }

    applyMigrationsV2_1<TransactionMetaData>({
      doc,
      docKey: PropertyRootKey.Meta.toString(),
      typeName: 'Property',
      migrations: [{
        name: 'Add uploaded docs',
        fn: state => {
          const instances = ensureFormStatesInstances(state, FormCode.UploadedDocument);
          for (const { formId, files } of formFileQueue) {
            instances.push({
              id: formId,
              upload: {
                v: 2,
                name: stripExtension(files.at(0)?.name || 'Uploaded Document'),
                files: files.map(f => ({
                  id: f.fileId,
                  contentType: ContentType.Pdf,
                  name: stripExtension(f.name),
                  size: f.size,
                  uploader: sessionInfo?.agentUuid ? {
                    id: sessionInfo.agentUuid,
                    linkedSalespersonId: sessionInfo.agentId,
                    name: sessionInfo?.name,
                    email: sessionInfo?.email
                  } : undefined,
                  created: Date.now()
                }))
              },
              formCode: FormCode.UploadedDocument,
              created: now.getTime(),
              modified: now.getTime(),
              annexures: []
            });
          }
        }
      }]
    });

    FileSync.triggerSync(fileSync);
    return formFileQueue.map(ffq => ({ formId: ffq.formId, name: stripExtension(ffq.files.at(0)?.name || 'Uploaded Document') }));
  }
}
