export interface AbortRequest {
  requestId: string;
  type: 'abort'
}

export const isUint8ArrayBuffer = (b: ArrayBuffer | Uint8Array): b is Uint8Array => 'buffer' in b;

export function isAbortRequest<T extends object>(e: T | AbortRequest): e is AbortRequest {
  return 'type' in e && e.type === 'abort';
}
