import {
  Annexure,
  ContentType,
  ExtraFormCode,
  FormCode,
  FormCodeUnion,
  FormInstance,
  MaterialisedPropertyData,
  TransactionMetaData,
  UploadType,
  uploadTypeOpts
} from '@property-folders/contract';
import * as Y from 'yjs';
import { FileSync } from '../offline/fileSync';
import { archiveFormInstancesOfTypes, ensureFormStatesInstances, FormTypes } from '../yjs-schema/property/form';
import { v4 } from 'uuid';
import { materialisePropertyData, materialisePropertyMetadata } from '../yjs-schema/property';
import { applyMigrationsV2, MigrationV2 } from '../yjs-schema';
import { createAnnexure, updateAnnexureLabelsImmer } from './annexure';
import { LinkBuilder } from './LinkBuilder';
import { FormUtil } from './form';
import { getValueByPath } from './pathHandling';
import { LegacyApi } from '../client-api/legacyApi';
import { SubscriptionFormCode } from '../subscription-forms';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { Predicate } from '../predicate';
import { PropertySearchApi } from '../client-api/propertySearchApi';

export const createNewFormInstanceMigration = (
  formCode: FormCodeUnion,
  newFormId: string,
  documentId?: number,
  formMeta?: Partial<FormInstance>,
  subscriptionFormId?: number
) => {
  const formType = FormTypes[formCode];
  const fam = formType.formFamily;
  return {
    name: 'Create new form instance',
    fn: (state: TransactionMetaData) => {
      if (!FormUtil.canAddForm(state.formStates ?? {}, formCode)) {
        console.warn('cannot add form', state.formStates, formCode);
        return false;
      }
      const instances = ensureFormStatesInstances(state, fam);
      archiveFormInstancesOfTypes(state, instances, formType.archiveSiblingTypesOnCreate);
      const dateNow = Date.now();
      instances.push({
        formCode: (formCode as FormCodeUnion),
        id: newFormId,
        created: dateNow,
        modified: dateNow,
        dataModified: dateNow,
        annexures: [],
        subscription: documentId && subscriptionFormId
          ? {
            fileName: (formCode as SubscriptionFormCode),
            documentId: documentId,
            formId: subscriptionFormId
          }
          : undefined,
        ...formMeta
      });
    }
  };
};

/**
 *
 * @param ydoc
 * @param formCode
 * @param fileSync Only strictly required for RSAA where we insert a standard annexure
 * @param formMeta
 * @returns
 */
export async function handleNewForm(
  ydoc: Y.Doc | undefined,
  formCode: FormCodeUnion,
  fileSync: FileSync,
  formMeta?: Partial<FormInstance>,
  dataRootKey: PropertyRootKey | string = PropertyRootKey.Data,
  metaRootKey: PropertyRootKey | string = PropertyRootKey.Meta
): Promise<{
  formId: string,
  documentId?: number
  clauseChildId: string
} | undefined> {

  if (!(formCode in FormTypes)) {
    throw new Error('Invalid form code');
  }
  if (!(ydoc && formCode in FormTypes)) {
    console.warn('cannot create new form', !!ydoc, formCode in FormTypes);
    return undefined;
  }

  const newId = v4();

  const propertyData = materialisePropertyData(ydoc, dataRootKey);
  const propertyMeta = materialisePropertyMetadata(ydoc, metaRootKey);
  const addingAnnexure = initialiseAnnexures({
    formCode,
    formId: newId,
    property: propertyData,
    fileSync,
    meta: propertyMeta,
    dataRootKey,
    ydoc
  });

  /* Do some stuff to work out what form families are already present */
  const formType = FormTypes[formCode];
  const { documentId, formId: subscriptionFormId } = formType.subscription && !formMeta?.external
    ? await LegacyApi.createDocument({
      mode: 'property',
      formCode: formCode as SubscriptionFormCode,
      documentInstanceName: formType.label,
      propertyId: propertyData.id,
      propertyFormId: newId,
      entityId: propertyMeta.entity?.id
    })
    : { documentId: undefined, formId: undefined };

  const annexureMigration = await addingAnnexure;

  if (formType?.clonePreviousInfo) {
    const latest = FormUtil.getLatestInstanceInFamily(formCode, propertyMeta);
    if (formType?.clonePreviousInfo?.cover && latest?.cover) {
      formMeta = {
        ...formMeta,
        cover: latest.cover
      };
    }
  }

  const changed = !!(applyMigrationsV2<TransactionMetaData>({
    doc: ydoc,
    docKey: metaRootKey,
    typeName: 'Property',
    migrations: [
      createNewFormInstanceMigration(formCode, newId, documentId, formMeta, subscriptionFormId),
      annexureMigration
    ].filter(Predicate.isNotNull)
  }));
  const formCodeFamily = FormTypes[formCode].formFamily;
  const clauseChildId = (ydoc.getMap(metaRootKey).toJSON() as TransactionMetaData).formStates?.[formCodeFamily]?.clauseChildId;
  if (!clauseChildId) return undefined; // Pretty key party of form family initialisation
  return changed
    ? { formId: newId, documentId, clauseChildId }
    : undefined;
}

/**
 * Set up any missing annexure files/records, and then return a ydoc migration function which would add them.
 * @param formCode
 * @param formId
 * @param property
 * @param fileSync
 */
async function initialiseAnnexures({
  formCode,
  formId,
  property,
  fileSync,
  meta,
  dataRootKey,
  ydoc
}: {
  formCode: FormCodeUnion,
  formId: string,
  property: MaterialisedPropertyData,
  fileSync: FileSync,
  meta: TransactionMetaData,
  dataRootKey: string,
  ydoc: Y.Doc
}): Promise<MigrationV2<TransactionMetaData> | undefined> {
  const loadedAnnexures = new Array<Annexure>();
  const formType = FormTypes[formCode];
  const tryAddPlan = () => {
    if (property.titleDivision?.plan) {
      const plan = property.titleDivision.plan;
      loadedAnnexures.push({
        id: plan.id,
        name: uploadTypeOpts[UploadType.PropertyPlan],
        contentType: plan.contentType,
        uploadType: UploadType.PropertyPlan,
        preserveFile: true,
        managed: true,
        binding: {
          path: 'titleDivision.plan',
          root: dataRootKey
        }
      });
    }
  };

  const tryAddComparableSalesAnnexure = () => {
    if (property.comparableSales?.annexureRef?.['family_'+formType.formFamily]) {
      loadedAnnexures.push({
        id: property.comparableSales?.annexureRef?.['family_'+formType.formFamily],
        name: uploadTypeOpts.comparable,
        contentType: ContentType.Pdf,
        uploadType: UploadType.ComparableSales,
        preserveFile: true,
        managed: true,
        binding: {
          path: `comparableSales.annexureRef.family_${formType.formFamily}`,
          root: dataRootKey
        }
      });
    }
  };

  const tryAddMarketingUploadAnnexure = () => {
    if (property.marketingFeesOptions?.externalFeeScheduleAnnexureRef?.['family_'+formType.formFamily]) {
      loadedAnnexures.push({
        id: property.marketingFeesOptions?.externalFeeScheduleAnnexureRef?.['family_'+formType.formFamily],
        name: 'Marketing Schedule',
        contentType: ContentType.Pdf,
        uploadType: UploadType.MarketingSchedule
      });
    }
  };

  const tryAddDownloadAnnexure = async (url: string, name: string, linkedTitle?: string) => {
    const annexureId = await createAnnexure({
      file: await fetch(url),
      coversheetText: '',
      nameOverride: name,
      fileSync,
      formCode,
      formId,
      linkedFormId: formId,
      property,
      noEditRemove: true,
      store: fileSync.store,
      ydoc
    });
    if (annexureId) {
      loadedAnnexures.push({
        id: annexureId,
        contentType: ContentType.Pdf,
        noEditRemove: true,
        name: 'Form R1',
        coversheetText: '',
        linkedFormId: formId,
        linkedTitle
      });
    }
  };

  switch (formCode) {
    case FormCode.RSAA_SalesAgencyAgreement: {
      await tryAddDownloadAnnexure(LinkBuilder.propertiesPrescribedPdfs('Approved-R1_final_sales_agency_agreements-retrieved-2023-08-31.pdf'), 'Form R1');

      for (const title of property.saleTitles || []) {
        const uploaded = meta.formStates?.[FormCode.UploadedDocument]?.instances?.find(i => i.upload?.linkedTitle === title.title);
        if (uploaded?.upload) {
          loadedAnnexures.push({
            id: uploaded.id,
            code: uploaded.formCode,
            noEditRemove: true,
            name: `Land Services SA - Title Details Report - ${title.title}`,
            linkedTitle: title.title,
            preserveFile: true,
            coversheetText: '',
            uploadType: UploadType.TitleDetails,
            linkedFormId: formId
          });
        } else if (title.propertyCacheId) {
          // we need to fetch it this way instead.
          await tryAddDownloadAnnexure(
            PropertySearchApi.getPdfFromCache(title.propertyCacheId),
            `Land Services SA - Title Details Report - ${title.title}`,
            title.title
          );
        }
      }

      tryAddPlan();
      break;
    }
    case ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent: {
      tryAddPlan();
      tryAddComparableSalesAnnexure();
      tryAddMarketingUploadAnnexure();
      // I did consider adding a thing to look for bound annexures, but 2 issues here:
      // 1. the marketing upload doesn't currently use that anyway
      // 2. We'd have to patch in the signing history to the handleNewForm function
      break;
    }
    case FormCode.RSC_ContractOfSale:
    case ExtraFormCode.SCV_ContractOfSaleVariation:
    case ExtraFormCode.SCT_ContractOfSaleTermination:
    case FormCode.OfferToPurchase:
      tryAddPlan();
      break;
  }

  return loadedAnnexures.length
    ? {
      name: `Initialise ${formCode} annexures`,
      fn: draft => {
        const annexureListPath = (FormUtil.getFormPath(formCode, formId) ?? '') + '.annexures';
        const annexureList = getValueByPath(annexureListPath, draft, true) as Annexure[] | undefined;
        if (!Array.isArray(annexureList)) {
          console.warn('No annexures list!', annexureListPath);
          return false;
        }

        for (const annexure of loadedAnnexures) {
          annexureList.push(annexure);
        }
        updateAnnexureLabelsImmer(annexureList);
      }
    }
    : undefined;
}
