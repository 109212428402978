import { useEffect, useState } from 'react';
import {
  ContentType,
  FormInstance,
  FormInstanceSigning,
  FormSigningState,
  SigningPartyType
} from '@property-folders/contract';
import { useLiveQuery } from 'dexie-react-hooks';
import { FileStorage, ReadFile, StorageItemFileStatus } from '@property-folders/common/offline/fileStorage';
import { AppFileProvider } from '@property-folders/components/dragged-components/signing/SigningProcess';
import * as Y from 'yjs';
import {
  determinePreviewFiles,
  generateDocumentDropdownInfo,
  getCompletedFiles,
  PreviewFile,
  PreviewType,
  PropertyFormYjsDal
} from '@property-folders/common/yjs-schema/property/form';
import { Predicate } from '@property-folders/common/predicate';
import { sortBy } from 'lodash';
import { YFormUtil } from '@property-folders/common/util/yform';
import { FileTrackState, PropertyRootKey, StillUploadingStates } from '@property-folders/contract/yjs-schema/property';
import { fillPdf } from '@property-folders/common/signing/fill-pdf';
import {
  applyPdfChanges,
  ChangeFn,
  makeUploadPdfChangeFns,
  PdfInformationExtractor
} from '@property-folders/common/util/pdf';
import { ExtractedField } from '@property-folders/common/util/pdf/types';
import { useFileTrack } from '../../hooks/useTransactionField';
import { stitch } from '@property-folders/common/util/pdf/pdf-stitch';
import { mutateDocument } from '@property-folders/common/util/upload-forms';
import { PdfWorker, usePdfWorker } from '../../hooks/usePdfWorker';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';

function wrappedCreateObjectUrl(blob: Blob, name?: string) {
  const url = URL.createObjectURL(blob);
  console.log(`createObjectURL (${name || ''})`, blob.type, url);
  return url;
}

export interface PdfPreviewUrl {
  id: string,
  name: string,
  url: string
}

export type UnsetFieldsHandler = (fields: ExtractedField[]) => void;

/**
 * Prepare a pdf preview url for signing session preview.
 * note: was written for subscription forms, but could be extended out for regular stuff
 */
export function usePdfPreviewUrl({
  form,
  yDoc,
  formId,
  formCode,
  unsetFieldsHandler,
  brand,
  previewPdfTransforms,
  downloadAndPrintTransforms
}: {
  form: FormInstance | undefined,
  yDoc: Y.Doc | undefined,
  formId: string,
  formCode: string,
  unsetFieldsHandler?: UnsetFieldsHandler,
  brand: EntityBrandFormConfig,
  previewPdfTransforms?: ChangeFn[]
  downloadAndPrintTransforms?: ChangeFn[]
}) {
  const signing = form?.signing;
  const worker = usePdfWorker();
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState<PdfPreviewUrl[]>([]);
  const state = signing?.state || FormSigningState.None;
  // if it's not nothing, and it's not configuring, then it must be a signing session - and we want to show for those.
  const show = (state !== FormSigningState.None && state !== FormSigningState.Configuring) || !!form?.order?.pdfPreview || !!form?.upload;
  const completedFiles = getCompletedFiles(signing?.session);
  const showFinal = completedFiles.length;
  const { files: previewFileList, fill: buildWithSignatures, buster: buildWithSignaturesBuster, applyActions } = determinePreviewFiles(form);
  const fileTrack = useFileTrack()??{};
  const { previewFiles, buster  } = useLiveQuery(async () => {
    const files = await Promise.all(previewFileList.map<Promise<RegeneratePreviewFile>>(async pf => {
      const data = await FileStorage.read(pf.id);
      return {
        ...data,
        cover: pf.cover,
        actions: applyActions ? makeUploadPdfChangeFns(pf.actions || [], false) : undefined,
        buster: `${pf.id}:${data?.size}:${pf.cover ?? false}:${pf.actions?.length || 0}:${pf.cover ? pf.name : ''}`,
        name: pf.name || ''
      };
    }));
    const previewFiles = files.filter(Predicate.isNotNull);
    return {
      previewFiles,
      buster: previewFiles.map(f => f.buster).join('|')
    };
  }, [
    signing?.session?.file?.id,
    previewFileList?.map(pf => `${pf.id}:${pf.cover ?? false}:${pf.actions?.length || 0}:${pf.cover ? pf.name : ''}`).join(''),
    signing?.session?.fields
  ]) || { previewFiles: [], buster: '' };

  const signatureFileList = form?.signing?.session?.fields?.map(field => field.file?.id)?.filter(Predicate.isTruthy)??[];
  const signatureFilesMeta = useLiveQuery(async () => {
    return signatureFileList ? Promise.all(signatureFileList?.map(fid => FileStorage.readMeta(fid))) : undefined;
  }, [signatureFileList?.join('')]);

  const filesReady = previewFiles.length === 0 || previewFiles?.every(p=>!p || p?.fileStatus===StorageItemFileStatus.Available);
  const sigsReady = signatureFilesMeta?.every(p=>p?.fileStatus===StorageItemFileStatus.Available);
  const pdfLoadErrorMessage = previewFileList.some(f=>f.id && StillUploadingStates.has(fileTrack[f.id]?.state))
    ? 'File is still being uploaded by other user'
    : previewFileList.some(f=>f.id && fileTrack[f.id]?.state === FileTrackState.ServerProcessing)
      ? 'Server is processing the file'
      : !filesReady
        ? 'Getting file'
        : !sigsReady
          ? 'Getting signatures'
          : '';

  const regenerateForDownloadPrintUsage = async () => {
    if (!(yDoc && previewFiles.length)) return null;

    if (unsetFieldsHandler) unsetFieldsHandler([]);

    const items = await regeneratePreview({
      yDoc,
      formId,
      formCode,
      signing,
      buildWithSignatures,
      previewFileList,
      show,
      previewFiles,
      unsetFieldsHandler,
      merge: form?.upload ? { id: form.id, name: form.upload.name } : undefined,
      brand,
      worker,
      alwaysActions: Array.isArray(downloadAndPrintTransforms) && downloadAndPrintTransforms.length
        ? downloadAndPrintTransforms
        : undefined
    });
    const data = await worker.stitchPdf({ pdfs: await Promise.all(items.map(async (i)=>(await fetch(i.url)).arrayBuffer())) });
    return URL.createObjectURL(new Blob([data], { type: ContentType.Pdf }));
  };

  useEffect(() => {
    if (!(yDoc && previewFiles.length)) {
      setPdfPreviewUrl([]);
      return;
    }

    if (unsetFieldsHandler) unsetFieldsHandler([]);

    regeneratePreview({
      yDoc,
      formId,
      formCode,
      signing,
      buildWithSignatures,
      previewFileList,
      show,
      previewFiles,
      unsetFieldsHandler,
      merge: form?.upload ? { id: form.id, name: form.upload.name } : undefined,
      worker,
      brand,
      alwaysActions: Array.isArray(previewPdfTransforms) && previewPdfTransforms.length
        ? previewPdfTransforms
        : undefined
    }).then(result => {
      if (!result.length) return;
      setPdfPreviewUrl(result);
    });
  }, [
    show,
    showFinal,
    buster,
    !!yDoc,
    signing?.session?.id || '',
    signing?.session?.initiator?.timeZone || 'Australia/Adelaide',
    buildWithSignaturesBuster,
    applyActions,
    filesReady
  ]);

  return { pdfPreviewUrl, pdfLoadErrorMessage, regenerateForDownloadPrintUsage };
}

export type RegeneratePreviewFile = ReadFile & {
  cover?: boolean;
  actions?: ChangeFn[];
  buster: string;
  name: string;
};

async function regeneratePreview({
  yDoc,
  show,
  previewFiles,
  buildWithSignatures,
  previewFileList,
  signing,
  formCode,
  formId,
  unsetFieldsHandler,
  merge,
  worker,
  brand,
  alwaysActions
}: {
  yDoc: Y.Doc,
  show: boolean,
  previewFiles: RegeneratePreviewFile[],
  buildWithSignatures: boolean,
  previewFileList: PreviewFile[],
  signing?: FormInstanceSigning,
  formCode: string,
  formId: string,
  unsetFieldsHandler?: UnsetFieldsHandler,
  merge?: { id: string, name: string },
  worker: PdfWorker,
  brand: EntityBrandFormConfig,
  alwaysActions?: ChangeFn[]
}): Promise<PdfPreviewUrl[]> {
  if (!(show && previewFiles[0]?.data && !!yDoc)) {
    return [];
  }
  if (!previewFiles?.every(p => p?.id)) {
    return [];
  }

  const parties = signing?.parties || [];
  const sortedPdfs = sortBy(previewFiles, pf =>
    parties
      .filter(p => p.signedPdf?.id === pf.id)
      .some(p => p.type === SigningPartyType.SignWet)
  );

  const previewPdfs: Array<PdfPreviewUrl & {signingPartyType?: SigningPartyType, previewType?: PreviewType}> = [];
  let mergePreviewType: PreviewType | undefined = undefined;
  const mergeQueue: (ArrayBuffer | Uint8Array<ArrayBufferLike>)[] = [];
  for (const [index, pf] of sortedPdfs.entries()) {
    const { text, signingPartyType } =  generateDocumentDropdownInfo(
      index + 1,
      previewFiles.length,
      pf.id,
      parties);
    const preview = previewFileList.find(p => p.id === pf.id);
    const data = pf.data
      ? await mutateDocument({
        pdf: pf.data,
        name: pf.name,
        actions: [...(pf.actions ?? []), ...(alwaysActions ?? [])],
        cover: pf.cover,
        fns: {
          makeCover: (title: string) => {
            return worker.generateCoverPage({
              formType: 'simple',
              documentLabel: '',
              headline: title,
              agentName: '',
              meta: {
                documentLabel: '',
                headline: title,
                agentName: ''
              },
              brand,
              noBoldContentMode: true
            });
          }
        }
      })
      : undefined;
    if (merge) {
      if (data) {
        mergeQueue.push(data);
        if (!mergePreviewType) {
          mergePreviewType = preview?.type;
        }
      }
    } else {
      previewPdfs.push({
        id: pf.id || '',
        name: text,
        url: data ? wrappedCreateObjectUrl(new Blob([data], { type: ContentType.Pdf }), 'base/complete pdf') : '',
        signingPartyType,
        previewType: preview?.type
      });
    }
  }

  if (merge && mergeQueue.length) {
    // merge and push to previewPdfs
    previewPdfs.push({
      id: merge.id,
      name: merge.name,
      url: wrappedCreateObjectUrl(new Blob([await stitch(mergeQueue)], { type: ContentType.Pdf }), 'base/complete pdf'),
      signingPartyType: undefined,
      previewType: mergePreviewType
    });
  }

  const { dataRootKey, metaRootKey } = YFormUtil.getFormLocationFromId(formId, yDoc)??{ dataRootKey: PropertyRootKey.Data, metaRootKey: PropertyRootKey.Meta };

  // fill in pre-completion signing data
  if (buildWithSignatures && signing?.session?.id) {
    const bytes = await fillPdf(
      new PropertyFormYjsDal(yDoc, dataRootKey, metaRootKey),
      new AppFileProvider(),
      formId,
      formCode,
      signing.session.id,
      signing.session.initiator?.timeZone || 'Australia/Adelaide',
      true,
      false,
      {
        noFlatten: !!unsetFieldsHandler
      }
    );

    if (bytes) {
      if (unsetFieldsHandler) {
        try {
          const fp = await (new PdfInformationExtractor(bytes)).getFieldPositions();
          unsetFieldsHandler(fp);
        } catch (err: unknown) {
          console.error('failed to extract unfilled field info from pdf', err);
        }
      }
      const transformedBytes = alwaysActions?.length ? await applyPdfChanges(bytes, alwaysActions) : bytes;
      const intermediate = previewPdfs.find(p => p.previewType === PreviewType.intermediate);
      intermediate && (intermediate.url = URL.createObjectURL(new Blob([transformedBytes], { type: ContentType.Pdf })));
      if (intermediate) {
        intermediate.url = wrappedCreateObjectUrl(new Blob([transformedBytes], { type: ContentType.Pdf }), 'filled pdf');
      }
    }
  }

  return previewPdfs;
}
