import { ValidationDefnType } from '../validation-field';
import { saafieldGroups } from './sales-agency-agreement';

export const agencyAgreementVariationOverlay: ValidationDefnType = {
  form1AndSearches: {
    epfOrderingAutomatic: {
      _requiredIf: false
    }
  }
};

// One restriction added in the variation is that the vendor's price can not be revised up for
// auctions from the previously executed agreement. However, we already modify price groups in the
// existing group function, so instead the group function has been updated to also pay attention
// to variations.
export const aavFieldGroups = {
  ...saafieldGroups
};