
export function getSigningLinkEmailHeadline({
  reminder,
  senderName,
  entityName,
  aDocumentName
}: {
  reminder: boolean | undefined,
  senderName: string,
  entityName: string,
  aDocumentName: string,
}): string {
  const parts: string[] = [];

  if (reminder) {
    parts.push('This is a reminder that');
  }

  if (senderName) {
    parts.push(senderName);
    if (entityName) {
      parts.push('from', entityName);
    }
    parts.push('has sent you');
  } else {
    parts.push('you have been sent');
  }

  parts.push(aDocumentName);

  parts.push('to review and sign electronically.');

  return parts.join(' ');
}
