export class ArrayUtil {
  public static partition<T>(items: T[], partitions: number): T[][] {
    const partitionSize = Math.ceil(items.length / partitions);
    return this.chunk(items, partitionSize);
  }

  public static chunk<T>(items: T[], size: number): T[][] {
    const chunks = [];
    for (let i = 0; i < items.length; i += size) {
      chunks.push(items.slice(i, i + size));
    }
    return chunks;
  }

  public static groupBy<TItem, TKey>(items: TItem[], keySelector: (t: TItem) => TKey | undefined): Map<TKey, TItem[]> {
    const result = new Map<TKey, TItem[]>();
    for (const item of items) {
      const key = keySelector(item);
      if (typeof key === 'undefined') continue;

      const groupList = result.get(key);
      if (groupList) {
        groupList.push(item);
      } else {
        result.set(key, [item]);
      }
    }
    return result;
  }

  public static binarySearchMax<T>(items: T[], predicate: (item: T) => boolean): T {
    if (!items.length) throw new Error('no item to return');
    if (items.length === 1) return items[0];

    const mid = Math.floor(items.length / 2);
    const isPredicate = predicate(items[mid]);
    return isPredicate
      ? this.binarySearchMax(items.slice(mid), predicate)
      : this.binarySearchMax(items.slice(0, mid), predicate);
  }

  public static range(to: number, from?: number) {
    if (!from) return [...new Array(to + 1).keys()];

    return [...new Array(to + 1 - from).keys()].map(x => x + from);
  }

  public static count<T>(items: T[], predicate: (item: T) => unknown): number {
    let count = 0;

    for (const item of items) {
      if (predicate(item)) {
        count ++;
      }
    }

    return count;
  }
}
