export interface PaddingConfig {
  left: number,
  right: number,
  top: number,
  bottom: number,
  toWidth?: number,
  toHeight?: number,
  vCenter?: boolean
}

export function padCanvas(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, config: PaddingConfig) {
  const width = canvas.width;
  const height = canvas.height;
  const imgData = context.getImageData(0, 0, width, height);

  const newWidth = config.toWidth
    ? Math.max(config.toWidth, width + config.left + config.right)
    : width + config.left + config.right;
  const newHeight = config.toHeight
    ? Math.max(config.toHeight, height + config.top + config.bottom)
    : height + config.top + config.bottom;

  const pTop = config.vCenter
    ? (newHeight - height) / 2
    : config.top;

  canvas.width = newWidth;
  canvas.height = newHeight;
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.putImageData(imgData, config.left, pTop);
}
