import './PhpPage.scss';
import { useLoaderData, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { buildYDoc } from '@property-folders/components/form-gen-util/buildYDoc';
import { FormCode, PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field/';
import { ServeToNewPurchaserModal } from '@property-folders/components/dragged-components/signing/ServeToNewPurchaserModal';
import { ReturnToPreparerModal } from '@property-folders/components/dragged-components/ordering/ReturnToPreparerModal';
import { PDFPreviewer } from '@property-folders/components/dragged-components/PDFViewer/PDFPreviewer';
import { basePhpPageMessageHandler } from '~/pages/PhpPage';
import { YjsDocContextType } from '@property-folders/common/types/YjsDocContextType';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormContext } from '@property-folders/components/context/FormContext';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { handleNewFormOrderInternal } from '@property-folders/common/util/handleNewFormOrder';
import { Properties } from '@property-folders/common/client-api/properties';
import { FormOrderState, LookupPropertiesResultItem } from '@property-folders/contract';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { extractDisplayInstance } from '@property-folders/components/dragged-components/Form1Card';
import { orderBy } from 'lodash';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { generateHeadlineFromMaterialisedData, materialisePropertyData, materialisePropertyMetadata } from '@property-folders/common/yjs-schema/property';
import { CreateInPropertyFolderPane } from '~/components/create-form/CreateInPropertyFolderPane';
import { uploadForm1 } from '@property-folders/components/dragged-components/signing/Form1RequiredSection';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { useStore } from 'react-redux';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { useQpdfWorker } from '@property-folders/components/hooks/useQpdfWorker';

interface EpfIframePageProps {
  url: string;
  needsReload: boolean;
}

interface LoaderData {
  additionalQueryParams?: { [key: string]: string }
}

const PREVIEW_PSEUDO_TARGET = '#newformpdfpreview';
export function EpfIframePage({ url: urlBase, needsReload }: EpfIframePageProps) {
  const navigate = useNavigate();
  const navType = useNavigationType();
  const { instance: fileSync } = useContext(FileSyncContext);
  const store = useStore();
  const { hash: locationHash, pathname, search, key: locationKey } = useLocation();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const loaderData: LoaderData | null = useLoaderData() as any;
  const [previewUrl, internalSetPreviewUrl] = useState<string | undefined>(undefined);
  const [previewFileName, setPreviewFileName] = useState<string | undefined>(undefined);
  const [showModalDetails, setShowModalDetails] = useState<undefined | {
    context: ModalWithinYContextProps,
    modal: 'serve' | 'return'
  }>(undefined);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [suggestedPropertyFolder, setSuggestedPropertyFolder] = useState<LookupPropertiesResultItem[]>();
  const [selectedPropertyFolder, setSelectedPropertyFolder] = useState('');
  const [showAddToPropertyFolderModal, setShowAddToPropertyFolderModal] = useState<undefined | {documentId: number, address?: string}>();
  const renderTextLayer = useBreakpointValue({ base: false, sm: true }, true);
  const qpdfWorker = useQpdfWorker();

  const setPreviewUrl = (newUrl: string | undefined) => {
    if (newUrl) {
      navigate(pathname + search + PREVIEW_PSEUDO_TARGET, { preventScrollReset: true });
    }
    internalSetPreviewUrl(newUrl);
  };

  const searchForProperty = (address: string) => {
    const { ac, results } = Properties.lookup({
      searchTerm: address
    });

    results
      .then(data => {
        if (!data) return;
        if (ac.signal.aborted) return;
        const sorted = orderBy(data.items, i => i.created, 'desc');
        setSuggestedPropertyFolder(sorted);
        setSelectedPropertyFolder(sorted?.[0]?.propertyId);
      })
      .catch(console.error);
  };

  const url = new URL(urlBase);
  LinkBuilder.applyQueryString(url.searchParams, {
    ...loaderData?.additionalQueryParams,
    wrapped: true
  });

  useEffect(() => {
    setIframeLoaded(false);
  }, [locationKey]);

  useEffect(() => {
    if (!iframeRef.current?.contentWindow) {
      return;
    }

    const messageHandler = (e: MessageEvent<MessageEventData | string>) => {
      if (!e.data) return;
      const { data } = e;

      // pilfered from PhpPage.tsx
      if (typeof data === 'string') {
        switch (data) {
          case 'epf_iframe_loaded':
            setIframeLoaded(true);
            return;
        }

        basePhpPageMessageHandler({
          e: e as MessageEvent<string>,
          navigate,
          setPreviewUrl,
          needsReload,
          setPreviewFileName
        });
        return;
      }

      if (typeof data !== 'object') return;
      if ('source' in data && typeof data.source === 'string' && data.source.startsWith('react-devtools-')) return;
      const { action, propertyFolderId, formCode, formId, viewOrder } = data;
      switch (action) {
        case 'view_form':
          navigate(LinkBuilder.documentPath({
            id: propertyFolderId
          }, {
            id: formId
          }, !!(FormTypes[formCode].subscription)) + (viewOrder ? '?view_order=1' : ''), {  } );
          return;
        case 'initiate_serve':
          setShowModalDetails({
            modal: 'serve',
            context: {
              formCode,
              formId,
              propertyFolderId
            }
          });
          return;
        case 'initiate_return':
          setShowModalDetails({
            modal: 'return',
            context: {
              formCode,
              formId,
              propertyFolderId
            }
          });
          return;
        case 'search_pf':
          searchForProperty(data.address);
          return;
        case 'add_to_property_folder':
          setShowAddToPropertyFolderModal({ documentId: data.documentId, address: data.address });
          return;
        default:
          return;
      }
    };

    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [iframeRef.current]);

  const iframeStyle = useMemo(() => {
    return {
      border: 'none',
      width: '100%',
      display: iframeLoaded
        ? undefined
        : 'none'
    };
  }, [iframeLoaded]);

  useEffect(() => {
    if (previewUrl && locationHash !== PREVIEW_PSEUDO_TARGET && navType === 'POP') {
      setPreviewUrl(undefined);
      return;
    }
  }, [locationHash]);

  useEffect(() => {
    if (locationHash === PREVIEW_PSEUDO_TARGET && previewUrl == undefined) {
      navigate(pathname + search, { replace: true, preventScrollReset: true }); // This will retrigger this effect, but having removed the hashes, nothing should happen
    }
  }, [previewUrl]);

  return <>
    {!iframeLoaded &&
      <div className='w-100 h-100 d-flex flex-row justify-content-center align-items-center'>
        <Spinner
          animation='border'
          style={{
            width: '6rem',
            height: '6rem',
            marginInline: 'auto'
          }}/>
      </div>}
    <iframe
      ref={iframeRef}
      key={locationKey}
      style={iframeStyle}
      src={url.toString()}
    />
    {!!previewUrl && <PDFPreviewer
      url={previewUrl}
      filename={previewFileName}
      onClose={() => {
        setPreviewUrl(undefined);
      }}
      tryMakeScrollWork={true}
      renderTextLayer={renderTextLayer}
    />}

    {!!showModalDetails &&
      <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={!!showModalDetails} onClose={() => setShowModalDetails(undefined)} />}>
        <ModalWithinYContext {...showModalDetails.context} onClose={() => setShowModalDetails(undefined)}>
          {showModalDetails.modal === 'serve' &&
          <ServeToNewPurchaserModal
            formCode={showModalDetails.context.formCode}
            formId={showModalDetails.context.formId}
            onClose={() => setShowModalDetails(undefined)}/>}
          {showModalDetails.modal === 'return' &&
          <ReturnToPreparerModal
            onClose={() => setShowModalDetails(undefined)}/>}
        </ModalWithinYContext>
      </ErrorBoundary>
    }

    {!!suggestedPropertyFolder?.length &&
      <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={suggestedPropertyFolder?.length} onClose={() => setSuggestedPropertyFolder(undefined)} />}>
        <Modal show={!!suggestedPropertyFolder?.length} size='lg' onHide={() => setSuggestedPropertyFolder(undefined)}>
          <Modal.Header closeButton>
            <div className='d-flex flex-column'>
              <Modal.Title>Order Form 1 for Property Folder</Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body>
            The following Property Folders match the address you have entered.<br/>
            Choose a Property Folder to order a Form 1, or cancel to proceed without a Property Folder.
            <div className={'my-3'}>
              {suggestedPropertyFolder?.map(pf => <div className={'p-1 d-flex align-items-center'}>
                <Form.Check
                  type='radio'
                  name={`property-${pf.propertyId}`}
                  id={`property-${pf.propertyId}`}
                  label={
                    <div>
                      <div className={'fw-bold'}>{pf.headline}</div>
                      <div>{`Created: ${formatTimestamp(pf.created, '', false)}`}{!!pf.vendors?.length && ` | Vendor${pf.vendors?.length>1?'s':''}: ${pf.vendors.join(', ')}`}</div>
                    </div>}
                  checked={selectedPropertyFolder === pf.propertyId}
                  onChange={() => setSelectedPropertyFolder(pf.propertyId)}
                />
              </div>)}
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-secondary' onClick={() => setSuggestedPropertyFolder(undefined)}>Cancel</Button>
            <AsyncButton onClick={async() => {
              const entityId = (await LegacyApi.getFulfillersForDocument(SubscriptionFormCode.SAF001V2_Form1)).at(0)?.entityId;
              if (!entityId) return undefined;

              const formType = FormTypes[SubscriptionFormCode.SAF001V2_Form1];
              const { ydoc } = buildYDoc(selectedPropertyFolder);
              const meta = materialisePropertyMetadata(ydoc);
              const existingInstance = extractDisplayInstance(meta.formStates?.[FormCode.Form1]?.instances);
              if (existingInstance?.orphanOrder?.order?.state === FormOrderState.ClientOrdering) {
                navigate(LinkBuilder.documentPath({ id: selectedPropertyFolder },{ id: existingInstance.orphanOrder.id, nicetext: formType.label },!!formType.subscription));
                return;
              }

              const newDoc = await handleNewFormOrderInternal(ydoc, SubscriptionFormCode.SAF001V2_Form1, entityId);
              if (!newDoc) return;

              navigate(LinkBuilder.documentPath({ id: selectedPropertyFolder },{ id: newDoc.id, nicetext: formType.label },!!formType.subscription));
            } }>Order for Property Folder</AsyncButton>
          </Modal.Footer>
        </Modal>
      </ErrorBoundary>
    }

    {!!showAddToPropertyFolderModal &&
      <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={!!showAddToPropertyFolderModal} onClose={() => setShowAddToPropertyFolderModal(undefined)} />}>
        <Modal show={!!showAddToPropertyFolderModal} size='lg' onHide={() => setShowAddToPropertyFolderModal(undefined)}>
          <Modal.Header closeButton>
            <div className='d-flex flex-column'>
              <Modal.Title>Add Form 1 to Property Folder</Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body>
            <CreateInPropertyFolderPane
              formCode={FormCode.Form1}
              placeholder={' '}
              label={'Search for a Property Folder'}
              createLabel={'Add'}
              allowReplace={true}
              allowNew={false}
              defaultSearch={showAddToPropertyFolderModal.address}
              onCreate={async (propertyId) => {
                const documentId = showAddToPropertyFolderModal?.documentId;
                const pdf = await AjaxPhp.generatePdf(documentId, true);
                if (!pdf) return setShowAddToPropertyFolderModal(undefined);
                const pdfResponse = await fetch(pdf.link);
                const pdfContent = new Uint8Array(await pdfResponse.arrayBuffer());
                const { ydoc, localProvider } = buildYDoc(propertyId);
                const data = materialisePropertyData(ydoc);
                const headline = generateHeadlineFromMaterialisedData(data);
                await localProvider.whenSynced;

                const applied = await uploadForm1({
                  store,
                  ydoc,
                  propertyId,
                  fileSync,
                  sessionInfo,
                  data: pdfContent,
                  decryptPdf: data => qpdfWorker.decrypt({ pdf: data })
                });
                if (applied) {
                  const formType = FormTypes[applied.formCode];
                  navigate(LinkBuilder.documentPath(
                    { id: propertyId, nicetext: headline },
                    { id: applied.formId, nicetext: formType.label },
                    !!formType.subscription));
                }
                setShowAddToPropertyFolderModal(undefined);
              }}
              onClose={()=>setShowAddToPropertyFolderModal(undefined)}
            />
          </Modal.Body>
        </Modal>
      </ErrorBoundary>
    }
  </>;
}

interface MessageEventData {
  action: string,
  propertyFolderId: string,
  formCode: string,
  formId: string,
  viewOrder: number,
  address: string,
  documentId: number
}

interface ModalWithinYContextProps {
  propertyFolderId: string,
  formCode: string,
  formId: string
}

function ModalWithinYContext({
  propertyFolderId,
  formCode,
  formId,
  children,
  onClose
}: React.PropsWithChildren<ModalWithinYContextProps> & { onClose: () => void }) {
  // const [loadedYDoc, setLoadedYDoc] = useState<undefined | Y.Doc>(undefined);
  const [
    contextValues,
    setContextValues
  ] = useState<undefined | { yjs: YjsDocContextType, form: FormContextType }>(undefined);

  useEffect(() => {
    if (!propertyFolderId) return;

    const provisionFunc = async () => {
      const {
        ydoc,
        localProvider
      } = buildYDoc(propertyFolderId);

      await localProvider.whenSynced;

      setContextValues({
        yjs: {
          ydoc,
          docName: propertyFolderId,
          transactionRootKey: PropertyRootKey.Data, // EPF doesn't operate with sublineages
          transactionMetaRootKey: PropertyRootKey.Meta,
          declareDebounce: () => { /**/ },
          clearDeclareDebounce: () => { /**/ },
          awareness: undefined
        },
        form: {
          formId,
          formName: formCode,
          transactionRules: propertyFolder,
          metaRules: propertyMeta
        }
      });
    };

    provisionFunc();
  }, [propertyFolderId]);

  if (!contextValues) {
    return <div style={{
      background: '#2A2A2E',
      opacity: '0.5',
      color: 'white',
      height: '100%',
      width: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999999
    }} onClick={onClose}>
      <div className={'w-100 h-100 d-flex flex-column justify-content-center align-items-center'}>
        <Spinner animation="border" style={{ width: '6rem', height: '6rem' }}/>
      </div>
    </div>;
  }

  return <YjsDocContext.Provider value={contextValues.yjs}>
    <FormContext.Provider value={contextValues.form}>
      {children}
    </FormContext.Provider>
  </YjsDocContext.Provider>;
}
