import './EditUserModal.scss';
import { Accordion, Alert, Button, Col, Container, FloatingLabel, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { AddressPartsSchema, Maybe } from '@property-folders/contract';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import useDetectSticky from '@property-folders/components/hooks/useDetectSticky';
import clsJn from '@property-folders/common/util/classNameJoin';
import { isEqual } from 'lodash';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import {
  validateChange,
  ValidationNode
} from '@property-folders/common/yjs-schema/property/validation/process-validation';
import { ValidationErrorLabel } from '~/pages/settings/tabs/components/ValidationErrorLabel';
import { MemberOption, TeamType, UserTeamSelector } from '~/pages/settings/tabs/components/UserTeamSelector';
import { BareAddressSelector } from '@property-folders/components/dragged-components/address/BareAddressSelector';
import { Predicate } from '@property-folders/common/predicate';
import { EntityLabel, getCompanyNamingLines } from '@property-folders/components/display/EntityLabel';
import { useFeatureFlags } from '@property-folders/components/hooks/useFeatureFlags';

type NumericBool = 0 | 1;

interface AdminLoadAgentResponse extends BaseAjaxResponse {
  entities: {
    EntityID: number;
    ManagementID: number;
    PropertyCount: number;
    ActiveSigningCount: number;
  }[];
  salesTeams?: Record<number, {
    id: number;
    name: string;
    items: MemberOption[]
  }>;
  data: {
    AgentABN: string,
    AgentAddress1: string,
    AgentAddress2: string,
    AgentCompanyName: string,
    AgentEmail: string,
    AgentName: string,
    AgentPhone: string,
    AgentPostCode: string,
    AgentRLA: string,
    AgentShowLogo: NumericBool,
    AgentState: string,
    AgentSuburb: string,
    AgentTradingName: string,
    AssocAgentABN: string,
    AssocAgentCompanyName: string,
    AssocAgentRLA: string,
    CanOrder: NumericBool,
    CanTemplate: NumericBool,
    CanShareWithEveryone?: NumericBool,
    CanShareWith?: MemberOption[],
    DC?: string,
    Form1ServiceAddressIsRLAUser: NumericBool,
    Form1ServiceAddressUser: string
    Form1ServiceFaxOrEmailUser: string
    GnafID: string,
    InitialsFile?: string,
    InitialsFileDelete?: NumericBool,
    InitialsFileModifiedAt?: string,
    IsAdmin: NumericBool,
    IsAssocAgent: NumericBool,
    IsAuctioneer: NumericBool,
    IsContractor: NumericBool,
    IsMarketingTemplateEditor: NumericBool,
    IsPropertyManager: NumericBool,
    IsSalesperson: NumericBool,
    OfflineProperties: NumericBool,
    OwnForms: NumericBool,
    OwnTemplates: NumericBool,
    PromoteECUser?: NumericBool,
    SignatureFile?: string,
    SignatureFileDelete?: NumericBool,
    SignatureFileModifiedAt?: string,
  };
}

export function EditUserModal({
  onClose,
  agentId,
  onSave,
  entityId
}: {
  onClose: () => void,
  onSave: () => Promise<void>
  agentId: number,
  entityId?: number
}) {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [init, setInitData] = useState<Maybe<AdminLoadAgentResponse>>(undefined);
  const [latest, setLatestData] = useState<Maybe<AdminLoadAgentResponse>>(undefined);
  const [headerIsStuck, headerRef] = useDetectSticky(undefined, undefined, false);
  const [footerIsStuck, footerRef] = useDetectSticky(undefined, undefined, true);
  const formRef = useRef<HTMLFormElement>(null);
  const unchanged = useMemo(() => {
    return isEqual(init, latest);
  }, [init, latest]);
  const ff = useFeatureFlags();
  const {
    selectedEntities,
    selectedEntityIds,
    expectedBnSubtype,
    flagContractors,
    flagGreatforms,
    flagGreatformsSharing,
    flagSignAnythingSharing,
    flagStateSa,
    flagEpfLinked
  } = useMemo(() => {
    if (!entityPhpInfo?.entities) return { selectedEntities: [] };
    if (!latest?.entities) return { selectedEntities: [] };

    const selectedEntities = entityPhpInfo.entities.filter(phpEntity => latest.entities.find(e => e.EntityID === phpEntity.entityId));
    const bnSubtypes = [...new Set<string>(selectedEntities.map(e => countryToBnSubtype(e.country)).filter(Predicate.isNotNull))];
    const flagGreatforms = Boolean(selectedEntities.find(e => e.useNewPropertyTransactions));

    return {
      selectedEntities,
      selectedEntityIds: selectedEntities.map(e => e.entityId),
      expectedBnSubtype: bnSubtypes.length === 1
        ? bnSubtypes[0]
        : 'bn',
      flagContractors: Boolean(selectedEntities.find(e => e.useContractors)),
      flagGreatforms,
      flagGreatformsSharing: Boolean(flagGreatforms && ff.teamManagementVisible),
      flagSignAnythingSharing: Boolean(flagGreatforms && ff.myFiles),
      flagStateSa: Boolean(selectedEntities.find(e => e.state?.toLowerCase() === 'sa')),
      flagEpfLinked: Boolean(selectedEntities.find(e => e.epfAgencyId))
    };
  }, [latest, entityPhpInfo?.entities]);

  useEffect(() => {
    LegacyApi.ajax<AdminLoadAgentResponse>('admin_loadagent', { AgentID: agentId.toString() })
      .then(data => {
        if (agentId === 0 && entityId && entityPhpInfo?.entities?.length && !data.entities.length) {
          const match = entityPhpInfo.entities.find(e => entityId === e.entityId);
          if (match) {
            data.entities = [{
              EntityID: match.entityId,
              ManagementID: match.managementId,
              PropertyCount: 0,
              ActiveSigningCount: 0
            }];
          }
        }
        setInitData(data);
        setLatestData(data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const validationResult = useMemo(() => {
    return validateChange(
      latest || { data: {}, entities: [] },
      {
        _type: 'Map',
        data: {
          _type: 'Map',
          AgentName: { _type: 'string', _required: true },
          AgentEmail: { _type: 'string', _subtype: 'email', _required: true },
          AgentPhone: { _type: 'string', _subtype: 'phone' },
          AgentABN: { _type: 'string', _subtype: expectedBnSubtype },
          AssocAgentABN: { _type: 'string', _subtype: expectedBnSubtype }
        },
        entities: {
          _type: 'Array',
          _children: {
            _type: 'Map'
          }
        }
      },
      {
        data: {
          AgentName: { _required: true },
          AgentEmail: { _required: true }
        },
        entities: {
          _minimum: 1
        }
      },
      {},
      []
    );
  }, [latest, expectedBnSubtype]);
  const valid = validationResult._validationResult.valid;
  if (!valid && !loading) {
    // frontend debugging
    console.error('validationResult', validationResult);
  }

  const setLatestDataField = (key: keyof AdminLoadAgentResponse['data'], value: string | NumericBool | string[]) => {
    setLatestData(cur => {
      if (!cur) return cur;
      const clone = structuredClone(cur);
      // @ts-ignore
      clone.data[key] = value;
      return clone;
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!valid) {
      return;
    }

    setSaving(true);
    setErrorMessage('');
    AjaxPhp.adminSaveAgent({
      agentId,
      // @ts-ignore
      formData: new FormData(e.currentTarget)
    })
      .then(response => {
        if (response?.success) {
          return onSave();
        }

        if (response?.message) {
          setErrorMessage(response.message);
          return;
        }

        console.error(response);
        setErrorMessage('There was an error saving changes. Please try again.');
      })
      .catch(err => {
        console.error(err);
        setErrorMessage('There was an error saving changes. Please try again.');
      })
      .finally(() => setSaving(false));
  };

  function getValidationErrors(...path: string[]) {
    let current = validationResult;
    for (const item of path) {
      current = current?.[item];
    }
    return (current as ValidationNode | undefined)
      ?._validationResult
      ?.errors || [];
  }

  function getValidationErrorsMulti(...paths: string[][]) {
    return paths.flatMap(path => getValidationErrors(...path));
  }

  function makeHidden(name: keyof AdminLoadAgentResponse['data']) {
    if (!latest) return <></>;

    return <input type='hidden' name={name} value={latest.data[name] ?? ''} />;
  }

  function makeText(
    label: string,
    type: string,
    name: keyof AdminLoadAgentResponse['data']
  ) {
    if (!latest) return <></>;

    const errors = getValidationErrors('data', name);

    return <FloatingLabel label={label}>
      <Form.Control
        type={type}
        name={name}
        placeholder={''}
        defaultValue={latest.data[name] as string}
        onChange={e => {
          setLatestDataField(
            e.currentTarget.name as keyof AdminLoadAgentResponse['data'],
            e.currentTarget.value);
        }}
        isInvalid={Boolean(errors.length)}
      />
      <ValidationErrorLabel label={label || ''} errors={errors}/>
    </FloatingLabel>;
  }

  function makeCheck(
    name: keyof AdminLoadAgentResponse['data'],
    label: string
  ) {
    if (!latest) return <></>;

    return <Form.Check
      type='checkbox'
      id={name}
      name={name}
      label={label}
      value='1'
      defaultChecked={latest.data[name] == '1'}
      onChange={e => {
        setLatestDataField(
          e.currentTarget.name as keyof AdminLoadAgentResponse['data'],
          e.currentTarget.checked ? 1 : 0);
      }}
    />;
  }

  const showEcIntegration = Boolean(flagGreatforms && flagStateSa);
  const showEpfIntegration = Boolean(flagStateSa && latest?.data?.IsSalesperson == 1 && flagEpfLinked);
  const presentableAgentName = latest?.data?.AgentName || 'this user';
  const { pc: allPropertyCount, ac: allActiveCount } = init?.entities?.reduce(
    (acc, cur) => {
      return { pc: acc.pc + (cur.PropertyCount || 0), ac: acc.ac + (cur.ActiveSigningCount || 0) };
    },
    { pc: 0, ac: 0 }
  ) ?? { pc: 0, ac: 0 };

  return <Modal show={true} onHide={saving ? undefined : onClose} size='lg' backdrop='static' keyboard={false}>
    {loading
      ? <>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center align-items-center p-5'>
          <Spinner animation='border'/>
        </Modal.Body>
        <Modal.Footer>
          <Button /*disabled={saving}*/ variant='outline-secondary' onClick={onClose}>Cancel</Button>
        </Modal.Footer>
      </>
      : (init && latest) ? <Form ref={formRef} onSubmit={onSubmit} noValidate>
        <Modal.Header ref={headerRef} closeButton={!saving} className={clsJn('sticky bg-white', headerIsStuck && 'stuck')} data-stick='top'>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey='agency-access' flush alwaysOpen>
            <Container style={{ paddingInline: 'var(--bs-accordion-body-padding-x)' }}>
              <Row>
                <Col xs={12} sm={6}>
                  {makeText('Name', 'text', 'AgentName')}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} className={'mt-2'}>
                  {makeText('Email', 'email', 'AgentEmail')}
                </Col>
                <Col xs={12} sm={6} className={'mt-2'}>
                  {makeText('Mobile', 'text', 'AgentPhone')}
                </Col>
              </Row>
            </Container>
            {entityPhpInfo?.entities?.length && entityPhpInfo.entities.length > 1 ? <QuickAccordionItem
              eventKey={'agency-access'}
              title='Agency access'
              afterTitle={<ValidationErrorLabel
                label='entities'
                errors={getValidationErrors('entities')}
                message={'Agent must belong to at least one Entity'}/>}
            >
              <p>If you operate with multiple Agencies in reaforms, you can assign users to all or some of them.</p>
              <p>If a user is not assigned to an Agency, they cannot see or create any of the content in that
                Agency.</p>
              <div className='d-flex flex-column gap-2'>
                {entityPhpInfo.entities.map(entity => {
                  const initEntity = init.entities.find(e => e.EntityID === entity.entityId);
                  const propertyCount = initEntity?.PropertyCount ?? 0;
                  const activeCount = initEntity?.ActiveSigningCount ?? 0;
                  const match = latest.entities.find(e => e.EntityID === entity.entityId);
                  const { headline } = getCompanyNamingLines(entity.companyName, entity.tradingName, entity.profileName);
                  return <>
                    <Form.Check
                      key={entity.entityId}
                      type={'checkbox'}
                      id={`Entity_${entity.entityId}`}
                      name={`Entity_${entity.entityId}`}
                      value={entity.entityId}
                      defaultChecked={Boolean(initEntity)}
                      label={<EntityLabel {...entity} />}
                      onChange={e => {
                        const checked = e.currentTarget.checked;
                        setLatestData(cur => {
                          if (!cur) return cur;
                          if (checked && cur.entities.find(e => e.EntityID === entity.entityId)) return cur;
                          if (!checked && !cur.entities.find(e => e.EntityID === entity.entityId)) return cur;

                          const clone = structuredClone(cur);
                          if (checked) {
                            clone.entities.push({ EntityID: entity.entityId, ManagementID: entity.managementId, PropertyCount: propertyCount });
                          } else {
                            clone.entities = clone.entities.filter(e => e.EntityID !== entity.entityId);
                          }
                          return clone;
                        });
                      }}
                    />
                    {propertyCount > 0 && !match && <Alert variant='warning'>
                      <p className='mb-0'>{presentableAgentName} is the salesperson for {propertyCount === 1 ? '1 property folder' : `${propertyCount} property folders`} within <em>{headline}</em>.</p>
                      <p className='mb-0'>Saving this change will apply the following changes to these property folders:</p>
                      <ul>
                        <li>Remove {presentableAgentName} and their team's access.</li>
                        {activeCount > 0 && <li>Void {presentableAgentName}'s active signing sessions.</li>}
                      </ul>
                      <p className='mb-0'>It is advisable to reassign active property folders to a different salesperson so that signing may continue.</p>
                    </Alert>}
                  </>;
                })}
              </div>
            </QuickAccordionItem> : <>
              {latest?.entities?.length === 1 && <Form.Check
                hidden={true}
                type='checkbox'
                id={`Entity_${latest.entities[0].EntityID}`}
                name={`Entity_${latest.entities[0].EntityID}`}
                value={latest.entities[0].EntityID}
                defaultChecked={true}
              />}
            </>}
            <QuickAccordionItem eventKey={'sub-perms'} title='Subscription permissions'>
              <Row>
                <Col xs={12} sm={6}>
                  {makeCheck('CanTemplate', 'Edit document templates')}
                </Col>
                <Col xs={12} sm={6}>
                  {makeCheck('OwnForms', 'View only documents they create')}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  {makeCheck('IsMarketingTemplateEditor', 'Edit marketing templates')}
                </Col>
                <Col xs={12} sm={6}>
                  {makeCheck('OwnTemplates', 'View only templates they create')}
                </Col>
              </Row>
            </QuickAccordionItem>
            {flagContractors && <QuickAccordionItem
              eventKey={'sub-roles'}
              title='Subscription roles'
              afterTitle={<ValidationErrorLabel errors={getValidationErrorsMulti(
                ['data', 'AgentABN']
              )} message='Validation errors found.' />}
            >
              <p>A contractor is a non-employee operating within the reaforms subscription. Contractors can be
                white-labelled so their legal documents carry the contractor's agency details and branding:</p>
              <Row className='mt-2'>
                <Col>
                  {makeCheck('IsContractor', 'This user is a contractor')}
                </Col>
              </Row>
              {Boolean(latest.data.IsContractor) && <>
                <Row>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Contractor\'s Company Name', 'text', 'AgentCompanyName')}
                  </Col>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Contractor\'s Trading Name', 'text', 'AgentTradingName')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Contractor\'s ABN', 'text', 'AgentABN')}
                  </Col>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Contractor\'s RLA/Agent Registration', 'text', 'AgentRLA')}
                  </Col>
                </Row>
                <Row>
                  <Col className='mt-2'>
                    {makeHidden('GnafID')}
                    {makeHidden('AgentAddress1')}
                    {makeHidden('AgentAddress2')}
                    {makeHidden('AgentSuburb')}
                    {makeHidden('AgentState')}
                    {makeHidden('AgentPostCode')}
                    <BareAddressSelector
                      id={'AgentAddressFull'}
                      label={'Agent Address'}
                      defaultValue={[latest.data.AgentAddress1, latest.data.AgentAddress2].join(' ').trim()}
                      onAddressSelect={(value, partsRaw, gnaf) => {
                        const parseResult = AddressPartsSchema.safeParse(partsRaw);
                        if (parseResult.success) {
                          const parts = parseResult.data;
                          setLatestData(cur => {
                            if (!cur) return cur;
                            const clone = structuredClone(cur);
                            clone.data.GnafID = gnaf || '';
                            clone.data.AgentAddress1 = value;
                            clone.data.AgentAddress2 = '';
                            clone.data.AgentSuburb = parts.Suburb || '';
                            clone.data.AgentState = parts.State || '';
                            clone.data.AgentPostCode = parts.Postcode || '';
                            return clone;
                          });
                        } else {
                          setLatestData(cur => {
                            if (!cur) return cur;
                            const clone = structuredClone(cur);
                            clone.data.GnafID = gnaf || '';
                            clone.data.AgentAddress1 = value;
                            clone.data.AgentAddress2 = '';
                            clone.data.AgentSuburb = '';
                            clone.data.AgentState = '';
                            clone.data.AgentPostCode = '';
                            return clone;
                          });
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    {makeCheck('AgentShowLogo', 'Show Agency logo on documents')}
                  </Col>
                </Row>
              </>}
            </QuickAccordionItem>}
            {flagGreatforms && <QuickAccordionItem
              eventKey={'gf-roles'}
              title='Greatforms roles'
              afterTitle={<ValidationErrorLabel errors={getValidationErrorsMulti(
                ['data', 'AssocAgentABN']
              )} message='Validation errors found.' />}
            >
              <p>A Salesperson can sign legal documents on behalf of the Agency in sales transactions:</p>
              {makeCheck('IsSalesperson', 'This user is a Salesperson')}
              {allPropertyCount > 0 && !latest?.data?.IsSalesperson && <Alert variant='warning'>
                <p className='mb-0'>{presentableAgentName} is the salesperson for {allPropertyCount === 1 ? '1 property folder' : `${allPropertyCount} property folders`}.</p>
                <p className='mb-0'>Saving this change will apply the following changes to these property folders:</p>
                <ul>
                  <li>Remove {presentableAgentName} and their team's access.</li>
                  {allActiveCount > 0 && <li>Void {presentableAgentName}'s active signing sessions.</li>}
                </ul>
                <p className='mb-0'>It is advisable to reassign active property folders to a different salesperson so that signing may continue.</p>
              </Alert>}
              <hr/>
              <p>A Property Manager can sign legal documents on behalf of the Agency in property management
                transactions:</p>
              {makeCheck('IsPropertyManager', 'This user is a property manager')}
              <hr/>
              <p>An Auctioneer can sign legal documents with the authority of a Licensed Auctioneer:</p>
              {makeCheck('IsAuctioneer', 'This user is a Licensed Auctioneer')}
              <hr/>
              <p>An Associated Agent operates an independent business within a licensed Agency:</p>
              {makeCheck('IsAssocAgent', 'This user is an Associated Agent')}
              {Boolean(latest.data.IsAssocAgent) && <>
                <Row>
                  <Col className='mt-2'>
                    {makeText('Associated Agent\'s Company Name', 'text', 'AssocAgentCompanyName')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Associated Agent\'s ABN', 'text', 'AssocAgentABN')}
                  </Col>
                  <Col xs={12} sm={6} className='mt-2'>
                    {makeText('Associated Agent\'s RLA/Agent Registration', 'text', 'AssocAgentRLA')}
                  </Col>
                </Row>
              </>}
            </QuickAccordionItem>}
            {flagGreatforms && <QuickAccordionItem eventKey={'gf-preferences'} title='Greatforms preferences'>
              <h5>Offline access</h5>
              <p>Greatforms supports many ways or working, including the preparation of legal documents without an
                Internet connection. When offline is enabled, a users browser will store changes made by the user, and
                synchronise them to the Greatforms servers once an Internet connection is available.</p>
              <p>Some features do not work offline including:</p>
              <ul>
                <li>Land Services SA integration.</li>
                <li>Remote signing.</li>
                <li>On screen signing with SMS verification.</li>
              </ul>
              {makeCheck('OfflineProperties', 'Enable access to Greatforms when offline')}
            </QuickAccordionItem>}
            {(flagGreatformsSharing || flagSignAnythingSharing) && <QuickAccordionItem
              eventKey={'gf-sharing'}
              title={flagSignAnythingSharing && flagGreatformsSharing
                ? 'Greatforms and SignAnything sharing'
                : flagSignAnythingSharing
                  ? 'SignAnything sharing'
                  : 'Greatforms sharing' }
            >
              <h6>Sharing</h6>
              {flagGreatformsSharing && <p>
                Greatforms property folders and documents are visible to everybody by default,
                and can be restricted to specific users and teams by the owner of the property folder.
              </p>}
              {flagSignAnythingSharing && <p>
                SignAnything envelopes are private to the uploader by default,
                and can be shared with specific users and teams by the owner.
              </p>}
              <Form.Check
                type='radio'
                id={'CanShareWithEveryoneOn'}
                name={'CanShareWithEveryone'}
                label={`${presentableAgentName} can share content with everybody in their agencies`}
                value={'1'}
                defaultChecked={latest.data.CanShareWithEveryone == 1}
                onChange={e => {
                  setLatestDataField('CanShareWithEveryone', 1);
                }}
              />
              <Form.Check
                type='radio'
                id={'CanShareWithEveryoneOff'}
                name={'CanShareWithEveryone'}
                label={latest.data.CanShareWithEveryone
                  ? `${presentableAgentName} can share content with their teams, as well as specific users and teams`
                  : `${presentableAgentName} can share content with their teams, as well as these specific users and teams:`}
                value={'0'}
                defaultChecked={!latest.data.CanShareWithEveryone}
                onChange={e => {
                  setLatestDataField('CanShareWithEveryone', 0);
                }}
              />
              {!latest.data.CanShareWithEveryone && <>
                <UserTeamSelector
                  name={'CanShareWith'}
                  defaultValue={latest.data.CanShareWith || []}
                  mode={{ type: 'general', entityIds: selectedEntityIds || [], agentId }}
                  onChange={values => {
                    setLatestDataField('CanShareWith', values.map(v => v.value));
                  }}
                />
              </>}
              {flagGreatformsSharing && Boolean(latest.data.IsSalesperson) && <>
                <hr/>
                <h6>Sales team members</h6>
                <p>When <em>{presentableAgentName}</em> is assigned as a Salesperson to a property, <em>{presentableAgentName}'s Sales Team</em> will be included.</p>
                <div className='d-flex flex-column gap-3'>
                  {selectedEntities?.map((entity) => {
                    const salesTeam = latest.salesTeams?.[entity.entityId];
                    const teamId = salesTeam?.id || 0;
                    const teamName = salesTeam?.name || `${presentableAgentName}'s Sales Team`;
                    const { headline } = getCompanyNamingLines(entity.companyName, entity.tradingName, entity.profileName);
                    return <div key={entity.entityId}>
                      <p className='mb-0'>
                        Sales team members for properties belonging to <em>{headline}</em>:
                      </p>
                      <UserTeamSelector
                        name={`Teams_1_${entity.entityId}`}
                        defaultValue={salesTeam?.items || []}
                        mode={{
                          type: 'add-to-team',
                          teamId,
                          entityId: entity.entityId,
                          teamType: TeamType.Sales,
                          addAutos: teamId === 0
                        }}
                        onChange={items => {
                          setLatestData(cur => {
                            if (!cur) return cur;

                            const clone = structuredClone(cur);
                            if (!clone.salesTeams) {
                              clone.salesTeams = {};
                            }
                            if (clone.salesTeams[entity.entityId]) {
                              clone.salesTeams[entity.entityId].items = items;
                            } else {
                              clone.salesTeams[entity.entityId] = {
                                id: teamId,
                                name: teamName,
                                items: items
                              };
                            }

                            return clone;
                          });
                        }}
                      />
                    </div>;
                  })}
                </div>
              </>}
            </QuickAccordionItem>}
            {!flagGreatformsSharing && Boolean(latest.data.IsSalesperson) && <>
              {selectedEntities?.map((entity) => {
                const salesTeam = latest.salesTeams?.[entity.entityId];
                const teamId = salesTeam?.id || 0;
                const teamName = salesTeam?.name || `${presentableAgentName}'s Sales Team`;
                return <UserTeamSelector
                  key={entity.entityId}
                  hidden={true}
                  name={`Teams_1_${entity.entityId}`}
                  defaultValue={salesTeam?.items || []}
                  mode={{
                    type: 'add-to-team',
                    teamId,
                    entityId: entity.entityId,
                    teamType: TeamType.Sales,
                    addAutos: teamId === 0
                  }}
                  onChange={items => {
                    setLatestData(cur => {
                      if (!cur) return cur;

                      const clone = structuredClone(cur);
                      if (!clone.salesTeams) {
                        clone.salesTeams = {};
                      }
                      if (clone.salesTeams[entity.entityId]) {
                        clone.salesTeams[entity.entityId].items = items;
                      } else {
                        clone.salesTeams[entity.entityId] = {
                          id: teamId,
                          name: teamName,
                          items: items
                        };
                      }

                      return clone;
                    });
                  }}
                />;
              })}
            </>}
            {Boolean(latest.data.SignatureFile || latest.data.InitialsFile) &&
              <QuickAccordionItem eventKey={'stored'} title='Stored signature / initials'>
                <div className='d-flex flex-column gap-3'>
                  {latest.data.SignatureFile && <div>
                    {makeCheck('SignatureFileDelete', 'Remove signature')}
                    {latest.data.SignatureFileDelete != 1 && <>
                      <div style={{ height: '50px' }}>
                        <img src={latest.data.SignatureFile} style={{ height: '100%', objectFit: 'contain' }}/>
                      </div>
                      {latest.data.SignatureFileModifiedAt &&
                        <div>Modified: {latest.data.SignatureFileModifiedAt}</div>}
                    </>}
                    {latest.data.SignatureFileDelete == 1 && <span>
                    reaforms will prompt for a new signature during signing, if required.
                    </span>}
                  </div>}
                  {latest.data.InitialsFile && <div>
                    {makeCheck('InitialsFileDelete', 'Remove initials')}
                    {latest.data.InitialsFileDelete != 1 && <>
                      <div style={{ height: '50px' }}>
                        <img src={latest.data.InitialsFile} style={{ height: '100%', objectFit: 'contain' }}/>
                      </div>
                      {latest.data.InitialsFileModifiedAt && <div>Modified: {latest.data.InitialsFileModifiedAt}</div>}
                    </>}
                    {latest.data.InitialsFileDelete == 1 && <span>
                    reaforms will prompt for new initials during signing, if required.
                    </span>}
                  </div>}
                </div>
              </QuickAccordionItem>}
            {Boolean(showEcIntegration || showEpfIntegration) &&
              <QuickAccordionItem eventKey={'integration'} title='Integration preferences'>
                {showEcIntegration && <>
                  <h5>Eckermann Conveyancers</h5>
                  <p>Eckermann Conveyancers is South Australia's premiere conveyancer, and part of the same group of
                    companies that brings you Greatforms. Our conveyancers have extensive knowledge of the legal content
                    of reaforms, and are excellent partners to the agencies using Greatforms. </p>
                  <p>After signing documents, Greatforms can ask Vendors and Purchasers for consent to discuss their
                    conveyancing needs with Eckermann Conveyancers.</p>
                  <Row>
                    <Col sm={6}>
                      <FloatingLabel label='Promote Eckermann Conveyancers'>
                        <Form.Select
                          name='PromoteECUser'
                          defaultValue={getYesNoNoneOptionValue(latest.data.PromoteECUser)}
                          onChange={e => setLatestDataField('PromoteECUser', getYesNoNoneFieldValue(e.target.value))}
                        >
                          <option value='1'>Yes</option>
                          <option value=''>No preference</option>
                          <option value='0'>No</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                  </Row>
                </>}
                {showEpfIntegration && <>
                  <h5 className='mt-4'>Eckermann Property Forms</h5>
                  <p>Eckermann Property Forms has extensive integration with reaforms and Greatforms, making it easy for
                    you to order premium Form 1 preparation for your sales transactions. </p>
                  <h6>Methods of Service</h6>
                  <p>You can customise the information that our team will enter into the Form 1 under the Methods of
                    Service for cooling off notices when this user is selected as the Salesperson.</p>

                  <Row>
                    <Col xs={12} sm={6}>
                      {makeText('Fax or email address for notices', 'text', 'Form1ServiceFaxOrEmailUser')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} className='mt-2'>
                      {makeText('Address for notices', 'text', 'Form1ServiceAddressUser')}
                    </Col>
                    <Col xs={12} sm={6} className='mt-2 align-content-center'>
                      {makeCheck('Form1ServiceAddressIsRLAUser', 'This address is the RLA address')}
                    </Col>
                  </Row>
                </>}
              </QuickAccordionItem>}
          </Accordion>
        </Modal.Body>
        <Modal.Footer ref={footerRef} className={clsJn('sticky bg-white', footerIsStuck && 'stuck')} data-stick="bot">
          {errorMessage && <span className='text-danger'>{errorMessage}</span>}
          <Button disabled={saving} variant='outline-secondary' onClick={onClose}>Cancel</Button>
          <SpinnerButton type='submit' processing={saving} disabled={unchanged || !valid}>Save</SpinnerButton>
        </Modal.Footer>
      </Form> : <div>Failed to load</div>}
  </Modal>;
}

function getYesNoNoneOptionValue(value?: string | NumericBool) {
  if (value == '1') return '1';
  if (value == '0') return '0';
  return '';
}

function getYesNoNoneFieldValue(value?: string) {
  switch (value) {
    case '1':
      return 1;
    case '0':
      return 0;
    default:
      return '';
  }
}

function QuickAccordionItem({ eventKey, title, children, afterTitle }: React.PropsWithChildren<{
  eventKey: string | number,
  title: string,
  afterTitle?: React.ReactNode
}>) {
  return <Accordion.Item eventKey={eventKey.toString()}>
    <Accordion.Header>
      <div className='w-100 me-2 d-flex flex-row justify-content-between align-items-baseline'>
        <strong>{title}</strong>{afterTitle}</div>
    </Accordion.Header>
    <Accordion.Body>{children}</Accordion.Body>
  </Accordion.Item>;
}

function countryToBnSubtype(country: string | undefined): string {
  switch (country?.toLowerCase()) {
    case 'australia':
      return 'abnacn';
    case 'new zealand':
      return 'nzbn';
    default:
      return '';
  }
}
