import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { Maybe } from '@property-folders/contract';
import { GetPropertyAccessPermissionsResult } from '@property-folders/contract/rest/property/access-permissions';
import { useEffect, useState } from 'react';

export function usePropertyAccessPermissions(
  propertyId: string | undefined,
  serverPending: boolean,
  deps: Array<unknown>
) {
  const [data, setData] = useState<Maybe<GetPropertyAccessPermissionsResult>>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (serverPending) return;
    setLoading(true);
  }, [serverPending]);

  useEffect(() => {
    if (!propertyId) return;
    const ac = new AbortController();
    WrappedFetch.json<GetPropertyAccessPermissionsResult>(LinkBuilder.restApi(`/properties/${propertyId}/access-permissions`), { signal: ac.signal })
      .then(res => {
        if (ac.signal.aborted) return;
        if (res?.issues?.length) console.warn(res.issues);
        setData(res);
      })
      .catch(err => console.error(err))
      .finally(() => {
        if (ac.signal.aborted) return;
        setLoading(false);
      });

    return () => {
      ac.abort();
    };
  }, [propertyId, ...deps]);

  return { data, loading };
}
