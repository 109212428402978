import { PendingRemoteSigningSessionPage, RemoteSigningSessionDataField } from '@property-folders/contract';
import { renderTextSimple } from '@property-folders/common/util/image';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { asPercentage } from './common';
import { Image } from 'react-bootstrap';

export function DataField({ page: { width: pageWidth, height: pageHeight }, field, timeZone }: { page: PendingRemoteSigningSessionPage, field: RemoteSigningSessionDataField, timeZone: string }) {
  const location = field.position;
  const asImage = renderTextSimple({
    text: field.type === 'ts-party' && field.value && field.value > 0 ? formatTimestamp(field.value, timeZone, false) : undefined,
    containingBox: field.position,
    fontSize: field.appearance?.fontSize
  });

  return <div
    style={{
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
      left: asPercentage(location.x, pageWidth),
      bottom: asPercentage(location.y, pageHeight),
      width: asPercentage(location.width, pageWidth),
      height: asPercentage(location.height, pageHeight)
    }}>
    <Image src={asImage} style={{ objectFit: 'contain' }} />
  </div>;
}
