import { FieldPosition, PendingRemoteSigningSessionPage } from '@property-folders/contract';
import { renderTextSimple } from '@property-folders/common/util/image';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { Image } from 'react-bootstrap';
import { asPercentage } from './common';

export function Timestamp(props: { page: PendingRemoteSigningSessionPage; location: FieldPosition; value?: number; timeZone: string, appearance?: { fontSize?: number }}) {
  const {
    location,
    page: {
      width: pageWidth,
      height: pageHeight
    },
    value,
    timeZone,
    appearance
  } = props;

  const asImage = renderTextSimple({
    text: value && value > 0 ? formatTimestamp(value, timeZone, false) : undefined,
    containingBox: location,
    fontSize: appearance?.fontSize ?? 12
  });

  return <div
    style={{
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      left: asPercentage(location.x, pageWidth),
      bottom: asPercentage(location.y, pageHeight),
      width: asPercentage(location.width, pageWidth),
      height: asPercentage(location.height, pageHeight)
    }}>
    <Image src={asImage} style={{ objectFit: 'contain' }} />
  </div>;
}
