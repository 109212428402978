import Select from 'react-select';
import { useMemo, useState } from 'react';
import { useBreakpointValue } from '../hooks/useBreakpointValue';
import { Maybe } from '@property-folders/contract';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { DocumentType } from '@property-folders/contract/rest/document';

type Option = { value: DocumentType | 'all', label: string };
function notExplicitlyFalse(value?: boolean) {
  return typeof value === 'undefined' || value;
}

export function SearchDocumentType({
  onChange
}: {
  onChange: (filter?: DocumentType) => void
}) {
  const { myFiles, newPropertyFolders } = useFeatureFlags();
  const myFilesEnabled = Boolean(newPropertyFolders && myFiles);
  const width = useBreakpointValue<string | number>({ lg: 180 }, '100%');
  const outerClass = useBreakpointValue({ lg: '' }, 'w-100');
  const options = useMemo<Option[]>(() => {
    if (!newPropertyFolders && !myFilesEnabled) return [];

    const result: Option[] = [
      { value: 'all', label: 'All types' },
      { value: DocumentType.Subscription, label: 'reaforms' }
    ];

    if (newPropertyFolders) result.push({ value: DocumentType.Property, label: 'Greatforms' });
    if (myFilesEnabled) result.push({ value: DocumentType.MyFile, label: 'SignAnything' });

    return result;
  }, [newPropertyFolders, myFilesEnabled]);
  const [value, setValue] = useState<Maybe<DocumentType>>(undefined);

  if (!options.length) {
    return <></>;
  }

  const selected = options.find(o => o.value === value) || options[0];

  return <Select
    className={outerClass}
    options={options}
    isClearable={false}
    isSearchable={false}
    styles={{
      container: (baseStyles) => ({
        ...baseStyles,
        marginLeft: width === '100%' ? '0 !important' : undefined,
        height: 42.25 // height of input
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        background: 'var(--bs-body-bg)',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgb(var(--bs-secondary-rgb))',
        borderRadius: 0,
        height: '100%',
        fontSize: '1.25rem',
        width
      })
    }}
    value={selected}
    onChange={c => {
      if (c == null) return;
      if (c.value === 'all') {
        setValue(undefined);
        onChange(undefined);
      } else {
        setValue(c.value);
        onChange(c.value);
      }
    }}
  />;
}
