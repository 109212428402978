import {
  Annexure,
  ContentType,
  FileRef,
  FormCode,
  FormInstance,
  TransactionMetaData,
  UploadType
} from '@property-folders/contract';
import { FormUtil } from '@property-folders/common/util/form';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { Maybe } from '@property-folders/common/types/Utility';
import _ from 'lodash';
import { DataGeneration } from '@property-folders/common/util/dataExtractTypes';
import { LineageContext } from '../../hooks/useVariation';
import { PathType } from '@property-folders/contract/yjs-schema/model';

import {
  getHierarchyNode,
  getValueByPath,
  normalisePathToStr,
  normalisePathToStrArray
} from '@property-folders/common/util/pathHandling';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { useSelector } from 'react-redux';
import { preservedAnnexureOrderHistoryList } from '@property-folders/common/util/form/annexure';
import { createAnnexureFromUploadedDocument, deleteAnnexure } from '@property-folders/common/util/annexure';
import { ensureFormStatesInstances, FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormContext } from '../../context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormUserInteractionContext } from '../../context/FormUserInteractionContext';
import '../../dragged-components/form/ProposedLotAnnexure.scss';
import { UploadedDocumentsSelector } from '../../dragged-components/UploadedDocumentsSelector';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { materialisePropertyData, materialisePropertyMetadata } from '@property-folders/common/yjs-schema/property';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';

/**
 *
 * @param param0.parentPath Path to proposedLot object parent (as applicable)
 * @param param0.myPath Path to proposedLot object
 * @returns
 */
export function ComparableSalesAnnexure({ parentPath = ['comparableSales'], myPath = ['annexureRef'] }: {
  parentPath?: PathType
  myPath?: PathType
}) {
  const { transactionRootKey, docName, ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);

  const fullPathSegs = normalisePathToStr([...normalisePathToStrArray(parentPath??[]), ...normalisePathToStrArray(myPath??[])]);

  const formFamilyCode = FormTypes[formCode].formFamily;
  const familyCodePath = `family_${formFamilyCode}`;

  const {
    value: annexureRef,
    handleUpdate: updateAnnexureRef,
    handleRemove: removeAnnexureRef,
    fullPath,
    transactionRootKey: annexureRefKey
  } = useTransactionField<string>({
    parentPath: fullPathSegs,
    myPath: familyCodePath
  });
  const { value: uploadedDocs } = useTransactionField<Maybe<FormInstance[]>>({
    parentPath: `formStates.${FormCode.UploadedDocument}.instances`,
    bindToMetaKey: true,
    ydocForceKey: PropertyRootKey.Meta
  });

  const { snapshotHistory } = useContext(LineageContext);
  const formParentPath = FormUtil.getFormPath(formCode, formId) || '';
  const annexuresPath = `${formParentPath}.annexures`;
  const { updateDraft: updateAnnexures } = useYdocBinder<Annexure[]>({ path: annexuresPath, bindToMetaKey: true });

  const annexuresForm = useLightweightTransaction<Annexure[]>({ parentPath: formParentPath, myPath: 'annexures', bindToMetaKey: true })?.value??[];

  const { annexures, usingPrevious, orderList } = useMemo(()=>{
    return preservedAnnexureOrderHistoryList(annexuresForm, snapshotHistory);
  }, [annexuresForm, snapshotHistory]);

  const shouldShowFullValidationError = useContext(FormUserInteractionContext).userShouldSeeAllValidation;
  const fullValidationError = useSelector((state: any) => {
    const baseTree = state?.validation?.errorPaths?.[docName ?? '']?.[transactionRootKey ?? '']?.[formCode];
    const result = !!getHierarchyNode(normalisePathToStrArray(fullPath), baseTree);
    return !!result;
  });

  const flagValidationError = shouldShowFullValidationError && fullValidationError;

  const proposalAnnexureCheck = _.find(annexures, annex=>annex.id === annexureRef);
  const proposalAnnexure = proposalAnnexureCheck && typeof proposalAnnexureCheck === 'object' && proposalAnnexureCheck.state !== DataGeneration.Removed ? proposalAnnexureCheck : null;

  // if there is an annexure but not an uploaded file, fix it
  useEffect(() => {
    if (!ydoc) return;

    const data = materialisePropertyData(ydoc, transactionRootKey);
    const meta = materialisePropertyMetadata(ydoc, transactionMetaRootKey);
    const annexureRefPathSegs = normalisePathToStrArray([
      ...normalisePathToStrArray(parentPath??[]),
      ...normalisePathToStrArray(myPath??[]),
      familyCodePath
    ]);
    const annexureFileId = getValueByPath(annexureRefPathSegs, data, true);

    if (typeof annexureFileId !== 'string') {
      return;
    }

    const info = FormUtil.getFormState(formCode, formId, meta)?.annexures?.find(a => a.id === annexureFileId);
    if (!info) {
      return;
    }

    const upload = typeof annexureFileId === 'string'
      ? FormUtil.getFormState(FormCode.UploadedDocument, annexureFileId, meta)
      : undefined;

    if (upload) {
      return;
    }

    const newInstance: FormInstance = {
      id: info.id,
      formCode: FormCode.UploadedDocument,
      created: Date.now(),
      modified: Date.now(),
      upload: {
        v: 2,
        name: 'name' in info && info.name
          ? info.name
          : 'Comparable Sales',
        files: [{
          id: info.id,
          contentType: 'contentType' in info && info.contentType
            ? info.contentType
            : ContentType.Pdf,
          order: 0,
          name: 'name' in info && info.name
            ? info.name
            : 'Comparable Sales'
        }]
      }
    };

    applyMigrationsV2<TransactionMetaData>({
      doc: ydoc,
      docKey: PropertyRootKey.Meta.toString(),
      typeName: 'Property',
      migrations: [{
        name: 'add missing uploaded document',
        fn: state => {
          const instances = ensureFormStatesInstances(state, FormCode.UploadedDocument);
          if (instances.some(i => i.id === newInstance.id)) return;

          instances.push(newInstance);
        }
      }]
    });
  }, [ydoc, transactionMetaRootKey, transactionRootKey]);

  const selectedRefs = useMemo<FileRef[]>(() => {
    if (!proposalAnnexure?.data) return [];
    const data = proposalAnnexure.data;
    const { id } = data;
    return [{
      id,
      contentType: 'contentType' in data && data.contentType ? data.contentType : ContentType.Pdf
    }];
  }, [proposalAnnexure?.id]);

  const handleSelectionChange = useCallback((refs: FileRef[]) => {
    const ref = refs.at(0);
    if (ref) {
      // add/change annexure
      createAnnexureFromUploadedDocument({
        file: ref,
        name: 'Comparable Sales',
        uploadType: UploadType.ComparableSales,
        updateAnnexures,
        replace: proposalAnnexure?.id,
        managed: true,
        binding: {
          path: fullPath,
          root: annexureRefKey
        },
        uploadTypeUnique: true,
        orderList: orderList || []
      });
      updateAnnexureRef(ref.id, true);
    } else if (proposalAnnexure?.id) {
      // remove annexure
      const annexure = annexures.find(x => x.id === proposalAnnexure.id);
      if (annexure) {
        deleteAnnexure(annexure.data, usingPrevious, annexures, updateAnnexures);
      }
      removeAnnexureRef();
    }
  }, [updateAnnexureRef, removeAnnexureRef, updateAnnexures, proposalAnnexure?.id, annexures]);

  return <div className='flex-grow-1'>
    <UploadedDocumentsSelector
      selectedFileRefs={selectedRefs}
      onSelect={handleSelectionChange}
      multi={false}
      uploadTypeFilter={UploadType.ComparableSales}
      label={'Select or upload a comparable sale'}
      overlayLabel={'Drop comparable sale here'}
      error={flagValidationError ? 'A comparable sale is required' : undefined}
      inputClassName={composeErrorPathClassName(fullPath, undefined)}
    />
  </div>;
}
