import { DivisionType, GstFreeReason, SaleMethod } from './enum';
import { EmailLinkType, WorkflowActionPartyType } from '../yjs-schema/entity-settings';
import { CoverSheetMode, UploadType } from './index';

export const lotOptions = {
  'allotment': 'Allotment',
  'section': 'Section',
  'lot': 'Lot',
  'unit': 'Unit'
};

export const planOptions = {
  'deposited': 'Deposited',
  'filed': 'Filed',
  'community': 'Community',
  'strata': 'Strata'
};

export const planOptionsFull = {
  'DEPOSITED PLAN': 'Deposited',
  'FILED PLAN': 'Filed',
  'PRIMARY COMMUNITY PLAN': 'Primary Community',
  'STRATA PLAN': 'Strata',
  'SECONDARY COMMUNITY STRATA PLAN': 'Secondary Community Strata',
  'TERTIARY COMMUNITY STRATA PLAN': 'Tertiary Community Strata',
  'HUNDRED': 'Hundred',
  'TOWNSHIP': 'Township'
};

export const titleTypeOptions: Record<string, string> = {
  'CT': 'Certificate of Title',
  'CR': 'Crown Record',
  'CL': 'Crown Lease'
};

export const saleMethodOptions: Record<SaleMethod, string> = {
  [SaleMethod.Auction]: 'Auction',
  [SaleMethod.PrivateTreaty]: 'Private treaty',
  [SaleMethod.OffMarket]: 'Off-market',
  [SaleMethod.ExpressionOfInterest]: 'Expression of interest',
  [SaleMethod.Other]: 'Other'
};

export const emailRecipientOptions: Record<WorkflowActionPartyType, string> = {
  'purchaser': 'Purchasers',
  'vendor': 'Vendors',
  'agent': 'Sales Representatives'
};

export const emailLinkTypeOptions: Record<EmailLinkType, string> = {
  [EmailLinkType.External]: 'Include link to external website',
  [EmailLinkType.File]: 'Include link to document'
};

export const depositPayAtOpts = {
  'afterCooling': 'on the next Business Day following the expiration of the cooling-off period;',
  'immediate': 'immediately after auction;',
  'coolingWaived': 'if no cooling-off rights, or cooling-off rights are waived, then on signing this Contract; or',
  'other': 'other'
};

export const gstFreeReasonOpts = {
  [GstFreeReason.GoingConcern]: 'the supply of a going concern; or',
  [GstFreeReason.FarmlandForFarmingBusiness]: 'the supply of farmland supplied for a farming business'
};

export const uploadTypeOpts: Record<UploadType, string> = {
  [UploadType.PropertyPlan]: 'Property Plan',
  [UploadType.FormR3]: 'Form R3',
  [UploadType.ComparableSales]: 'Comparable Sales',
  [UploadType.TitleDetails]: 'Land Services SA - Title Details Report',
  [UploadType.DwellingPlan]: 'Dwelling Plans',
  [UploadType.DwellingSpecification]: 'Dwelling Specifications'
};

export const divisionTypeOptions: Record<DivisionType, string> = {
  [DivisionType.Community]: 'Community Division',
  [DivisionType.Torrens]: 'Torrens Division'
};

export const twelveMonthsAfterContractDate = { 'true': '12 months after Contract Date' };

export const coverSheetModeOptions: Record<CoverSheetMode, string> = {
  [CoverSheetMode.Standard]: 'Standard',
  [CoverSheetMode.Custom]: 'Custom',
  [CoverSheetMode.None]: 'None'
};
