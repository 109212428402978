import {
  FormCode,
  FormCodeUnion,
  LookupPropertiesResult,
  LookupPropertiesResultItem
} from '@property-folders/contract';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { OfflineProperties } from '@property-folders/common/offline/offlineProperties';
import { FormUtil } from '@property-folders/common/util/form';
import { Properties } from '@property-folders/common/client-api/properties';

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { castOptions } from '~/components/create-form/CreateSubscriptionFormPane';
import { Form } from 'react-bootstrap';

const searchResultsLimit = 5;
export function SelectPropertyFolder({
  formCode,
  label,
  placeholder,
  defaultSearch,
  onSelect
}: {
  formCode?: FormCodeUnion,
  label?: string,
  placeholder: string,
  defaultSearch?: string,
  onSelect: (items: LookupPropertiesResultItem[]) => void
}) {
  const [idPrefix] = useState(v4());
  const online = useOnline();

  const [propertyQuery, setPropertyQuery] = useState(defaultSearch||'');
  const [propertyLoading, setPropertyLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<LookupPropertiesResultItem[]>([]);
  const [properties, setProperties] = useState<LookupPropertiesResult>({ items: [] });

  useEffect(() => {
    if (!online) {
      setPropertyLoading(true);
      const ac = new AbortController();
      OfflineProperties.search(propertyQuery)
        .then(data => {
          if (ac.signal.aborted) return;

          setProperties({
            items: data.slice(0,searchResultsLimit).map<LookupPropertiesResultItem>(op => {
              return {
                propertyId: op.id,
                headline: op.headline,
                saleAddresses: [op.address],
                vendors: op.vendors || [],
                addRestriction: formCode
                  ? FormUtil.getFormRestriction(op.meta?.formStates || {}, formCode, formCode === FormCode.RSC_ContractOfSale)
                  : undefined,
                created: op.created || -1
              };
            })
          });
          setSelectedProperty(prev =>
            prev.filter(prevItem => data.find(x => x.id === prevItem.propertyId)));
        })
        .catch(console.error)
        .finally(() => setPropertyLoading(false));
      return () => {
        ac.abort();
      };
    }

    const { ac, results } = Properties.lookup({
      searchTerm: propertyQuery,
      formCode,
      sublineageIntent: formCode === FormCode.RSC_ContractOfSale,
      limit: searchResultsLimit
    });

    results
      .then(data => {
        if (!data) return;
        if (ac.signal.aborted) return;
        setProperties(data);
      })
      .catch(console.error)
      .finally(() => setPropertyLoading(false));

    return () => {
      setPropertyLoading(false);
      ac.abort();
    };
  }, [propertyQuery, online, defaultSearch]);

  const searchFunc = useCallback((query: string) => {
    setPropertyQuery(query);
  }, []);

  return <AsyncTypeahead
    id={`${idPrefix}-select-property-folder`}
    labelKey='headline'
    defaultInputValue={defaultSearch}
    filterBy={() => { return true; }}
    placeholder={placeholder}
    options={properties.items}
    onChange={x => {
      const items = castOptions<LookupPropertiesResultItem>(x);
      setSelectedProperty(items);
      onSelect(items);
    }}
    selected={selectedProperty}
    isLoading={propertyLoading}
    onSearch={searchFunc}
    minLength={0}
    maxResults={searchResultsLimit}
    defaultOpen={!!defaultSearch}
    emptyLabel='No matching Property Folder found.'
    renderInput={({ inputRef, referenceElementRef, value, ...inputProps }) => (
      <>
        {label && <Form.Label htmlFor={`${idPrefix}-select-property-folder`} className='fs-5'>{label}</Form.Label>}
        <Form.Control
          value={value as string | string[] | number | undefined}
          {...inputProps}
          ref={(node: any) => {
            inputRef(node);
            referenceElementRef(node);
          }}
          tabIndex={1}
          size='lg'
        />
      </>
    )}
    renderMenuItemChildren={(option) => {
      const asResult = option as LookupPropertiesResultItem;
      const addresses = asResult.saleAddresses.join(', ');
      const vendors = asResult.vendors.join(', ');
      return <div>
        <div className='fw-bold'>{asResult.headline}</div>
        <div className='text-truncate' title={addresses}><small>{addresses}</small></div>
        <div className='text-truncate' title={vendors}><small>{vendors}</small></div>
      </div>;
    }}
  />;
}
