import { Navigate, useNavigate } from 'react-router-dom';
import * as Y from 'yjs';
import * as awarenessProtocol from 'y-protocols/awareness';
import { Awareness } from 'y-protocols/awareness';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import {
  AwarenessData,
  FormInstance,
  FormSigningState,
  MaterialisedPropertyData,
  SigningParty,
  SigningPartySourceType,
  TransactionMetaData
} from '@property-folders/contract';
import { FormUtil } from '@property-folders/common/util/form';
import { YFormUtil } from '@property-folders/common/util/yform';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SigningProcessShim } from '@property-folders/components/dragged-components/signing/SigningProcess';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { generateHeadlineFromMaterialisedData, getDocumentName } from '@property-folders/common/yjs-schema/property';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { NonSalespersonHostedSigningInstructionWrapper } from '@property-folders/components/dragged-components/signing/HostedSigning';
import { initialPresence } from '~/pages/TransactionHomePage';
import { RoomProvider } from '@y-presence/react';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { FileSync } from '@property-folders/common/offline/fileSync';

import { YjsDocContextType } from '@property-folders/common/types/YjsDocContextType';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { PropertyRootKey, SignerProxyType } from '@property-folders/contract/yjs-schema/property';
import { DocumentLoading } from '@property-folders/components/display/form/DocumentLoading';

export function SigningPage() {
  const data = useReactRouterData();

  const transId = data.transId as string;
  const formId = data.formId as string;
  const partyId = data.partyId as string;
  const ydoc = data.ydoc as Y.Doc;
  const awareness = data.awareness as Awareness;

  const [_, setObserveTriggerEvent] = useState<number>(0);

  useEffect(()=>{
    // because the loader is not reactive, we don't get any rerenders once the ydoc has actually
    // loaded the data. As such, we need to observe it ourselves to look for something indicating
    // the ydoc has loaded. I think 'id' is a good choice, as it should exist on every valid
    // property folder, and should never change in a pf
    if (!ydoc) return;
    const rootMap = ydoc.getMap<MaterialisedPropertyData>(PropertyRootKey.Data);
    const observerFunc = (event: Y.YMapEvent<MaterialisedPropertyData> ) => {
      if (event.keysChanged.has('id')) {
        setObserveTriggerEvent(ps=>ps+1);
      }
    };
    rootMap.observe(observerFunc);
    return ()=>{rootMap.unobserve(observerFunc);};
  }, [ydoc]);

  const { dataRootKey, metaRootKey, instance: { formCode } } = YFormUtil.getFormLocationFromId(formId, ydoc)??{ instance: {} };

  const {
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, metaRootKey);
  // Even though we already got the instance above, this binding may rerender the page on changes in
  // meta? I'm not actually sure because we're not referencing bindState
  const meta = metaBinder?.get();
  const instance = formCode
    ? FormUtil.getFormState(formCode, formId, meta)
    : undefined;

  const {
    binder: dataBinder
  } = useImmerYjs<MaterialisedPropertyData>(ydoc, dataRootKey);
  const propertyData = dataBinder?.get();

  const staticProviderValue: YjsDocContextType = useMemo(() => ({
    ydoc,
    awareness,
    docName: transId,
    transactionRootKey: dataRootKey ?? PropertyRootKey.Data, // Fallback values
    transactionMetaRootKey: metaRootKey ?? PropertyRootKey.Meta,
    clearDeclareDebounce: () => { /**/ },
    declareDebounce: () => { /**/ }
  }), [ydoc, transId, awareness]);
  const documentPath = propertyData && instance
    ? LinkBuilder.documentPath({
      id: propertyData.id,
      nicetext: generateHeadlineFromMaterialisedData(propertyData)
    },  {
      id: instance.id,
      nicetext: getDocumentName(instance.formCode, instance)
    }, { isSubscriptionForm: !!instance.subscription?.documentId, folderType: meta?.folderType })
    : undefined;

  return ydoc && awareness && formCode && documentPath
    ? <YjsDocContext.Provider value={staticProviderValue}>
      <RoomProvider<AwarenessData> awareness={awareness ?? new awarenessProtocol.Awareness(ydoc)} initialPresence={initialPresence}>
        <SigningPageInner
          transactionId={transId}
          formId={formId}
          formCode={formCode}
          partyId={partyId}
          documentPath={documentPath}
        />
      </RoomProvider>
    </YjsDocContext.Provider>
    : <DocumentLoading loadFailed={false} />;
}

export function SigningPageInner({
  transactionId,
  formId,
  formCode,
  partyId,
  documentPath
}: {
  transactionId: string,
  formId: string,
  formCode: string,
  partyId: string,
  documentPath: string
}) {
  const navigate = useNavigate();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { instance: fileSync } = useContext(FileSyncContext);
  const parentPath = FormUtil.getFormPath(formCode, formId);
  const {
    value: formInstance,
    fullPath: formPath
  } = useLightweightTransaction<FormInstance>({
    parentPath,
    myPath: '',
    bindToMetaKey: true
  });
  const {
    value: signingParty
  } = useLightweightTransaction<SigningParty>({
    parentPath: formPath,
    myPath: `signing.parties.[${partyId}]`,
    bindToMetaKey: true
  });

  const proxySigningReaformsUsers = [SignerProxyType.Auctioneer, SignerProxyType.Salesperson].includes(signingParty?.proxyAuthority);
  const currentParty = proxySigningReaformsUsers ? signingParty?.proxyLinkedId : signingParty?.snapshot?.linkedSalespersonId;

  const isPartySalesperson = proxySigningReaformsUsers || signingParty?.source.type === SigningPartySourceType.Salesperson;
  const isPartyCurrentSalesperson = AuthApi.useIsCurrentSessionSalesperson(currentParty);
  const [signingAlreadyDone, setSigningAlreadyDone] = useState(false);

  useEffect(() => {
    if (!formInstance?.signing?.session?.fields) {
      return;
    }

    const partyFields = (formInstance.signing.session.fields || []).filter(f => f.partyId === partyId);
    if (!partyFields.every(f => !!f.timestamp)) {
      return;
    }
    setSigningAlreadyDone(true);
  }, [!!formInstance?.signing?.session?.fields]);

  // we want to be particular about it being false. undefined could mean it's still loading.
  if ((isPartySalesperson && isPartyCurrentSalesperson === false) || signingAlreadyDone) {
    return <Navigate to={documentPath} />;
  }

  if (!(signingParty && formInstance?.signing)) {
    // still loading
    return <div>loading...</div>;
  }

  const partyIsCurrentUser = Boolean((signingParty?.source?.agencySalesPersonId && signingParty.source.agencySalesPersonId === sessionInfo?.agentId) ||
    signingParty?.snapshot?.linkedSalespersonId && signingParty.snapshot.linkedSalespersonId === sessionInfo?.agentId);

  switch (formInstance?.signing?.state) {
    case FormSigningState.OutForSigning:
    case FormSigningState.OutForSigningPendingUpload:
    case FormSigningState.OutForSigningPendingServerProcessing:
    case FormSigningState.Signed:
    case FormSigningState.SignedPendingUpload:
    case FormSigningState.SignedPendingDistribution:
      return isPartySalesperson || partyIsCurrentUser
        ? <SigningProcessShim
          partyId={partyId}
          formPath={formPath}
          formId={formId}
          formCode={formCode}
          propertyId={transactionId}
          onSigningComplete={() => {
            FileSync.triggerSync(fileSync);
            navigate(documentPath);
          }}
          onFailure={() => navigate(documentPath)}/>
        : <NonSalespersonHostedSigningInstructionWrapper
          partyId={partyId}
          formPath={formPath}
          formId={formId}
          formCode={formCode}
          propertyId={transactionId}
          onExit={() => {
            FileSync.triggerSync(fileSync);
            navigate(documentPath);
          }}
          onFailure={() => navigate(documentPath)}/>;
    case FormSigningState.Configuring:
    case FormSigningState.None:
    default:
      return <Navigate to={documentPath} />;
  }
}

