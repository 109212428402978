import {
  ContentType,
  FormCodeUnion,
  FormInstance,
  MaterialisedPropertyData, Maybe
} from '@property-folders/contract';
import { useLightweightTransaction } from './useTransactionField';
import { FormUtil } from '@property-folders/common/util/form';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { useEffect, useState } from 'react';
import { applyPdfChanges, injectCustomFields } from '@property-folders/common/util/pdf';
import { getUploadAsV2 } from '@property-folders/common/yjs-schema/property';
import { FileStorage, FileType } from '@property-folders/common/offline/fileStorage';
import { useLiveQuery } from 'dexie-react-hooks';
import { usePdfWorker } from './usePdfWorker';

export function useFormFileRef(formId?: string, formCode?: FormCodeUnion, enabled?: boolean) {
  // load form instance details: upload file, custom fields, completed signing file(?)
  const parentPath = formId
    ? FormUtil.getFileFormPath({ id: formId, code: formCode })
    : undefined;
  const { value: form } = useLightweightTransaction<FormInstance>({
    parentPath,
    bindToMetaKey: true,
    // always in the root, even if the requesting document is in a sublineage
    ydocForceKey: PropertyRootKey.Meta
  });
  // use data from the current sublineage
  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ parentPath: '' });

  const upload = getUploadAsV2(form?.upload, form?.created);
  const files = enabled ? upload?.files?.map(f => ({ id: f.id, contentType: f.contentType })) : undefined;
  const contentType = upload?.files?.at(0)?.contentType ?? ContentType.Pdf;
  const buster = JSON.stringify(files);

  const [resultFile, setResultFile] = useState<Maybe<Blob>>(undefined);
  const [status, setStatus] = useState<'loading' | 'ready' | 'none' | 'error'>(formId ? 'loading' : 'none');

  useEffect(() => {
    if (!formId) {
      setResultFile(undefined);
      setStatus('none');
    }
  }, [formId]);

  useEffect(() => {
    if (!enabled) return;
    if (!files?.length) return;
    (async () => {
      try {
        for (const file of files) {
          const result = await FileStorage.read(file.id);
          if (result) {
            return;
          }
          await FileStorage.queueDownload(file.id, FileType.PropertyFile, file.contentType, {});
        }
      } catch ( err: unknown) {
        console.error(err);
      }
    })();
  }, [enabled, buster]);

  const worker = usePdfWorker();
  const baseFileData = useLiveQuery(async () => {
    if (!enabled) return undefined;
    if (!files?.length) return undefined;

    const pdfs: ArrayBuffer[] = [];
    for (const file of files) {
      const data = await FileStorage.readFileOnly(file.id);
      if (!data?.size) continue;
      if (file.contentType !== data.type as ContentType) continue;
      pdfs.push(await data.arrayBuffer());
    }

    if (pdfs.length === 0) return undefined;
    if (pdfs.length === 1) return pdfs[0];

    return await worker.stitchPdf({
      pdfs
    });
  }, [enabled, buster]);

  useEffect(() => {
    if (!enabled) return;
    if (!baseFileData) return;

    const ac = new AbortController();
    setStatus('loading');
    (async () => {
      if (!property) return;
      try {
        const result = await applyPdfChanges(
          baseFileData,
          [
            pdf => injectCustomFields({
              customFields: form?.signing?.customFields ?? [],
              pdf,
              fieldIdMap: new Map(),
              parties: form?.signing?.parties ?? [],
              property
            }),
            pdf => {
              pdf.getForm().flatten();
              return Promise.resolve();
            }
          ]
        );
        if (ac.signal.aborted) return;
        setResultFile(new Blob([result], { type: contentType }));
        setStatus('ready');
      } catch (err: unknown) {
        console.error(err);
        setStatus('error');
      }
    })();

    return () => {
      ac.abort();
    };
  }, [enabled, baseFileData, form?.signing?.customFields, property, contentType]);

  return { file: resultFile, status };
}
