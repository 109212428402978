import './MarkServedModal.scss';
import './Form1RequiredSection.scss';
import { Button, Form, Modal } from 'react-bootstrap';
import React, { ChangeEvent, useContext, useMemo, useState } from 'react';
import {
  archiveFormInstancesOfTypes,
  ensureFormStatesInstances,
  FormTypes,
  PropertyFormYjsDal,
  rsaaExecuted
} from '@property-folders/common/yjs-schema/property/form';
import { AsyncButton, SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import {
  AgentSessionInfoResult,
  ContentType,
  ExtraFormCode,
  FormCode,
  FormCodeUnion,
  ManifestType,
  MaterialisedPropertyData,
  Maybe,
  SaleMethod,
  SigningParty,
  TransactionMetaData
} from '@property-folders/contract';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useNavigate } from 'react-router-dom';
import { useImmerYjs } from '../../hooks/useImmerYjs';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { handleNewFormOrderInternal } from '@property-folders/common/util/handleNewFormOrder';
import { LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { useDropzone } from 'react-dropzone';
import { Icon } from '../Icon';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import clsJn from '@property-folders/common/util/classNameJoin';
import { v4 } from 'uuid';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { useStore } from 'react-redux';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { FileSyncContext } from '../../context/fileSyncContext';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { FormContext } from '../../context/FormContext';
import { AnyAction, Store } from 'redux';
import * as Y from 'yjs';
import { useEntity } from '../../hooks/useEntity';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { FormWarningDialog, setupWarnIntercept, ShowWarnParams } from '../Wizard/FormWarningDialog';
import { useQpdfWorker } from '../../hooks/useQpdfWorker';

type CreateForm1Action =
  | 'action-order'
  | 'action-create'
  | 'action-upload'
  | 'action-mark';
const mapActionToFormCode: Record<CreateForm1Action, FormCodeUnion> = {
  'action-order': SubscriptionFormCode.SAF001V2_Form1,
  'action-create': SubscriptionFormCode.SAF001V2_Form1,
  'action-upload': ExtraFormCode.Form1Upload,
  'action-mark': FormCode.Form1
};

export function Form1RequiredSection({
  formCode: formCodeRaw,
  propertyId,
  contractSigned,
  purchasers
} : {
  formCode?: FormCodeUnion,
  propertyId: string,
  contractSigned: boolean,
  purchasers: SigningParty[]
}) {
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const propertyData = dataBinder?.get();
  const { binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const propertyMeta = metaBinder?.get();
  const formDal = new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey);
  const navigate = useNavigate();
  const isAuction = propertyData?.sale?.saleMethod === SaleMethod.Auction;
  const [showUpload, setShowUpload] = useState(false);
  const localEntity = useEntity(propertyMeta?.entity?.id) as EntitySettingsEntity;
  const [action, setAction] = useState(localEntity?.epfAgencyId ? 'action-order' : 'action-create');
  const [showWarn, setShowWarn] = useState<Maybe<ShowWarnParams>>(undefined);
  const [processing, setProcessing] = useState(false);
  const { instance: fileSync } = useContext(FileSyncContext);
  const formCode = formCodeRaw || mapActionToFormCode[action];

  const handleOkBase = async () => {
    if (!ydoc || !propertyMeta?.entity?.id || !fileSync) return;

    // prefer to use the one that corresponds with the action, rather than the one passed in
    const createFormCode = mapActionToFormCode[action];
    switch (action) {
      case 'action-order': {
        const entityId = (await LegacyApi.getFulfillersForDocument(createFormCode as SubscriptionFormCode)).at(0)?.entityId;
        if (!entityId) return undefined;

        const newDoc = await handleNewFormOrderInternal(ydoc, formCode, entityId);
        if (!newDoc) return;
        navigateToForm(newDoc.id, formCode);
        break;
      }
      case 'action-create': {
        const newForm = await handleNewForm(ydoc, createFormCode, fileSync);
        if (!newForm) return;
        navigateToForm(newForm.formId, formCode);
        break;
      }
      case 'action-upload': {
        // upload to form1 form code, but mark with upload stuff
        setShowUpload(true);
        break;
      }
      case 'action-mark': {
        const newForm = await handleNewForm(ydoc, createFormCode, fileSync, { external: true });
        if (!newForm) return;
        // Form 1 stuff doesn't involve the proxy
        formDal.createRecipientsAndMarkManuallyServed(createFormCode, newForm.formId, purchasers?.map(purchaser => ({
          id: purchaser.id,
          name: purchaser.snapshot?.name||'',
          email: purchaser.snapshot?.email,
          address: purchaser.snapshot?.addressSingleLine || ''
        })));
        break;
      }
    }
  };

  const handleOk = setupWarnIntercept(() => {
    setProcessing(true);
    handleOkBase().finally(() => setProcessing(false));
  }, !rsaaExecuted(propertyMeta) ? (inner) => {
    setShowWarn({
      formCode: formCode as FormCodeUnion,
      actionName: 'create',
      title: 'Sales Agency Agreement not fully executed',
      message: 'The Form 1 should not be created until the Sales Agency Agreement has been fully executed',
      continueFn: () => {
        setShowWarn(undefined);
        inner();
      }
    });
  } : undefined);

  const buttonText = useMemo(()=>{
    switch (action) {
      case 'action-order': return 'Order';
      case 'action-create': return 'Create';
      case 'action-upload': return 'Upload';
      case 'action-mark': return 'Mark Served';
    }
  }, [action]);

  const handleChange = e => {
    setAction(e.target.id);
  };

  const navigateToForm = (instanceId: string, formCode: string) => {
    if (!propertyId) return;
    const formType = FormTypes[formCode];
    navigate(LinkBuilder.documentPath({ id: propertyId, nicetext: generateHeadlineFromMaterialisedData(propertyData) },
      { id: instanceId, nicetext: formType.label },!!formType.subscription));
  };

  return <div>
    <div>
      {contractSigned ? 'A' : 'Once this'} contract has been fully signed and executed between the Vendors and Purchasers{contractSigned?'.':','} {!isAuction ? `${contractSigned?'T':'t'}he Purchasers may terminate the agreement until the Cooling-Off period expires.` : ''}<br/>
      {!isAuction ? <div className={'mt-4'}>The Cooling-Off period will not begin until a <span className={'font-italic'}>Form 1 - Vendors Declaration</span> has been served on the Purchasers.</div> : ''}
    </div>
    <div className={'mt-4'}>
      <div className={'mb-2'}>Please select from one of the following options:</div>
      <div className={'ms-2'}>
        <Form.Check disabled={!localEntity?.epfAgencyId} type='radio' id={'action-order'} label='Order a Form 1 from Eckermann Property Forms' checked={action==='action-order'} onChange={handleChange} />
        <Form.Check type='radio' id={'action-create'} label='Create a Form 1 in reaforms, send to Vendors for signing, and serve' checked={action==='action-create'} onChange={handleChange} />
        <Form.Check type='radio' id={'action-upload'} label='Upload a Vendor signed Form 1 as a PDF' checked={action==='action-upload'} onChange={handleChange} />
        <Form.Check type='radio' id={'action-mark'} label='Mark Purchasers as served' checked={action==='action-mark'} onChange={handleChange} />
      </div>
    </div>
    <SpinnerButton onClick={handleOk} className={'float-end'} processing={processing}>{buttonText}</SpinnerButton>
    {showUpload && <Form1UploadModal onClose={(formCode, formId) => {
      if (formCode && formId) {
        navigateToForm(formId, formCode);
      }
      setShowUpload(false);
    }} />}
    {!!showWarn && <FormWarningDialog
      formCode={showWarn.formCode}
      title={showWarn.title}
      message={showWarn.message}
      actionName={showWarn.actionName}
      onCancel={() => setShowWarn(undefined)}
      onContinue={() => showWarn?.continueFn()}
    />}
  </div>;
}

function Form1UploadModal({
  onClose
}: {
  onClose: (formCode?: FormCodeUnion, formId?: string) => void
}) {
  const { ydoc, docName: propertyId, transactionRootKey: sourceSublineageId } = useContext(YjsDocContext);
  const { formId: sourceFormId, formName: sourceFormCode } = useContext(FormContext);
  const { instance: fileSync } = useContext(FileSyncContext);
  const store = useStore();
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const [pdf, setPdf] = useState<{ filename: string, data: Uint8Array } | undefined>(undefined);
  const [fileError, setFileError] = useState('');
  const [uploadError, setUploadError] = useState('');
  const qpdfWorker = useQpdfWorker();

  const handleDrop = async (acceptedFiles: File[] | FileList) => {
    if (!acceptedFiles) return;
    setFileError('');
    const file = acceptedFiles?.[0];
    if (!file) {
      setPdf(undefined);
      return;
    }

    const decryptedPdf = await qpdfWorker.decrypt({ pdf: await file.arrayBuffer() });
    const pdfFile = await processPdf(decryptedPdf);
    if (!pdfFile?.pdf) {
      setPdf(undefined);
      setFileError('Failed to prepare selected PDF for upload');
      return;
    }
    if (pdfFile.isEncrypted) {
      setPdf(undefined);
      setFileError('Selected PDF is encrypted');
      return;
    }

    setPdf({
      filename: file.name,
      data: pdfFile.pdf
    });
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.target.files && handleDrop([...event.target.files]);
  };

  const { getRootProps, getInputProps, inputRef, isDragAccept, isDragActive } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    multiple: false,
    accept: { 'application/pdf': [] }
  });

  const doUpload = async () => {
    if (!propertyId) return;
    if (!pdf?.data) return;
    if (!ydoc) return;

    setUploadError('');

    try {
      const applied = await uploadForm1({
        data: pdf.data,
        sessionInfo,
        source: sourceFormId && sourceFormCode ? {
          formId: sourceFormId,
          formCode: sourceFormCode as FormCodeUnion,
          sublineageId: sourceSublineageId
        } : undefined,
        fileSync,
        ydoc,
        propertyId,
        store,
        decryptPdf: data => qpdfWorker.decrypt({ pdf: data })
      });

      if (applied) {
        onClose(applied.formCode, applied.formId);
      } else {
        setUploadError('Failed to create form');
      }
    } catch (err: unknown) {
      console.error(err);
      setUploadError('Failed to create form');
    }
  };

  return <Modal show={true} onHide={() => onClose()}>
    <Modal.Header>
      <Modal.Title>Upload a Vendor signed Form 1 as a PDF</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div {...getRootProps({
        className: clsJn(
          'upload-form-1-selector d-flex flex-row cursor-pointer',
          isDragAccept && 'drop-accept',
        ),
        onClick: () => inputRef?.current?.click()
      })}>
        <input {...getInputProps({
          className: 'd-none',
          accept: '.pdf',
          onChange: handleInputChange
        })} />
        <div className='w-100 py-5 d-flex flex-row justify-content-center align-items-center gap-2'>
          <Icon name='upload'/>
          {pdf
            ? <span>{pdf.filename}</span>
            : <span>Select or drag a PDF file...</span>}
        </div>
      </div>
      {fileError && <span className='text-danger'>{fileError}</span>}
    </Modal.Body>
    <Modal.Footer>
      <div className='w-100 d-flex flex-row justify-content-end gap-2'>
        <Button variant='outline-secondary' onClick={() => onClose()}>Cancel</Button>
        <AsyncButton variant='primary' disabled={!pdf} onClick={doUpload}>Upload</AsyncButton>
      </div>
      {uploadError && <span className='text-danger'>{uploadError}</span>}
    </Modal.Footer>
  </Modal>;
}

export async function uploadForm1({
  data,
  propertyId,
  store,
  fileSync,
  ydoc,
  sessionInfo,
  source,
  decryptPdf
}: {
  data: Uint8Array,
  propertyId: string,
  store?: Store<unknown, AnyAction>,
  fileSync?: FileSync,
  ydoc: Y.Doc,
  sessionInfo?: AgentSessionInfoResult,
  source?: { formId: string, formCode: FormCodeUnion, sublineageId: string }
  decryptPdf: (data: Uint8Array) => Promise<ArrayBuffer>;
}) {
  const formId = v4();
  // keep them distinct - allows replacing the file later
  const fileId = v4();
  const formCode = ExtraFormCode.Form1Upload;
  const formType = FormTypes[formCode];
  const familyCode = formType.formFamily;
  const blobData = await decryptPdf(data);

  const file = new Blob([blobData], { type: ContentType.Pdf });
  await FileStorage.write(
    fileId,
    FileType.PropertyFile,
    ContentType.Pdf,
    file,
    StorageItemSyncStatus.PendingUpload,
    {
      propertyFile: {
        propertyId,
        formId,
        formCode
      }
    },
    { store, ydoc },
    {
      manifestType: ManifestType.None
    },
    undefined
  );

  FileSync.triggerSync(fileSync);

  const applied = Boolean(applyMigrationsV2<TransactionMetaData>({
    doc: ydoc,
    docKey: PropertyRootKey.Meta.toString(),
    typeName: 'Property',
    migrations: [{
      name: 'add uploaded form 1 doc',
      fn: state => {
        const instances = ensureFormStatesInstances(state, familyCode);
        archiveFormInstancesOfTypes(state, instances, formType.archiveSiblingTypesOnCreate);
        const now = Date.now();
        instances.push({
          id: formId,
          formCode,
          created: now,
          modified: now,
          dataModified: now,
          annexures: [],
          upload: {
            v: 2,
            name,
            files: [{
              id: fileId,
              contentType: ContentType.Pdf,
              name,
              uploader: sessionInfo?.agentUuid ? {
                id: sessionInfo.agentUuid,
                linkedSalespersonId: sessionInfo.agentId,
                name: sessionInfo?.name,
                email: sessionInfo?.email
              } : undefined,
              size: file.size,
              created: Date.now()
            }]
          },
          onSignedNavigation: source
        });
      }
    }]
  }));

  return applied ? {
    formId,
    formCode
  } : undefined;
}
