import * as Y from 'yjs';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Container, FloatingLabel, Form, FormControl, Modal } from 'react-bootstrap';
import FormCheck from 'react-bootstrap/FormCheck';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import './WetSigningModal.scss';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { useStore } from 'react-redux';
import { FormCode, META_APPEND, PropertyRootKey, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { useLineageFormless } from '../../hooks/useLineageFormless';
import { SignatureSection } from '@property-folders/common/util/pdfgen/sections/signatureSection';
import { generatePartiesSummaryString } from '@property-folders/common/util/pdfgen/sections/statement-of-parties';
import { SetupNetStateWritingYjsDocContext } from '../../form-gen-util/yjsStore';
import { Awareness } from 'y-protocols/awareness';
import { canonicalisers, friendlyDateFormatter } from '@property-folders/common/util/formatting';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';
import { FormUtil } from '@property-folders/common/util/form';
import { v4 } from 'uuid';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { createNewFormInstanceMigration } from '@property-folders/common/util/handleNewForm';
import { ContentType, ManifestData, ManifestType } from '@property-folders/contract';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import { useQpdfWorker } from '../../hooks/useQpdfWorker';

enum TerminationModalState {
  Initial,
  Confirm
}

export type AssertTerminatedModalProps = {
  onClose: ()=>void,
  formFamilyCode: FormCode|null,
  formNoun: string,
  lineageKey: string|null,
  ydoc: Y.Doc,
  ydocId: string,
  awareness: Awareness,
  wrongMethodText?: JSX.Element
};

export function AssertTerminatedModal(props: AssertTerminatedModalProps) {
  const { lineageKey } = props;
  return <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={!!props.formFamilyCode} onClose={props.onClose} />}>
    <SetupNetStateWritingYjsDocContext awareness={props.awareness} ydoc={props.ydoc} docName={props.ydocId} transactionMetaRootKey={lineageKey ? lineageKey+META_APPEND : PropertyRootKey.Meta} transactionRootKey={lineageKey ?? PropertyRootKey.Data} >
      <AssertTerminatedModalInner {...props} />
    </SetupNetStateWritingYjsDocContext>
  </ErrorBoundary>;
}
export function AssertTerminatedModalInner({
  onClose,
  formFamilyCode: formFamilyCode,
  formNoun = 'Contract',
  wrongMethodText
}: AssertTerminatedModalProps) {
  const { ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { snapshotHistory, snapshotLoadError } = useLineageFormless({
    formFamily: formFamilyCode??FormCode.RSC_ContractOfSale,
    requireAllHistory: true,
    transactionMetaRootKey,
    ydoc
  });

  const latestInstance = snapshotHistory?.instanceList[snapshotHistory?.instanceList.length-1];

  const qpdfWorker = useQpdfWorker();
  const { instance: fileSync } = useContext(FileSyncContext);

  const uploadInput = useRef<HTMLInputElement | null>(null);
  const [state, setState ] = useState(TerminationModalState.Initial);
  const [ errorMessage, setErrorMessage ] = useState<string>('');
  const [pdf, setPdf] = useState<{filename:string, data:ArrayBuffer}|undefined>(undefined);
  const [signers, setSigners] = useState([]);
  const id = latestInstance?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id??'';
  const property = snapshotHistory?.data?.[id];

  const [willUpload, setWillUpload] = useState(true);
  const [confirmText, setConfirmText] = useState('');
  const [terminationDate, setTerminationDate] = useState('');

  useEffect(()=>{
    if (!(property && latestInstance)) {
      return;
    }
    SignatureSection.buildSignersFromSigningSession(latestInstance, property).then(setSigners);
  },[latestInstance, property]);

  const parties = signers && generatePartiesSummaryString(signers);

  const store = useStore();

  const handleDrop = async (acceptedFiles: File[] | FileList) => {
    setPdf({
      filename: acceptedFiles?.[0]?.name,
      data: await acceptedFiles?.[0]?.arrayBuffer()
    });
  };

  useEffect(()=> {
    if (!pdf) return;

    // we can't work on the original pdf.data or it becomes detached
    const copyOfPdfData = new ArrayBuffer(pdf.data.byteLength);
    // copies the data - https://stackoverflow.com/a/22114687
    new Uint8Array(copyOfPdfData).set(new Uint8Array(pdf.data));

    qpdfWorker.decrypt({ pdf: copyOfPdfData }).then(decryptedPdf => {
      processPdf(decryptedPdf).then(pdfFile => {
        if (!pdfFile?.pdf || pdfFile?.isEncrypted) {
          setErrorMessage(`Failed to attach ${pdf.filename}. Please make sure PDF is not encrypted and try again`);
          setPdf(undefined);
          return;
        }
      });
    });
  }, [pdf]);

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.target.files && handleDrop(event.target.files);
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    accept: { 'application/pdf': [] }
  });

  const confirmTermination = async () => {
    if (!ydoc) {
      console.error('No ydoc to set termination on');
      return;
    }
    if (!formFamilyCode) {
      console.error('No form family specified');
      return;
    }
    if (!parties) {
      // Can also happen if the snapshots didn't load
      console.error('No signers, cannot produce parties list');
      return;
    }
    if (!property) {
      console.error('No property, snapshot likely didn\'t load');
      return;
    }

    const primaryFormTypeDesc = FormTypes[formFamilyCode];
    const terminationFormCode = primaryFormTypeDesc.externalTerminationCode;
    if (!terminationFormCode) {
      console.warn('Form family has no specified external termination form');
      return;
    }

    const newFormId = v4();
    let pdfId = '';

    if (willUpload && pdf?.data) {
      const decryptedPdf = await qpdfWorker.decrypt({ pdf: pdf.data });
      const pdfFile = await processPdf(decryptedPdf);
      const manifestData: ManifestData = {
        manifestType: ManifestType.None
      };

      pdfId = v4();

      await FileStorage.write(
        pdfId,
        FileType.PropertyFile,
        ContentType.Pdf,
        new Blob([pdfFile.pdf.buffer], { type: ContentType.Pdf }),
        StorageItemSyncStatus.PendingUpload,
        {
          propertyFile: {
            propertyId: property.id,
            formId: newFormId,
            formCode: formFamilyCode
          }
        },
        { store, ydoc },
        manifestData,
        undefined,
      );

      FileSync.triggerSync(fileSync);
    }

    // Mark terminated, and link termination file.
    applyMigrationsV2<TransactionMetaData>({
      typeName: 'Property',
      doc: ydoc,
      docKey: transactionMetaRootKey,
      migrations: [{
        name: 'Create form instance for termination',
        fn: (draft) => {
          const family = FormUtil.getFormFamilyState(formFamilyCode??'', draft);
          if (!family) {
            console.warn('No lineage family to create termination form on');
            return false;
          }
          if (!Array.isArray(family.instances)) {
            console.warn('No form instances to terminate!');
            return false;
          }
          createNewFormInstanceMigration(terminationFormCode, newFormId).fn(draft);
          const newInst = FormUtil.getFormInstanceFromFamilyState(family, newFormId);
          if (!newInst) {console.warn('Newly created instance not found'); return false;}
          newInst.annexures = [];
          if (pdfId) {
            newInst.annexures.push({
              label: 'A',
              name: 'Termination Agreement',
              contentType: ContentType.Pdf,
              id: pdfId
            });
          }

          family.terminatedTime = (new Date(terminationDate)).getTime();
          family.terminatedExternal = true;
        }
      }]
    });

    onClose?.();
  };
  const now = new Date();
  // toISOString may return in UTC, which could result in odd date alignment
  const todayDateStr = `${now.getFullYear()}-${(now.getMonth()+1).toString().padStart(2,'0')}-${(now.getDate()).toString().padStart(2,'0')}`;

  const continueDisabled = !canonicalisers.date(terminationDate).valid || (willUpload && !pdf);
  const confirmationPhrase = 'TERMINATE';
  return (
    <Modal dialogClassName="modal-wet-signing" show={!!formFamilyCode} onHide={onClose} backdrop="static">
      <Modal.Header closeButton><Modal.Title>Mark {formNoun} as Terminated</Modal.Title></Modal.Header>
      <Modal.Body>
        {state === TerminationModalState.Initial &&
          <div>
            <div className={'mb-3'}>
              <FormCheck type='radio' label='I will upload the Termination document' checked={willUpload} id={'willUpload'} onChange={() => setWillUpload(true)} />
              <FormCheck type='radio' label='I will not upload the Termination document' checked={!willUpload} id={'willNotUpload'} onChange={() => setWillUpload(false)} />
            </div>
            {willUpload
              ? <div>
                <input {...getInputProps()} ref={uploadInput} className={'d-none'} accept={'.pdf'} onChange={handleUpload} />

                <div style={{ height: '140px' }} {...getRootProps()} className={clsJn('drop-target mb-3', isDragAccept && 'drop-accept', pdf && 'is-uploaded' )} tabIndex={-1}>
                  {pdf
                    ? <div className={'d-flex text-black'}><Icon name='check_circle' icoClass='me-1' style={{ color: 'green' }}/>{pdf.filename}<span className="material-symbols-outlined cursor-pointer ms-2" onClick={()=>setPdf(undefined)}>delete</span></div>
                    : <>
                      <div className={'mb-2'}>Drag and drop the signed PDF</div>
                      <div className={'mb-2'}>or</div>
                      <Button variant={'primary'} className={'mr-3'} onClick={() => uploadInput?.current?.click()}>Choose PDF</Button>
                    </>}
                </div>
                {errorMessage && <Alert variant='danger' dismissible onClose={()=>setErrorMessage('')}>{errorMessage}</Alert>}

              </div>
              : <Alert style={{ height: '140px' }} variant="info">
                <Alert.Heading>Note</Alert.Heading>
                Termination of an agreement is a potential point of dispute. Where an reaforms agreement has been terminated by an external process, we strongly recommend that the associated documentation is uploaded to reaforms to provide a complete record for future reference.
              </Alert>
            }

            <Container fluid className={'mb-3 p-0'}>
              <FloatingLabel label={<span>Date of Termination <sup style={{ color: 'red' }}>*</sup></span>}>
                <FormControl
                  value={terminationDate}
                  onChange={e=>setTerminationDate(e.target.value)}
                  type='date'
                  max={todayDateStr}
                />
              </FloatingLabel>
            </Container>

          </div>
        }

        {state === TerminationModalState.Confirm &&
          <Container fluid className={'mb-3 p-0'}>
            <div className={'mb-2'}>You are confirming that the {formNoun} between {parties} was terminated by the final signature on the <span className='fw-bold'>{friendlyDateFormatter(new Date(terminationDate))}</span>.</div>

            <div className={'mb-2'}>
              <p>
              If you are marking this {formNoun} as terminated with this method, you:
                <ul>
                  <li>confirm the binding nature of this termination has already been established outside of reaforms; and</li>
                  <li>will be unable to edit or vary this document and must create a new {formNoun} instead.</li>
                </ul>
              </p>
              {wrongMethodText}

            </div>
            <div className={'mb-3'}>
              <div className={'mb-1'}>To confirm the details entered, type "{confirmationPhrase}" below:</div>
              <Form.Control placeholder={confirmationPhrase} style={{ width: '200px' }} value={confirmText} onChange={e => setConfirmText(e.target.value)}></Form.Control>
            </div>
          </Container>
        }

      </Modal.Body>
      <Modal.Footer>
        <Button variant={'secondary'} className={'mr-3'} onClick={onClose}>Cancel</Button>
        {state === TerminationModalState.Initial && <Button variant={'primary'} className={'mr-3'} disabled={continueDisabled} onClick={() => setState(TerminationModalState.Confirm)}>Continue</Button>}
        {state === TerminationModalState.Confirm && <Button variant={'secondary'} className={'mr-3'} onClick={() => setState(TerminationModalState.Initial)}>Back</Button>}
        {state === TerminationModalState.Confirm && <Button variant={'primary'} className={'mr-3'} disabled={confirmText.toUpperCase() !== confirmationPhrase} onClick={confirmTermination}>Submit</Button>}
      </Modal.Footer>
    </Modal>
  );
}
