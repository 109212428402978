import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { FIRST_PAGE_MARGIN } from '../../measurements';
import { minimalCoverPage } from '../../index';

export function generateSimpleCoverPageDefinition(opts: { headline: string, brand: EntityBrandFormConfig }) {
  return {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    content: [
      minimalCoverPage(
        opts.headline,
        opts.brand
      )
    ],
    pageMargins: FIRST_PAGE_MARGIN
  };
}
