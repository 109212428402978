import { InstanceHistory, MaterialisedPropertyData } from '@property-folders/contract';

import { buildSigningTimelines } from '../../../../util/dataExtract';

/**@deprecated Ticket 14351 converts this to a warning rather than any real enforcement. That's up to the wizard component to handle*/
export function preventChangeToAuctionifVendorPrice (proposedUpdate: any, data: MaterialisedPropertyData, latestSnapshot: MaterialisedPropertyData, snapshotHistory: InstanceHistory) {
  if (typeof proposedUpdate !== 'string' || proposedUpdate !== 'auction') {
    // We are only going to pop up this warning if the user is changing to an auction.
    return false;
  }

  const instanceGroupings = buildSigningTimelines(snapshotHistory).instanceSets??[];
  const currentInstanceSnapshots = instanceGroupings[instanceGroupings.length-1].map(inst => snapshotHistory.data[inst.signing?.session?.associatedFiles?.propertyDataSnapshot?.id||'']);

  const priceHistory = currentInstanceSnapshots.map(s=>Number(s.sale?.vendorPrc)).filter(a=>a>0);
  const minPrice = priceHistory.length > 0 ? Math.min(...priceHistory) : null;
  return !!minPrice && Number(data.sale?.vendorPrc) > minPrice;
}

/**@deprecated code as taken from advertRestrict.ts */
function alternativeImplementation() {

  // Before the pmap is set, let's disallow changes to the vendor price in auction on variation
  if (history && history.instanceList.length > 0 && s.vendorPrc && typeof s.vendorPrc === 'number') {
    const instanceGroupings = buildSigningTimelines(history).instanceSets;
    // In case there are no snapshots for some reason, don't attempt to check below
    if (instanceGroupings.length) {
      // Assuming that the latest instance is current. We should not be able to send a variation that
      // has expired for signing, so we should be good
      const currentInstanceSnapshots = instanceGroupings[instanceGroupings.length-1].map(inst => history.data[inst.signing?.session?.associatedFiles?.propertyDataSnapshot?.id||'']);
      const methodPath = `${generateParentPath(updatedPath)}.saleMethod`;
      const anyAuction = s.saleMethod === 'auction' || currentInstanceSnapshots.map(s=>getValueByPath(methodPath, s, true)==='auction').filter(a=>a).length > 0;
      if (anyAuction) {
        const vendorPrcPath = `${generateParentPath(updatedPath)}.vendorPrc`;
        const priceHistory = currentInstanceSnapshots.map(s=>getValueByPath(vendorPrcPath, s, true)).filter(a=>a>0);
        const minPrice = priceHistory.length > 0 ? Math.min(...priceHistory) : null;
        if (minPrice && (s.vendorPrc??0) > minPrice) {
          s.vendorPrc = minPrice;
        }
      }
    } else {
      console.error('No latest signed documents!');
    }
  }

}