import { CoverSheetCustomisation, CoverSheetMode, CustomFieldType, ExtraFormCode, FormCode, FormCodeUnion, FormInstance } from '@property-folders/contract';

export function ensureCoverSheetCustomisation(value: FormInstance): CoverSheetCustomisation {
  if (!value.cover) {
    value.cover = {
      mode: CoverSheetMode.Standard,
      custom: {}
    };
  }

  if (value.cover.mode == null) {
    value.cover.mode = CoverSheetMode.Standard;
  }

  return value.cover;
}

export function getCoverSheetMode(value?: FormInstance): CoverSheetMode {
  return value?.cover?.mode ?? CoverSheetMode.Standard;
}

export type CustomFieldFilter = (field: CustomFieldType) => boolean;
export function getCustomFieldsFilterWhenCoveringFor(formCodes?: FormCodeUnion[]): CustomFieldFilter {
  if (!formCodes?.length) {
    return () => true;
  }
  const allowed = new Set<CustomFieldType>();
  for (const formCode of formCodes) {
    switch (formCode) {
      case FormCode.RSAA_SalesAgencyAgreement:
      case ExtraFormCode.AAV_SalesAgencyAgreementVariation:
      case ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent:
        allowed.add(CustomFieldType.saleAddress);
        allowed.add(CustomFieldType.saleTitle);
        allowed.add(CustomFieldType.proposedAllotments);
        allowed.add(CustomFieldType.propertySummary);
        allowed.add(CustomFieldType.primaryVendor);
        allowed.add(CustomFieldType.salesperson);
        allowed.add(CustomFieldType.text);
        allowed.add(CustomFieldType.checkmark);
        break;
      case FormCode.RSC_ContractOfSale:
      case ExtraFormCode.SCV_ContractOfSaleVariation:
      case ExtraFormCode.SCT_ContractOfSaleTermination:
      case ExtraFormCode.SCTE_ContractOfSaleTerminationExternal:
        allowed.add(CustomFieldType.saleAddress);
        allowed.add(CustomFieldType.saleTitle);
        allowed.add(CustomFieldType.proposedAllotments);
        allowed.add(CustomFieldType.propertySummary);
        allowed.add(CustomFieldType.primaryVendor);
        allowed.add(CustomFieldType.primaryPurchaser);
        allowed.add(CustomFieldType.purchasePrice);
        allowed.add(CustomFieldType.settlement);
        allowed.add(CustomFieldType.salesperson);
        allowed.add(CustomFieldType.text);
        allowed.add(CustomFieldType.checkmark);
        break;
      default:
        allowed.add(CustomFieldType.text);
        allowed.add(CustomFieldType.checkmark);
        break;
    }
  }

  return (field) => {
    return allowed.has(field);
  };
}
