import { configureStore } from '@reduxjs/toolkit';
import validation from '@property-folders/common/redux-reducers/validation';
import navigation from '@property-folders/common/redux-reducers/navigation';
import { restApiSlice } from '@property-folders/common/redux-reducers/restApiSlice';
import entityMeta from '@property-folders/common/redux-reducers/entityMeta';
import guidanceNotes from '@property-folders/common/redux-reducers/guidanceNotes';
import newlyAvailableFiles from '@property-folders/common/redux-reducers/available-files';

export const store = configureStore({
  reducer: {
    validation,
    navigation,
    [restApiSlice.reducerPath]: restApiSlice.reducer,
    entityMeta,
    guidanceNotes,
    newlyAvailableFiles
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware()
    .concat(restApiSlice.middleware)
});

// Get the type of our store variable
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];
