import { find, isInteger } from 'lodash';
import {
  Annexure,
  DivisionType,
  ItemiserItem,
  LandType,
  lotOptions,
  planOptions,
  SaleAddress,
  SaleSubTitle,
  SaleTitle,
  SigningParty,
  TitleDivision,
  TitleInclusionState,
  titleTypeOptions,
  UploadType,
  uploadTypeOpts
} from '@property-folders/contract';
import {
  A4_HEIGHT,
  A4_WIDTH,
  FIRST_PAGE_MARGIN,
  MARGIN_BELOW_FOOTER,
  MARGIN_ITEM_LEFT,
  MarginTuple as MarginTuple,
  mmToPoints,
  SUBSEQUENT_PAGE_MARGIN
} from './measurements';
import { reaformsCharcoal } from '../../visual';
import { Predicate } from '../../predicate';
import { AgencyContact, EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { checkboxFontSize, LegalJurisdiction, minimumFontSize } from './constants';
import { formatTimestamp } from '../formatting/functions/formatTimestamp';
import { numberDisplay2Decimal } from '../formatting/functions/numberDisplay2Decimal';
import { parseInt2 } from '../formatting/functions/parseInt2';
import { canonicalisers } from '../formatting/canonicalisers';
import { mapOptsForCheckbox } from './display-transformations';
import { buildFieldName, FieldType, SystemField } from '../../signing/pdf-form-field';
import { checkbox, checkboxChecked } from '../../assets/checkbox';
import { determineTitleInclusionState } from '../../yjs-schema/property/validation/expected-evaluator';
import { formatBI } from './formatBI';
import { formatAct } from './formatters/clauses';
import type { FieldPlaceholderStyleValue } from './standards';
import { FieldPlaceholder, FieldPlaceholderStyle } from './standards';

import type { Content, Size } from 'pdfmake/interfaces';

export { pdfDefaultStyles } from './standards';

const HORIZONTAL_INDENT_STEP = 8;

export function leftIndent(multiplier = 1, existingMargin = [0,0,0,0]) {
  const updated = existingMargin.slice();
  updated[0] = updated[0] + multiplier * HORIZONTAL_INDENT_STEP;
  return updated;
}

export function leftIndentSideEffect(element: Record<string,any>, multiplier = 1) {
  const existingMargin = element?.margin || [0,0,0,0];
  element.margin = leftIndent(multiplier, existingMargin);
  return element;
}

export const fieldsSpacing = 6;

export const noborder = [false,false,false,false];
export const lineBorder = [false,false,false,true];

// Remembering that display values are being passed from the system

export function optionsText(value: any, options: {[value: string]: string}) {
  const gotten = options[value?.toString() || ''];
  if (gotten !== undefined) {
    return gotten;
  } else {
    return Object.values(options).join(' / ');
  }
}

export type textBlock = {
  text: string | textBlock[]
  style?: string,
  margin?: MarginTuple,
  fontSize?: number
};

export function inlineBooleanSelect(options: {'true': string, 'false': string}, selected?: boolean) {
  return typeof selected==='boolean'?options[`${selected}`]:Object.values(options).join(' / ');
}

export const fullWidthBlankLine = () => generateFieldTable([{ text: ' ', fontSize: 12, border: [false,false,false,true] },blankField()], ['auto','*']);

export function freeTextArea({
  title,
  content,
  linesIfEmpty = 3,
  fieldTitle,
  bookmark,
  isVariation
}: { title?: string | textBlock | textBlock[] | undefined, content: string | undefined, linesIfEmpty?: number, fieldTitle?: string, bookmark?: string | string[], isVariation?: boolean}) {
  const stackParts = [];
  if (typeof title === 'string') {
    stackParts.push({ text: title, style: 'sectionSubTitle' });
  } else if (Array.isArray(title)) {
    stackParts.push({ text: title });
  } else {
    stackParts.push(title);
  }
  if (content && fieldTitle) {
    stackParts.push(singleFieldTable({
      fieldName: fieldTitle, fieldValue: content, _: undefined, fieldColons: true,
      isVariation,
      contentStyleOverride: 'content'
    }));
  } else if (content) {
    stackParts.push({ text: content, style: 'content', margin: [0, fieldsSpacing, 0, 0] });
  } else {

    stackParts.push({
      stack: spaceStackLinesSideEffect([
        ...(fieldTitle ? [singleFieldTable({
          fieldName: fieldTitle,
          fieldValue: '',
          _: undefined,
          fieldColons: true,
          labelStyleOverride: undefined,
          isVariation,
          contentStyleOverride: 'content'
        })]:[]),
        ...new Array((fieldTitle?linesIfEmpty-1:linesIfEmpty)).fill({}).map(a=>fullWidthBlankLine())
      ])
    });
  }
  return itemSubsection({
    subsectionTitle: undefined, titleLineContent: undefined, subsectionContent: stackParts, unbreakable: undefined, bookmark: bookmark,
    isVariation
  });
}

export function singleFieldOrFreeTextContinuationArea(fieldName: string, fieldValue?: string, linesIfEmpty: number = 2, isVariation: boolean = false) {
  if (fieldValue) {
    return singleFieldTable({ fieldName, fieldValue, isVariation });
  } else {
    return freeTextContinuationArea(fieldName, '', linesIfEmpty, undefined, undefined, isVariation);
  }
}

export function freeTextContinuationArea(textPrior: string, content: string, linesIfEmpty = 2, style?: string, withContentAttrs?: {[key:string]:any}, isVariation = false) {
  if (content) {
    return { text: [...(Array.isArray(textPrior)?textPrior:[textPrior]), ' ',{ text: content, style: 'content' }], style, withContentAttrs };
  }
  return { stack: spaceStackLinesSideEffect([
    generateFieldTable([fieldLabel({ fieldName: textPrior, fieldColons: true, isVariation }), blankField()]),
    ...new Array((linesIfEmpty-1)).fill({}).map(fullWidthBlankLine)
  ]) };
}

type ExtendedItemiserItem = ItemiserItem&{enable?: boolean, itemDesc?: string|Content};

export function itemiser (items?:ExtendedItemiserItem[], totalText = 'Total', incompleteExtraCount = 4, descriptionSuffix = '', checkboxesOtherwiseFilterNotEnabled?: boolean, totalAlignment?: string, descriptionBorder?: boolean[], desriptionWidth?: string, hideTotal?: boolean) {
  items = items?.filter(item=>checkboxesOtherwiseFilterNotEnabled||(item.enable == null || item.enable));
  const filledItems = items || ([{},{},{}] as ExtendedItemiserItem[]); // 3 + 4 later means 7 blank total, but prefill should prevent this
  const reals = (items || [])
    .map(({ itemCost, enable }) => {
      if (enable != null && !enable) {
        return;
      }
      const cannon = canonicalisers.audWithNegative(itemCost || '');
      if (cannon.valid && typeof cannon.canonical === 'number') {
        return cannon.canonical;
      }
    })
    .filter(Predicate.isNotNullish);
  const noTotalAllDisabled = checkboxesOtherwiseFilterNotEnabled && items?.filter(({ enable })=>enable!=null).length === items?.filter(({ enable })=>enable==false);
  const total = !noTotalAllDisabled && reals.length > 0 ? reals.reduce((sum, value) => sum + value, 0) : '';
  const allDescsPresent = filledItems.filter(({ itemDesc })=>itemDesc).length === filledItems.length;
  // We determine data entry to be complete if prices and descriptions are present for all items
  if (incompleteExtraCount > 0 && (reals.length !== items?.length || !allDescsPresent)) {
    filledItems.push(...(new Array(incompleteExtraCount)).fill({} as ExtendedItemiserItem) as ExtendedItemiserItem[]); // 4 extra lines if the data entry is not complete
  }
  return [
    {
      table: {
        widths: [checkboxesOtherwiseFilterNotEnabled ? '4%' : null, desriptionWidth || '80%','1%','1%','18%'].filter(Predicate.isNotNullish),
        body: [
          ...filledItems.map(({ itemDesc, itemCost, enable }) => {
            const costNumber = (typeof itemCost === 'string' ? itemCost : '').replace('$','');
            const multiLineDesc = itemDesc && typeof itemDesc === 'object';
            return [
              checkboxesOtherwiseFilterNotEnabled && (typeof enable === 'boolean' ? {
                svg: enable ? checkboxChecked : checkbox,
                border: noborder,
                fit: [15, 15],
                margin: multiLineDesc? [0,-4,0,-4] : [0,-2,0,-4]
              }: { text: [], border: noborder }),
              (
                multiLineDesc
                  ? { ...itemDesc, border: descriptionBorder || lineBorder, margin: [0,-2,0,0] }
                  : { text: itemDesc ? itemDesc+descriptionSuffix : itemDesc, border: descriptionBorder || lineBorder }
              ),
              { text: '', border: noborder },
              {
                text: '$',
                border: lineBorder,
                verticalAlign: multiLineDesc ? 'bottom' : undefined,
                margin: multiLineDesc ? [0,-2,0,0] : undefined
              },
              {
                text: costNumber || '',
                border: lineBorder,
                alignment: 'right',
                verticalAlign: multiLineDesc ? 'bottom' : undefined,
                margin: multiLineDesc ? [0,-2,0,0] : undefined
              }
            ].filter(Predicate.isTruthy);
          }),
          ...insertIf(!hideTotal,[ checkboxesOtherwiseFilterNotEnabled && { text: [], border: noborder },
            { text: totalText || '', border: noborder, alignment: totalAlignment || 'right'  },
            { text: '', border: noborder },
            { text: '$', border: lineBorder },
            { text: typeof total === 'number' ? numberDisplay2Decimal(total) : '', border: [false,false,false,true], alignment: 'right' }
          ].filter(Predicate.isTruthy))
        ]
      },
      layout: {
        paddingLeft: ()=>5,
        paddingTop: (i)=> i ? 10 : 4,
        paddingBottom: ()=>3,
        paddingRight: ()=>5,
        hLineWidth: () => 0.5
      }
    }
  ];
}

function tryGetDisplayText(value: string, display: string): string {
  if (typeof canonicalisers[display] !== 'function') return value;
  const canon = canonicalisers[display](value);
  return canon.valid
    ? canon.display
    : value;
}

export function itemiserGeneric (items: any[], columns: { name: string, header: string, width: string, alignment: string, display?: string }[], incompleteExtraCount = 1) {
  const filledItems = (!items || items?.length === 0) ? [{}] : items;
  const reals = (items || [])
    .map(({ itemCost }) => {
      const cannon = canonicalisers.audWithNegative(itemCost || '');
      if (cannon.valid && typeof cannon.canonical === 'number') {
        return cannon.canonical;
      }
    })
    .filter(Predicate.isNotNullish);
  const allDescsPresent = filledItems.filter(({ itemDesc })=>itemDesc).length === filledItems.length;
  // We determine data entry to be complete if prices and descriptions are present for all items
  if (incompleteExtraCount > 0 && (reals.length !== items?.length || !allDescsPresent)) {
    filledItems.push(...(new Array(incompleteExtraCount)).fill({} as ItemiserItem) as any[]);
  }

  return [
    {
      table: {
        widths: columns.map(c => c.width||'*'),
        body: [
          columns.map(c => ({ text: c.header, bold: true })),
          ...filledItems.map(item => {
            return columns.map((c) => {
              const value = item[c.name];
              const displayText = value && c.display
                ? tryGetDisplayText(value, c.display)
                : value ?? ' ';

              return {
                text: displayText,
                border: [1, 0, 1, 1],
                alignment: c.alignment || 'left'
              };
            });
          })
        ]
      },
      layout: {
        paddingLeft: ()=>5,
        paddingTop: ()=>5,
        paddingBottom: ()=>5,
        paddingRight: ()=>5,
        hLineWidth: () => 0.5
      }
    }
  ];
}

export function stringListMapperRender (stringList: string[], options: {name: string, label: string}[]) {
  const labelMappedChattels = stringList.map(o=>{
    const obj = find(options, co=>co.name===o);
    return obj ? obj.label : o;
  });
  return {
    text: labelMappedChattels.length > 0 ? stringList.join(', ') : 'None',
    style: 'content'
  };
}

export function generateCheckboxText(
  text: string|object,
  selected?: boolean,
  boxAttrOverrides: {[attr:string]: string|number|boolean} = {},
  textAttrOverrides: {[attr:string]: string|number|boolean} = {},
  splitStringOnlyBySemicolon = false
) {
  let textObj = text;
  const speculativeSplit = splitStringOnlyBySemicolon && typeof text === 'string' && text.split(';');
  if (speculativeSplit && speculativeSplit.length > 1) {
    const contentSection = speculativeSplit[0] + ';';
    const remain = speculativeSplit.slice(1).join(';');
    textObj = { text: [
      { text: contentSection, style: selected ? 'content' : undefined, ...textAttrOverrides },
      { text: remain }
    ] };
  } else {
    textObj = { text: typeof text === 'string' ? (' ' + text) : [' ', text||'error'], style: selected ? 'content' : undefined, ...textAttrOverrides };
  }

  return [
    { text: selected?'☒':'☐', style: 'checkbox', font: 'DejaVu', fontSize: checkboxFontSize, baselineOffset: 2.5, ...selected && { color: 'black' }, ...boxAttrOverrides },
    { text: textObj, margin: [0,5,0,0]  }
  ];
}

export function generateCheckboxTextColumns(
  text: string|object,
  selected?: boolean,
  boxAttrOverrides: {[attr:string]: string|number|boolean} = {},
  textAttrOverrides: {[attr:string]: string|number|boolean} = {}
) {
  const textObj = { text: typeof text === 'string' ? (' ' + text) : [' ', text||'error'], style: selected ? 'content' : undefined, ...textAttrOverrides };
  return {
    columns: [
      { width: 'auto', text: selected ? '☒' : '☐', style: 'checkbox', font: 'DejaVu', fontSize: checkboxFontSize, baselineOffset: 2.5, ...selected && { color: 'black' }, ...boxAttrOverrides },
      { width: '*', text: textObj, margin: [8,5.5,0,0] }
    ]
  };
}

export type CheckboxDefn =
  | string
  | { other: true, label?: string, content?: string, selectMatch?: string}
  | { other?: false, label: string|object, selectMatch: string};

export function renderCheckboxBase (
  entry: CheckboxDefn,
  selected?: string[],
  boxAttrOverrides?: {[attr:string]: string|number|boolean},
  textAttrOverrides?: {[attr:string]: string|number|boolean},
  highlightTextBeforeSemicolon = false
) {
  if (typeof entry === 'string') {
    const isSelected = selected?.includes(entry);
    return {
      text: generateCheckboxText(entry, isSelected, boxAttrOverrides, textAttrOverrides, highlightTextBeforeSemicolon)
    };
  }
  if (entry.other) {
    const isSelected = selected?.includes(entry.selectMatch||entry.label||'Other'||'other');
    const inner = generateCheckboxText(
      { text: [(entry.label || 'Other'), ': ', entry.content || drawUnderline(FieldPlaceholderStyle.Default)] },
      isSelected,
      boxAttrOverrides,
      textAttrOverrides,
      highlightTextBeforeSemicolon
    );
    return { text: inner };
  }
  const isSelected = Predicate.isNotNullish(entry.selectMatch) && selected?.includes(entry.selectMatch);
  return {
    text: generateCheckboxText(entry.label, !!isSelected, boxAttrOverrides, textAttrOverrides, highlightTextBeforeSemicolon)
  };
}

export function generateCheckboxInlineTextLine(
  entriesParam: CheckboxDefn[] | {[key:string]: string},
  selectedParam?: (string|number)[]|string|number|boolean,
  otherContent?: string,
  fontSize?: number,
  boxAttrOverrides?: {[attr:string]: string|number|boolean},
  textAttrOverrides?: {[attr:string]: string|number|boolean}
) {

  if (!(entriesParam && typeof entriesParam === 'object')) {
    console.error('Must be array or plain object');
    return;
  }
  const selected = Array.isArray(selectedParam) ? selectedParam : (
    typeof selectedParam === 'boolean' ? [selectedParam.toString()] : (
      selectedParam ? [selectedParam] : []
    )
  );
  const entries = Array.isArray(entriesParam) ? entriesParam : mapOptsForCheckbox(entriesParam, otherContent);
  const textLine = entries.map((entry, idx)=>{
    const rArr = [renderCheckboxBase(entry, selected, boxAttrOverrides, { fontSize, ...textAttrOverrides })];
    if (idx > 0) rArr.splice(0,0,'    ');
    return rArr;
  }).flat();
  return { text: textLine };
}

interface Checkbox {
  label: string;
  isSelected?: boolean;
}

export function generateCheckbox(isSelected?: Checkbox['isSelected']) {
  return { text: isSelected ? '☒' : '☐', style: 'checkbox', font: 'DejaVu', fontSize: checkboxFontSize, color: 'black' };
}

export function generateCheckboxRowsV2(entries: Checkbox[], options?: { columns?: number, isNumbered?: boolean }) {
  const { columns = 3, isNumbered = false } = options || {};
  const rows = [];
  let cellNumber = 0;

  for (let i = 0; i < entries.length; i += columns) {
    const cellsToBe = entries.slice(i, i + columns);
    const cells = [];

    if (!cellsToBe) {
      continue;
    }

    for (let ii = 0; ii < columns; ii++) {
      const cell = cellsToBe[ii];

      cellNumber++;

      // PDFMake needs an object for each table cell to generate (can use colSpan if later wanted stretching behaviour)
      if (!cell) {
        cells.push({ width: '*', columns: [] });
        continue;
      }

      const { label, isSelected = false } = cell;
      const numberedColumn = [];
      isNumbered && numberedColumn.push({ width: 14, alignment: 'right', text: `${cellNumber}.` });

      cells.push({
        width: '*',
        // Space between checkbox and number/content
        columnGap: 5,
        columns: [
          { ...generateCheckbox(isSelected), width: 'auto' },
          // Create columns for number and content for alignment and separation
          {
            style: isSelected ? 'content' : undefined,
            columnGap: 5,
            baselineOffset: 3,
            columns: [
              ...numberedColumn,
              { width: '*', text: label }
            ]
          }
        ]
      });
    }

    rows.push({
      // Space between cells
      columnGap: 10,
      columns: cells,
      // Space between rows
      margin: [0, 0, 0, fieldsSpacing]
    });
  }

  return {
    unbreakable: true,
    stack: rows
  };
}

export function generateCheckboxRows(entries: CheckboxDefn[], selected?: string[], cols = 3, highlightTextBeforeSemicolon = false) {
  const outRows = [];
  for (let i = 0; i < entries.length; i+=cols) {
    const rowSeg = entries.slice(i, i+cols);
    if (!rowSeg) {
      continue;
    }
    outRows.push({
      table: {
        widths: (new Array(cols)).fill('*'),
        body: [rowSeg.map((entry, idx) => {
          return {
            colSpan: idx < rowSeg.length-1 ? 1 : cols - idx,

            table: {
              body: [renderCheckboxBase(entry, selected, undefined, { baselineOffset: 0.5 }, highlightTextBeforeSemicolon).text],
              margin: [0,0,0,0]
            },
            layout: 'noBorders'
          };

        })]
      },
      layout: {
        paddingRight: ()=>0,
        paddingLeft: ()=>0,
        paddingTop: ()=>0,
        paddingBottom: ()=>0,
        hLineWidth: ()=>0,
        vLineWidth: ()=>0
      }
    });
  }
  return {
    unbreakable: true,
    stack: outRows,
    margin: [0,-4,0,0] //offset the gap caused by the checkbox large font
  };
}

function drawCoverPageField({ label, value, system }: {label: string, value?: string, system?: SystemField}) {
  const margin = [0, 32, 0, 0];
  const labelPart = {
    border: [false, false, false, false],
    text: label+':',
    style: 'coverHeading',
    margin
  };

  if (system) {
    return [
      labelPart,
      {
        border: [false, false, false, true],
        margin,
        height: 14,
        fontSize: 12,
        acroform: {
          type: 'text',
          id: buildFieldName({ type: FieldType.System, fieldId: system }),
          options: {
            value: '',
            textColor: '#404040'
          }
        }
      }
    ];
  }

  return [
    labelPart,
    {
      border: [false, false, false, !value],
      text: value ?? '',
      margin,
      fontSize: 12
    }
  ];
}

export const innerCoverPage = (marginArray: MarginTuple, pageWidth: number, pageHeight: number) =>
  (title: string, fields: {label: string, value?: string, system?: SystemField}[], brand: EntityBrandFormConfig) => {
    const [mLeft, mTop, mRight, mBottom] = marginArray;
    const contentSpan = pageWidth - mLeft - mRight;
    const defns = [
      {
        canvas: [
          {
            type: 'polyline',
            lineWidth: mmToPoints(0.8),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: contentSpan, y: 0 }],
            lineColor: brand.lineColour
          }

        ],
        margin: [0,60,0,0]
      },
      {
        text: 'COVER PAGE',
        style: 'coverStatement',
        alignment: 'center',
        margin: [72, 12, 72, 0]
      },
      {
        text: title.toUpperCase(),
        style: 'coverTitle',
        alignment: 'center',
        margin: [0, 0, 0, 12]
      },
      {
        canvas: [{
          type: 'polyline',
          lineWidth: mmToPoints(0.8),
          closePath: false,
          points: [{ x: 0, y: 0 }, { x: contentSpan, y: 0 }],
          lineColor: brand.lineColour
        }],
        margin: [0,0,0,0]
      },
      {
        stack: [{
          text: 'This cover page is optional and for convenience only, and does not form part of the legal document.',
          style: 'documentSubtitle',
          fontSize: 12
        }],
        margin: [72,0,72,0]
      },
      fields?.length ? {
        margin: [72, 40, 72, 0],
        table: {
          widths: ['35%', '*'],
          body: (fields??[]).map(drawCoverPageField)
        },
        layout: {
          hLineWidth: ()=> 0.5,
          hLineColor: ()=>reaformsCharcoal
        }
      } : undefined
    ].filter(Predicate.isNotNull);

    return defns;
  };

export const coverPage = innerCoverPage(FIRST_PAGE_MARGIN, A4_WIDTH, A4_HEIGHT);

export const innerMinimalCoverPage = (marginArray: MarginTuple, pageWidth: number, pageHeight: number) =>
  (title: string, brand: EntityBrandFormConfig) => {
    const [mLeft, mTop, mRight, mBottom] = marginArray;
    const contentSpan = pageWidth - mLeft - mRight;
    const defns = [
      {
        canvas: [
          {
            type: 'polyline',
            lineWidth: mmToPoints(0.8),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: contentSpan, y: 0 }],
            lineColor: brand.lineColour
          }

        ],
        margin: [0,60,0,0]
      },
      {
        text: title.toUpperCase(),
        style: 'coverStatement',
        alignment: 'center',
        margin: [72, 12, 72, 12]
      },
      {
        canvas: [{
          type: 'polyline',
          lineWidth: mmToPoints(0.8),
          closePath: false,
          points: [{ x: 0, y: 0 }, { x: contentSpan, y: 0 }],
          lineColor: brand.lineColour
        }],
        margin: [0,0,0,0]
      }
    ];

    return defns;
  };

export const minimalCoverPage = innerMinimalCoverPage(FIRST_PAGE_MARGIN, A4_WIDTH, A4_HEIGHT);

/**
 *
 * @param marginArray Page margins in points. [left, top, right, bottom]. 72 points to the inch
 * @param pageWidth Width of entire page in points. 72 points to the inch
 * @param pageHeight Height of entire page in points. 72 points to the inch
 * @returns
 */
export const innerDocumentTitle = (marginArray: MarginTuple, pageWidth: number, pageHeight: number) =>
/**
 *
 * @param Form title
 * @returns
 */
  (title: string, level = 1, brand: EntityBrandFormConfig, pageBreak = true) => {
    const [mLeft, mTop, mRight, mBottom] = marginArray;
    const contentSpan = pageWidth - mLeft - mRight;
    return [
      {
        canvas: [
          {
            type: 'polyline',
            lineWidth: mmToPoints(0.8),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: contentSpan, y: 0 }],
            lineColor: brand.lineColour
          }

        ],
        pageBreak: pageBreak ? 'before' : ''
      },
      {
        text: level === 1 ? title.toUpperCase() : title,
        style: level === 1 ? 'documentTitle' : `documentTitleL${level}`,
        alignment: 'center',
        margin: [0, 12, 0, 12]
      },
      {
        canvas: [{
          type: 'polyline',
          lineWidth: mmToPoints(0.8),
          closePath: false,
          points: [{ x: 0, y: 0 }, { x: contentSpan, y: 0 }],
          lineColor: brand.lineColour
        }]
      }
    ];
  };

/**
 *
 * @param Form title
 * @returns Content block for pdfMake
 */
export const documentTitle = innerDocumentTitle(FIRST_PAGE_MARGIN, A4_WIDTH, A4_HEIGHT);

export interface DocumentSubTitleConfig {
  margin?: [number?, number?, number?, number?],
  pageBreak?: boolean
}

export const documentSubTitle = (text: string, config?: DocumentSubTitleConfig) => {
  return {
    text: text.toUpperCase(),
    style: 'documentSubtitle',
    bold: true,
    alignment: 'center',
    margin: config?.margin || [0, 0, 0, 12],
    pageBreak: config?.pageBreak ? 'before' : ''
  };
};

export const innerStandardHeader = (marginArray: MarginTuple) =>
  (currentPage: number, title: string, hasCoverPage = true, ourProductLogo: string, agencyContact?: AgencyContact) => {
    const [mLeft, _mTop, mRight, _mBottom] = marginArray;

    if (currentPage === (hasCoverPage ? 2 : 1) ) {
      const aName = agencyContact?.agencyName;
      const phone = agencyContact?.agencyPhone;
      const rla = agencyContact?.agencyRla;
      const email = agencyContact?.agencyEmail;
      const lineCollection = [
        ...(aName ? [{
          text: [
            aName ? aName : null
          ].filter(Predicate.isNotNullish).join(', '),
          style: 'headerAgencyContact'
        }]  : []),

        ...((phone || rla) ? [{
          text: [
            rla ? `RLA ${rla}` : null,
            phone?`P: ${canonicalisers.phone(phone).display}`:null
          ].filter(Predicate.isNotNullish).join(', '),
          style: 'headerAgencyContact'
        }] : []),

        ...(email ? [{
          text: [
            email?`E: ${email}`:null
          ].filter(Predicate.isNotNullish).join(', '),
          style: 'headerAgencyContact'
        }] : [])
      ];

      const lines = lineCollection.length;

      return [
        {
          margin: [mLeft, mmToPoints(10), mRight, 0],
          columns: [
            {
              width: 'auto',
              stack: [{ image: 'agencyLogo', fit: [400, mmToPoints(15)] }],
              alignment: 'left'

            },
            {
              width: 'auto',
              stack: lineCollection,
              lineHeight: 1.2,
              alignment: 'left',
              margin: [fieldsSpacing, lines >= 3 ? 8 : lines >= 2 ? 13 : 18,0,0]
            },
            { width: '*', text: ' ' },
            {
              width: 'auto',
              stack: [
                { svg: ourProductLogo, fit: [400, mmToPoints(12)] }
              ],
              style: 'header',
              alignment: 'right'
            }
          ]
        }
      ];
    } else {
      return {
        margin: [mLeft, mmToPoints(10), mRight, 0],
        stack: [{ columns:
          [

            {
              stack: [
                { image: 'agencyLogo', fit: [300, mmToPoints(11)] }
              ],
              style: 'header',
              alignment: 'left',
              width: 100
            },{
              text: hasCoverPage && currentPage === 1 ? ' ' : title.toUpperCase(),
              fontSize: 11,
              color: 'black',
              alignment: 'center',
              margin: [0,8,0,0],
              width: '*'
            },{
              stack: [
                { svg: ourProductLogo, fit: [300, mmToPoints(10)] }
              ],
              style: 'header',
              alignment: 'right',
              width: 100
            }
          ]
        }]
      };
    }
  };

export const standardHeader = innerStandardHeader(FIRST_PAGE_MARGIN);

function getPageNumberInfo(hasCoverPage: boolean, currentPage: number, pageCount: number) {
  const pageNum = (hasCoverPage ? currentPage - 1 : currentPage);
  const pagesTotal = (hasCoverPage ? pageCount - 1 : pageCount);
  return {
    pageNum,
    pageNumberText: pageNum > 0
      ? pageCount
        ? `Page ${pageNum} of ${pagesTotal}`
        : `Page ${pageNum}`
      : ''
  };
}

export const  innerStandardFooter = (marginArray: MarginTuple) =>
  (currentPage: number, title: string, hasCoverPage = true, pageCount: number, useMinimalFooter = false, statusText?: string, dateFilledText?: string) =>{
    const { pageNumberText } = getPageNumberInfo(hasCoverPage, currentPage, pageCount);
    const [mLeft, _mTop, mRight, _mBottom] = marginArray;
    const generateField = (id: string) => ({
      acroform: { id, type: 'text', options: { value: '', align: 'right', textColor: reaformsCharcoal } },
      height: 13,
      alignment: 'right'
    });
    const statusField = statusText
      ? { text: statusText, alignment: 'right' }
      : generateField(buildFieldName({ type: FieldType.System, fieldId: 'SIGNING_AND_DATE' }));

    const sharedFooter = { style: 'footer', margin: [mLeft, 0, mRight, MARGIN_BELOW_FOOTER] };
    const copyright = { text: `© reaforms Pty Ltd ${new Date().getFullYear()}` };
    const pageNumber = { text: pageNumberText, alignment: 'center' };
    const stack = [];

    !useMinimalFooter && stack.push({ text: `‘${title}’ v2.0`, alignment: 'center', margin: [0, 0, 0, 8] });
    stack.push({ columns: [copyright, pageNumber, statusField] });

    return [
      { ...sharedFooter, stack }
    ];
  };

export const standardFooter = innerStandardFooter(FIRST_PAGE_MARGIN);

export function privacyStatement() {
  return [
    {
      text: 'Privacy Statement',
      style: 'sectionTitle',
      alignment: 'center',
      pageBreak: 'before'
    },
    { text: formatBI(`The Agent used personal information collected from you to act as your Agent and to perform its obligations under this Agreement. The Agent may also use such information collected to promote the services of the Agent and/or seek potential clients. The Agent may disclose information to other parties including media organisations on the internet to potential tenants, or to clients of the Agent both existing and potential as well as tradespersons, strata corporations, government and statutory bodies and to ot her parties as required by law. The Agent will only disclose information in this way to other parties as required to perform their duties under this Agreement for the purposes specified above or as otherwise allowed under the ${formatAct(LegalJurisdiction.Commonwealth, 'PrivacyAct1988')}. If you would like to access this information you can do so by contacting the Agent at the address and contact numbers in this Agreement. You can correct any information if it is inaccurate, incomplete or out-of-date. Real estate and tax law requires some of this information to be collected.`) }
  ];
}

export function drawUnderline(fieldPlaceholder: FieldPlaceholderStyleValue = FieldPlaceholderStyle.Default, { margin = undefined } = {}) {
  const field = FieldPlaceholder[fieldPlaceholder];
  const blankSpaces = Number(field?.width) || FieldPlaceholder[FieldPlaceholderStyle.Default].width;
  const prefix = field?.prefix ?? '';
  const suffix = field?.suffix ?? '';

  return {
    text: `${prefix}`.padEnd(blankSpaces, '\t') + suffix,
    preserveLeadingSpaces: true,
    preserveTrailingSpaces: true,
    margin: margin,
    decoration: 'underline',
    decorationCombine: true
  };
}

export function drawUnderlineBetter(
  fieldPlaceholder: FieldPlaceholderStyleValue = FieldPlaceholderStyle.Default, { margin = undefined } = {}) {
  const field = FieldPlaceholder[fieldPlaceholder];
  const blankSpaces = Number(field?.width) || FieldPlaceholder[FieldPlaceholderStyle.Default].width;
  const prefix = field?.prefix ?? '';
  const suffix = field?.suffix ?? '';

  return {
    text: [
      prefix,
      {
        text: ''.padEnd(blankSpaces, '\t'),
        decoration: 'underline',
        decorationCombine: true,
        color: 'black',
        preserveLeadingSpaces: true,
        preserveTrailingSpaces: true
      },
      suffix
    ].filter(s=>s !== ''),
    preserveLeadingSpaces: true,
    preserveTrailingSpaces: true,
    margin: margin
  };
}

export function drawPlaceholder(id: string, height: number, margin: number[] = []) {
  return [
    {
      acroform: {
        type: 'text',
        id,
        options: {
          value: ''
        }
      },
      height,
      margin
    }
  ];
}

export const lineIfEmpty = (value: any, fieldPlaceholder: FieldPlaceholderStyleValue = FieldPlaceholderStyle.Default) => {
  if (value) {
    return { text: value, style: 'content' };
  } else {
    return drawUnderlineBetter(fieldPlaceholder);
  }
};

export function attachStackFirstItem (
  contentList: (Content|string)[],
  attachment: Content | Content[],
  unbreakable = true,
  indent = false
) {
  let nv = contentList;
  const margin = indent ? [MARGIN_ITEM_LEFT, 0, 0, 0] : [0, 0, 0, 0];

  if (contentList.length > 0) {
    nv = [
      {
        unbreakable,
        stack: [
          ...(Array.isArray(attachment) ? attachment : [attachment]),
          {
            margin,
            stack: [
              contentList[0]
            ]
          }
        ]
      },
      {
        margin,
        stack: contentList.slice(1)
      }
    ];
  }
  return nv;
}

/* Rules for spacing

  Top margin is to be avoided as much as possible
  All items are to be contained in both a Section and Subsection, even with no subsection title
*/

const sectionBMargin = 1.5*fieldsSpacing;
const afterSectionBMargin = 15;
export const itemSection = (
  { itemNo, itemTitleParam, bookmark, stackContent, superTitle = false, isVariation = false, pageBreakBefore = false, titleStyleOverride, topMarginOverride, titleMarginBottomOverride }
  : {
    itemNo: number | undefined,
    itemTitleParam: string,
    bookmark: string | string[],
    stackContent: object[],
    superTitle?: boolean,
    isVariation?: boolean,
    pageBreakBefore?: boolean,
    titleStyleOverride?: string,
    topMarginOverride?: number,
    titleMarginBottomOverride?: number
  }
) => {
  const bookmarkArr = (Array.isArray(bookmark)
    ? bookmark
    : (bookmark?[bookmark]:[])
  ).map(bookmarkAnchor);
  const bookmarkEndArr = (Array.isArray(bookmark)
    ? bookmark
    : (bookmark?[bookmark]:[])
  ).map(bm=>bookmarkAnchor(`${bm}_!END`));
  const itemTitle = superTitle || titleStyleOverride ? itemTitleParam : itemTitleParam.toLocaleUpperCase();
  const headerText = Predicate.isNotNullish(itemNo) && itemTitle
    ? `ITEM ${itemNo} – ${itemTitle}`
    : itemTitle
      ? itemTitle
      : '';
  const header = {
    text: headerText,
    style: titleStyleOverride || (superTitle ? 'documentTitleL2' :'sectionSubTitle'),
    margin: [0,0,0, titleMarginBottomOverride ?? sectionBMargin],
    fontSize: isVariation ? minimumFontSize : undefined
  };
  return [{
    ...pageBreakBefore && { pageBreak: 'before' },
    margin: [0,!pageBreakBefore?(topMarginOverride ?? afterSectionBMargin):0,0,sectionBMargin-subsectionBMargin], // Only 1x field spacing because item subsections should space 2x below
    stack: [
      // This stack shouldn't need the same stack binding treatment to prevent breaking, because the header is not
      // composed of columns, unlike the subsection title
      ...attachStackFirstItem(stackContent, [...bookmarkArr, header], !pageBreakBefore, !!itemNo), //no indent if theres no item heading
      ...bookmarkEndArr
    ]
  }];
};

export const subsectionBMargin = fieldsSpacing;

function titleCheckBox (label: string, isSelected: boolean) {
  return generateCheckboxText(
    label,
    isSelected,
    {
      color: reaformsCharcoal,
      baselineOffset: 2.2
    },
    {
      style: isSelected ? 'titleCheckBoxSelected' : 'titleCheckBox'
    }
  );
}

export function bookmarkAnchor (bm: string) {
  return (typeof window !== 'undefined' ? window : {} as any)?.fnDev?.focusDebuggerEnabled
    ? { lineHeight: 1, text: bm||'error', id: bm, style: 'hiddenText', fontSize: 6, margin: [0,0,0,0], color: `${bm}`.endsWith('_!END') ? 'red' : 'blue' }
    : { lineHeight: 0, text: '=', id: bm, style: 'hiddenText', margin: [0,0,0,0] };
}

export function itemSubsection (
  {
    subsectionTitle,
    titleLineContent,
    subsectionContent,
    unbreakable = true,
    bookmark,
    otherStackAttributes,
    titleOverrideAttributes, isVariation = false,
    titleBottomMargin = 0
  }: {
    subsectionTitle?: string | undefined,
    titleLineContent?: string | { text: string } | {
      yesNoBox: true;
      trueLabel: string;
      trueWidth?: Size;
      falseLabel: string;
      falseWidth?: Size;
      currentVal: boolean | undefined;

    } | undefined,
    subsectionContent?: object[],
    unbreakable?: boolean,
    bookmark?: string | string[],
    otherStackAttributes?: { [p: string]: any },
    titleOverrideAttributes?: { [p: string]: any },
    isVariation?: boolean,
    titleBottomMargin?: number
  }
) {
  // We could perhaps pick up these using something like
  /*
  document.evaluate('//span[contains(@style, "font-size: 0px") and contains(text(),"=")]', document.querySelector('.PDFContainer.primary'))
  */
  const bookmarks = bookmark?(Array.isArray(bookmark)?bookmark:[bookmark]).map(bookmarkAnchor):[];
  const bookmarkEnd = bookmark?(Array.isArray(bookmark)?bookmark:[bookmark]).map(bm=>bookmarkAnchor(`${bm}_!END`)):[];

  let composedTitle: Content = {
    text: [
      {
        text: subsectionTitle
          ? (subsectionTitle.endsWith('   ') ? subsectionTitle : subsectionTitle.trimEnd() + (titleLineContent ? '    ' : ''))
          : ' ',
        fontSize: isVariation && subsectionTitle ? minimumFontSize : undefined
      }],
    style: subsectionTitle ? 'sectionSubTitle' : 'hiddenText',
    fontSize: isVariation && subsectionTitle ? minimumFontSize : undefined,
    ...titleOverrideAttributes
  };

  if (typeof titleLineContent === 'object' && 'yesNoBox' in titleLineContent) {
    const { currentVal, falseLabel, trueLabel, falseWidth, trueWidth } = titleLineContent;

    composedTitle = {
      columns: [
        composedTitle,
        {
          ...generateInlineCheckboxes([
            { label: trueLabel, contentWidth: trueWidth ?? 70, isSelected: !!currentVal },
            { label: falseLabel, contentWidth: falseWidth ?? 70, isSelected: Predicate.boolFalse(currentVal) }
          ]),
          width: '*',
          fontSize: minimumFontSize
        }
      ],
      columnGap: 5,
      style: subsectionTitle ? 'sectionSubTitle' : 'hiddenText',
      fontSize: isVariation && subsectionTitle ? minimumFontSize : undefined,
      ...titleOverrideAttributes
    };

  } else if (titleLineContent) {
    composedTitle?.text?.push(titleLineContent);
  }

  const wrappedTitle = {
    stack: [
      ...bookmarks,
      composedTitle
    ],
    // This unbreakable: true seems to binds the bookmarks and the columns width, and in doing so,
    // prevents other pages from going haywire should the column end up on a page boundary
    unbreakable: true,
    margin: subsectionTitle ? [0,4,0,titleBottomMargin] : undefined
  };

  const fo = {
    margin: [0,0,0,subsectionBMargin*2],
    stack: [...(subsectionContent?.length > 0 ? attachStackFirstItem(subsectionContent, wrappedTitle, unbreakable) : [wrappedTitle]), ...bookmarkEnd],
    unbreakable: unbreakable ? true : undefined,
    ...otherStackAttributes
  };
  return [fo];
}

export interface CheckboxProps extends Checkbox {
  /**
   * Column width including checkbox.
   * @default '*'
   */
  width?: Size;
  /**
   * Width of inline text content.
   * @default '*'
   */
  contentWidth?: Size;
}

export function generateInlineCheckboxes(entries: CheckboxProps[]) {
  const cells = [];

  for (let i = 0; i < entries.length; i++) {
    const { label, isSelected, width = '*', contentWidth = '*' } = entries[i];
    cells.push({
      width,
      // Space between checkbox and content
      columnGap: 5,
      columns: [
        { ...generateCheckbox(isSelected), width: 'auto' },
        // Create columns for content for alignment and separation
        {
          style: isSelected ? 'content' : undefined,
          baselineOffset: 3,
          columns: [
            { width: contentWidth, text: label }
          ]
        }
      ]
    });
  }

  return {
    // Space between cells
    columnGap: 10,
    columns: cells
  };
}

export function twoValueCheckbox(opt: {label: any, trueLabel: string, falseLabel: string, currentVal?: boolean}) {
  const { trueLabel, falseLabel, currentVal, label } = opt;
  const checkboxes = [
    generateInlineCheckboxes([
      { label: trueLabel, isSelected: !!currentVal },
      { label: falseLabel, isSelected: Predicate.boolFalse(currentVal) }
    ])
  ];

  return {
    columns: [
      {
        ...label,
        width: '*'
      },
      {
        columns: checkboxes,
        fontSize: minimumFontSize,
        width: 'auto',
        margin: [0,-4.5,0,0]
      }
    ]
  };
}

const auto2width = ['auto', '*', 'auto', '*'];
const widthsSpec = auto2width;

export const fieldLabel = ({
  fieldName,
  fieldColons = true,
  styleOverride,
  isVariation = false,
  hasLeftMargin,
  noWrap
}: {
  fieldName: string;
  fieldColons?: boolean;
  styleOverride?: string;
  isVariation?: boolean;
  hasLeftMargin?: boolean;
  noWrap?: boolean
}) => {
  return ({
    text: Array.isArray(fieldName)
      ? fieldName.map(soro=>typeof soro === 'string'
        ? {
          text: soro,
          style: styleOverride || 'tableFieldLabel',
          fontSize: isVariation ? minimumFontSize : undefined
        }
        : soro
      )
      : (fieldColons ? fieldName+':' : fieldName),
    style: styleOverride || 'tableFieldLabel',
    fontSize: isVariation ? minimumFontSize : undefined,
    border: noborder,
    marginLeft: hasLeftMargin && 5,
    noWrap
  });
};

export const generateFieldTable = (row: {[key:string]: any}[], widths?: (string|number)[], margin?: MarginTuple) => {
  const resultingWidth = [...(widths ?? widthsSpec)].slice(0,row.length);

  return {
    table: {
      widths: resultingWidth,
      body: [
        row
      ]
    },
    layout: sectionFieldsLayout,
    margin: margin
  };
};

/**Confine width of element with a column spec. Shallow copies item to set column width
 *
 * @param item An element, not a list of elements
 * @param widthSpec width attribute to be added to column child item
 * @returns
 */
export function confineWidthWithColumn(item: object, widthSpec: number | string) {
  return {
    columns: [
      {
        ...item,
        width: widthSpec
      }
    ]
  };
}

export const spaceStackLinesSideEffect = (stackList: {[key:string]: any}[], indentMultiplier = 0, verticalMarginMultiplier = 1) => {
  for (const rowi in stackList) {
    if (parseInt(rowi) < stackList.length-1) {
      const mutableMargin = stackList[rowi]?.margin?.slice() || [0,0,0,0];
      mutableMargin[3] = mutableMargin[3]+fieldsSpacing*verticalMarginMultiplier;
      stackList[rowi].margin = mutableMargin;
    }
    if (indentMultiplier) {
      leftIndentSideEffect(stackList[rowi], indentMultiplier);
    }
  }
  return stackList;
};

export const blankField = (fieldPlaceholder: FieldPlaceholderStyleValue = FieldPlaceholderStyle.Default, options?: { prefix?: string; suffix?: string; blankSpaces?: number; contentStyleOverride?: string }) => {
  const field = FieldPlaceholder[fieldPlaceholder];
  // Use override values, otherwise passed values, or resort to default field
  const blankSpaces = options?.blankSpaces ?? field?.width;
  const prefix = options?.prefix ?? field?.prefix ?? '';
  const suffix = options?.suffix ?? field?.suffix ?? '';

  return {
    color: reaformsCharcoal,
    margin: [3, 0, 3, 0],
    text: `${prefix}${blankSpaces ? ' '.repeat(blankSpaces) : ''}${suffix}`,
    preserveLeadingSpaces: true,
    preserveTrailingSpaces: true,
    alignment: suffix ? 'right' : undefined,
    style: options?.contentStyleOverride,
    border: [false, false, false, true]
  };
};

export const knownPair = (
  { fieldName, fieldValue, fieldColons, labelStyleOverride, contentStyleOverride, isVariation }: { fieldName: string, fieldValue: any, fieldColons?: boolean, labelStyleOverride?: string, contentStyleOverride?: string, isVariation?: boolean }) => [{
  text: [
    { ...fieldLabel({ fieldName: fieldName, fieldColons: fieldColons, styleOverride: labelStyleOverride, isVariation  }) },
    '  ',
    { text: fieldValue, style: contentStyleOverride ?? 'content' }
  ],
  border: noborder,
  margin: [0,0,4,0]
}];
export const unknownPair = ({
  fieldName,
  fieldColons,
  labelStyleOverride,
  contentStyleOverride,
  blankCount = 1,
  prefix,
  suffix,
  isVariation,
  blankSpaces,
  fieldPlaceholder,
  hasLeftMargin,
  noWrap
}: {
  fieldName: string,
  fieldColons?: boolean;
  labelStyleOverride?: string;
  contentStyleOverride?: string;
  blankCount?: number;
  prefix?: string;
  suffix?: string;
  isVariation?: boolean;
  blankSpaces?: number;
  fieldPlaceholder?: FieldPlaceholderStyleValue;
  hasLeftMargin?: boolean;
  noWrap?: boolean;
}) => {
  return [
    fieldLabel({ fieldName, fieldColons, styleOverride: labelStyleOverride, isVariation, hasLeftMargin, noWrap }),
    ...Array(blankCount).fill('').map(_=>blankField(fieldPlaceholder, { blankSpaces, contentStyleOverride, prefix, suffix }))
  ];};
export const singleFieldTable = (
  {
    fieldName,
    fieldValue,
    _,
    fieldColons = true,
    labelStyleOverride,
    blankPrefix,
    blankSuffix,
    isVariation,
    contentStyleOverride,
    fieldPlaceholder
  }: {
    fieldName: string,
    fieldValue?: string | (string | object)[],
    _?: undefined | null,
    fieldColons?: boolean,
    labelStyleOverride?: string,
    blankPrefix?: string,
    blankSuffix?: string,
    isVariation?: boolean,
    contentStyleOverride?: string,
    fieldPlaceholder?: FieldPlaceholderStyleValue
  }
) => {
  return arbitraryFieldsRowTable(
    {
      fields: [
        [fieldName, fieldValue]
      ],
      fieldColons,
      labelStyleOverride,
      contentStyleOverride,
      relativeSpace: undefined,
      blankPrefix,
      blankSuffix,
      isVariation,
      fieldPlaceholder
    }
  );};

export function fieldBoolCheckboxes(value: boolean| undefined, trueLabel?: string, falseLabel?: string,  boxAttrOverrides?: {[attr:string]: string|number|boolean}, textAttrOverrides?: {[attr:string]: string|number|boolean}) {
  const inline = inlineBoolChecks(value, trueLabel, falseLabel, 10.5, boxAttrOverrides, textAttrOverrides);
  inline.text.splice(0,0,'   ');
  return inline;
}

export function inlineBoolChecks(value: boolean| undefined, trueLabel = 'Yes', falseLabel = 'No', fontSize?: number, boxAttrOverrides?: {[attr:string]: string|number|boolean}, textAttrOverrides?: {[attr:string]: string|number|boolean}) {
  return {
    text: [
      ...generateCheckboxText(trueLabel, value, boxAttrOverrides, { fontSize, ...textAttrOverrides }),
      '   ',
      ...generateCheckboxText(falseLabel, Predicate.boolFalse(value), boxAttrOverrides, { fontSize, ...textAttrOverrides })
    ],
    fontSize
  };
}

export const fieldLabelInTable = (fieldName: string) => {
  return generateFieldTable([fieldLabel({ fieldName: fieldName, fieldColons: false })]);
};

export const containAndIndent = (element) => {
  return {
    stack: [
      {
        stack: [element],
        margin: [fieldsSpacing,0,0,0]
      }
    ]
  };
};

function pickIndexIfArray<T = any>(value: T[]|T|undefined, index: number) {
  if (Array.isArray(value)) {
    return value[index];
  }
  return value;
}

/**
 *
 * @param fields List of field name value pairs
 * @param fieldColons Whether to show colons after a field name. In list mode, will do a look up to the position as found in fields
 * @param labelStyleOverride Override label style. If list of styles is provided, will do a look up to the position as found in fields
 * @param contentStyleOverride Override content style. If list of styles is provided, will do a look up to the position as found in fields
 * @param relativeSpace List of integers of how much relative space to take up for each field.
 * @param rowBlankOverride Override width attribute of unknown values in order of fields
 * @returns
 */
export const arbitraryFieldsRowTable = (
  {
    fields,
    fieldColons = true,
    labelStyleOverride,
    contentStyleOverride,
    relativeSpace,
    blankPrefix,
    blankSuffix,
    isVariation,
    blankSpaces,
    fieldPlaceholder,
    overflowIfAllPresent
  }: {
    fields: [label: string, value: string | undefined, fieldOpts?: { blankPrefix?: string; blankSuffix?: string; fieldPlaceholder?: FieldPlaceholderStyleValue } | undefined][],
    fieldColons?: boolean | boolean[],
    labelStyleOverride?: string | (string | undefined)[],
    contentStyleOverride?: string | (string | undefined)[],
    relativeSpace?: number[],
    blankPrefix?: string | string[],
    blankSuffix?: string | string[],
    isVariation?: boolean,
    blankSpaces?: number,
    fieldPlaceholder?: FieldPlaceholderStyleValue,
    overflowIfAllPresent?: boolean
  }
) => {
  // Should add left margins to following fields if more than one field
  const shouldHaveLeftMargins = fields.length > 0;
  const filledSpacing = Array(fields.length).fill('auto') as string[];
  const pairs: {[x:string]: any}[] = [];
  const renderAsText = overflowIfAllPresent && fields.filter(([label, value]) => !value || !label).length === 0;

  if (renderAsText) {
    const rArray: Content[] = fields.flatMap(([label, value, fieldOpts], i, arr) => {
      const isLast = i >= arr.length-1;
      const dots = pickIndexIfArray(fieldColons, i) ?? true;
      const sty = pickIndexIfArray(labelStyleOverride, i) ?? 'tableFieldLabel';
      const styC = pickIndexIfArray(contentStyleOverride, i) ??'content';
      const labelText = `${label}${dots?':':''} `;
      const labelStyle = { style: sty };
      const contentStyle = { style: styC, fontSize: isVariation ? minimumFontSize : undefined };
      return [{ ...labelStyle, text: labelText, noWrap: true }, { ...contentStyle, text: `${value}${isLast?'':'  '}` }];
    });

    return { text: rArray };
  }

  for (let i = fields.length-1; i >= 0; i -= 1) {
    const [label, value, fieldOpts] = fields[i];
    const dots = pickIndexIfArray(fieldColons, i);
    const sty = pickIndexIfArray(labelStyleOverride, i);
    const styC = pickIndexIfArray(contentStyleOverride, i);
    if (!value) {
      let relSpace = relativeSpace?.[i];
      if (!(relSpace && isInteger(relSpace) && relSpace > 0)) {
        relSpace = 1;
      }
      for (let bl = 0; bl < relSpace; bl++) {
        filledSpacing.splice(i+1,0,FieldPlaceholder[pickIndexIfArray(fieldOpts?.fieldPlaceholder ?? fieldPlaceholder, i) ?? FieldPlaceholderStyle.Default].width === '*' ? '*' : 'auto');
      }

      const prefix = fieldOpts?.blankPrefix ?? blankPrefix;
      const suffix = fieldOpts?.blankSuffix ?? blankSuffix;
      pairs.splice(0,0,...unknownPair({
        fieldName: label,
        fieldColons: dots,
        labelStyleOverride: sty,
        contentStyleOverride: styC,
        blankCount: relSpace,
        prefix: pickIndexIfArray(prefix, i),
        suffix: pickIndexIfArray(suffix, i),
        isVariation,
        blankSpaces,
        fieldPlaceholder: pickIndexIfArray(fieldOpts?.fieldPlaceholder ?? fieldPlaceholder, i),
        hasLeftMargin: shouldHaveLeftMargins && i > 0,
        noWrap: true
      }));
    } else {
      pairs.splice(0,0,...knownPair({
        fieldName: label,
        fieldValue: value,
        fieldColons: dots,
        labelStyleOverride: sty,
        contentStyleOverride: styC,
        isVariation
      }));
    }
  }

  return generateFieldTable(pairs, filledSpacing);
};

export const twoFieldTable = (
  {
    fieldName,
    fieldValue,
    fieldPlaceholder,
    fieldName2,
    fieldValue2,
    fieldPlaceholder2,
    _,
    fieldColons = true,
    labelStyleOverride,
    isVariation,
    contentStyleOverride
  }: {
    fieldName: string,
    fieldValue: string,
    fieldPlaceholder?: FieldPlaceholderStyleValue,
    fieldName2: string,
    fieldValue2: string,
    fieldPlaceholder2?: FieldPlaceholderStyleValue,
    _?: undefined | null,
    fieldColons?: boolean | [boolean, boolean],
    labelStyleOverride?: string | [(string | undefined), (string | undefined)],
    isVariation?: boolean,
    contentStyleOverride?: string
  }
) => {
  return arbitraryFieldsRowTable(
    {
      fields: [
        [fieldName, fieldValue, { fieldPlaceholder }],
        [fieldName2, fieldValue2, { fieldPlaceholder: fieldPlaceholder2 }]
      ],
      fieldColons,
      labelStyleOverride,
      isVariation,
      contentStyleOverride
    },
  );
};

const sectionFieldsLayout = {
  paddingLeft: ()=>0,
  paddingTop: ()=>0,
  paddingBottom: ()=>0,
  paddingRight: ()=>0,
  hLineWidth: ()=> 0.5,
  vLineWidth: ()=> 0, // Acts as padding when border: false applies otherwise
  hLineColor: ()=>reaformsCharcoal
};

export const format = (text: string) => {
  // if we don't add an extra space at the start then the formatting is back to front...
  // just gotta make sure we remove it at the end
  const tempStartSpace = text.startsWith('*');
  if (tempStartSpace) {
    text = ' ' + text;
  }

  const formattedText = text.split(/(\*)(?!\1)/)?.filter(t=>t&&t!=='*')?.map((t,i,c) => ({ text: t, style: i%2===1 || c.length===1 ? 'content' : '' }));

  return tempStartSpace
    ? formattedText.slice(1)
    : formattedText;
};

export const choice = (val, whenTrue, whenFalse, leadingAsterisk) => {
  return val==null ? `${leadingAsterisk?'*':''}${whenTrue} / ${whenFalse}` : val ? whenTrue : whenFalse;
};

export const insertIf = (condition, ...elements) => {
  return condition ? elements : [];
};

export const _ = number => {
  return '_'.repeat(number);
};

function describeTitle (titleOnly?: boolean, useDescriptionOfLand?: boolean) {
  return function (title: SaleTitle, titleIndex: number): Content[] {
    const { titleType, volume, folio } = canonicalisers.title(title.title).components ?? {};
    const subs = Array.isArray(title.subTitles) && title.subTitles.length > 0 ? title.subTitles : [{}] as SaleSubTitle[];
    const noDivisions = title.isWhole && !subs.some(s => s.portionType !== TitleInclusionState.whole);
    const getAllotmentPortionType = (st: {portionType: TitleInclusionState}) => {
      switch (st.portionType) {
        case TitleInclusionState.portion:
          return 'part';
        case TitleInclusionState.whole:
          return 'whole';
        case TitleInclusionState.none:
          return 'none';
        default:
          return 'whole / part';
      }
    };

    const getTitlePortionType = (t: Pick<SaleTitle, 'isWhole'|'subTitles'>) => {
      if (t.isWhole) {
        return 'whole';
      }

      if (title.isWhole == null) {
        // putting "none" here seems a bit silly, you'd just completely exclude it
        return 'whole / part';
      }

      const subs = Array.isArray(title.subTitles) && title.subTitles.length > 0 ? title.subTitles : [{}] as SaleSubTitle[];
      const completelyExcluded = !subs.some(s => s.portionType !== TitleInclusionState.none);

      return completelyExcluded
        ? 'none'
        : 'portion';
    };

    const titlePortionPart = getTitlePortionType(title);

    if (titlePortionPart === 'none') {
      return [];
    }

    // this only works in the case where we have no divisions
    // because we can't really split up the land description from lssa
    if (title.legalLandDescription && noDivisions) {
      let r: Content[] = [];
      const secondTitleConnector = titleIndex > 0 || !titleOnly ? 'and ' : '';
      const being = titleOnly ? 'Being' : 'being';
      const titleTypeOption = titleTypeOptions[typeof titleType === 'string' ? titleType as 'CT'|'CR'|'CL' : 'CT'];

      r = [
        ...format(
          ` ${secondTitleConnector}${being} *${titlePortionPart}* of the land in ${titleTypeOption} Volume `
        ),
        lineIfEmpty(volume, FieldPlaceholderStyle.Amount),
        ' Folio ',
        lineIfEmpty(folio, FieldPlaceholderStyle.Amount),
        ...format(
          ` and being *${titlePortionPart}* of `
        ),
        ...format(title.legalLandDescription)
      ];
      return r;
    }

    // in this case, we'll use hundreds and area from the parcel rather than the address
    // we'll also group together parcels from the same plan to shorten the text
    if (title.descriptionOfLand && useDescriptionOfLand) {
      let r: Content[] = [];
      const secondTitleConnector = titleIndex > 0 || !titleOnly ? 'and ' : '';
      const being = titleOnly ? 'Being' : 'being';
      const titleTypeOption = titleTypeOptions[typeof titleType === 'string' ? titleType as 'CT'|'CR'|'CL' : 'CT'];
      r = [
        ...format(
          ` ${secondTitleConnector}${being} *${titlePortionPart}* of the land in ${titleTypeOption} Volume `
        ),
        lineIfEmpty(volume, FieldPlaceholderStyle.Amount),
        ' Folio ',
        lineIfEmpty(folio, FieldPlaceholderStyle.Amount)
      ];

      const parcelGroups: {[plan: string]: SaleSubTitle[]} = {};
      for (const sub of subs) {
        // deposited plan 12 whole allotment
        const plan = `${sub.plan} ${sub.planid}  ${sub.portionType} ${sub.lot}`;
        if (!parcelGroups[plan]) {
          parcelGroups[plan] = [];
        }

        parcelGroups[plan].push(sub);
      }

      r = r.concat(Object.keys(parcelGroups).map(key => {
        const parcels = parcelGroups[key];
        const totalParcels = parcels.length;

        if (totalParcels === 0) {
          return [];
        }

        const firstParcel = parcels[0];
        const multipleRelatedParcels = totalParcels > 1;
        const portionType = getAllotmentPortionType(firstParcel);

        const parcelsText = parcels.map((parcel, idx) => {
          const firstParcel = idx === 0;
          const lastParcel = idx === totalParcels - 1;
          const text = [];
          if (firstParcel) {
            const plural = multipleRelatedParcels ? 's' : '';

            text.push(
              // and being whole of allotment
              ...format(` and being *${portionType}* of *${parcel.lot ? parcel.lot + plural : '*' + Object.values(lotOptions).join(' / ')}* `),
            );
          } else {
            text.push(...format(lastParcel ? ' *and* ' : '*,* '));
          }

          text.push(lineIfEmpty(parcel.lotid, FieldPlaceholderStyle.Amount));

          return text;
        }).flat();

        const areaText = 'area' in firstParcel ? [' in the Area named ', lineIfEmpty(firstParcel.area, FieldPlaceholderStyle.Name)] : [];
        const hundredsText: string[] = [];
        if ('hundreds' in firstParcel && firstParcel.hundreds) {
          const inHundreds = firstParcel.hundreds.filter(h => !h.outOfHundreds);
          const outHundreds = firstParcel.hundreds.filter(h => h.outOfHundreds);
          const irrigationArea = firstParcel.hundreds.filter(h => h.irrigationArea);

          const totalOutHundreds = outHundreds.length;
          const totalInHundreds = inHundreds.length;
          const totalIrrigationArea = irrigationArea.length;

          hundredsText.push(
            ...outHundreds.map((hundred, idx) => {
              const first = idx === 0;
              const last = idx === totalOutHundreds - 1;
              const text = [];

              if (first) {
                text.push(' out of Hundreds (');
              } else {
                text.push(...format(last ? ' *and* ' : '*,* '));
              }

              text.push(...format(`*${hundred.name}*`));

              if (last) {
                text.push(') ');
              }

              return text;
            }).flat(),
            ...[totalOutHundreds > 0 && (totalInHundreds > 0 || totalIrrigationArea > 0) ? ' and ' : ''],
            ...inHundreds.map((hundred, idx) => {
              const first = idx === 0;
              const last = idx === totalInHundreds - 1;
              const plural = totalInHundreds > 1 ? 's' : '';
              const text = [];

              if (first) {
                text.push(` in the Hundred${plural} of `);
              } else {
                text.push(...format(last ? ' *and* ' : '*,* '));
              }

              text.push(...format(`*${hundred.name}*`));

              return text;
            }).flat(),
            ...[totalInHundreds > 0 && totalIrrigationArea > 0 ? ' and ' : ''],
            ...irrigationArea.map((hundred, idx) => {
              const first = idx === 0;
              const last = idx === totalOutHundreds - 1;
              const plural = totalInHundreds > 1 ? 's' : '';
              const text = [];

              if (first) {
                text.push(' in the ');
              } else {
                text.push(...format(last ? ' *and* ' : '*,* '));
              }

              text.push(...format(`*${hundred.name}*`));

              return text;
            }).flat(),
          );
        }

        return [
          ...parcelsText,
          ...insertIf(firstParcel.plan !== 'H', ...format(` on *${firstParcel.plan ? firstParcel.plan : '*' + Object.values(planOptions).join(' / ') + ' Plan'}* `)),
          ...insertIf(firstParcel.plan !== 'H', lineIfEmpty(firstParcel.planid, FieldPlaceholderStyle.Amount)),
          ...areaText,
          ...hundredsText
        ].filter(Predicate.isNotNull).flat();
      })).flat();

      return r;
    } else {
      let r: Content[] = [];
      const secondTitleConnector = titleIndex > 0 || !titleOnly ? 'and ' : '';
      const being = titleOnly ? 'Being' : 'being';
      const titleTypeOption = titleTypeOptions[typeof titleType === 'string' ? titleType as 'CT'|'CR'|'CL' : 'CT'];
      r = [
        ...format(
          ` ${secondTitleConnector}${being} *${titlePortionPart}* of the land in ${titleTypeOption} Volume `
        ),
        lineIfEmpty(volume, FieldPlaceholderStyle.Amount),
        ' Folio ',
        lineIfEmpty(folio, FieldPlaceholderStyle.Amount)
      ];

      r = r.concat(subs.map(st => {
        const portionType = getAllotmentPortionType(st);

        return [
          ...format(` and being *${portionType}* of *${st.lot ? st.lot : '*' + Object.values(lotOptions).join(' / ')}* `),
          lineIfEmpty(st.lotid, FieldPlaceholderStyle.Amount),
          ...insertIf(st.plan !== 'H', ...format(` on *${st.plan ? st.plan : '*' + Object.values(planOptions).join(' / ') + ' Plan'}* `)),
          ...insertIf(st.plan !== 'H', lineIfEmpty(st.planid, FieldPlaceholderStyle.Amount))
        ];
      }).flat());

      return r;
    }
  };
}

function getFirstTextNode(node?: Content): Content {
  if (Array.isArray(node)) {
    for (const listItem of node) {
      const nodeRes = getFirstTextNode(listItem);
      if (nodeRes) {
        return nodeRes;
      }
    }
  }
  if (Array.isArray(node?.text)) {
    return node;
  }

  let listing;
  if (Array.isArray(node?.stack)) {
    listing = node.stack;
  } else if (Array.isArray(node?.ol)) {
    listing = node.ol;
  } else if (Array.isArray(node?.table?.body)) {
    listing = node.table.body.flat().flat();
  }

  if (listing) {
    return getFirstTextNode(listing);
  }
}

export function propertySection(
  {
    itemNo,
    addresses,
    titles,
    titleDivision,
    isVariation = false,
    annexures = [],
    divisionPlanOptional = false
  }: {
    itemNo: number,
    addresses: SaleAddress[],
    titles: SaleTitle[],
    titleDivision: TitleDivision,
    isVariation?: boolean
    annexures?: Annexure[],
    divisionPlanOptional?: boolean
}
) {
  const addressContent = [];
  addresses = addresses || [{}];

  const unclaimedTitles = new Set(titles);
  let addressIdx = 0;
  const injectedBookmarks = new Set<string>();
  for (; addressIdx < (addresses.length||(titles.length?0:1)); addressIdx++) {
    const address = addresses[addressIdx] || {};
    let relatedTitles = titles.filter(t=>Array.isArray(t.linkedAddresses) && t.linkedAddresses.includes(address.id));
    if (relatedTitles.length === 0 && addresses.length === 1) {
      relatedTitles = titles;
    }
    if (relatedTitles.length === 0) {
      relatedTitles = [{ subTitles: [] }];
    }
    for (const title of relatedTitles) {
      unclaimedTitles.delete(title);
    }
    const { titleInclusionState, explicit } = determineTitleInclusionState(relatedTitles);
    if (titleInclusionState === TitleInclusionState.none) {
      // do not express the address here.
      // alternatively, we could express the address as "being *none of* the land situated at {description}"
      continue;
    }

    const emptyAddress = !address.streetAddr;

    const composedAddress = address.streetAddr && address.subStateAndPost ? address.streetAddr+', '+address.subStateAndPost : undefined;
    // Note that by extracting the text from this, it's possible for users to enter a suburb name that
    // is misspelt or doesn't exist, potentially reducing our data quality. It would be preferred to
    // enforce the use of a selected suburb from a list, but at the moment, we don't have an offline
    // suburb store. This would thus lock out offline use of this if we use the address.suburb version
    // which can only be set by a suburb dropdown.
    const { suburb } = canonicalisers.stateSubPost(address.subStateAndPost).components ?? {};
    const outOfHundreds = address.hundred === 'Out of Hundreds';
    const showIrrigation = address.irrigationArea && (!address.hundred || outOfHundreds);

    const allTitlesHaveDescriptionOfLand = relatedTitles.find(t => !t.descriptionOfLand || t.descriptionOfLand.length === 0) == undefined;

    let remainingTextContent: Content[] = [];
    if (allTitlesHaveDescriptionOfLand) {
      remainingTextContent = [
        ...relatedTitles.map(describeTitle(false, true)).flat()
      ].filter(Boolean);
    } else {
      remainingTextContent = [
        ...relatedTitles.map(describeTitle(false)).flat(),
        ' in the Area named ', lineIfEmpty(suburb, FieldPlaceholderStyle.Name),
        ...!showIrrigation
          ? outOfHundreds ? ` out of Hundreds (${address.suburb})` : [' in the Hundred of ']
          : format(` in the *${address.irrigationArea}* Irrigation ${address.irrigationArea === 'Renmark' ? 'District' : 'Area'}`),
        ...insertIf(!showIrrigation && !outOfHundreds, lineIfEmpty(address.hundred, FieldPlaceholderStyle.Location)), '.'
      ].filter(Boolean);
    }

    const propertyStack = [
      {
        lineHeight: 1.5,
        text: remainingTextContent,
        style: 'sectionText'
      }
    ];

    const propertyLineUnpositioned = freeTextContinuationArea(
      titleInclusionState === TitleInclusionState.portion
        ? format('Being *a portion of* the land situated at')
        : 'Being the land situated at',
      composedAddress||'',
      2,
      'sectionText',
      { lineHeight: 1.5 }
    );
    if (composedAddress) {
      remainingTextContent.splice(0,0,propertyLineUnpositioned);
    } else {
      propertyStack.splice(0,0,propertyLineUnpositioned);
    }

    if (emptyAddress || address.additionalDesc) {
      const additionalLine = freeTextContinuationArea(
        'Additional description of Property',
        address.additionalDesc??'',
        2,
        'sectionText',
        { lineHeight: 1.5 }
      );
      propertyStack.push({
        ...additionalLine,
        margin: [0,0,0,10]
      });
    }

    const attachedBookmarkText = [
      `field_focus_saleAddrs.[${address.id}]`,
      ...relatedTitles.filter(title=>title.id).map((title,idx)=>`field_focus_saleTitles.[${title.id}]`)
    ].filter(bm => !injectedBookmarks.has(bm));

    attachedBookmarkText.forEach(bm => injectedBookmarks.add(bm));
    const bookmarkEnds = attachedBookmarkText.map(bm=>`${bm}_!END`);

    addressContent.push({
      unbreakable: true,
      columns: [
        {
          width: '*',
          unbreakable: true,
          stack: [
            ...(address.useAdditionalDescAsLegalDesc ? [{ lineHeight: 1.5, text: address.additionalDesc, style: 'sectionText' }] : propertyStack),
            ...bookmarkEnds.map(bookmarkAnchor)
          ]
        },
        {
          width: 'auto',
          stack: attachedBookmarkText.map(bookmarkAnchor)
        }
      ]
    });
  }
  const unclaimedList = Array.from(unclaimedTitles);

  const stackType = (addresses.length + unclaimedList.length) > 1 ? 'ol' : 'stack';
  for (const title of unclaimedList) {
    const remainingTextContent = [
      ...[title].map(describeTitle(true)).flat()
    ];
    const propertyStack = [
      {
        lineHeight: 1.5,
        text: remainingTextContent,
        style: 'sectionText'
      }
    ];

    // We can get away with not putting the column at the end like the above property section
    // there is only one bookmark. If there are multiple bookmarks, we need to use the columns
    // approach above
    const bookmarkText = [`field_focus_saleTitles.[${title.id}]`];
    const bookmarkEnds = bookmarkText.map(bm=>`${bm}_!END`);
    getFirstTextNode(propertyStack)?.text?.splice(0,0,...bookmarkText.map(bookmarkAnchor));
    addressContent.push({
      unbreakable: true,
      stack: [
        ...spaceStackLinesSideEffect(propertyStack),
        ...bookmarkEnds.map(bookmarkAnchor)
      ]
    });
  }

  const { titleInclusionState, explicit } = determineTitleInclusionState(titles);
  const explicitPortions = explicit && titleInclusionState === TitleInclusionState.portion;

  const sectionContent = [
    {
      [stackType]: addressContent
    }
  ].filter(Predicate.isTruthy);
  if (explicitPortions && !titleDivision.depositChoice) {
    sectionContent.push({
      unbreakable: true,
      stack: [
        { text: [{ text: '☐', font: 'DejaVu', fontSize: 12 }, '  Select if the Property is a proposed lot in a Community Division that is yet to deposit'] },
        { text: [{ text: '☐', font: 'DejaVu', fontSize: 12 }, '  Select if the Property is a proposed allotment in a Land Division that is yet to deposit'] }
      ]
    });
  } else if (explicitPortions && titleDivision.depositChoice && [DivisionType.Community, DivisionType.Torrens].includes(titleDivision.depositChoice)) {
    // We're relying on UUIDs being unique, and that the annexures we see are for this form only.
    // Because of this, even if we search for annexure IDs from different forms, we should only
    // match the one in this annexure set. As such we don't need to refer to the form code to find
    // the matching annexure
    const attachedAnnexure = titleDivision.plan ? find(annexures, annex => annex.id === titleDivision.plan.id) : undefined;
    const annexureLabel = attachedAnnexure?.label ? `*Annexure ${attachedAnnexure?.label}*` : 'Annexure __';
    const annexureName = attachedAnnexure && 'name' in attachedAnnexure && attachedAnnexure.name ? attachedAnnexure.name : uploadTypeOpts[UploadType.PropertyPlan];

    const isCommunityDiv = titleDivision.depositChoice === 'commDiv';
    // Proposed Lot(s) [insert reference inserted] in proposed Community Plan, as set out at Annexure [insert
    // "Proposed Allotment(s) [insert reference inserted] in proposed Deposited Plan, as set out at Annexure [insert]"
    const type = isCommunityDiv ? 'Lot' : 'Allotment';
    const reference = titleDivision.proposedLots ? `*${titleDivision.proposedLots}*` : '__________';

    const textToFormat = [
      `Proposed ${type}(s) ${reference} in proposed ${isCommunityDiv ? 'Community Plan' : 'Land Division'}`,
      attachedAnnexure || !divisionPlanOptional
        ? `as set out at ${annexureLabel} - ${annexureName}`
        : undefined
    ].filter(Predicate.isNotNull).join(', ');

    sectionContent.push({
      unbreakable: true,
      stack: [
        {
          text: format(textToFormat),
          style: 'sectionText'
        }
      ]
    });
  }

  if (titles.length === 0 && addresses.some(a => !a.useAdditionalDescAsLegalDesc)) {
    sectionContent.push({ text: '* select the applicable option', margin: [0,6,0,0] });
  }

  return itemSection({
    itemNo: itemNo,
    itemTitleParam: 'PROPERTY',
    bookmark: ['bookmark_property', 'subsection-property'],
    stackContent: itemSubsection({ subsectionTitle: undefined, titleLineContent: undefined, subsectionContent: sectionContent, unbreakable: false }),
    isVariation: isVariation
  }
  );
}

export function fieldFocus(...fields: string[]) {
  return fields.map(str => `field_focus_${str}`);
}

export function fieldFocusMap(map: {[lead:string]: string[]}) {
  return fieldFocus(...Object.entries(map||{}).map(([prefix,suffixes])=>suffixes.map(sf=>`${prefix}.${sf}`)).flat());
}

export function standardPageMargins(currentPage: number, useCoverPage: boolean) {
  const pageNum = useCoverPage ? currentPage - 1 : currentPage;
  return pageNum > 1 ? SUBSEQUENT_PAGE_MARGIN : FIRST_PAGE_MARGIN;
}

export function landTypeAsString(landType?: LandType | string) {
  if (!landType) return '';
  if (typeof landType === 'string') return '';
  return (Object.keys(LandType) as (keyof typeof LandType)[])
    .find(name => LandType[name] === landType) || '';
}

export function getDocumentTitle(label: string, landType?: LandType | string) {
  return [label, landTypeAsString(landType)]
    .filter(Predicate.isTruthy)
    .join(' - ');
}

export function timestampOfAgreement(completedAtMs?: number, parties?: SigningParty[]): number {
  if (completedAtMs) return completedAtMs;
  if (!parties?.length) return 0;

  const partyTimestamps = parties.map(p => p.signedTimestamp || 0);
  return Math.max(...partyTimestamps);
}

export function saaCommencementDate(completionCandidates: { completedAtMs: number, otherStart: boolean, otherStartDate: string }) {
  const { otherStartDate, otherStart, completedAtMs } = completionCandidates;
  return new Date(otherStart && otherStartDate != null ? otherStartDate : completedAtMs);
}

export function dateOfExpiryRelativeToCommencement(commencementCandidates: { completedAtMs: number, otherStart: boolean, otherStartDate: string }, timeZone: string, agencyDuration?: number | string) {

  const days = ((duration?: string | number) => {
    switch (typeof duration) {
      case 'number':
        return duration > 0
          ? duration
          : undefined;
      case 'string': {
        const canon = canonicalisers.days(duration);
        if (!canon.valid) return undefined;
        const parsed = parseInt2(canon.canonical);
        if (!parsed || isNaN(parsed)) return undefined;

        return parsed > 0
          ? parsed
          : undefined;
      }
      default:
        return undefined;
    }
  })(agencyDuration);

  if (!days) return '';

  const commencementDate = saaCommencementDate(commencementCandidates);
  // -1 because we need the last valid day, not the first invalid day
  return formatTimestamp(commencementDate.setDate(commencementDate.getDate() + days - 1), timeZone, false);
}
