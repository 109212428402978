import { $getRoot, $getSelection, RootNode } from 'lexical';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { CodeNode } from '@lexical/code';
import { LinkNode } from '@lexical/link';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { ToolbarPlugin } from './lexical/ToolbarPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { $generateNodesFromDOM, $generateHtmlFromNodes } from '@lexical/html';
import {
  TRANSFORMERS,
  $convertFromMarkdownString,
  $convertToMarkdownString
} from './lexical/lexical-markdown/src';
import { ListNode, ListItemNode } from './lexical/lexical-list/src';
import { UNDERLINE } from './lexical/UnderlineTransformer';
import { ListPlugin } from './lexical/LexicalListPlugin';
import ListMaxIndentLevelPlugin from './lexical/ListMaxIndentLevelPlugin';
import { DisabledPlugin } from './lexical/DisabledPlugin';
import './RichTextEditor.scss';
import clsJn from '@property-folders/common/util/classNameJoin';
import { INDENT } from './lexical/IndentTransformer';
import React, { memo } from 'react';
import { LexicalMsWordPasteInterceptorPlugin } from './lexical/LexicalMsWordPasteInterceptorPlugin';
import { ReplacementTokenNode, ReplacementTokenPlugin } from './lexical/replacement-token-plugin';
import { ReplacementToken } from '@property-folders/common/util/process-template';

export enum EditorMode {
  MARKDOWN = 'MARKDOWN',
  HTML = 'HTML'
}

type RichTextEditorProps = {
  namespace: string
  onUpdate?: (output: string) => void;
  className?: string;
  value?: string;
  disabled?: boolean;
  onFocus?: (e: Event) => void;
  onBlur?: (e: Event) => void;
  outputMode: EditorMode,
  inputMode?: EditorMode
  style?: React.CSSProperties
  contentEditableStyle?: React.CSSProperties;
  toolbar?: () => JSX.Element;
  tokens?: Set<ReplacementToken>
};

const transformers = [INDENT, ...TRANSFORMERS, UNDERLINE];

function wrap(el: ChildNode, wrapper: Element) {
  if (!el.parentNode) {
    return;
  }

  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

function getTextNodesIn(node: ChildNode, includeWhitespaceNodes = false) {
  const textNodes: ChildNode[] = [], whitespace = /^\s*$/;

  function getTextNodes(node: ChildNode) {
    if (['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(node.nodeName)) {
      return;
    }

    if (node.nodeType == 3) {
      if (includeWhitespaceNodes || !whitespace.test(node.nodeValue ?? '')) {
        textNodes.push(node);
      }
    } else {
      for (let i = 0, len = node.childNodes.length; i < len; ++i) {
        getTextNodes(node.childNodes[i]);
      }
    }
  }

  getTextNodes(node);
  return textNodes;
}

function wrapTextInParagraphTags(domParser: DOMParser, text: string): Document {
  const document = domParser.parseFromString(
    text,
    'text/html'
  );

  const textNodes = getTextNodesIn(document.body);
  for (const node of textNodes) {
    wrap(node, document.createElement('p'));
  }

  return document;
}

export const RichTextEditorComponent = (props: RichTextEditorProps) => {
  const editable = !props.disabled ?? true;
  const domParser = new DOMParser();
  const inputMode = props.inputMode ?? props.outputMode;
  const { outputMode } = props;

  const onFocus = (e: Event) => {
    if (typeof props.onFocus === 'function') {
      props.onFocus(e);
    }
  };
  const onBlur = (e: Event) => {
    if (typeof props.onBlur === 'function') {
      props.onBlur(e);
    }
  };

  const initialConfig: InitialConfigType = {
    namespace: props.namespace,
    theme: {
      text: {
        bold: 'font-semibold',
        underline: 'font-underline',
        italic: 'font-italic',
        strikethrough: 'font-line-through',
        underlineStrikethrough: 'underlined-line-through'
      }
    },
    onError: error => {
      console.error(error);
    },
    nodes: [
      HorizontalRuleNode,
      HeadingNode,
      LinkNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      ReplacementTokenNode
    ], // not all are allowed, but MD plugin requires them.
    editorState: editor => {
      if (!props.value) {
        return;
      }

      if (inputMode === EditorMode.HTML) {
        // gotta bind text to a text node
        const dom = wrapTextInParagraphTags(domParser, props.value);
        editor.update(() => {
          const nodes = $generateNodesFromDOM(editor, dom);
          $getRoot().select();
          $getSelection()?.insertNodes(nodes);

          if (inputMode !== outputMode) {
            props.onUpdate?.($convertToMarkdownString(transformers));
          }
        });
      } else {
        $convertFromMarkdownString(props.value ?? '', transformers);

        if (inputMode !== outputMode) {
          editor.getEditorState().read(() => {
            props.onUpdate?.($generateHtmlFromNodes(editor));
          });
        }
      }
    },
    editable
  };

  return <div className={clsJn('rich-text-editor', props.className)} style={props.style}>
    <LexicalComposer initialConfig={initialConfig}>
      <NodeEventPlugin
        nodeType={RootNode}
        eventType='focus'
        eventListener={onFocus}
      />

      <NodeEventPlugin
        nodeType={RootNode}
        eventType='blur'
        eventListener={onBlur}
      />

      <LexicalMsWordPasteInterceptorPlugin transformers={transformers} />

      {editable
        ? props.toolbar
          ? props.toolbar()
          : <ToolbarPlugin />
        : <></>}

      <RichTextPlugin
        contentEditable={<ContentEditable
          className='rte-content-editable form-control'
          key={props.namespace}
          style={props.contentEditableStyle}
        />}
        placeholder={<div className='rte-placeholder'>Enter some text...</div>}
        ErrorBoundary={LexicalErrorBoundary}
      />

      <MarkdownShortcutPlugin transformers={transformers} />

      <OnChangePlugin
        onChange={(editorState, editor, _tags) => {
          editorState.read(() => {
            if (typeof props.onUpdate === 'function') {
              switch (props.outputMode) {
                case EditorMode.HTML:
                  props.onUpdate($generateHtmlFromNodes(editor));
                  break;
                case EditorMode.MARKDOWN:
                  props.onUpdate($convertToMarkdownString(transformers));
                  break;
              }
            }
          });
        }}
      />

      <HistoryPlugin />
      <ListPlugin />
      <ListMaxIndentLevelPlugin maxDepth={4} />
      <TabIndentationPlugin />
      <DisabledPlugin disabled={props.disabled} />
      <ReplacementTokenPlugin tokens={props.tokens} />
    </LexicalComposer>
  </div>;
};

export const RichTextEditor = memo(RichTextEditorComponent);
